import React,{useState} from 'react'
import { Header } from './Header.jsx'

export const Content = (props) => {
    const typeAhead =() => {}
    const back =() => props.back('parcel')
    const [Load,setLoad] = useState('')
  return (
  <>
     <Header name="Parcel Details" back ={back} bottomBtn="no" Bar={'no'} inputFocus='no'  Placeholder="Search for area,street name..." typAhead={typeAhead} show={'props.show'} Loading={Load} >


</Header>
    
  </>
  )
}

import React, {useEffect ,useState} from 'react'

import { FaUserAlt } from "react-icons/fa";
import { RiPhoneFill } from "react-icons/ri";
import { GoLocation } from "react-icons/go";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBinLine } from "react-icons/ri";
import './DeliveryAdd.css'
import axios from 'axios';

export const DeliveryAdd = (props) => {

const [address,setAddress] = useState('')

useEffect (() => {

const fetch =async() => {

const response = await axios(`https://www.easy-ship.in/delivery_allAdd.php?custcode=7814002400`)
if(response.data)
{
  setAddress(response.data)
  console.log(response.data)
  }
}
if(!address)
fetch()

})

  return (
   <>

{address?"": <>
  <div className="card-body">
    <div className="avatar-placeholder placeholder rounded-circle"></div>
    <div className="text-placeholder placeholder w-50"></div>
    <div className="text-placeholder placeholder w-75"></div>
</div>
   
<div className="card-body">
    <div className="avatar-placeholder placeholder rounded-circle"></div>
    <div className="text-placeholder placeholder w-50"></div>
    <div className="text-placeholder placeholder w-75"></div>
</div>
</>}


 {address ? address.map((x) => <div> 
<div className='addCon user-select-none p-0 position-relative'>
    <div className='position-relative p-2 d-none'><GoLocation size="24"/>
    {/* <div className='activeLoc' ><BsCheckCircleFill color="#008000" size="0"/> </div> */}
    </div>
    
    <div className='addreess w-100 pe-4'>
        <div className='title'>{x.company}</div>
   
    <div className='addDetail pe-3'>{x.add1},{x.add2}</div>
    
    <div className='addDst'>{x.pincode}-{x.city},<span className='ps-1'>{x.state}</span><span className='ps-'>{x.country}</span></div>
    <div className='PersonDetail pe-3'>
<div className=' PersonalFont text-nowrap ps-0'><FaUserAlt size="12" color="gray"/><div className='personGap text-truncate' style={{width:'120px'}}>{x.name}</div> </div>
<div className='  PersonalFont text-nowrap'><RiPhoneFill size="16" color="gray"/><div className='personGap'>+{x.cnt_code}<span className='ps-1'>{x.receiver}</span></div></div>
</div>
 </div>
 <div className='d-flex flex-column justify-content-center  delete align-item-center m-0'>
     {/* <div className='py-2'><AiOutlineEdit size='18' /></div> */}
     <div className='d-flex '><RiDeleteBinLine size='18'  color='white' /></div>
 </div>
    </div>
    <hr className=' px-0 divider my-2 '/>
    </div>):''}
   </>
  )
}

import React, { useEffect, useState,Suspense, useRef } from 'react'
import './Label.css'
import { Barcode } from './Barcode';
import {useParams,useNavigate} from 'react-router-dom'

import { jsPDF } from "jspdf";

export const Label = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const lbl = useRef()
  const {custId} = useParams()

let a=0;


  const [label,setLabel] = useState('')

const download = async() => {
 
  const input = lbl.current
  const pdf = new jsPDF('p', 'pt', 'a4');

 pdf.html(input).then(() => {
        pdf.save('ShipLabel.pdf');
        window.close() ;
      });
} 

useEffect(() => {
  console.log(props.user)
fetch(`https://queekdelever.com/queekApi/shipLabel.php?custId=${custId}`)
.then(res => res.json())
.then(data => {
  if(data.length > 0)
{  setLabel(data)
  setTimeout(() => {download()},100) }
  else
  {  window.close() ;}

})
},[])

  return (
    
    <>

      
<Suspense fallback={<div>Loading...</div>}>
    <div className="page " ref={lbl}  id='pdf-element' >
   
        {/* top label */}
        
      <div  className="d-flex">
        <div className='row p-0 m-0'>
        {/* barcode label 1 */}
      < >
      { label && label.map((x) =>     <div key={a= a+1} className='lblFormat '>
          <div className='subpage '>
          { <div className='borderSqu'>
            <div className='pt-1 labelTxt ps-2'>{x.fName}</div>
             <div className='twoLine labelTxt ps-2'>{x.fAdd}</div>
              <div className='labelTxt ps-2'>{x.fCty}-{x.fPincode}</div>

            <div className='btmBorder my-1'></div>

          <div className=''>
          <div className='ms-1 delAdd mb-1'>Shipping Address</div>
         {x.tCompany &&   <div className='ps-2  '><span className='p-0'><b>{x.tName} </b> </span></div>}
            <div className='ps-2 lblAds text-capitalize'><span className='p-0'><b>{x.tCompany ? x.tCompnay : x.tName}</b></span></div>

            <div className='lblAds ps-2 text-capitalize my-1'>{x.tadd}</div>
            {/* <div ><>{x.tcity} {x.tpincode}</></div> */}
            <div className='lblAds pt-1 ps-2'> +{x.cntCode} {x.mob} </div>

            <div className='dstCitty ps-2 pt-1'><b>{x.tcity}  {x.tpincode}</b></div>
            </div>
             <div className='btmBorder'></div>
            <div className='d-flex justify-content-between py-2 px-2'>
            <div className='fs-6'><b>PRE-PAID</b></div>
            <div className='fs-6'><b>{x.network ==='Ground'?'Surface':x.network}</b></div>
              </div>

              <div className='btmBorder'></div>
              <div  className='mt-2 d-none'>TRACKING NO #: {x.awb}</div>
        <div className='d-flex justify-content-center my-2 '> <Barcode awb ={x.awb} /> </div>

              {/* <div className='pt-2 labelTxt ps-2'>SHIP DATE: 07 OCTOBER 2022</div> */}
              <div className='btmBorder'></div>
           <div className='col-12 check-add text-dark topBd'>
           <div className='row'>
           <div className='delAdd col-6'>Product</div>
            <div className='delAdd col-3 borderLeft'>Price</div>
            <div className='delAdd col-3 borderLeft'>Total</div>
            </div>
           
             <div className='btmBorder'></div>
            
              <div className='row'>
            <div className='delAdd col-6'>{x.content}</div>
            <div className='delAdd col-3 borderLeft'>{x.decValue}</div>
            <div className='delAdd col-3 borderLeft'>{x.decValue}</div>
           </div>
           </div>
              {/* <div className='btmBorder'></div> */}

<div className='retrunAdd text-dark p-1' >
  Return & Pickup Address: SRA CORPORATION,Chandigarh Road, Jamalpur Awana, Ludhiana, Punjab 140 010
</div>
          </div>
           }

          {/* {x.network === 'FOA' && <div className=' borderSqu'>
          <div className='d-flex justify-content-center my-2 '> <Barcode awb ={x.fwdNo} width={4} /> </div>
          <div className='d-flex justify-content-between px-2'>
            <div>{x.tpincode}</div>
            <div className=''><div className='d-flex justify-content-end'><b>{x.sortCode}</b></div></div>
            </div>
          <div className='btmBorder'></div>
          <div className='d-flex justify-content-between ps-2 pb-2 bg-light'>
          <div className='d-flex flex-column '>
            <div className='delAdd'>Shipping Address</div>
            <div className='check-add text-dark '><><b>{x.tName}</b></></div>
            <div className='delAdd text-dark text-lowercase'>{x.tadd}</div>
            <div className='labelTxt delAdd text-dark text-lowercase'>{x.destCode}</div>
            <div className='labelTxt'><b>PIN: {x.tpincode}</b></div>
          </div>
          <div className='d-flex flex-column px-2 align-items-center justify-content-center borderLeft bg-white'>
            <div><b>Pre-paid</b></div>
            <div><b>Surface</b></div>
          </div>
          </div>
           
          <div className='btmBorder'></div>

          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex flex-column ps-2'>
            <div className='delAdd '>Seller: {x.fName}</div>
            <div className='delAdd '>Address: {x.fAdd}</div>
            </div>
            <div className='delAdd text-dark pe-2'>Dt: {x.bkDate}</div>
          </div>
           <div className='btmBorder'></div>
           <div className='col-12 check-add text-dark topBd'>
           <div className='row'>
           <div className='delAdd col-6'>Product</div>
            <div className='delAdd col-3 borderLeft'>Price</div>
            <div className='delAdd col-3 borderLeft'>Total</div>
            </div>
           
             <div className='btmBorder'></div>
            
              <div className='row'>
            <div className='delAdd col-6'>{x.content}</div>
            <div className='delAdd col-3 borderLeft'>{x.decValue}</div>
            <div className='delAdd col-3 borderLeft'>{x.decValue}</div>
           </div>
           </div>

           <div className='d-flex justify-content-center my-2 '> <Barcode awb ={x.awb} width={5} /> </div>

           
 <div className='btmBorder'></div>

<div className='retrunAdd text-dark p-1' >
  Return Address: SRA CORPORATION, COURIER OFFICE,Chandigarh Road, Jamalpur Awana, Opp. Kutia Sahib Gurudwara,Ludhiana, Punjab 140 010
</div>
          </div>} */}

        </div> 
        </div>
        ) } 
      
     
        </>
        </div>
            </div>

            {/* __________________________________________________________________________ */}

          
    </div>
  

</Suspense>

    </>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import { BsChevronLeft } from "react-icons/bs";
import { FaUserAlt } from "react-icons/fa";
import { FaPaste } from "react-icons/fa";
import { Header } from '../../Components/Header.jsx';
import { NormalButton } from '../../Components/NormalButton.jsx';
import Drawer from "react-bottom-drawer";
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown'
import { SpinnerRoundFilled } from 'spinners-react';
import './DeliveryForm.css'
import { RateList } from './RateList.jsx';
import { Spinner } from '../../Components/Spinner.jsx';

export const DeliveryForm = (props) => {
  const stripchar = require('stripchar').StripChar;

  const [isVisible, setIsVisible] = React.useState();
  const [booking,setBooking] = React.useState([])
  const name =useRef(null)
  const company = useRef(null)
  const mobile = useRef(null)
  const add = useRef(null)
  const zipcod = useRef(null)
  const country = useRef(null)
const [nameErr,setNameErr] = useState('')
const [comErr,setComErr] = useState('')
const [mobErr,setMobErr] = useState('')
const [addErr,setAddErr] = useState('')
const [pinErr,setPinErr] = useState('')
const [cntCode, setCntCode] = useState('91')
const [hide,setHide] = useState(true)
const [intCIty, setIntCity] = useState('')
const [city,setCity] = useState('')
const [Label,setLabel] = useState(false)
const [validCity,setValidCity] = useState('')
const cityName = useRef(null)
const zip = useRef(null)
const [load,setLoader] = useState(false)
const [zp,setZp] = useState(1)
const [caption,setCaption] = useState('')
const [wait, setWait] = useState(false)
const [remote,setRemote] = useState('')
const [inital,setInital] = useState(1)
const [select,setSelect] = useState('1')
const [rateLoader,setRateLoader] = useState(false)
const [farAway,setFarAway] = useState(props.booking)
const [paste,setPaste] = useState(false)
const copytoClipboard = useRef(null)
const [isHide,setIsHide] = useState(false)
const [remoteErr,setRemoteErr] = useState('form-control')
const rem = useRef(null)
const [loader,setLodd] = useState(false)

const [extCharger,setExtCharges]= useState()
const [amt,setAmt] = useState()

let a=0;
let b=0;

const selectRate =(e) => {
  setAmt(remote[e-1].rate)
  setSelect(e) 
  setInital('')}

useEffect(() => {
const fetch = async() => {
  setLodd(true)
const  response = await axios.get(`https://queekdelever.com/queekApi/cntCode.php?name=${props.booking[0].country}`)
  if(response.data)
  {
  
    setCntCode(response.data[0])
   setZp(response.data[0].zp)
   if(response.data[0].zp > 0)
   setCaption('Search pincode/zipcode')
   else
   setCaption('Search city name')
   setLodd(false)
  }
}
// if(!cntCode)
 fetch()
name.current.value =''
company.current.value=''
add.current.value=''  

if(props.booking[0].dstLocation === props.booking[0].country)
{
  zipcod.current.value =''
}


if(props.action === 'edit')
{
 name.current.value= props.booking[0].name
  company.current.value= props.booking[0].company
  mobile.current.value = props.booking[0].receiver.trim()
  // setCntCode(props.booking[0].cntCode)
  if(props.booking[0].add1 === props.booking[0].add2)
  add.current.value = props.booking[0].add1
  else
  add.current.value = props.booking[0].add1+' ,'+props.booking[0].add2
  zipcod.current.value = props.booking[0].pincode
  setLabel(true)
  setCity(props.booking[0].country === 'India' ? props.booking[0].city : (props.booking[0].city +', ' + props.booking[0].state && props.booking[0].state))
}
else {
name.current.value =''
company.current.value=''
add.current.value=''
if(props.booking[0].country !== 'India')
zipcod.current.value = ''
setCity(props.booking[0].country === 'India' ? props.booking[0].city : (props.booking[0].city +', ' + props.booking[0].state && props.booking[0].state))
// setCity('')
// zipcod.current.value=''
}


},[])


    const back =() => {
      if(props.action ==='edit')
     return props.back('delivery')
      
      if(props.booking[0].content)
      {props.back('close')
      props.cart(props.booking[0].user)
    }
      else
        props.back('parcel')
    }

    const onClose = React.useCallback(() => {
      setIsVisible();
    }, []);

    const checkoutt = () => {
      addAddress()
      // props.checkout('checkout')
    }


    const fetchRemote =() => {
      console.log('fetchRemote')
      fetch(`https://queekdelever.com/queekApi/RemoteArea.php?zipcode=${zipcod.current.value}&cc=${cntCode['code']}`)
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if(data.length > 0)
       { 
        setIsHide(true)
        RemoteRaes()
      }
        else
        {
          setRemote(null)
          addAddress()
        }

      })
    }


    const RemoteRaes =() => {
      console.log('remoteRaes')
      let type =  props.booking[0].content === 'document' ? 'dox' : 'ppx'
       fetch(`https://www.queekdelever.com/queekApi/domestic_quote.php?swgt=${props.booking[0].wgt}&pincode=${props.booking[0].country}&type=${type}&zp=${zipcod.current.value}`)
      .then(response => response.json())
      .then(data => 
      {  setRemote(data)
        setIsHide(true)
        setRateLoader(true)
        setAmt(data[0].rate)
      })
      }
    
    const submit =() => {
 if(name.current.value === '' || name.current.value.length <3)
      {
       return setNameErr('is-invalid')
      }
      else {  setNameErr('form-control')}

      if(add.current.value === '' || add.current.value.length <3)
      {
     return setAddErr('is-invalid') 
      
      }
      else{setAddErr('form-control')}
  
    if (props.booking[0].country ==='India')
     { if(mobile.current.value.length !== 10)
      {
      return  setMobErr('is-invalid')
      }
      else{setMobErr('form-control')}}
      else {
        if(mobile.current.value.length < 5)
      {
        return setMobErr('is-invalid')
      }
      else{setMobErr('form-control')}
      }

    
      if(zipcod.current.value === '' )
      {
        if(zp > 0  && city ==='')
        return setPinErr('is-invalid') 
          else
     return setPinErr('is-invalid') 
      
      }
      else{setPinErr('form-control')}

      // fetchRemote()
    
      addAddress()
    
    }

 const addAddress =() => {

  if(name.current.value !== '' || name.current.value.length >2)
  if(add.current.value !== '' || add.current.value.length > 2 )
  if(mobile.current.value.length > 5 )
  if(zipcod.current.value !== '')
  {
    addDeliveryAdd()
    }
}

const addDeliveryAdd = async() => {

 if(city.includes(','))
    {
      const city1 =(city.substring(0,city.indexOf(',')))
      const state1 = ((city.substring(city.indexOf(',')+1)))
      const response = await axios.get(`https://www.queekdelever.com/queekApi/updateDeliverAdd.php/updateDeliverAdd.php?mob=${mobile.current.value}&name=${name.current.value}&company=${company.current.value}&add1=${add.current.value}&pincode=${zipcod.current.value}&city=${city1}&state=${state1}&cntname=${[props.booking[0].country]}&loccd=${cntCode['code']}&isd_no=${cntCode['cntCode']}`)
      if(response.status ===200)
      {      setWait(true)
        if(props.action==='edit')
        return props.back('delivery')
          else
          {
            addNew()
             props.back('checkout')
             props.finalCheck(false)
              return
          }

      }
    }
    else
    {
    
      const response = await axios.get(`https://www.queekdelever.com/queekApi/updateDeliverAdd.php/updateDeliverAdd.php?mob=${mobile.current.value}&name=${name.current.value}&company=${company.current.value}&add1=${add.current.value}&pincode=${zipcod.current.value}&city=${city}&cntname=${[props.booking[0].country]}&loccd=${cntCode['code']}&isd_no=${cntCode['cntCode']}`)
      if(response.status ===200)
      {      setWait(true)
        if(props.action==='edit')
        return props.back('delivery')
          else
        { 
          addNew() 
           props.back('checkout')
           props.finalCheck(false)
           return
        }
        }}
}


const addNew =async () => {

  const add = await axios.post(`https://www.queekdelever.com/queekApi/addShipment.php?sender=${props.booking[0].user}&receiver=${mobile.current.value}&rate=${amt ? amt : props.booking[0].rate}&service=${props.booking[0].service}&wgt=${props.booking[0].wgt}&content=${props.booking[0].content}&lat=${props.booking[0].lat}&lng=${props.booking[0].lng}&shipType=${props.booking[0].shipType}&pickId=${props.booking[0].pickBoy}&empId=${props.empId}&km=${( props.booking[0].km)/1000}&type=${props.booking[0].type}`)
}

    const inputChange =() => {
      setNameErr('form-control')
      setComErr('form-control')
      setMobErr('form-control')
      setAddErr('form-control')
      setPinErr('form-control')
      name.current.value = name.current.value.length > 0 ? stripchar.RSExceptUnsAlpNum(name.current.value) :''
      company.current.value =  company.current.value.length > 0 ? stripchar.RSExceptUnsAlpNum(company.current.value) : ''
      // add.current.value = add.current.value.length > 0  ?  stripchar.RSExceptUnsAlpNum(add.current.value) : ''
      specialChar(add.current.value)
      }

      const specialChar =(e) => {
        let  string = e.replace(/[&\\\#+()$~%'":*?<>{}]/g,'');
         add.current.value = string
        }
    

    const  fetchZip = async(e) => {
      
   
const response = await axios.get(`https://queekdelever.com/queekApi/zipcodeInt.php?cnt=${cntCode['code']}&zipcode=${e}`)

setLoader(true)

if(response.data !=='notFound')
{
  if(e.length >2 )
{
  setHide(true)
  setIntCity(response.data)
  console.log(response.data)
  setLoader(false)
}
else {setHide(false)
  setLoader(false)
  setIntCity('')}
}
else { 
  setIntCity('')
  setHide(false)
}

}

const fetchCanada =(e) => {
  // console.log(e)
    // fetch(`https://queekdelever.com/queekApi/caPostcode.php?zp=L2E1N8`)
}


    const zipcode =(e) => {
      
    if(props.action === 'edit')
    {if(props.booking[0].country === 'India')
    {return}}
    
    if(cntCode['code'] === 'CA')
    {
  return  fetchCanada(e)
    }

      zip.current.value =''
    setIntCity('')
    setTimeout(() => {zip.current.focus()},200)
    setHide(false)
      
    }


  const showCode =() => setLabel(true)
    const hideCode =() =>{ 
      if(mobile.current.value.length >0)
      setLabel(true)
      else
      setLabel(false)
    }
  
    const typAhead =(e) => {
     
      const fetch = async () => {
        let response=''
 if(zp > 0)
 {
  response = await axios.get(`https://queekdelever.com/queekApi/zipcodeInt.php?cnt=${cntCode['code']}&zipcode=${e.target.value}`)
 }
 if(zp === '0')
 {
  response = await axios.get(`https://queekdelever.com/queekApi/cityInt.php?cnt=${cntCode['code']}&city=${e.target.value}`)
 }
       
if(response.data !=='notFound')
      { 
        if (cntCode['code'] === 'GB')
        setIntCity(response.data.result)
        else
        setIntCity(response.data)
setLoader(false)
      }
      else {
        setLoader(false)
      }
  }
  if(e.target.value.length > 0)
  {
    setLoader(true)
    fetch()

}

  }
  const back1 =() => setHide(true)
  const getCity =(e,f,g,h) => {

    if(zp > 0)
   {zipcod.current.value =e
    if(g)
    setCity(f+', '+g)
    else
    setCity(f)
    setHide(true)}
    if(zp === '0')
    {
      setCity(f)
      setHide(true)
      if(h)
      zipcod.current.value =f +', '+h
      else
      zipcod.current.value =f 
    }
  }

   const fetchCity =(e) => {

    if(cntCode['code'] == 'CA')
    {
      if(e.length === 6)
      {
        setLodd(true)
        fetch(`https://queekdelever.com/queekApi/caPostcode.php?zp=${e}`)
      .then(response => response.json())
      .then(data => {setCity(data[0].city+ ', '+ data[0].state )
      setLodd(false)
    })}
    }
    if(e.length === 6)
    {
      fetch(`https://queekdelever.com/queekApi/check_pincode.php?pincode=${e}`)
    .then(response => response.json())
    .then(data => { 
    setCity(data[0].city)
    })
    }
   }


   const extractAdd = async () => {
    let ads = await navigator.clipboard.readText();
    name.current.value =ads
    setPaste(true)

  // ads='Dinesh Sharma, C/O IT Warehouse Tower C, Building No.3, Amdocs Development Center LLP, Gurgaon SEZ Unit, Gurgaon Infospace Ltd, Vill Dundahera Sector 21, Gurugram, Haryana 122016'
     ads = ads.replace(/\n/g,"~");
     ads = ads.replace(/, /g,"~");
     fetch(`https://queekdelever.com/queekApi/easy_surname.php?city=${ads}`)
     .then(response => response.json())
     .then(data =>{
      console.log(data)
      name.current.value =data[0].full_name
      company.current.value =data[0].company
      if(!data[0].full_name)
      name.current.value =data[0].company
      add.current.value =data[0].full_address
      mobile.current.value =data[0].tel
      setLabel(true)
      setPaste(false)
     })
  }

  const remoteAmount =() => {
    let chg = rem.current.value
    if(chg ==='' || chg <= 0 )
    setRemoteErr('form-control is-invalid')
    else
   { setRemoteErr('form-control')

   let charges = parseFloat(props.booking[0].rate) + parseFloat(chg)
   setExtCharges(charges)
    addAddress()
    
  }

  }

  return (
  <>
<div className={` ${hide ? 'd-none' :'d-block'}`}>
<div className={`mt-2 ZipSerach d-flex  align-items-center`}><span className="px-2 pe-4 back" onClick={back1}><BsChevronLeft size="18"/></span> <input ref={zip} onChange={typAhead} className='searchInput' placeholder= {caption} /> 
{load ? <span className='px-2 pe-2'>
<div className="clearfix">
  <div className="spinner-border float-right spinner" role="status">
    <span className="sr-only"></span>
  </div>
</div>

</span>:""}
</div>
<hr className='divider mt-2'/>
<ul className= {`list-group list-group-flush mt-2 `} >

{ intCIty && cntCode['code'] !=='GB'   &&  intCIty.map((x) =>  <li key={b=b+1}  onClick={()=>{getCity(x.postalCode,x.city,x.countryDivisionName,x.suburb)}} className="list-group-item listZip">{zp === '0' ? x.city ? x.city :x.city : x.postalCode+', '+ x.city}</li> ) }
{/* {intCIty && cntCode['code'] ==='CA'   ? intCIty.map((x) =>  <li key={b=b+1}  onClick={()=>{getCity(x.pincode,x.city,x.state)}} className="list-group-item listZip">{ x.pincode+', '+ x.city}</li> ):""} */}
{intCIty && cntCode['code'] ==='GB' ? intCIty.map((x) =>  <li key={b=b+1}  onClick={()=>{getCity(x.postcode,x.admin_district)}} className="list-group-item listZip">{ x.postcode+', '+ x.admin_district}</li> ):""}

</ul>


</div>

  <div className={`user-select-none ${hide ? 'd-block':'d-none' } `}>
 <div className={`d-flex justify-content-between parcelHeader`}><div className="px-2 pe-4 back" onClick={back}><BsChevronLeft size="18"/> <span className='ps-2'>Delivery address</span> </div> 
 <div onClick={extractAdd} className='p-2 pe-2'><FaPaste size='20' /> </div>

 </div>


 <div className='mt-4 px-3 '>

{/* person name  */}
<form className="form-floating">
  <input type="text" ref={name} onChange={inputChange}    className={`form-control text-capitalize  ${nameErr}`} id="floatingInputValue" placeholder="Person name" />
  <label htmlFor="floatingInputValue">Person name</label>
  
</form>

{/* company name */}

<form className="form-floating mt-3">
  <input ref={company} type="text" onChange={inputChange}  className={`form-control  text-capitalize ${comErr}`}   id="floatingInputValue" placeholder="Person name" />
  <label htmlFor="floatingInputValue">Company name (optional)</label>
</form>

 {/* address detail  */}
 <form className="form-floating mt-3">
  <input ref={add} type="text"   onChange={inputChange}  className={`form-control text-capitalize ${addErr}`}  id="floatingInputValue" placeholder="Person name" />
  <label htmlFor="floatingInputValue">Street address, apt, floor etc</label>
</form>

  {/* mobile number  */}
  <div className='position-relative'>
  <form className="form-floating mt-3">
  <input  ref={mobile} type="number" onFocus={showCode} onBlur={hideCode} maxLength={10} onChange={inputChange}  className={`form-control  ps-5 ${mobErr}`} disabled={props.action === 'edit' ? true : false}  id="floatingInputValue" placeholder="Person name"  />
  <label htmlFor="floatingInputValue">Mobile Number</label>
</form> 
<div className={`position-absolute top-50 start-0 translate-middle-y pe-5  cntCode  ${Label ? 'd-block' :'d-none'} text-dark  ps-3`}>+{ cntCode && cntCode['cntCode']}</div>
</div>
 {/* pincode detail  */}
 <div className='position-relative'>
<form className="form-floating mt-3">
  <input type="text" ref={zipcod}   className={`form-control text-uppercase ${pinErr}`} id="floatingInputValue" onClick={zipcode}  onChange={(e) => fetchCity(e.target.value) }  placeholder="Person name" defaultValue={  props.booking[0].pincode  } disabled={props.action==='edit' ? false : props.booking[0].country === 'India' ? true : false} />
  <label htmlFor="floatingInputValue">{zp > 0 ? "Pincode":"City"}</label>
</form>
<div className={`position-absolute top-50 end-0 translate-middle-y pe-2 text-success  ` }>{  props.booking[0].country ==='India' ?  city : zp==='0' ? "" : city}</div>

{/* {intCIty ? intCIty.map((x) =>  <div className='position-absolute top-100  international cursor '>{x.city}</div>) :""} */}
</div>


 {/* Country name  */}
 <form className="form-floating mt-3">
  <input type="text" className="form-control" id="floatingInputValue" placeholder="Person name" defaultValue={ props.booking[0].country} disabled />
  <label htmlFor="floatingInputValue">Country</label>
</form>

<div className='mt-4' onClick={submit}><NormalButton   name= {props.action ==='edit' ? 'Update delivery address' : 'Add delivery address' }  loading={isHide} /> </div>
{/* <div className='mt-5'><NormalButton name="Next" /></div> */}

<Drawer isVisible={isHide}>
{/* <div className="alert alert-danger" role="alert">
<div className='d-flex justify-content-between' ><div>  <h4 className="alert-heading">Remote Area!</h4></div>
<div><button onClick={() => setIsHide(false)} type="button" className="btn-close" aria-label="Close"></button></div>

</div>
  <p>This zipcode is Out of delivery area. </p>
  <div>{remote && remote.map((x) => <><span key={b=b+1} >{x.network} | </span></> )}</div>
  <hr/>
 <div className='d-flex justify-content-between  align-items-center  '>
  <div><input ref={rem} placeholder='Remote area charges' className={`${remoteErr}`}  defal /></div>
  <div> <btton disabled={false}  onClick={remoteAmount}>Submit</btton></div>
 </div>
</div> */}

 {!rateLoader && <>
   <div className="card-body">
     <div className="avatar-placeholder placeholder rounded-circle"></div>
     <div className="text-placeholder placeholder w-50"></div>
     <div className="text-placeholder placeholder w-75"></div>
 </div>
 
 <div className="card-body">
     <div className="avatar-placeholder placeholder rounded-circle"></div>
     <div className="text-placeholder placeholder w-50"></div>
     <div className="text-placeholder placeholder w-75"></div>
 </div>
 
 <div className="card-body">
     <div className="avatar-placeholder placeholder rounded-circle"></div>
     <div className="text-placeholder placeholder w-50"></div>
     <div className="text-placeholder placeholder w-75"></div>
 </div>
  </>
  }

      <div className='text-danger d-flex justify-content-between '><div>Remote area Zipcode</div>
  <div><button onClick={() => setIsHide(false)} type="button" className="btn-close" aria-label="Close"></button></div>
      </div>

      <div className=' text-nowrap my-3 text-center '> <div className=' text-dark'><span className='dst-route' >Select Delivery Option</span> </div></div>

       {  remote && rateLoader && <div className={`rateHt1 mt-1 scroll-y  pe-4 ps-1 ${remote.length >2 ?'rateWt' :'mt-3'}`}>

       {  remote && remote.map((x) => <div  key={a=a+1} onClick={() => {selectRate(x.sr)}}  className={` ${remote.length ===2 ? "mb-4" : ""}   `}  ><RateList  iconType={'By Air'} service={x.full_name} delTime={'8-15 business day delivery by end of the day most of area' } price={x.rate }  active ={inital === x.sr ? true:  select ===x.sr ? true:false}   /></div>)}
      
    <div className='my-2' onClick={checkoutt}><NormalButton name='Proceed for delivery'  /></div> 
 {/* <div className='my-2'>  <button type="button" className="btn btn-outline-secondary">Book again </button></div>  */}
   
 </div> } 
    </Drawer>



 </div>

  </div>
{ loader && <Spinner />}
{ paste && <Spinner />}
  </>
  )
}

import React, { useEffect, useState } from 'react'
import { FiCheckCircle } from "react-icons/fi";
import './ProgessIndicator.css'
import { FaEllipsisV } from "react-icons/fa";
import { GrMapLocation } from "react-icons/gr";
import { IoIosCall} from "react-icons/io";

export const ProgressIndicator = (props) => {
    const [progess,setProgess] = useState(0)
    const [clr,setClr] = useState('gray')
   const [ht,setHt] =useState(60)

    useEffect(() => {

   

   
        if(props.active )
  progess < 100 && setTimeout(() => {setProgess(progess+2.2)

setClr('green')
  },10)  



 
    },[props.active,progess])
const call =() => {
  <a href="tel:+15555551212">555-555-1212</a>
}

  return (
   <>
   <div className='d-flex px-4 '>
<div className='d-flex flex-column  align-items-center'>
<div className={`${props.curLoc ? 'd-none' :'d-block'}`} ><FiCheckCircle  color={clr} size="24"/></div>
<div className={`${props.curLoc ? 'd-block':'d-none' } curDot`}></div>
<div className={`progress ${props.last ? 'd-none' :'d-block'  }`} style= {{height: 70}}>
  <div className="progress-bar bg-success" role="progressbar" style={{height: progess  , width:1.5}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
</div>
</div>
<div className='d-flex flex-column ps-3 '>
<div className=''>{props.status} </div>
{/* <div  className={`d-flex align-items-center ${props.status ==='Initiate Pickup Request' ? 'd-block':'d-none'} locFnt text-muted`}> H.no. L.i.g 789 phb colony jamalpur awana,ludhiana, punjab</div> */}
<div  className={`d-flex align-items-center position-relative   ${props.status ==='Awaiting for pickup boy' && props.curLoc  ? 'd-block':'d-none'} pkBoyCon text-muted`}> 
<div className='d-flex flex-column position-absolute top-0'><img src={'https://www.queekdelever.com/DeliveryBoy/delivreryBoy.png'} alt='img' className='pic' />
<div className = 'transV position-absolute top-50 start-50 translate-middle mt-4'><FaEllipsisV size='10' /> </div>
<div className='position-absolute top-100 start-50 translate-middle-x mt-2 '> <GrMapLocation  size='18'  color='yellow' /> </div>
 </div>
  <div className='d-flex flex-column ps-5 '>
   <div className='BoyName  position-relative '><div>Sunny Sharma</div><a href="tel:7814002400"> <div  className="position-absolute top-0 start-100 translate-middle-x pe-4 "><div className='call'><IoIosCall color='white' size='16' /> </div> </div> </a></div> 
    <div className='BikeN text-muted'> PB 10 ED 2022</div>
 <div className='mt-2 pkAdd'> H.No. L.I.G  789 P.H.B colony jamalpur awana ludhaina flsl lgldg lgdldg l lgldgldg</div>
</div> 


</div>
<div className='timeDate text-muted'>{props.dateTime}</div>

</div>

   </div>
   </>
  )
}

import React, { useRef, useState, useEffect } from 'react'
import Map from '../../Components/Map.jsx';
import { BsRecordCircle } from "react-icons/bs";
import { withScriptjs } from "react-google-maps";
import { useCurrentPosition } from 'react-use-geolocation';
import axios from 'axios';
import pick from './origin.svg'
import { HiLocationMarker } from "react-icons/hi";
import './FoodDelivery.css'
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoIosCall } from "react-icons/io";
import { BiRupee } from "react-icons/bi";
import {useParams,useNavigate} from 'react-router-dom'
import { ImSad } from "react-icons/im";
import { Spinner } from '../../Components/Spinner.jsx';
import { SpinnerRoundFilled } from 'spinners-react';
import { ImRadioChecked } from "react-icons/im";
import {BsCheckAll} from "react-icons/bs";
import {BsCheckCircleFill} from "react-icons/bs";
import Drawer from "react-bottom-drawer";
import { PaymentGateway } from './PaymentGateway';
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import { Rate } from './Rate.jsx';
import logo from './logo.png'
import { MdOutlineWrongLocation } from "react-icons/md";




class MapView extends React.Component {
    shouldComponentUpdate(nextProps) 
    {
    // return nextProps.org !== this.props.org;
      return this.props.update !=='false'
      }
     render(){
    
       
        const MapLoader = withScriptjs(Map);
    
    const route =(e) => {
    if(e === 'ok')
    this.props.routes(true)
    else
    this.props.routes(false)
    }
      return  <MapLoader
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBah2C34AUXeAESdNgB5GoXKgk6XhlYhMU"
      loadingElement={<div style={{ height: `100%` }}  />}
      origin = {this.props.org}
    height={this.props.height}
      marker={false}
      route={route}
      dest ={this.props.dest}
    />
      }
    }

export const FoodDelivery = (props) => {
    const MapLoader = withScriptjs(Map);
    const [update,setUpdate] = useState('true')
    const [position, error] = useCurrentPosition();
    const [lat,setLat] = useState('30.901649625');
    const [lng,setLng] = useState('76.90586687500002');
    const [org,setOrg] = React.useState(null)
    const [placeName,setPlaceName] = useState()
    const [isHide,setIsHide] = useState(false)
    const [con,setCon] = useState(false)
    const {id} = useParams()
    const [storeData,setStoreData] = useState()
    const [wait,setWait] = useState(false)
    const [method,setMethod] =useState('CASH')
    const[isVisible,setIsVisible] = useState(false)
    const [rate,setRate] = useState()
    const [dest,setDest] = useState()
    const [distance,setdistance] = useState()
    const [tarif,setTarif] = useState()
    const[gps,setGps] = useState(false)


// ****************************Payment Gateway************************************
const Razorpay = useRazorpay();
// create new order
const createOrder =async() => {
  return '3553535'
}

const handlePayment =async(params) => {
    const order = await createOrder(params); //  Create order on your backend
                const options = {
                    key: "rzp_test_iTUbvmt85QXrcY", // Enter the Key ID generated from the Dashboard
                    amount: params *100 , // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                    currency: "INR",
                    name: "Queek Delever",
                    description: "Test Transaction",
                  //   image: "https://www.queekdelever.com/img/icon.png",
                  //   order_id: "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
                    handler: function (response) {
                      // alert(response.razorpay_payment_id);
                      // alert(response.razorpay_order_id);
                      // alert(response.razorpay_signature);
          
                    // shipment && update()
                       sucessfulPayment()
          
                     
          
                  // console.log(response.razorpay_payment_id)
                  // console.log(response.razorpay_order_id)
                  // console.log(response.razorpay_signature)
                  
                    //   props.back('paymentDone')
                    //   props.rate('Main')
                    //   props.active(false)
                    },
                    prefill: {
                      name: "Piyush Garg",
                      email: "youremail@example.com",
                      contact: id,
                    },
                    notes: {
                      address: "Razorpay Corporate Office",
                    },
                    theme: {
                      color: "#ffe600",
                    },
                  };
                
                  const rzp1 = new Razorpay(options);
                
                  rzp1.on("payment.failed", function (response) {
                    alert(response.error.code);
                    alert(response.error.description);
                    alert(response.error.source);
                    alert(response.error.step);
                    alert(response.error.reason);
                    alert(response.error.metadata.order_id);
                    alert(response.error.metadata.payment_id);
                  });
                
                  rzp1.open();
                
  }




// *************************************************************************

    const routes =(e) => {
     if(e)
     setUpdate('false')
     }

 

      const loadLocation = async (lat,lng) => {
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBah2C34AUXeAESdNgB5GoXKgk6XhlYhMU`)
      if(response.data){
        setPlaceName(response.data.results[0].formatted_address)
      }
      
      }

      const fetch1= async() => {
        const response = await axios.get(`https://queekdelever.com/queekApi/FoodRequest.php?custId=${id}`)

       let res = typeof(response.data) ==='string' ?response.data.trim() : response.data
        

        if(res !== 'noDelivery')
        {
          setStoreData(response.data)
        setWait(false)
        setIsHide('Found')
        if(response.data[0].dLat && response.data[0].dLng )
        {setOrg({lat:parseFloat(response.data[0].lat) ,lng :parseFloat(response.data[0].lng) })
        setDest({lat:parseFloat(response.data[0].dLat) ,lng :parseFloat(response.data[0].dLng) })}
       }
        else
        {
          setIsHide('noFound')
       }
      }


    useEffect(() =>{
    
      if(position)
     { 
      setGps(false)
      setOrg({lat:position.coords.latitude,lng:position.coords.longitude})
     fetch1()
     if(!dest)
      loadLocation(position.coords.latitude,position.coords.longitude)}

      if(!position && !error)
    { setGps(true)
      setIsHide('Found')
    }
      
    },[position])


    const proceed =async() => {

      setIsVisible(true)
   
    }

    const tariff =() => {
      fetch(`https://queekdelever.com/queekApi/FoodDelCost.php?custId=${id}`)
      .then(response => response.json())
      .then(data => {
setTarif(data)
      })
    }

useEffect(() => {
 
  tariff()
},[])


    useEffect(() => {
      const interval = setInterval(() => {
               fetch1()},15000)
              return () => clearInterval(interval)
    })


    const next = async() => {
      if(method ==='UPI')
      handlePayment(65)
      if(method ==='CASH')
      {
        setWait(true)
        setIsVisible(false)
        const res = await axios.post(`https://queekdelever.com/queekApi/FoodDeliveryUpdate.php?custID=${id}&lat=${position.coords.latitude}&lng=${position.coords.longitude}&loc=${placeName}&payment=${rate}&km=${distance}`)
    if(res.status ===200)
    {
      fetch1()
  AssignBoy(storeData[0].Id)
    }
      }
    }

    const AssignBoy=(e) => {
fetch(`https://queekdelever.com/queekApi/AssignBoy.php?Id=${e}`)
.then(response => response.json())
.then(data => {
data.map((x) => {
  whatsMsg(x.boyID,data[0].storeName,x.storeAdd,x.delLoc,x.payment,e,x.empId)

})
})
    }
  

  const sucessfulPayment =async() => {

    const res = await axios.post(`https://queekdelever.com/queekApi/FoodDeliveryUpdate.php?custID=${id}&lat=${position.coords.latitude}&lng=${position.coords.longitude}&loc=${placeName}&payment=Prepaid&km=${distance}`)
    if(res.status ===200)
    {
      setWait(true)
        setIsVisible(false) 
      fetch1()}
  }

  const kms =async() => {

    let org = storeData[0].lat+'%2C'+ storeData[0].lng
    let dst = position.coords.latitude+'%2C'+position.coords.longitude

    setOrg({lat:parseFloat(storeData[0].lat), lng: parseFloat(storeData[0].lng)})

    setDest({lat: position.coords.latitude, lng: position.coords.longitude})
   
 const response = await axios.get(`https://easy-ship.in/local_kms.php?org=${org}&dst=${dst}`)
     if(response.data)
     {
      setUpdate('true')
    // setDuration(response.data.rows[0].elements[0].duration.text)
      let km =(response.data.rows[0].elements[0].distance.value)/1000
      km = Math.ceil(km)
      setdistance(km)
    
      if( km <= 3)
     {
     
       if( parseFloat(storeData[0].qty ) > parseFloat(storeData[0].wgt) )
       setRate(parseFloat(tarif[0].basic) +   ((parseFloat(storeData[0].qty)-1) * tarif[0].qty ))
       else
       setRate(parseFloat(tarif[0].basic)  + ((parseFloat(storeData[0].wgt)-1) * tarif[0].kg )) 
    }
      else {
        km = parseInt(km) -3
        console.log(typeof(rate))
        if( parseFloat(storeData[0].qty ) > parseFloat(storeData[0].wgt) )
      setRate(parseFloat(tarif[0].basic) + (km * parseFloat(tarif[0].km) + ((parseFloat(storeData[0].qty)-1) * tarif[0].qty ) ))
        else
        setRate(parseFloat(tarif[0].basic) + (km * parseFloat(tarif[0].km) + ((parseFloat(storeData[0].wgt)-1) * tarif[0].kg ) ))
      }
     setWait(false)
     }
   }


   const whatsMsg =(sender,storeName,storeAdd,delLoc,payment,id,empId) => {
    

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' ,
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3YWxsZXRJZCI6IjYyYWExMmZiMDA2NmQzN2M5OWIxNDMxNSIsInVzZXJJZCI6IjYyYWExMmZiMDA2NmQzN2M5OWIxNDMxNiIsImRldmljZUlkIjoiNjJiNDU5ODczZDhhMzEwYTRlYmE4NDUyIiwiaWF0IjoxNjU1OTg2Njg0LCJleHAiOjE4Nzc0Mjk4ODR9.CTTbtkuTLfOYWWXYVGfQMN0uEY_vb_ck0ivRFHzx_LU' ,
        'Access-Control-Allow-Origin':'origin-list',
        'Access-Control-Allow-Headers': '*'
      },

        body: JSON.stringify({
          "numbers": `91${sender}`,
          "message": {
            "text": `*Food Pickup* : From ${storeName} , ${storeAdd}  *Delivery To:* ${delLoc} `,
            "footer": `Payment :  ${payment}`,
            "templateButtons": [
              {
                "urlButton": {
                  "displayText": "Accept Pickup Now",
                    "url": `https://queekdelever.com/#/acceptPickup/${id}/${empId}`
                }
              },
              
             
             
            ]
          }
        }
         )
    };
    fetch('https://api.whatsspot.in/api/message/fast/text', requestOptions )
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(err => console.log('Error occur '+ err))
        

// empty dependency array means this effect will only run once (like componentDidMount in classes)

}


  return (
 <>
{isHide ==='noFound' && <div className='vh-100 d-flex flex-column align-items-center justify-content-center '>
 <div className='my-2'><ImSad size='28' /></div>
   <div>Sorry, no food delivery</div>
</div>}

{!isHide && <Spinner />}

 <div className= {`position-relative ${isHide ==='Found' ?'d-block' :'d-none'} `}>
 <MapView org={org}  update={update} height={'0px'} routes={routes} dest={dest}  />
{ !dest && <div className={` marker-pos1 `}>
          <div className="d-flex justify-content-center align-items-end">
            <img src={pick}  alt='pickup' style ={{height:50}} />
          </div>
        </div>}
        <div className="LocCard1 ">
        {/* for location confirm 8888**************************/}
{ storeData && !storeData[0].status && !gps && <div className={`locationCon   ${con  ? 'd-none' :'d-block'}  `}>
<div className='pt-3 text mb-2 text-dark'><span className='pe-2'><HiLocationMarker size='20' /></span>Delivery Location</div>
          <div className="d-flex justify-content-between mt-4 ">
            {/* <div className="ShortName"> {loc.short_name} </div> */}
            <div className='locLabel text-muted ps-2'>YOUR CURRENT LOCATION</div>
          </div>
        <div className="locDetail mt-2 ps-2">
            <div className='pdLoc text-dark fs-6'>{placeName && placeName}</div>
          </div>
    <div className='mt-3 d-flex justify-content-center' onClick={() =>{
      setCon(true)
      setWait(true)
       kms()
    }} ><button className='btn btn-success'>Confirm Location</button></div>
       
</div>}
{/* ************************************************************************************ */}

{/* *****************************Payment Form ************************************************ */}
<div className={`d-flex  justify-content-center  d-none  `}>
     <div className='overlayPickup d-flex justify-content-start'>
     <div className='d-flex flex-column w-100 '>
      <div className=' mt-1  paymentLbl  text-center mt-3'>Select payment option</div>
    <div className=' px-3 mt-2 '>
    
    <div className={`paymentOption mt-3 mx-2 rounded d-flex justify-content-between ${method==='CASH'?'border border-success':'border border-white' } `} onClick={() => setMethod('CASH')} >
      <div className='px-2 '>Cash on Delivery</div>
      <div className='pe-2'><ImRadioChecked size='18' color={method==='CASH' ?'green' :'gray'} /></div>
    </div>


    <div className={`paymentOption mx-2 rounded d-flex justify-content-between  mt-2 ${method==='UPI'?'border border-success':'border border-white' } `}  onClick={() => setMethod('UPI')} >
      <div className='px-2'>Online Payment</div>k
      <div className='pe-2'><ImRadioChecked size='18' color={method==='UPI' ?'green' :'gray'} /></div>
    </div>  

    </div>
    </div>
     </div> 
     </div>
    {/* ***************************************Turn On Location******************************** */}

   <div className={`d-flex  justify-content-center  ${ gps ?'d-block':'d-none'} `}>
     <div className='overlayPickup '>
      <div className='d-flex flex-column align-items-center '>
      <div className='my-3'><MdOutlineWrongLocation  size='40' color='red' /></div>
       <div className='text-center'>Enable Geolocation</div>
       <div className='ptext text-muted text-center px-2 mt-3'> Your GPS Location is Off, please Turn on  GPS Location for Door Step Delivery. </div>
      </div>
     </div>
     </div> 

{/* **************************************************************************************************

{/* ***************************************Waiting for pickup boy******************************** */}

   <div className={`d-flex  justify-content-center  ${storeData && storeData[0].status ==='Confirm' ?'d-block':'d-none'} `}>
     <div className='overlayPickup '>
      <div className='d-flex flex-column align-items-center'>
      <div className='waitPik mt-3'></div>
       <div className='mt-4'>Waiting for pickup boy</div>
       <div className='ptext text-muted text-center px-2 mt-3'>Sit back and relax while your order is being prepared or packed  and we’ll notify once it’s done</div>
      </div>
     </div>
     </div> 

{/* ******************************************************************************************************* */}
      {/* pickup and drop delivery */}

   <div className= {`d-flex   justify-content-center ${storeData && storeData[0].boyId  ? 'd-block' :'d-none' }`}>
     <div className='overlayPickup2 justify-content-start '>
       {/* row 1st */}
        <div className='d-flex flex-column ps-4 mt-3'>
        {storeData && storeData[0].status ==='Delivered'  &&   <div className='pt-4 mb-2'>  <BsCheckAll color='#008000' size='35' /></div>
}  
{storeData && storeData[0].status !=='Delivered'  &&   <div className='pt-3'>  <img src={'https://www.queekdelever.com/DeliveryBoy/delivreryBoy.png'} alt='img' className='pic' /></div>
}     {/* <div className='ps-3 pb-2'><BsThreeDotsVertical size= '0' color='white' /></div> */}
     {storeData && storeData[0].status !=='Out for pickup' && <div className='ps-3 mt-2'><BsRecordCircle size='22' color='#ae361c' /></div>}
    { storeData && storeData[0].status ==='Out for pickup' &&  <div className='ps-2 mt-2'> <SpinnerRoundFilled	size='40' color='#ae361c' /></div>}
     <div className='ps-3'><BsThreeDotsVertical  size='24'  /></div>
{  storeData && storeData[0].status !== 'Out for delivery' &&  <div className='ps-3'><HiLocationMarker size='24' color={storeData && storeData[0].status ==='Delivered' ? '#00800' :'gray' } /></div>}
{ storeData && storeData[0].status ==='Out for delivery' &&  <div className='ps-2'> <SpinnerRoundFilled	size='40' color='#008000' /></div>}

      </div>

      {/* row 2nd */}
<div className='ps-3 mt-4 w-100 pe-3 '>
  <div className='position-relative'>
 {storeData && storeData[0].status !=='Delivered' && <div className='BoyName mt-2'>{storeData && storeData[0].bName}</div>}
 {storeData && storeData[0].status ==='Delivered' && <div className='BoyName1 mt-2 '>Order Deliverd...!!!</div>}

{ storeData && storeData[0].status !=='Delivered' && <div className='BikeN text-muted mt-1'>{storeData && storeData[0].bikeNo }</div> } 
{ storeData && storeData[0].status !=='Delivered' && <div className='position-absolute top-0 start-100 translate-middle  pe-4 mt-4 '> <a href={`tel: ${storeData && storeData[0].phone}`} > <div className='call cursor '> <IoIosCall color='white'  /> </div></a> </div>}
  </div>
  <div><hr className='pdividie my-1'  /></div>

  <div className='pdLabel  d-flex justify-content-between'>{storeData && storeData[0].name } { storeData && storeData[0].status ==='Out for pickup' && <div className='onwB '>Out for pickup </div>}</div>
  <div className='pdLoc text-muted  '>{storeData && storeData[0].add  }</div>
  <div><hr className='pdividie my-1'  /></div>

  <div className='pdLabel d-flex justify-content-between'>Drop Location { storeData && storeData[0].status ==='Out for delivery' && <div className='onwB '>On the way</div>}</div>
  <div className='pdLoc  text-muted '>{storeData && storeData[0].delLoc }</div>

</div>
     </div>
     </div> 
     
     
     {/* ************************************************************************************ */}
     {/* ******************************************************************************************************* */}
      {/* Delivery charges with pickup & delivery  */}

   <div className= {`d-flex  justify-content-center ${con ? 'd-block':'d-none'  }  ${storeData &&  storeData[0].status ?'d-none' :'d-block'   }   `}>
     <div className='overlayPickup2 justify-content-start  pt-3'>
       {/* row 1st */}
      <div className='d-flex flex-column ps-2 mt-3'>
    {/* <div>  <img src={'https://www.queekdelever.com/DeliveryBoy/delivreryBoy.png'} alt='img' className='pic' /></div> */}
     {/* <div className='ps-3 '><BsThreeDotsVertical size= '24' /></div> */}
     <div className='ps-3  mt-3'><BsRecordCircle size='22' color='#ae361c' /></div>
     <div className='ps-3'><BsThreeDotsVertical  size='24'  /></div>
     <div className='ps-3'><HiLocationMarker size='24' color='#008000' /></div>
      </div>

      {/* row 2nd */}
<div className='ps-3 mt-3 w-100 pe-3 '>
  <div className='position-relative d-none'>
  <div className='BoyName mt-2'>Sunny Sharma</div>
  <div className='BikeN text-muted mt-1'>PB 10 ED 5021</div>
  <div className='position-absolute top-0 start-100 translate-middle  pe-4 mt-4 '><div className='call '> <IoIosCall color='white' /></div></div>
  </div>
  {/* <div><hr className='pdividie my-1'  /></div> */}

  <div className='pdLabel  d-flex justify-content-between'>{storeData && storeData[0].name}<div className='onwB d-none'>on the way</div></div>
  <div className='pdLoc text-muted  '>{storeData && storeData[0].add}</div>
  <div><hr className='pdividie my-1'  /></div>

  <div className='pdLabel d-flex justify-content-between'>Drop Location <div className='onwB d-none'>on the way</div></div>
  <div className='pdLoc  text-muted '>{placeName && placeName}</div>



{ !wait && storeData && storeData[0].payer !=='Store' && <div className='mt-3' onClick={() => {proceed()}} ><button className='btn btn-success'>Pay for delivery <BiRupee size='18' />{rate && rate}</button></div>
}
 
{ !wait && storeData && storeData[0].payer ==='Store' && <div className='mt-3' onClick={() => {next()}} ><button className='btn btn-success'>Proceed for delivery </button></div>
}

{wait &&  <div className='mt-3'  ><button className="btn btn-primary" type="button" disabled>

  <span className="spinner-border spinner-border-sm ps-2" role="status" aria-hidden="true"></span>
</button>
</div>}





</div>

     </div>
     
     </div> 
     
     
     {/* ************************************************************************************ */}
        </div>
      { wait && <Spinner />}
</div>

<Drawer isVisible={isVisible} >

<div className='d-flex justify-content-between px-3 mb-3'>
  <div>Proceed for payment</div>
<div onClick={() => setIsVisible(false)}><button type="button" className="btn-close" aria-label="Close"></button></div>
</div>

<div className='my-3'>

<div className={`paymentOption1 mx-2 rounded d-flex justify-content-between  ${method==='CASH'?'border border-success':'border border-white' } `} onClick={() => setMethod('CASH')} >
<div className='px-2 fName py-3 '>Cash on Delivery</div>
      {/* <div className='pe-2'><ImRadioChecked size='18' color={method==='CASH' ?'green' :'gray'} /></div> */}
    </div>

    <div className={`paymentOption1 mt-2 mx-2 rounded d-flex justify-content-between align-items-center  ${method==='UPI'?'border border-success':'border border-white' } `}  onClick={() => setMethod('UPI')} >
      <div className='px-2 fName py-3'>Online Payment</div>
      {/* <div className='pe-2'><ImRadioChecked size='18' color={method==='UPI' ?'green' :'gray'} /></div> */}
    </div>  

    <div className='mt-3' onClick={() => next() } > <button className='btn btn-success'>Next</button></div>
</div>
</Drawer>


 </>
  )
}

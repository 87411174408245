import React, { useEffect, useRef, useState} from 'react'
import { BsChevronLeft } from "react-icons/bs";
import { FaRupeeSign } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import { NormalButton } from '../../Components/NormalButton';
import { Spinner } from '../../Components/Spinner';
import Drawer from "react-bottom-drawer";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";


import './Invoice.css'

export const Invoice = (props) => {

  const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    maximumSignificantDigits: 5 
  }).format(value);


    const [hide,setHide] = useState('close')
    const back =() => props.back('close')
    const [caption,setCaption] = useState('Add single item ')
    const [load,setLoader] = useState(false)
    const item = useRef(null)
    const madeOf = useRef(null)
    const qty = useRef(null)
    const hsn = useRef(null)
    const amt = useRef(null)
    const detail = useRef(null)
    const [boxNo,setBoxNo] = useState(1)
    const [invContent,setInvContent] = useState('')
    const [itemErr,setItemErr] = useState('form-control')
    const [descErr,setDescErr] = useState('form-control')
    const [qtyErr,setQtyErr] = useState('form-control')
    const [hsnErr,setHsnErr] = useState('form-control')

    const [addErr,setAddErr] = useState(false)

    const [itemData,setItemData] = useState('')
    const [flagInput,setFlagInput] = useState(false)
    const [invData,setInvData] = useState('')
    const [editDrawer,setEditDrawer] = useState(false)
    const show =(e) =>  setHide(e)
    const [srNo,setSrNo] = useState('')
    const [parArr,setParArr] = useState('')
    const [addCap,setAddCap] = useState('Add')

    let a=0;
    let b=0;

    const fetchItem =(e) => {
       setTimeout(() => {
        fetch(`https://queekdelever.com/queekApi/InvoiceItem.php?item=${flagInput ? e : '100% '+e }`)
        .then(res => res.json())
        .then(data => setItemData(data))
       },500)


      if(e.length > 5)
      setAddErr(true)
      else
      setAddErr(false)
    }

    useEffect(() => {
      setLoader(true)
      fetch(`https://queekdelever.com/queekApi/InvoiceDetails.php?awb=${props.invAwb}`)
      .then(res => res.json()
      .then (data => {
        setInvData(data)
        setLoader(false)
        invContents()
      })
      )
    },[])

    const InsertDtails =(e) => {

     setAddCap('Add')

      if(item.current.value ==='' || item.current.value.length <=2)
      setItemErr('form-control is-invalid')
      else
      setItemErr('form-control')

      if(amt.current.value ==='' || amt.current.value  <= 0)
      setDescErr('form-control is-invalid')
      else
      setDescErr('form-control')

      if(qty.current.value ==='' || qty.current.value <= 0)
      setQtyErr('form-control is-invalid')
      else
      setQtyErr('form-control')

      if(hsn.current.value ==='' || hsn.current.value.length < 4)
      setHsnErr('form-control is-invalid')
      else
      setHsnErr('form-control')
    

      if(item.current.value !=='' && qty.current.value > 0 && amt.current.value > 0 && hsn.current.value.length > 3 )
{
  addParcelDetails(e)
}
      else
      {return}

  
    }

    const addParcelDetails =(e) => {
      setLoader(true)
      let amtTotal = amt.current.value * qty.current.value
        amtTotal =parseFloat(amtTotal).toFixed(2)
        fetch(`https://queekdelever.com/queekApi/InvoiceCreate.php?flag=${addCap ==='Add' ? e :'Edit'}&invoiceNo=${props.invAwb}&item=${item.current.value}&qty=${qty.current.value}&rate=${amt.current.value}&amount=${amtTotal}&boxNo=${boxNo}&srNo=${srNo}&hsn=${hsn.current.value}`)
        .then(res => res.json())
        .then(data => {
         
          invContents()
        })
    }

    const customItem =() => {

      if(detail.current.value.length <= 5)
      return

     item.current.value = detail.current.value
     setHide('form')
    }

      const EditDetail =() => {
        setAddCap('Update')
        item.current.value = parArr[0].item
        qty.current.value = parArr[0].qty
        amt.current.value = parArr[0].rate
        setEditDrawer(false)
        setHide('form')
      }
    
    const invContents =(e) => {
      setLoader(true)
      fetch(`https://queekdelever.com/queekApi/InvoiceDesc.php?awb=${props.invAwb}`)
      .then(res => res.json())
      .then (data => {
        setInvContent(data)
        setLoader(false)
        setHide('close')
        setEditDrawer(false)
        item.current.value=''
        qty.current.value =''
        amt.current.value=''
        hsn.current.value=''
        setAddCap('Add')
      })
    }

const updateData =(e,f) => {
 
   item.current.value =e
   itemPrice(e)
   setHide('form')

}

const itemPrice =(e) => {
  fetch(`https://queekdelever.com/queekApi/itemPrice.php?item=${e}`)
  .then( res => res.json())
  .then (data =>{
    console.log(data[0].rate)
     amt.current.value=data[data.length-1].rate
     hsn.current.value = data[data.length-1].hsn  
  })
}

const edit =(sr,item,rate,amt,qty,box) => {
 const parcel =[{
  item :item,
  rate: rate,
  amt : amt,
  box : box,
  qty: qty  
  }]
  setParArr(parcel)
  setSrNo(sr)
setEditDrawer(true)

}

const amtRef =(e) => {
  amt.current.value =parseFloat(e.target.value).toFixed(2)
}
  return (
    <>
    <div className='position-relative  vh-100 '>
      <div className={`sticky-top bg-white ${hide ==='close'? 'd-block' : 'd-none' }`}>
          <div className={`d-flex justify-content-between parcelHeader `}><div className="px-2 pe-4 back" > <span className='ps-2 text-center'>Invoice Generator</span> </div> <div className='pe-2' onClick={back} ><GrClose size='22'/></div></div>



            <div className='invFrm '>
                <div>{invData && invData[0].fName}</div>
                <div className='InvFAdd'><p >{invData && invData[0].fAdd}</p></div>
            </div>

            <div className='d-flex justify-content-between mt-2 ps-2 '>
                <div className='card p-2 px-4 ToWidth '>
                    <div className='fName mb-3' >Ship to</div>
                    <div className='toName'>{invData && invData[0].tName}</div>
                    <div className='invAds twoLine InvFAdd'>{invData && invData[0].tAdd1}</div>
                    <div className='invAds twoLine InvFAdd'>{invData && invData[0].tAdd2}</div>
                    <div className='invCnt'>{invData && invData[0].country}</div>

                </div>

                <div className='InvWdith card p-2 px-2'>
                <div className='fName'>Invoice Value</div>
                <div className='d-flex justify-content-center align-items-center mt-3'>
                    <div className='d-none'><FaRupeeSign size='30' /></div>
                            {/* formatter.format(shipment[].total) */}

                    <div className='invValue pe-3 text-center'>{invContent.length > 0 && numberFormat(invContent[invContent.length-1].total)}</div>
                </div>
                <div className='card curreny mt-3 text-center'>Currency INR</div>
                </div>

            </div>

            <div className='d-flex mt-2 ps-2 mx-2 invHeader text-white  py-1 '>
                    <div className='fName invDes '>Description</div> 
                    <div className='fName invQty'>Box</div> 
                    <div className='fName  invPrice'>Price</div>
                    <div className='fName invQty'>Qty</div> 
                    
                    <div className='fName invTotal'>Total</div>
                </div>
                </div>

                {/* <div className='bg-info h-100'>

                </div> */}

                <div className='scroll-y pt-2 pb-5 '>
                {invContent && invContent.map((x) => <div onClick={()=>edit(x.sr,x.item,x.rate,x.amount,x.qty,x.box)} key={b=b+1} className=' d-flex mt-2 ps-2 mx-2  '>
                    <div className='fName invDes '>{x.no}. {x.item}</div>  
                    <div className='fName text-center invQty'>{x.box}</div> 
                    <div className='fName text-center  invPrice'>{x.rate}</div>
                    <div className='fName text-center invQty'>{x.qty}</div> 
                   
                    <div className='fName text-center invTotal'>{x.amount}</div>
                </div>)
                }
                </div>

{hide ==='close' && !editDrawer &&   <div className=' fixed-bottom text-center btnInv py-2 cursor ' onClick={()=> show('form')}>+ Add Description</div>
}    
    {/* Descripton form  */}
    <div className={` position-absolute top-0 start-0 bg-white w-100 h-100  ${hide === 'form' ? 'd-block':'d-none'}`}>
        <div className='d-flex justify-content-between mb-4 desHead px-3'>
            <div>#5580207694</div>
            <div className='cursor' onClick={()=>show('close')}><GrClose size='22' color='white' /></div>
        </div>

<div className='p-3 '>
<div className="form-floating mb-3">
  <input  ref={item}  type="text"    onClick={()=> {
    setHide('desc')
    setFlagInput(false)
    setCaption('Add Single Item description')
    setItemData('')
setTimeout(() => { detail.current.focus()
    
},300)
    } } className={`${itemErr}`} id="floatingInput" placeholder="name@example.com" />
  <label htmlFor="floatingInput">Add Single Item description</label>
</div>

<div className="form-floating mb-3 d-none  ">
  <input ref={madeOf} type="text" onClick={
    ()=> {
        setHide('desc')
        setFlagInput(false)
        setCaption('Which items are made of')
        setItemData('')
    setTimeout(() => { detail.current.focus()
        
    },300)
        }
  } className="form-control" id="floatingInput" placeholder="name@example.com" />
  <label htmlFor="floatingInput">Which items are made of</label>
</div>



<div className="form-floating mb-3">
  <input ref={amt} onBlur={amtRef} type="number" className={`${descErr}`} id="floatingInput" placeholder="name@example.com" />
  <label htmlFor="floatingInput">Dec. Value (for custom purpose)</label>
</div>

<div className="form-floating mb-3">
  <input type="number" ref={qty} className={`${qtyErr}`} id="floatingInput" placeholder="name@example.com" />
  <label htmlFor="floatingInput">How many item are you sending</label>
</div>


<div className="form-floating mb-3">
  <input type="number" ref={hsn} className={`${hsnErr}`} id="floatingInput" placeholder="name@example.com" />
  <label htmlFor="floatingInput">HSN Code</label>
</div>


<div className="form-floating">
  <select onChange={(e) => setBoxNo(e.target.value)} className="form-select" id="floatingSelect" aria-label="Floating label select example">
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
  </select>
  <label htmlFor="floatingSelect">Number of Box</label>
</div>


        
        <div className='mt-5' onClick={() => InsertDtails('Add') }>
            <NormalButton name={addCap} />
        </div>
        </div>
    </div>

    {/* *************************************************************************** */}

    { hide ==='desc' && 

    <div className='h-100 position-absolute top-0 start-0 bg-white w-100 ' >
    <div className={`mt-2 ZipSerach d-flex  align-items-center`}><span className="px-2 pe-4 back" onClick={()=> show('form')} ><BsChevronLeft size="18"/></span> <input  ref={detail} onChange={(e)=> fetchItem(e.target.value)} className='searchInput' placeholder= {caption} /> 
{load ? <span className='px-2 pe-2'>
<div className="clearfix">
  <div className="spinner-border float-right spinner" role="status">
    <span className="sr-only"></span>
  </div>
</div>

</span>:""}
</div>

{<div className='mt-3'>
{itemData ? itemData.map((x) =>  <div onClick={() => {updateData(x.item)}} key={a=a+1} className=' px-3 bg-gray pb-1 '>{x.item}</div>) :""}
</div>}

<div className='fixed-bottom pb-3' onClick={() => customItem() }><NormalButton name={'Add'} dim={!addErr} /></div>

    </div>}

    

    {/* *********************************************************************************** */}
   

    <Drawer isVisible={editDrawer} >

    <div className=' my-1'>
      <div  className='d-flex justify-content-end mb-4 pe-3' onClick={() => {setEditDrawer(false)}}><GrClose  size='22' /></div>
     <div className='d-flex justify-content-between'>
     <div   onClick={()=>EditDetail('Edit')} className='invEdit d-flex justify-content-center align-item-center'><div className='pe-3'><FiEdit size='18'/></div> <div>Edit</div></div>
      <div onClick={()=>addParcelDetails('Del')} className='invDel d-flex justify-content-center align-item-center'><div className='pe-3'><AiOutlineDelete size='20' color='white' /></div> <div>Delete</div></div>
     </div>
    </div>

    </Drawer>

  {load &&  <Spinner />}
  </div>
    </>
  )
}

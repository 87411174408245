import React from 'react';
import aborad from './pickupImg.png';
import smartDelivery from './smartDelivery.png'
import './Slider.css';

export const Slider = () => {
  return (
    <>
<div className=''>
</div>
<img className='img-size' src={aborad} alt="aborad"  />
</>
  )

};

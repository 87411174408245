import React from 'react'
import { BsCheckCircle } from "react-icons/bs";

export const SuccessfullyAdd = (props) => {

    const back =(e) => props.back(e)
  return (
    <div className='vh-100  d-flex flex-column justify-content-center align-items-center'>
        <div ><BsCheckCircle size="80" color="green"/></div>
        <div className='my-4'>Shipment/Order Successfully Added</div>
       <button onClick={() => {back('checkout')}} type="button" className="btn btn-warning w-75 my-2">Checkout  for payment</button>
        <button onClick={() => {back('close')}} type="button" className="btn btn-outline-secondary w-75 my-2">Back to home</button>

    </div>
  )
}

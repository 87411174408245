import React from 'react'
import { Footer } from '../Footer'
import Navbar from '../Navbar'

export const Privacy = () => {
  return (
    <div>
        <Navbar />
    <div className='container'>
        <h2 className='mt-4'>Privacy Policy</h2>
        <h6 className='mt-3'>Protection of Customer Information</h6>
        <p className='txt-clr1 text-dark mt-3'>We understand the importance of privacy to our customers and visitors to Queek Delever web sites. For many years, our policy has been to treat what we learn about our customers as confidential. For example, we will not give or sell confidential or Personal Information (as described below) about our customer to any third party not affiliated with their transaction, except as required by law or as necessary to provide Queek Delever services to the customer.</p>
        <p className='txt-clr1 text-dark mt-3'>We collect and store information about every package we handle so that we can efficiently provide the package delivery services demanded by our customers. We use information about our customers, their packages, and their shipping activity to provide or enhance the services we make available to our customers, communicate with our customers about additional services they may find of value, satisfy our legitimate business interests (including performing trend analysis and market studies), set prices, establish credit, fulfill a Contract of Carriage or Service Agreement, accomplish the billing function, and comply with government regulations. Although government regulations vary in the many countries in which we operate, they frequently include the reporting of information to transportation, safety, customs, and other regulatory agencies. As we collect and use information about our customers, we may contract with vendors to assist us in processing that information for those purposes listed in the previous paragraph. These vendors are required to maintain the confidentiality of the information and are restricted from using the information for any other purpose. In addition, as Queek Delever continues to develop its business, we may buy or sell subsidiaries or business units. As part of these transactions, customer information of the subsidiaries or business units may be one of the transferred assets.</p>
       
        <h6 className='mt-3'>Shipment Information</h6>
        <p className='txt-clr1 text-dark mt-3'>Shipment Information, including Personal Information, provided as part of a shipping transaction represents a record of that business transaction and cannot be altered after the transaction is complete except as provided in the Queek Delever Terms and Conditions. Although we make reasonable efforts to limit access to our facilities and vehicles to authorized personnel, we are not responsible for maintaining the confidentiality of information that is printed and placed in plain view on a package or letter.</p>
        <p className='txt-clr1 text-dark mt-3'>We treat our data as assets that must be protected against loss and unauthorized access. We employ information security techniques to appropriately protect confidential information from unauthorized access by users inside and outside the company. Access to customer information is limited to those employees who have a legitimate business need for that information.</p>
        <p className='txt-clr1 text-dark mt-3'>Queek Delever Web Sites, and their supporting systems, employ generally accepted information security techniques such as firewalls, access control procedures and cryptography to appropriately protect confidential information from unauthorized access.</p>
        
        <h6 className='mt-3'>Customers are Responsible for Protecting Information and Systems on their Premises</h6>
        <p className='txt-clr1 text-dark mt-3'>Some information about Queek Delever customers and their packages is stored in shipping systems located on customer premises. Customers concerned about the security of their information in these systems should secure it by activating passwords and using physical access controls. When these systems (hardware or software) are provided by Queek Delever, we provide tools to facilitate customer efforts to safeguard their information.</p>
        
        <h6 className='mt-3'>Queek Delever Web Sites</h6>
        <p className='txt-clr1 text-dark mt-3'>The following paragraphs describe our commitment to the protection of Personal Information as it applies to the Queek Delever Web Sites, which are web sites controlled or operated by a Queek Delever entity (such as a Queek Delever business unit) or a Queek Delever affiliate, unless the web site expressly publishes a different or modified privacy policy.</p>
        
        <h6 className='mt-3'>What Information We Collect And How We Will Use It</h6>
        <p className='txt-clr1 text-dark mt-3'>Users can visit most areas of the Queek Delever Web Sites without revealing who they are or providing any information about themselves. However, some of our interactive services require that users identify themselves in order to enable the interactive function. We may ask users to provide some Personal Information in these situations.</p>
        
        <h6 className='mt-3'>Links to Other Sites</h6>
        <p className='txt-clr1 text-dark mt-3'>Queek Delever provides links to external sites as a convenience to the user. The inclusion of any link does not imply Queek Delever endorsement of the external site company, its web site, or the products and services it promotes and sells. Queek Delever is not responsible for the privacy practices or the content of such web sites.</p>
       
        <h6 className='mt-3'>Cookies</h6>
        <p className='txt-clr1 text-dark mt-3'>Cookies are small text files that a web site can send to a user's browser for storage on the hard drive. Cookies can make use of the web easier by saving and administering status, application, preferences and other user information. Most browsers are initially set to accept cookies but users can change the setting to refuse cookies or to be alerted when cookies are being sent. For instructions on how to change cookie settings, look in the Help menu on your Internet browser. Although refusal of cookies will not interfere with the ability to interact with the Queek Delever Web Sites, users will need to accept cookies in order to use certain functionality (for example, My Queek Delever) provided at the Queek Delever Web Sites.</p>
        
        <h6 className='mt-3'>Web Surveys</h6>
        <p className='txt-clr1 text-dark mt-3'> From time to time, we include survey forms on the Queek Delever Web Sites to better understand our customers' needs and to create web sites that respond to their interests. Customers may opt-out of participating in follow-up surveys. Queek Delever will not disclose survey response information to companies or individuals outside Queek Delever with two exceptions: 1. To vendors who are required to maintain the confidentiality of the information and are restricted from using the information for any purpose other than helping to provide services to Queek Delever or Queek Delever customers. 2. To vendors who are required to maintain the confidentiality of the information and are restricted from using the information for any purpose other than helping to provide services to Queek Delever or Queek Delever customers.</p>
       
        <h6 className='mt-3'>Queek Delever Web Site Applications</h6>
        <p className='txt-clr1 text-dark mt-3'>Users of Queek Delever Internet Shipping and other applications on the Queek Delever Web Sites that enable the purchase of products and services who choose to use credit cards for payment are required to provide information about themselves and their card. We use this information to establish credit for billing.</p>
        
        <h6 className='mt-3'>IP Addresses</h6>
        <p className='txt-clr1 text-dark mt-3'>The Queek Delever Web Sites collect IP addresses for system administration, security, and statistical analysis purposes. An IP address is a number that is automatically assigned to a computer whenever it is connected to the Internet. We log these addresses and analyze them to understand where requests originate so that we can provide the most efficient service, enhance security, ensure appropriate usage and produce traffic volume statistics.</p>
        
        <h6 className='mt-3'>IP Addresses</h6>
        <p className='txt-clr1 text-dark mt-3'>Your continued use of this site signifies your consent  to the Queek Delever Privacy Policy. We reserve the right, at our discretion, to modify, add, or remove portions of this policy at any time by posting the revised policy at the Queek Delever Web Sites. Please check this page periodically for changes.</p>
       
        <h6 className='mt-3'>Contacting Queek Delever</h6>
        <p className='txt-clr1 text-dark mt-3'>If you have questions about this privacy statement, the practices of this site, or your experience on any of the Queek Delever Web Sites, you can contact: contact@queekdelever.com. Comments, suggestions or materials sent or transmitted to Queek Delever shall be deemed to be non-confidential. Queek Delever shall have no obligation of any kind with respect to such comments, suggestions or materials and shall be free to use and distribute them to others without limitation, including, but not limited to developing and marketing products incorporating them.</p>
        </div>
        <Footer/>
    </div>
  )
}

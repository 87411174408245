import React from 'react';
import './Heading.css';

export const Heading = (props) => {
    return (
        <>
            <div className=' text-center position-relative'>
                <div className='heading '><h2 className='text-center text-nowrap fw-bold e-heading'>{props.heading}</h2>
                </div>
            </div>
        </>
    )
};

import React, { useEffect, useState ,useRef} from "react";
import { IoMdTabletLandscape } from "react-icons/io";
import "./QueekHome.css";
import { Search } from "../../Components/Search";
import { EBooking } from "./EBooking";
import { Book } from "./Book";
import  {Location}  from "./Location";
import { Pickup } from "./Pickup";
import { Delivery } from "./Delivery";
import { Destination } from "./Destination";
import { SideMenu } from "./SideMenu";
import { FiMenu } from "react-icons/fi";
import { BsBellFill } from "react-icons/bs";
import axios from "axios";
import { withScriptjs } from "react-google-maps";
import Map from "../../Components/Map.jsx";
import { GrClose } from "react-icons/gr";
import { Rate } from "./Rate";
import { DeliveryForm } from "./DeliveryForm.jsx";
import { TopHead } from "../../Components/TopHead";
import { AdderssBook } from "./AdderssBook";
import { PickupLocation } from "./PickupLocation";
import { DeliveryAddress } from "./DeliveryAddress";
import { BillAddress } from "./BillAddress";
import { Notification } from "./Notification";
import { useNavigate,useLocation } from 'react-router-dom';
import {Demo} from "../../Components/Demo";
import { SuccessfullyAdd } from "./SuccessfullyAdd";
import { ReadytoShip } from "../../Components/ReadytoShip";
import { Successful } from "./Successful";
import {LocalDelivery} from "./LocalDelivery";
import { Food } from "./Food";
import { Content } from "../../Components/Content";
import {MapWithControlledZoom} from './Polyline';
import { DashBoard } from "./DashBoard";
import { QueekPickup } from "./QueekPickup";
import { HomePickup } from "./HomePickup";
import { NewBooking } from "./NewBooking";
import { Operation } from "./Operation";
import { Offline } from "../../Components/Offline";
import { Cancel } from "../../Components/Cancel";
import { PaymentDone } from "./PaymentDone";
import {LivePosition} from "../../Components/LivePosition";
import { GpsCheck } from "../../Components/GpsCheck";
import { Initilation } from "../../Components/Initilation.jsx";
import { Chheckouts } from "./Chheckouts.jsx";
import  AddtoHome  from "./AddtoHome";
import Drawer from "react-bottom-drawer";
import { FoodStore } from "./FoodStore";
import ReactPWAInstallProvider from "react-pwa-install";
import { LocalBooking } from "./LocalBooking";
import { Spinner } from "../../Components/Spinner";
import { Waiting } from "../../Components/Waiting";
import { Calculator } from "./Calculator";
import { PrintZone } from "./PrintZone";
import { PaymentOk } from "./PaymentOk";
import { Cash } from "./Cash";
import { Invoice } from "./Invoice";
import { TrackEmp } from "./TrackEmp";
import { BackAlert } from "../../Components/BackAlert";
import { ShippingLabel } from "./ShippingLabel";
import { Helmet } from "react-helmet";



class MapView extends React.Component {

  shouldComponentUpdate(nextProps) {
// return nextProps.org !== this.props.org;
   console.log(this.props.update)
 return this.props.update !=='true'
 }


  componentDidMount () {
    
   if(this.props.serType === 'International')
   return
  }

  render(){
    const MapLoader = withScriptjs(Map);

const route =(e) => {
if(e === 'ok')
{this.props.routes(true)
}
else
this.props.routes(false)
}

  return  <MapLoader
  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBah2C34AUXeAESdNgB5GoXKgk6XhlYhMU"
  loadingElement={<div style={{ height: `100%` }}  />}
  origin = {this.props.org}
route = {route}
  dest ={this.props.dest}
  height={"300px"}
  marker={true}
  
/>
  }
}

export const QueekHome = (props) => {
  
  const login = useLocation();
   
  const navigate = useNavigate();

  const [isHide, setHide] = useState('close');
  const [isParcel,setParcel] =useState(null)
  const [isService, setService] =useState('Domestic')
  const [PickName, setPicName] =useState('')
  const [org,setOrg] = React.useState(null)
  const [update,setUpdate] = useState('false')
  const[Lat,setLat] =useState(null)
  const[Lng,setLng] =useState(null)
  const [manual,setManual] =useState('no')
const [locId,setlocId] = useState('')
const [cityDesc,setDesc] =useState('')
const [dest,setDest] =useState('')
const [dstLoc, setDestLoc] =useState('')
const [getLoc,setLoc] =useState('none')
const [destPlaceId,setPlaceId] =useState('')
const [Ebook,setEbook] = useState('main')
const [items,setItem] = useState('')
const [ opens, setOpen] = useState(false)
const [pCity, setCity] = useState(null)
const [newUser,setNewUser] = useState(false)
const [bookings,setBooking] = useState(false)
const [ pincode,setPincode] = useState('')
const [state,setState] = useState('')
const [country,setCountry] = useState('India')
const [skips,setSkip] = useState('')
const [ pending, setPending] = useState(0)
const [draw,setDraw] = useState()
const [wgtSlabs,setWgtSlab] = useState('')
const [act,setActive] = useState('')
const [rst,setRst] = useState('')
const [wayBill,setAwb] = useState('')
const [shipType,setShipType] = useState('')
const [pikStatus,setPickStatus] = useState('')
const [empolyee,setEmployee] = useState('')
const [dutyStat,setDutyStat] = useState(true)
const [mBook,setMBook] = useState('')
const [curPage,setCurPage] =useState('Dashboard')
const [sMobile,setSMobile] = useState('')
const [liveLoc,setLiveLoc] = useState(false)
const [initLoc,setInitLoc] = useState(false)
const [isVisible,setVisible] = useState(false)
const [qPickup,setQPickup] = useState()
const [recMob,setRecMob] = useState('')
const [udateWayBil,setUpdateAwb] = useState()
const [pUpate,setPUpate] = useState(false)
const [wait,setWait] = useState(false)
const [action,setAction] = useState('')
const [whatDel,setWhatDel] = useState(false)
const [empFlag,setEmpFlag] = useState('')
const [pickAwb,setPickAwb] = useState('')
const [perName,setPerName] = useState('')
const [paymentId,setPaymentId] = useState('')
const [finalFlag,setFinalFlag] = useState(false)
const [locations,setLocation] = useState('')
const [invCon,setInvCon] = useState('')
const [pkDate,setPkDate] = useState('')
const [nxtPk,setNxtPk] = useState('')
const [hld,setHld] = useState('')
const [editName,setEditName] = useState('')
const [editAdd,setEditAdd] = useState('')
const [editTag,setEditTag] = useState('')
const [editSr,setEditSr] = useState('')
const [parcelPk,setParcelPk] = useState('')
const [alertClose,setAlertClose] = useState(true)

  const RefHeight = useRef()

  const add=(e) => setEditAdd(e)
  const person = (e) =>setEditName(e)
  const tagName =(e) => setEditTag(e)
  const locSr =(e) => setEditSr(e)
  


    // for all address pickup, delivery etc
    const pickup =(e) => {
      setHide(e)
      setManual('no')
    }

   

  const weight =(e) => {
 setParcel(e)
  }
 const Show =(h) => {
    setHide(h)
}

const delivery =(e) => {
  setHide(e)
  setManual('no')
}

  const back =(e) => {
    setHide(e)
  }

 const location = (e) => {
   setHide(e)
   setlocId('')
   
 }

 const destination = (e) => {
   setHide(e)
 }

 

 const serType = (e) => {
  setService(e)
  setDest('')
  if(dstLoc)
 { setDestLoc('')
  setUpdate('false')
  if(country !=='India')
  setCountry('India')
setTimeout(() => {setUpdate('true')},100)
}
 }


 const mapRefresh= (e) => {
setUpdate(e)
 }

 const placeDesc =(e) => {
   setDesc(e)
 }

// *****************************************************************
const PendingPk = async(e) => {
  const response  = await axios.get(`https://www.queekdelever.com/queekApi/ReadyPickup.php?sender=${login.state.type.trim() ==='Employee' ? e : login.state.mobile}`)
  
  const res = typeof(response.data) ==='string'? response.data.trim() : response.data

  if(res !=='empty' )
  {
setPending(response.data)
  }
  else {
    setPending(0)
  }
}



//  currrent location api
useEffect ( () => {
  let url =''
  const user = login.state.mobile
  if(login.state.type === 'Employee')
  url =`https://www.queekdelever.com/queekApi/pickupFromHome.php?empId=${user}`
  else
  url =`https://www.queekdelever.com/queekApi/pickupFromHome.php?sender=${user}`

  fetch(url)
  .then(res => res.json())
  .then(data =>
   { 
    // setPickAwb('')
   
    setTimeout(() => {
      setPickAwb(data[0].awb)
      setHld(data[0].reason)
      setPkDate(data[0].shedule)
      setParcelPk(data[data.length-1].sr)
    },400)
  //  console.log(pickAwb)
  }
    )
  
  


const loadLocation = async () => {
 
const user = login.state.mobile
const response = await axios.get(`https://www.queekdelever.com/queekApi/loc_select.php?custcode=${user}`)

const res = typeof(response.data) ==='string'? response.data.trim() : response.data

if(res !== 'newUser')
  {
    setPerName(response.data[0].person_name)
   setPicName(response.data[0].name)
   let lat = parseFloat(response.data[0].lat)
   let lng = parseFloat(response.data[0].lng) 
  //  console.log(response.data[0].lat)
  setOrg({lat,lng})
  
 setCity(response.data[0].city)

  //  setUpdate('false')
PendingPk(login.state.mobile)
    }
    else {
     
      if(login.state.type.trim() ==='Employee')
      {
        const response = await axios.get(`https://queekdelever.com/queekApi/pickupBoy.php?custcode=${login.state.mobile}`)
          if(response.data)
          {
            setEmployee(response.data)
            let lat = parseFloat(response.data[0].lat)
   let lng = parseFloat(response.data[0].lng) 
   setOrg({lat,lng})
   setCity(response.data[0].branch)
  //  setCity(response.data[0].city)
  setUpdate('true')
  // PendingPk(login.state.mobile)
          }
  
         if(dutyStat)
         setEbook('Dashboard')
         else
         console.log(curPage)
        //  setEbook(curPage)
         
// if(mBook ==='mBooking')
// {setEbook('main')}
// else
// {setEbook('Dashboard')}
      }
      else {
        setHide('location')
        setNewUser(true)
      }
     
    }
  }

if( isHide ==='close')
 { loadLocation()

}

 },[isHide])
//  
 
 const lat =(e) => {
  setLat(e)
 }

 const lng =(e) => {
  setLng(e)
}

const GetplaceId= (e) => {
  setHide('location')
  setlocId(e)
//  
}

const localId =(e) => {
setHide('close')
setDestLoc(e)
setUpdate('false')
setTimeout(() => {setUpdate('true')},100)
// console.log(e)
setLoc('none') 
setUpdateAwb('')

}

// useEffect(() => {
// setUpdate(true)
// },[dstLoc])

const localAddress =(e) => {
  setDest(e)
}


 const searchBar =(e) => {
  setManual(e)
 
 } 
 
 useEffect ( () => {
        
  if(RefHeight.current){
      
    //  console.log('height is ' + RefHeight.current.clientHeight)
      
  }
},[]);

const next =(e) => {
setEbook(e)
setCurPage(e)
setHide('close')
}

const rates =(e) => {
setEbook(e)
setCurPage(e)
}

const item =(e) =>{
setItem(e)
}

const delAddress = (e) => {
setHide(e)
}

const checkout =(e) => {
setHide(e)
}
const back1 =(e) => {
  setHide(e)
  setEbook('main')
}

const open =(e) => {
 setOpen(e)
}

  
const barClose =(e) => {
 
  setOpen(e)
}


const event =(e) => {
 setHide(e)
 setOpen(false)
 if(e === 'logout')   { navigate('/login')}
}

const pincodes =(e,f) => {
  setPincode(e)
  setCountry(f)
}
const states =(e) => setState(e)

const booking = (e) => {
  setBooking(e)
}

const countryy =(e) => {
 
setCountry(e)
}
const reset =() => {
  setDestLoc('')
  if(dstLoc)
 setUpdate('true')
setTimeout(() => {setUpdate('false')},100)
setCountry('India')
}

const skip =(e) => {
   setSkip (e)}

const routes=(e) => 
{
setDraw(e)
if(e)
{ if(isService ==='International')
  setWait(false)
  else
  setTimeout(() => {setWait(false)},800)
}
}

useEffect(() => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
});
}, [])

const wgtSlab =(e) => setWgtSlab(e)
const active =(e) => {
  if(!e)
  setDest('')
  setActive(e)}

const awb =(e,f,g,h,i) => 
{ setAwb(e)
setShipType(g)
setPickStatus(i)

}

useEffect(() => {

  
  if(login.state.type.trim() === 'Employee')
{
setLiveLoc(true)
// setTimeout(() => {setLiveLoc(false)},10000)
const interval = setInterval(() => {
  // QuickReq()
//   setTimeout(() => {  FoodPickup()
// },1500)

},15000)
   return () => clearInterval(interval)
  }
},[])

const QuickReq = async() => {
const response = await axios.get(`https://www.queekdelever.com/queekApi/PickupNow.php?boyId=${login.state.mobile}`)

const res = typeof(response.data) ==='string' ? response.data.trim() : response.data

if(res !== 'empty' )
{
  setQPickup(response.data)
  setEbook('QuickPickup')
setTimeout(() => {  setCurPage('QuickPickup')},100)
}
else{
  // console.log(mBook)
// setEbook(curPage) 
}

}

const FoodPickup =async() => {
  const response = await axios.get(`https://queekdelever.com/queekApi/FoodPickup.php?boyId=${login.state.mobile}`)
 
 const res = typeof(response.data) ==='string' ? response.data.trim() : response.data
 
  if(res !== 'empty' )
  {
  setQPickup(response.data)
  setEbook('QuickPickup')
}
  else{
  // setEbook(curPage) 

   }
}

const dutyStatus =(e) => {

  setDutyStat(e)
  if(e)
  setEbook('offline')
  else
 { setEbook(curPage)
 setUpdate('true')
setTimeout(() => {setUpdate('false')},100)
}
// QuickReq()
}

const offBooking =(e) => {

  setEmpFlag(true)
  setHide('close')
  setTimeout(() => {
  setEbook('main')
  setCurPage('main')
},200)
setMBook(e)
}
const mSender =(e) => setPicName(e)
const mob =(e) =>{ setSMobile(e)
  PendingPk(e)
}
  
const cart =(e) => {
  if(login.state.type.trim() ==='Employee' )
 { 
  setCurPage('main')
  setEbook('main')
  PendingPk(e)}
}

const gpsError =(e) => console.log(e)
const mainPage =(e) => {
  
setInitLoc(true)
}




// useEffect(() => {
//   setTimeout(() => {
// setLiveLoc(true)
// setTimeout(() => {
//   setHide('close')
// },1000)
//   },1000)

// },[initLoc])


const pickRefresh =(e) => {
  if(e)
  setEbook('Dashboard')
  setCurPage('Dashboard')
}

const RecMob =(e) => setRecMob(e)

const page =(e) => {
  setEbook(e)
  setCurPage(e)
}
// **********************************Add to Home*******************************
const SenId =(e) => {
  PendingPk(e)
}

const bookAnother =(e) => {
  setEbook('main')
  setHide('close')
}

const updateAwb =(e) =>{
   setUpdateAwb(e)
   
  }

const mapLoad =(e) => {setWait(e)}
    
// *******************************************************************************

const actions =(e) => {
  setAction(e)
}

const rstDst =(e) => {

  if(e === 'main')
 { 
  setEbook('main')
 setMBook('mBooking')
 setCountry('India')
 setWhatDel(true)
  if(dest)
  {
setDestLoc('')
setDest('')
setUpdate('false')
setTimeout(() => {setUpdate('true')},100)
  }

}
if(e === 'Dashboard')
{
  if(dest)
  {
    setDest('')
    setUpdate('false')
    setTimeout(() => {setUpdate('true')},100)
  }
  setMBook('')
  setPicName('')
  setDestLoc('')
  setDest('')
  setCountry('India')

 setEbook('Dashboard')
  setCurPage('Dashboard')
}
}

useEffect(() => {
let mobile = login.state.mobile
    let type =login.state.type
    navigate('/pickup',{
      state:{mobile,type}
  });
  
 

  window.onpopstate = function(event)  {
    if(isHide ==='close')
    {
  
    setHide('close')
    navigate('/pickup',{
      state:{mobile,type}
  });
  }
  else
  {
    setHide('close')
  }
   
}
},[isHide])

const users =(e) =>{
  setSMobile(e)
  // console.log(sMobile)
}

const pickId =(e) => {
  setAwb(e)
}

const payId =(e) =>setPaymentId(e)
const custMob =(e)=> setSMobile(e)
const finalCheck =(e) =>{ 
  console.log(e)
  setFinalFlag(e)}
const loc = (e) => setLocation(e)
const isBox =(e) => setItem(e)
const InvAwb =(e) => {setInvCon(e)}
const newUserr =(e) => setNewUser(e)

const close =(e) => {
  setAlertClose(e)
}
return (
    <>

             <Helmet> <script disable-devtool-auto src='https://cdn.jsdelivr.net/npm/disable-devtool@latest/disable-devtool.min.js'></script></Helmet>

  {/* {isHide &&  <AddToHomeScreen startAutomatically = {true} displayPace />} */}

    {/* ******************************************************************************************* */}
      {/* E-booking with map  Start Code*/}
      <div className={`container-fluid   m-0  bg-primary booking-scr`}>
        <div className="row">
          <div className={`col-lg-5 px-0 vh-100  `}>
            <div className="HomeContainer px-0">


          
          {isHide ==='pickup'? (<Pickup back={back}  Location ={location} apiKey ={props.apiKey} pCity ={pCity} placeId={GetplaceId} mapRefresh={mapRefresh} bar={manual} lat={Lat} desc={placeDesc} lng={Lng} show={isHide} user={login.state.mobile}  person={person} add={add} tagName={tagName} locSr={locSr}  newUserr={newUserr}  />):""}
          {isHide ==='location' ? (<Location getLoc={isHide} back={back} apiKey ={props.apiKey} lat={lat} lng={lng} placeId={locId} desc={cityDesc} search={searchBar} newUser={newUser}  mapRefresh={mapRefresh} user={login.state.mobile} add={editAdd} person={editName} tag={editTag} sr={editSr} />):""} 
         
           {isHide ==='delivery' ? (<Delivery back={back} mapRefresh={mapRefresh}  Destination ={destination} serType ={isService} localId={localId}  search={searchBar} localAddress={localAddress} pCity ={pCity} user={login.state.type.trim()==='Employee' ? sMobile : login.state.mobile} pincode={pincodes} state={states} country={countryy} skip={skip} draw={draw} route={routes}  lType={login.state.type.trim()}  mapLoad ={mapLoad} delivery ={delAddress} action={actions} booking={booking} />):""}
          {isHide ==='destination' ?(<Destination back={back} Destination={destination} />) :"" }
          {isHide ==='parcel'? (<Search  back={back}  delLocation={dest}  Weigt={weight} content={cityDesc}  item={items}  next={next} delivery ={delAddress} org={org} dst={dstLoc}   users={users} booking={booking}  serType ={isService} pincode={pincode} state={state} country={country} skip={skips} userType={login.state.type.trim()}  awb={udateWayBil }   emp={login.state.type.trim()==='Employee' ? sMobile : login.state.mobile} finalCheck={finalCheck} />    ):""  }
          {isHide ==='delAddress' ? <DeliveryForm back={back} finalCheck={finalCheck} checkout={checkout} booking={bookings} action={action} cart={cart}  empId={login.state.mobile}  />:""}
          {isHide === 'checkout' && <Chheckouts loc={locations} custMob={custMob}  payId={payId} rates={rates} active={active} emp={login.state.type.trim() === 'Employee' ? true : false}  back={back} rstDst={rstDst}  empId={login.state.mobile}  serType={isService}    booking={bookings} reset={reset}  user={login.state.type.trim() ==='Employee'? sMobile : login.state.mobile} userID={sMobile}  cart={cart} SenId={SenId} bookAnother={bookAnother} updateAwb={updateAwb} flag={false}  finalCheck={finalFlag} isBox={isBox}  /> }
          {isHide ==='myShipment' ? <AdderssBook back={back} awb={awb} user={login.state.mobile} /> :""}
          {isHide ==='pickupAddress' ? <PickupLocation back={back}  /> :""}
          {isHide ==='deliveryAddress' ? <DeliveryAddress back={back}   /> :""}
          {isHide ==='billAddress' ? <BillAddress back={back} /> :""}
          {isHide ==='Notification' ? <Notification back={back} /> :""}
          {isHide ==='added' ? <SuccessfullyAdd back={back} /> :""}
          {isHide === 'success' ? <Successful back={back} /> :""}
          {isHide === 'LocalDel' ? <LocalDelivery back={back} awb={wayBill} shipType={shipType}  status={pikStatus} route={routes} pickType={login.state.type} /> :""}
          {isHide === 'content' ? <Content back={back} /> :""}
          {isHide ==='Polyline' ? <MapWithControlledZoom /> :"" }
          {isHide ==='HomePickup' ? <HomePickup  rstDst={rstDst} back={back} loc={loc} users = {users} empId={login.state.mobile} updateAwb={updateAwb} finalCheck={finalCheck} /> :"" }
          {isHide ==='newBooking' ? <NewBooking back={back} next={offBooking} mSender={mSender} mob={mob} serType={isService} lType ={login.state.type.trim()}  sender={recMob} pickRefresh={pickRefresh} /> :""}
          {isHide ==='Operation' ? <Operation back={back}  InvAwb={InvAwb} empId={login.state.mobile}  /> :""}
          {isHide ==='paymentDone' && <PaymentDone back={back} /> }
          {isHide ==='init' && <Initilation /> }
        {isHide === 'food' ? <Food back={back} /> :""}
        {isHide ==='foodStore' && <FoodStore back={back} empId={login.state.mobile} />  }
        {isHide === 'Calculator' && <Calculator back={back}/> }
        {isHide === 'print' && <PrintZone back={back} empId={login.state.mobile} /> }
        {isHide === 'cash' && <Cash empId ={login.state.mobile} back={back} /> }
        {isHide === 'invoice' && <Invoice back={back}  invAwb ={invCon} /> }
        {isHide === 'trackEmp' && <TrackEmp back={back} empId={login.state.mobile}  invAwb ={invCon} /> }

        {isHide === 'shipLabel' && <ShippingLabel user={login.state.mobile} /> }

        {isHide === 'pickReq' && <PaymentOk payId={paymentId} user={sMobile} back={back}  /> }
        {/* <ReactPWAInstallProvider enableLogging>
     {isHide  ==='close' && <AddtoHome /> }
     </ReactPWAInstallProvider> */}
        
          {/***************************** Main Page**********************************************************  */}
             
             <div className={`${isHide==='close' ? "d-block":"d-none"} h-100 position-relative `} >
        
         <div className={`${draw   ?'d-none':'d-block'}`} ><Initilation  /></div>
         
              <div className={`position-relative  d-flex align-items-start flex-column h-100 `}  >
          
              <div className={`MapContainer position-relative  `} >
               
{ country ==='India'   ?  <MapView org={org}  update={update} routes={routes} dest ={dstLoc} open={open} serType={isService} /> : isHide === 'close'  &&   <MapWithControlledZoom height={'300px'} dst={dstLoc} route={routes} />   }
{/* <MapView org={org}  update={update} routes={routes} dest ={dstLoc} open={open} />  */}
           {/* {  isService ==='Local'  ?  <MapView org={org}  update={update} routes={routes} dest ={dstLoc} open={open} /> : dstLoc ? } */}
     { <div className={ `${login.state. type.trim() === 'Employee' ? curPage ==='main' && PickName ?'d-block':'d-none' :'d-block' }`}  onClick={(()=> {
       back('checkout')
       setFinalFlag(false)
       })}><ReadytoShip  total={pending ? pending.length : '0'} /></div>}
          {login.state.type.trim() ==='Employee' && curPage==='main' &&  !dutyStat && <div onClick={()=>{setCurPage('Dashboard')
           setEbook('Dashboard')
           rstDst('Dashboard')
             }}><Cancel/></div>}
        </div>
             
              <div className= {`EbookingCont ${draw ?'d-block' :'d-none'} `}  >
           {org  &&    <div className={`${Ebook==='main' ? 'd-block' :'d-none'} `}> <EBooking Content={Show} wgtSlab={wgtSlab}  Wgt={isParcel}  Pickup={pickup} Delivery={delivery} serType={serType}  picName ={ PickName} dest ={dest}  next={next} item={item} draw= {draw} update={isHide} active={act} lType={login.state.type} user={login.state.mobile} RecMob={RecMob}  bType={isService}  awb={updateAwb} whatDel={whatDel} /></div>}
               { Ebook ==='rate' ? <Rate rates={rates} finalCheck={finalCheck} active={active}  delivery ={delAddress}  wgtSlab={wgtSlabs}  org={org} dst={dstLoc} empId={login.state.type.trim()==='Employee' ? login.state.mobile :''}  user={login.state.type.trim()==='Employee' ? sMobile : login.state.mobile}  item={items} serType ={isService} pincode={pincode} state={state} country={country} skip={skips} booking={booking} back={back} lType ={login.state.type} mob={sMobile}  />  :""}
            {Ebook ==='Dashboard' ?<DashBoard back={back} duty={dutyStat} page={page} parcelPk={parcelPk} /> :""}
            {Ebook ==='QuickPickup' && <QueekPickup data={qPickup && qPickup} empId={login.state.mobile} pickRefresh={pickRefresh} /> }
          {Ebook ==='offline' && <Offline />}
          {/* {   <div className="dashBoardLoader"></div>} */}
          {/* {liveLoc && !dutyStat && login.state.type ==='Employee' && <LivePosition gpsError={gpsError} id={login.state.mobile}   mainPage ={mainPage} />} */}
            {/* {liveLoc  && <LivePosition gpsError={gpsError} id={login.state.mobile}   mainPage ={mainPage} />} */}
           {Ebook ==='GpsEnable'  &&  <GpsCheck  />}
         
            </div>

              </div>
    
              
<div className= {`${draw ?'d-block':'d-none'}`} ><TopHead awb={pickAwb ? pickAwb : wayBill} pkDate={pkDate}  pickId={pickId}  back={back} open={open}  type={login.state.type.trim()} emp ={empolyee} id={login.state.mobile.trim()} dutyStatus={dutyStatus}  reason={hld}  /></div>
       <div><SideMenu contact={perName}  open={opens}  barClose={barClose} event={event} type={login.state.type} user={login.state.mobile} /></div>

     

              </div>
          {/* ************************************************************************************************ */}

            </div>
          </div>
        </div>
      </div>
    {/* ******************************************************************************************* */}

      {/* Search Bar container  */}

    

    {/* ******************************************************************************************* */}

      {/* rotate phone code */}
      <div className="container-fluid rotate">
        <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
          <div>
            <IoMdTabletLandscape size="100" />
          </div>
          <h1>rotate your phone </h1>
        </div>
      </div>
          {/* ******************************************************************************************* */}
{/* <Drawer isVisible={isVisible} >

<div>gsgskgsk</div>
{isHide && <AddtoHome />}
  </Drawer> */}
  
{wait && <Spinner />}

    </>
  );
};

// serviceWorkerRegistration.register();
import React,{useState,useEffect} from 'react'
import './Tracking.css'
import {useParams,useNavigate} from 'react-router-dom'
import { BsArrowRightCircle } from "react-icons/bs";
import { FaRegDotCircle } from "react-icons/fa";
import { BsTruck } from "react-icons/bs";
import axios from 'axios';
import Drawer from "react-bottom-drawer";
import pickupAds from'./pickupAds.svg'
import { FiBox } from "react-icons/fi";
import Navbar from '../Navbar'
import { FiCheckCircle } from "react-icons/fi";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { MdOutlineLocalShipping } from "react-icons/md";
import { GiCardboardBoxClosed } from "react-icons/gi";
import { Spinner } from './Spinner.jsx';
import { BsWhatsapp } from "react-icons/bs";
import { BiRightArrowCircle } from "react-icons/bi";
import { FaCircle } from "react-icons/fa";
import { BsCheckLg } from "react-icons/bs";
import DHL from '../pages/Ebook/dhl.png'
import FEDEX from '../pages/Ebook/fedex.png'
import UPS from '../pages/Ebook/ups.png'
import Invalid from '../pages/Ebook/Invalid.png'

import { AiOutlineExclamation } from "react-icons/ai";
import { Footer } from '../Footer';
import { Helmet } from "react-helmet";





export const Tracking = () => {
    const [data,setData] = useState(null)
    const [onBook,setOnBook] = useState(false)
    const [isVisible, setIsVisible] = React.useState();
    const [load,setLoad] = useState(true)
    const [prgLine,setPrgLine] = useState(0)
    const [netImg,setNetImg] = useState('')
    const [netSz,setNetSz] = useState('')
    const [invalid,setInvalid] = useState(false)
    const {awb} = useParams()
    const navigate = useNavigate()
    const [day,setDay] = useState('')
    let a=0;
    const WoD =[ "Sunday","Monday","Tuesday","Wednesday","Thursday",'Friday','Saturday']



    useEffect(() => {
    
        const fetch =async() => {
            const res =await axios.get(`https://www.queekdelever.com/queekApi/TrackingDetails.php?awb=${awb}`)
           
           
            if(res.data.length > 0)
           { 
            setInvalid(false)
            if(res.data.length > 0)
           { setData(res.data)
            if(res.data[0].curStatus ==='Label Created')
            setPrgLine(0)
            if(res.data[0].curStatus ==='In Transit')
            setPrgLine(1)
            if(res.data[0].curStatus ==='Not Delivered')
            setPrgLine(2)
            if(res.data[0].curStatus ==='Out For Delivery')
            setPrgLine(3)
            if(res.data[0].curStatus ==='Delivered')
            setPrgLine(4)

            
            if(res.data[0].mode.trim() ==='DHL')
           { setNetImg(DHL)
            setNetSz('dhlSize')}
            if(res.data[0].mode.trim() ==='FEDEX')
           { setNetImg(FEDEX)
           setNetSz('fedexSizs')}
            if(res.data[0].mode.trim() ==='UPS')
           { setNetImg(UPS)
            setNetSz('upsSize')
            }

            setLoad(false)
            setTimeout(() => {setIsVisible(true)},2000)
    }
        else {
            setLoad(false)  
        }
       
    }
    else
    {
    setLoad(false)   
     setInvalid(true)   
    }
        
          }
          setTimeout(()=> {setOnBook(true)},2000)

          fetch()
        },[awb])

        // useEffect(() => {
        //     const loggedInUser = localStorage.getItem("dinesh");
        //     if (loggedInUser) {
        //     //   const foundUser = JSON.parse(loggedInUser);
        //       console.log(loggedInUser)
        //     //   setUser(foundUser);
        //     }
        //   }, []);

          const share =() => {
            window.open(`whatsapp://send?text=https%3A%2F%2Fwww.queekdelever.com%2Ftracking%2F${awb}`)
          }
       
  return (
    <> 
    <Helmet> <script disable-devtool-auto src='https://cdn.jsdelivr.net/npm/disable-devtool@latest/disable-devtool.min.js'></script></Helmet>
   
    <div className='position-relative'>

        <Navbar/>
   
   <hr className='divide1 my-1' />

{/* <div className='container col-md-8 text-white  text-center topStatus'>
    In-Transit
</div> */}


 {data && <div>
<div className='container d-none col-md-8 py-3 '>
    <div className='d-flex justify-content-between px-1'>
    <div>
        <div className='origin'>Ludhiana</div>
        <div className='shipDate text-muted'>29,Jul 2022</div>
    </div>

    <div>
        <div className='orgg text-muted text-end'>Destination</div>
        <div className='origin text-end'>Delhi</div>
        <div className='shipDate text-muted text-end'>29,Jul 2022</div>
    </div>

    </div>
    <div></div>
</div>

<div className='container-fluid h-100 '>
    <div className='row'>
        <div className='col-sm'></div>
        <div className='col-sm-8 bg-white p-0 m-0 h-100  '>

        
        {/* Head */}
        <div className={`d-flex px-3  headTracking card border  rounded w-100 `}>

        <div className='position-relative'>
            <div className='position-absolute top-0 start-100 translate-middle-x' ><div onClick={share} className='me-3 mt-2'><BsWhatsapp size='23' color='#075E54' /> </div> </div>
<div className='d-flex flex-column  p-0 m-0'>
            <div className='d-flex justify-content-between align-items-center'>
    <div className=' d-flex flex-column w-100'>


        <div className='trackId pt-1'>Tracking Id <span className='px-2 trackNo'>#{awb}</span></div>
        <div><hr className='divide1 mt-3' /></div>

        <div className='statFnt   ps-2 my-1'><div className={`stst fs-3 ${data && data[0].curStatus ==='Not Delivered' ? 'penTxt'   : data && data[0].curStatus ==='Out For Delivery' ? 'text-dark' : data && data[0].curStatus  === 'Delivered' ? 'txtGreen' :'text-dark' }`}>{data && data[0].curStatus}</div> <div className='px-2'> {data && data[0].curStatus ==='Not Delivered' && <AiOutlineExclamationCircle size='0' color='#ae361c' />} {data && data[0].curStatus ==='Delivered' && <div><FiCheckCircle size='0' color='#008000' /></div> } {data && data[0].curStatus==='In Transit' && <MdOutlineLocalShipping size='0' color='black' />  }  {data && data[0].curStatus ==='Out For Delivery' && <GiCardboardBoxClosed size='0' color='#0d1740' /> } </div> </div>

        <div className='mt-2 '>
            <div className='d-flex px-4'>
            <div className={`col-3  position-relative ${ prgLine >= 0 ? prgLine === 2 ? 'pending'  :'green' :'gray' } `}> {prgLine === 0 && <div className='position-absolute top-50 start-100 translate-middle ms-2 zIndex'><div className='position-relative'><FaCircle size='30' color='#008000' /><div className='position-absolute top-50 start-50 translate-middle'><BiRightArrowCircle size='25' color='#fff' /></div></div></div>} </div>
            <div className={`col-3  position-relative ${ prgLine > 0 ? prgLine === 2 ? 'pending' :'green' :'gray' } `}> {prgLine === 1 && <div className='position-absolute top-50 start-100 translate-middle ms-2 zIndex'><div className='position-relative'><FaCircle size='30' color='#008000' /><div className='position-absolute top-50 start-50 translate-middle'><MdOutlineLocalShipping size='20' color='#fff' /></div></div></div>} {prgLine === 2 && <div className='position-absolute top-50 start-100 translate-middle ms-2 zIndex'><div className='position-relative'><FaCircle size='30' color='#c01919' /><div className='position-absolute top-50 start-50 translate-middle '><AiOutlineExclamation size='20' color='#fff' /></div></div></div>} </div>
             <div className={`col-3  position-relative ${ prgLine > 2 ? 'green' :'gray' } `}> {prgLine === 3 && <div className='position-absolute top-50 start-100 translate-middle ms-2 zIndex'><div className='position-relative'><FaCircle size='30' color='#008000' /><div className='position-absolute top-50 start-50 translate-middle'><FiBox size='20' color='#fff' /></div></div></div>} </div>
            <div className={`col-3  position-relative ${prgLine > 3 ? 'green' :'gray' } `}> {prgLine === 4 && <div className='position-absolute top-50 start-100 translate-middle ms-2 zIndex'><div className='position-relative'><FaCircle size='30' color='#008000' /><div className='position-absolute top-50 start-50 translate-middle'><BsCheckLg size='15' color='#fff' /></div></div></div>} </div>
        </div>
        </div>

        <div className='mt-4'><hr className='divide1 mt-3' /></div>
          <div className='orgLabel text-dark'>Shipped to</div>
          <div className='origin pb-1'>{data && data[0].dest}</div>
         {data && data[0].typeOf !=='Domestic' &&   <div className='d-flex mt-2 d'><div className='fName'>Handle by: <span>{data && data[0].mode}</span> </div></div>}
           { data && data[0].typeOf !=='Domestic' && <div ><img src={netImg} className= {netSz}  />  {data && data[0].fwdNo && <span className='ms-0 fName'>Awb: {data && data[0].fwdNo}</span>}</div>}
            {/* <div className='d-flex justify-content-between w-100 mt-1 '><div className='orgLabel mt-1'>Shipped to</div> <div className='orgLabel d-none'></div></div>
            <div className='d-flex justify-content-between w-100'><div className='origin'>{data && data[0].dest}</div> <div className='origin d-none'>{data && data[0].dest}</div></div> */}


      
        <div className='expect pt-2 mt-3 text-muted d-none'>{ data && data[0].curStatus === 'Delivered' ? '' :'' }</div>
        {/* <div className='d-flex align-items-end'>
            <div className='mt-2 d-none'>
                <div className='day text-center'>{WoD[`${data && data[0].wod}`] }</div>
                <div className='d-flex align-items-end mt-2'>
                <div className='date pe-1'>{data && data[0].Day}</div>
                <div className=''>
                    <div className='month'>{data && data[0].month}</div>
                    <div className='month'>{data && data[0].year}</div>
                </div>
                </div>
              
            </div>
            <div className='vr mt-3 mx-2'></div>
            <div>
                <div>By</div>
                <div>6:00pm</div>
            </div>
            </div> */}
 
        </div>
        </div>

  </div>

    <div className='col-12'>
    {/* <div>June 29,2022 11:10 | Ludhiana</div> */}
    {/* <div><hr className='divide1 my-1'  /></div> */}
    {/* <div className='orgg  text-muted text-start mt-3'><span  className='px-1 text-dark text-capitalize'>{data && data[0].org} to {data && data[0].dest}</span></div> */}
    {/* <div className='orgg text-muted text-start mt-2'>Destination: <span  className='px-1 text-dark text-capitalize'>{data && data[0].dest} </span></div> */}

    {/* <div className='shipHandle mt-3 text-muted'>{data && data[0].typeOf === 'Domestic' ?'' :'Shipment Handle by'}</div> */}
   {/* <div className='textc-center bg-info'><span className='day fs-5 text-center'>{data && data[0].typeOf ==='Domestic' ? '' : data && data[0].mode } </span> {data && data[0].typeOf !=='Domestic' && <span className=''>(Awb: {data && data[0].fwdNo  } )</span>} </div> */}
    </div>
 
    {/* <div className=' statusTran me-2 mt-2'><div><BsTruck size='24' color='white' /></div></div> */}
    
</div>
            
        </div>
{/* ************************************************************************** */}
<div className='d-none'>
    <div className='d-flex  justify-content-center py-2 align-items-center'>
   <div className=' d-flex flex-column '>
  <div> <span className='estTIme px-2'>DEL</span> <span className='ps-2'><BsArrowRightCircle size='20' color='black' /></span> <span className='estTIme px-3'>MUM</span></div>
 <div className='rAdd  mt-3 px-2 rounded bg-dark text-white me-2'>Tracking ID # 93959953</div>
  </div>    
<div>
    <div className='b-'>
    <div className='carrier px-3 text-muted'>Forward Through</div>
    <div className='network px-3'>DHL World Wide</div>
    <div className='rAdd px-3 '>Awb # 25929529529</div>
    </div>
</div>
</div>
</div>

<div><hr className='divide1 my-2'  /></div>
<div className='text-center'>Tracking History</div>
<div><hr className='divide1 my-2'  /></div>

{/* ****************************Tracking details************************ */}
{  <div className='d-flex flex-column justify-content-center w-100'>
{data && data.map((x)=>
<div key={a=a+1} className={`px-2  ${a >0 ? 'd-block' :'d-none'} `} >

<div className='d-flex align-items-center'>
    {/* ****************Date & time ************** */}
    <div className='d-flex flex-column text-end mt-3 mb-2 dateWidth'>
        <div className='dateD'>{x.date}</div>
        <div className='time d-flex justify-content-end'>{x.time}</div>
    </div>

    {/* *************************************************** */}
<div className='d-flex flex-column justify-content-center align-items-center position-relative'>
<div className='px-3 zIndex'><FaRegDotCircle size='20' color={a===1 ? '#008000' :'gray'}  /></div>
<div className={`TranLine1 ${data && data.length-1 === a ?'d-none' :'d-block'} `}  ></div>

</div>

<div className='d-flex flex-column mt-2 w-75 '>
    <div className='fName twoLine '>{x.status}</div>
    <div className='rAdd'>{x.loc}</div>
</div>


</div>

</div>
)}</div>}
{/* ************************************************************************* */}
    </div>
    <div className='col-sm'></div>
    </div>
       
    </div>
     {/* <Drawer isVisible={isVisible}>
   <div className='d-flex justify-content-end w-100 mt-2 pe-1'> <button type="button" className="btn-close" aria-label="Close" onClick={() => {setIsVisible(false) }}></button></div>
<div className='d-flex justify-content-center w-100'><img src={pickupAds} /></div>
  
  <div className='text-center doorPik mb-3 mt-2  '><span className='bgBlue'>Free</span> Door Step Pickup</div>
  <div className='cap text-muted '>Delivery anywhere across India or World Wide at your door step Free pickup with  real time tracking status</div>
   <div className='d-flex justify-content-center w-100 my-4' onClick={() => {navigate('/login')}}> <div className='bBtn text-center'>Book Now <span className=''></span></div></div>
    </Drawer> */}
</div> }

{invalid &&  <div className='d-flex justify-content-center flex-column'>
    <div className='text-danger  text-center fs-4 mt-4'>Invalid Tracking Number</div>
    <div className='d-flex justify-content-center mt-4'><img src={Invalid} className='img-Track' /></div>
    <div className='text-center text-muted fs-6 px-3 '>The tracking number you entered is invalid. Please correct it and retry.</div>
</div>}

{!data && load  && <Spinner/>} 
<Footer />   
</div>
 </>
  )
}

import React from 'react'
import { FoodItem } from '../../Components/FoodItem.jsx'

import { BsChevronLeft } from "react-icons/bs";
export const Food = (props) => {
   const back =() =>  props.back('close')
   const typAhead =() => {} 
  return (
   <>
<div>
  
<div className="parcelHeader"><span className="px-2  back" onClick={back}><BsChevronLeft size="18"/></span> Food Details</div>

</div>
   </>
  )
}

import React from 'react';

const Service =() =>{
    return(
        <>
        <h1>Service</h1>
      </>
    )
}

export default  Service;

import React, { useState } from 'react';
import './SmallTrack.css';
import { IoMdSend } from "react-icons/io";
import { Link } from "react-router-dom"; 

export const SmallTrack = () => {
    const [trk,setTrack] = useState('')

  return (
      <>
      <div className='container-fluid trck '>
          <div className='d-flex justify-content-between ps-2 bg-white my-1'>
            
              <input className='trc-input ' onChange={(e) => setTrack(e.target.value)}  placeholder='Track your shipment'/>
             
              <Link to={'/tracking/'+trk}>   <span ><IoMdSend size="25" color="#000"/></span></Link>

            
          </div>
          <div className='border mt-2 mt-1'></div>

      </div>
      </>
  )
};

import React, { useState,useEffect } from 'react'
import { BsCheck2All } from "react-icons/bs";
import { FiTruck } from "react-icons/fi";
import { Header } from '../../Components/Header.jsx';
import { MyShipment } from '../../Components/MyShipment.jsx';
import Drawer from "react-bottom-drawer";
import Moment from 'moment';
import './AddressBook.css'
import { FaRegDotCircle } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import { MdOutlineSearchOff } from "react-icons/md";
import axios from 'axios';
import DHL from './dhl.png'
import FEDEX from './fedex.png'
import UPS from './ups.png'
import { BsWhatsapp } from "react-icons/bs";
import { Spinner } from '../../Components/Spinner.jsx';


export const AdderssBook = (props) => {
  let b=0;
  const [isVisible, setIsVisible] = useState(false);
  const [data,setData] = useState('')
  const [pikAddress, setPikAddress] = useState('')
  const [awb,setAwb] = useState('')
  const [netImg,setNetImg] = useState('')
  const [netSz,setNetSz] = useState('')
  const [load,setLoad] = useState(false)
  const [empty,setEmpty] = useState(false)


    const back =() => {
        props.back('close')
    }
const typAhead =() => {

}



useEffect(() => {
  const fetch =async()=> {
      const response = await axios.get(`https://www.queekdelever.com/queekApi/pickupAddress.php?custcode=${props.user}`)
      if(response.data)
      {
        if(response.data !=='noService')
         { setPikAddress(response.data)
          setEmpty(false)
        }
          else
         { setPikAddress('')
         setEmpty(true)
        }
      }
  }
  if(!pikAddress)
  fetch()
})



  const fetch1 = async(e) => {
    setLoad(true)
    fetch(`https://www.queekdelever.com/queekApi/TrackingDetails.php?awb=${e}`)
    .then(res =>res.json())
    .then(data => {
      setAwb(e)
      setData(data)
      setLoad(false)
      setIsVisible(true)

      if(data[0].mode.trim() ==='DHL')
           { setNetImg(DHL)
            setNetSz('dhlSize')}
            if(data[0].mode.trim() ==='FEDEX')
           { setNetImg(FEDEX)
           setNetSz('fedexSizs')}
            if(data[0].mode.trim() ==='UPS')
           { setNetImg(UPS)
            setNetSz('upsSize')
            }

  
  })
  }
  
  const share =() => {
    window.open(`whatsapp://send?text=https%3A%2F%2Fwww.queekdelever.com%2F%23%2Ftracking%2F${awb}`)
  }

const getStatus =(e,f,g,h,i) => {


  if(f==='Label Created')
  {
    props.back('LocalDel')
    props.awb(e)
  }

//   if(h === 'Local')
//  { props.back('LocalDel')
//   props.awb(e,f,g,h,i)
// }
  else
  fetch1(e)
  // window.open(`https://www.queekdelever.com/#/tracking/${e}`)
 
}
const close =() => setIsVisible(false)

let a=0;
  return (
 <>

<Header name="My Pickups" back ={back} bottomBtn="no" Bar={'no'}  Placeholder="Search new location for pickup" typAhead={typAhead} show='' Loading={'done'} >

{pikAddress && pikAddress.map((x) => 
<div key={a=a+1}>
<div  onClick={() => getStatus(x.awb,x.status)}><MyShipment status={x.status}  pLoc={x.pikAddress} pCity={x.pCity} dLoc={x.delAddress} dCity={x.city} awb={x.awb} /></div>
 <div><hr className='divide' /></div>
</div>
 )}

<div className={`${empty ? 'd-none':'d-block'}`}>
{pikAddress?"": <>
  <div className="card-body">
    <div className="avatar-placeholder placeholder rounded-circle"></div>
    <div className="text-placeholder placeholder w-50"></div>
    <div className="text-placeholder placeholder w-75"></div>
</div>
   
<div className="card-body">
    <div className="avatar-placeholder placeholder rounded-circle"></div>
    <div className="text-placeholder placeholder w-50"></div>
    <div className="text-placeholder placeholder w-75"></div>
</div>

<div className="card-body">
    <div className="avatar-placeholder placeholder rounded-circle"></div>
    <div className="text-placeholder placeholder w-50"></div>
    <div className="text-placeholder placeholder w-75"></div>
</div>

<div className="card-body">
    <div className="avatar-placeholder placeholder rounded-circle"></div>
    <div className="text-placeholder placeholder w-50"></div>
    <div className="text-placeholder placeholder w-75"></div>
</div>
</>}
</div>

{empty && <div className={`${empty ?'d-block':'d-none'}d-flex flex-column align-items-center mt-5`}>
<div className='mt-5 d-flex justify-content-center'><MdOutlineSearchOff size='80' /></div>
  <div className='text-center text-muted'>Sorry, No shipment record found</div>
</div>}

<Drawer isVisible={isVisible} >
  <div className=' py-2 d-flex justify-content-between'> 
  <div><span className='fName pe-1'>Tracking ID#</span> <span className='fName '>{awb}</span></div>
  <div onClick={() => setIsVisible(false)}><GrClose size='18' /> </div>

  </div>

  <div className='pk-con d-none' >
    <div className='d-flex justify-content-between align-item-center'>
   {data && data[0].typeOf ==='Domestic' && <div className='pt-2 ps-2'> {data[0].curStatus === 'Delivered' ? <BsCheck2All color='#008000' size='19' /> : <FiTruck size='24' /> }  <span className='ps-1'>{data[0].curStatus}</span></div>}
      <div className=' pk-caption px-2'>
      { data && data[0].typeOf !=='Domestic' &&  <div className='fName py-1'>Handle By</div>}
        { data && data[0].typeOf !=='Domestic' && <div ><img src={netImg} className= {netSz}  />  {data && data[0].fwdNo && <span className='ms-0 fName'>Awb: {data && data[0].fwdNo}</span>}</div>}

      </div>
      <div onClick={share} className='me-3 mt-1'><BsWhatsapp size='23' color='#075E54' /> </div>
    </div>
  </div>
<div className='d-flex flex-column justify-content-center w-100 mt-3'>
{data && data.map((x)=>
<div key={b=b+1} className={`px-2  ${b >0 ? 'd-block' :'d-none'} `} >

<div className='d-flex align-items-center'>
    {/* ****************Date & time ************** */}
    <div className='d-flex flex-column text-end mt-3 mb-2 dateWidth'>
        <div className='dateD'>{x.date}</div>
        <div className='time d-flex justify-content-end'>{x.time}</div>
    </div>

    {/* *************************************************** */}
    
<div className='d-flex flex-column justify-content-center align-items-center position-relative  '>
<div className='px-3 zIndex'><FaRegDotCircle size='20' color={b===1 ? '#008000' :'gray'}  /></div>
<div className={`TranLine1 ${data && data.length-1 === b ?'d-none' :'d-block'} `}  ></div>

</div>

<div className='d-flex flex-column mt-2 w-75 '>
    <div className='fName twoLine '>{x.status}</div>
    <div className='rAdd'>{x.loc}</div>
</div>

</div>


</div>
)}</div>


</Drawer>
 </Header>
{load && <Spinner />}
 
 </>
  )
}

import React from 'react'
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import { BiRupee } from "react-icons/bi";

export const PaymentGateway = (props) => {
    const formatter = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 })
    const Razorpay = useRazorpay();
// create new order
const createOrder =async() => {
  return '3553535'
}

const handlePayment =async(params) => {
    const order = await createOrder(params); //  Create order on your backend
                const options = {
                    key: "rzp_live_aXWTAC7h5OLQWU", // Enter the Key ID generated from the Dashboard
                    amount: params *100 , // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                    currency: "INR",
                    name: "Queek Delever",
                    description: "Test Transaction",
                  //   image: "https://www.queekdelever.com/img/icon.png",
                  //   order_id: "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
                    handler: function (response) {
                        
                      updatePay(params,response.razorpay_payment_id)
                       setTimeout(() => { props.success('pickReq')},100)

                      props.payId(response.razorpay_payment_id)
                      props.custMob(props.user)
                      // alert(response.razorpay_payment_id);
                      // alert(response.razorpay_order_id);
                      // alert(response.razorpay_signature);
          
                    // shipment && update()
                       
          
                     
          
                  // console.log(response.razorpay_payment_id)
                  // console.log(response.razorpay_order_id)
                  // console.log(response.razorpay_signature)
                  
                    //   props.back('paymentDone')
                    //   props.rate('Main')
                    //   props.active(false)
                    },
                    prefill: {
                      name: "Piyush Garg",
                      email: "youremail@example.com",
                      contact: props.user,
                    },
                    notes: {
                      address: "Razorpay Corporate Office",
                    },
                    theme: {
                      color: "#ffe600",
                    },
                  };
                
                  const rzp1 = new Razorpay(options);
                
                  rzp1.on("payment.failed", function (response) {
                    alert(response.error.code);
                    alert(response.error.description);
                    alert(response.error.source);
                    alert(response.error.step);
                    alert(response.error.reason);
                    alert(response.error.metadata.order_id);
                    alert(response.error.metadata.payment_id);
                  });
                
                  rzp1.open();
                
  }

  const bookAnother = () => {

    props.SenId(props.user)
    props.backToHome('main')
    props.back('close')
    // props.rates('main')
    // props.active(false)
    // props.localId('')
    // props.cart(props.user)
  }

  const curDate =(e) => {
    const current = new Date();
    let month = current.getMonth()+1
     if (month <= 9)
      month = '0'+ month
    
      let day= current.getDate()+e
      if ( day <= 9)
      day = '0'+ day
    
    return `${current.getFullYear()}-${month}-${day}`;
  }
  

  const updatePay =(params,id) =>  {
   let today = new Date(),
    time = today.getHours();
    
  time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    fetch(`https://queekdelever.com/queekApi/cutoffTime.php?time=${time}`)
    .then( res => res.json())
    .then (data => 
      {
        if(data[0].shedule === 'Today')
        pickupNow(params,id,curDate(0))
        else
        pickupNow(params,id,curDate(1))

      }
      )
}
 const pickupNow =(params,id,pkDate) => {
  fetch(`https://queekdelever.com/queekApi/paymentTrans.php?custId=${props.user}&TransId=${id}&payment=${params}&mode=Online&pkDate=${pkDate}`)
  .then(res => res.json())
  .then(data => data)
}


  return (
   <>
  {!props.emp &&  <div onClick={bookAnother} className='payment-btn1 cursor user-select-none '>Book another</div>}
        {/* formatter.format(shipment[].total) */}
        <div onClick={() => handlePayment(props.amount)} className='payment-btn cursor bg-dark user-select-none text-white d-flex justify-content-center '><div>Payment</div> <div className='ps-2'><BiRupee/> </div><div>{(props.amount)}</div></div>
  

   </>
  )
}

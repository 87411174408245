import React,{useState, useEffect} from 'react'
import MapWithADirectionsRenderer from '../../Components/LocalMap.jsx';
import LocalMap1 from '../../Components/LocalMap.jsx';
import { withScriptjs } from "react-google-maps";
import Map from '../../Components/Map.jsx';
import './LocalDelivery.css'
import { BsThreeDotsVertical } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { IoIosCall } from "react-icons/io";
import {SphericalUtil, PolyUtil} from "node-geometry-library";
import { FaRoute } from "react-icons/fa";
import { BsChevronLeft } from "react-icons/bs";
import { FcAlarmClock } from "react-icons/fc";
import axios from 'axios';
import Moment from 'moment';
import pick from './origin.svg'
// import * as Pusher from "pusher"

class MapView extends React.Component {

  
  shouldComponentUpdate(nextProps) {
// return nextProps.org !== this.props.org;
    return this.props.update !=='false'
  }



  render(){

   
    const MapLoader = withScriptjs(Map);

const route =(e) => {
if(e === 'ok')
this.props.routes(true)
else
this.props.routes(false)
}
  return  <MapLoader
  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBah2C34AUXeAESdNgB5GoXKgk6XhlYhMU"
  loadingElement={<div style={{ height: `100%` }}  />}
  origin = {this.props.org}
height={this.props.height}
  marker={false}
  route = {route}
/>
  }
}



export const LocalDelivery = (props) => {
  const [roate,setRotate]= useState('')
  const MapLoader = withScriptjs(Map);
const [tracking,setTracking] = useState('')
const [curStatus,setCurStatus] = useState('')
const [org,setOrg] = useState('')
const [dest,setDest] = useState('')
const [update,setUpdate] =useState(true)
const [status,setStatus] = useState('')
const [pickpBoy,setPickBoy] = useState({lat:30.8964,lng:75.9095})
const [ht,setHt] = useState('0px')
const [hide,setHide] = useState(false)

const [localDeliver,setLocalDeliver] = useState('no')


  const back = () => {
    props.back("close");
    
  };


  useEffect(()=>{
  console.log(props.awb)
    fetch(`https://www.queekdelever.com/queekApi/pickupStatus.php?awb=${props.awb}`)
    .then(res => res.json())
    .then(data => 
      {

        if (data[0].status !=='Confirm')
        {
         props.back('close')
         return
        }
        else {
          setStatus(data)
        let lat =''
    let lng=''
        lat =parseFloat(data[0].pickupLat)
        lng =parseFloat(data[0].pickupLng)
    
       setOrg({lat,lng})
        }
      }
      )

//     const fetch1 = async() => {
//    const response = await axios.get(`https://www.queekdelever.com/queekApi/pickupStatus.php?awb=${props.awb}`)
//    if(response.data)
//    {
    
//     let lat =''
//     let lng=''

//     // setTimeout(()=> {  setUpdate('false')},500) 
//     // setFlick(false)
   
//     setStatus(response.data)

//    if(response.data[0].status ==='Accept')
//    {
//     lat =parseFloat(response.data[0].boyLat)
//     lng =parseFloat(response.data[0].boyLng)
//     setOrg({lat,lng})
  
//    lat = parseFloat(response.data[0].pickupLat)
//    lng = parseFloat(response.data[0].pickupLng)
//     setDest([{lat,lng}])
//    }

//    if (response.data[0].status !=='Confirm')
//    {
//     props.back('close')
//     return
//    }

//    else 
//    {
//       lat =parseFloat(response.data[0].pickupLat)
//      lng =parseFloat(response.data[0].pickupLng)
 
//     setOrg({lat,lng})
   
//     lat = parseFloat(response.data[0].destLat)
//     lng = parseFloat(response.data[0].destLng)
  
//     // setDest([{lat,lng}])

// }
//     lat = parseFloat(response.data[0].boyLat)
//     lng = parseFloat(response.data[0].boyLng)
//     // setPickBoy({lat,lng})
//      }
//         }

//         fetch1()
      
      
          // setTimeout(()=> { setUpdate('true')},500) 

//     const interval = setInterval(() => {
//        fetch()

// },15000)
//         return () => clearInterval(interval)
  
    },[])
  
useEffect(()=> {
  setUpdate(false)
  setTimeout( ()=> {setUpdate(true)},200)
},[ status && status[0].status])

const routes =() => {
setTimeout(() => {setHide(true)},500)
}
  return (
  
  <div className='position-relative'>

{/* {org && dest && update && <MapWithADirectionsRenderer org={org} dest = {dest}  update={true} boy={pickpBoy}/> } */}

{org && <MapView org={org} routes={routes}  update={update} height={ht}  />}
 {/* <div className= {` ${flick ?'d-block':'d-block'}`}>1 <MapView1 org={org} dest = {dest}  update={update} boy={pickpBoy} route={route} mapRefesh ={mapRefesh} /> </div> */}

{/* <div className= {`${!flick ?'d-block':'d-none'}`} >Map1</div> */}
{/* <div className= {`${flick ?'d-block':'d-none'}`} >Map2</div> */}

  {/* waiting for pickup */}
  
  {status ? status[0].status ==='Confirm' && status[0].network ==='Priority Delivery' ?
   <div className={`d-flex  justify-content-center d-none`}>
     <div className='overlayPickup '>
      <div className='d-flex flex-column align-items-center'>
      <div className='waitPik mt-3'></div>
       <div className='mt-4'>Waiting for pickup</div>
       <div className='ptext text-muted text-center px-2 mt-3'>Sit back and relax while your order is being prepared or packed  and we’ll notify once it’s done</div>
      </div>
     </div>
     </div> :"" :""}

    {/* assigned pickup boy */}
  {status && status[0].status !=='Pickup' &&  <div className='d-flex  justify-content-center  '>
     <div className='overlayPickup1 justify-content-start '>
       {/* row 1st */}
      <div className='d-flex flex-column ps-4 mt-3'>
   {status && status[0].status ==='Out for pickup' && <div >  <img src={'https://www.queekdelever.com/DeliveryBoy/delivreryBoy.png'} alt='img' className='pic' /></div>}
    {status && status[0].status ==='Confirm' &&<div className='d-flex justify-content-center ps-2 mt-3'><FcAlarmClock size='26' /> </div>}
     <div className='ps-3'><BsThreeDotsVertical/></div>
     <div className='hiLeft'><HiLocationMarker size='26' /></div>
      </div>

      {/* row 2nd */}
<div className='ps-3 mt-3 w-100 pe-3'>
{ status && status[0].status ==='Out for pickup' &&  <div className='position-relative '>
  <div className='BoyName mt-2'>Sunny Sharma</div>
  <div className='BikeN text-muted mt-1'>PB 10 ED 5021</div>
  <div className='position-absolute top-0 start-100 translate-middle  pe-4 mt-4 '><div className='call '> <IoIosCall color='white' /></div></div>
  </div>}

{status && status[0].status ==='Confirm' && <div>
<div className='position-relative'>
<div className='epl'>Estimated Pickup Time</div>
<div className='ept'>04:30 pm - 06:30 pm</div>
</div>
</div>}

  <div><hr className='pdividie my-2'  /></div>

  <div className='pdLabel text-muted d-flex justify-content-between'>Pickup Location {status && status[0].status==='Out for pickup' && <div className='onwB '>on the way</div>}</div>
  <div className='pdLoc text-truncat '>{status[0].pikAddress}</div>


</div>
     </div>
     </div>}

     {/* pickup and drop delivery */}

   {status ? status[0].status === 'Accept' ?  <div className= {`d-flex  justify-content-center `}>
     <div className='overlayPickup2 justify-content-start '>
       {/* row 1st */}
      <div className='d-flex flex-column ps-4 mt-3'>
    <div>  <img src={'https://www.queekdelever.com/DeliveryBoy/delivreryBoy.png'} alt='img' className='pic' /></div>
     <div className='ps-3 '><BsThreeDotsVertical size= '24' /></div>
     <div className='ps-3'><HiLocationMarker size='26' /></div>
     <div className='ps-3'><BsThreeDotsVertical  size='24'  /></div>
     <div className='ps-3'><HiLocationMarker size='26' /></div>
      </div>

      {/* row 2nd */}
<div className='ps-3 mt-3 w-100 pe-3  '>
  <div className='position-relative'>
  <div className='BoyName mt-2'>Sunny Sharma</div>
  <div className='BikeN text-muted mt-1'>PB 10 ED 5021</div>
  <div className='position-absolute top-0 start-100 translate-middle  pe-4 mt-4 '><div className='call '> <IoIosCall color='white' /></div></div>
  </div>
  <div><hr className='pdividie my-1'  /></div>

  <div className='pdLabel  d-flex justify-content-between'>Pickup Location <div className='onwB d-none'>on the way</div></div>
  <div className='pdLoc text-muted  '>Opp. kutia sahib gurudwara, chandigarh road, ludhiana 141010,punjab</div>
  <div><hr className='pdividie my-1'  /></div>

  <div className='pdLabel d-flex justify-content-between'>Drop Location <div className='onwB'>on the way</div></div>
  <div className='pdLoc  text-muted '>Opp. kutia sahib gurudwara, chandigarh road, ludhiana 141010,punjab</div>

</div>
     </div>
     </div> :"":""}




     <div className={`${props.newUser ?"d-none":"d-block"} MapHead`}>
            <div onClick={back} className="curBack">
              <BsChevronLeft color="#fff" size="20" />
            </div>
          </div> 

{/* custom marker  */}
{hide && <div className={` marker-pos1`}>
          <div className="d-flex justify-content-center align-items-end">
            <img src={pick}  alt='pickup' style ={{height:50}} />
          </div>
        </div>}

      {!hide &&  <div className='LocalWait '>
        <div><FaRoute size='40'  /></div>
          <div className='mt-1 rAdd'>Please Wait</div>
        </div>}

   </div>
   
  )
}

import React, { useState } from 'react'
import { MdOutlineMyLocation } from "react-icons/md";
import { BsSearch } from "react-icons/bs";
import pkImg from './pickup.jpg'
import Drawer from "react-bottom-drawer";

import './Location.css'
import { Header } from '../../Components/Header';
import { BottomMsg } from '../../Components/BottomMsg';
import { Spinner } from '../../Components/Spinner';

export const LocationOption = (props) => {
  const [serType,setType] = useState(true)
  const[tap,setTap] = useState('btn btn-warning')
  const[tap1,setTap1] = useState('cardPk')
  const [pkLoc,setpkLoc] = useState('')
  const [load,setLoad] = useState('')
  const [pkService,setPkService] = useState('')
  const [hide,setHide] = useState(false)

  let a=0;
const gpsLoc =() => {
  props.gps('getLoc')
}

const getLoc =() => {
  setTap('btn btn-outline-secondary')
  setTap1('cardPk')
}

const getLatLng1 = async(id,f) => {
  setHide(true)
  fetch(`https://queekdelever.com/queekApi/local_placeId.php?placeId=${id}`)
  .then(res => res.json())
  .then(data => {
    let lat =(data.results[0].geometry.location.lat)
    let lng =(data.results[0].geometry.location.lng)
    let strLen =(data.results[0].address_components.length)

    let city =data.results[0].address_components[strLen-5].long_name
    let pincode = data.results[0].address_components[strLen-1].long_name
    let state = data.results[0].address_components[strLen-3].long_name
    let country ='India'

    let pkAdd =[{
      lat: lat,
      lng: lng,
      city: city,
      pincode : pincode,
      state: state,
    }]

    pkPoint(pincode,pkAdd)
  //  console.log(pkAdd)
    // console.log(lat,lng,city,pincode,state)
  })
}

const pkPoint =(e,f) => {
  fetch(`https://queekdelever.com/queekApi/pkPoint.php?pincode=${e}`)
  .then(res => res.json())
  .then (data => {
    setPkService(data[0].Service)
    if(data[0].Service === 'Serviceable')
    {
      props.getLoc(f)
      
      return
    }
    setHide(false)
    setTimeout(() => {setPkService('')},2000)
  }
  )

}


const manuall =() => setType(false)
const typeAhead =(e) => {
  let srch =    e.replace(/\s+/g, '%20').toLowerCase()

  if(e.length > 0)
  setTimeout(() => {locFind(srch)},500)
}

  const locFind =(e) => {
    setLoad('done')
    fetch(`https://www.queekdelever.com/queekApi/local_autocomplete.php?places=${e}%punjab%20india`)
    .then(res => res.json())
    .then(data => {setpkLoc(data.predictions)
      setLoad('')
    })
  
  }

const back =(e) => {
 setType(true)
}
  return (
    <>
    
   <div className={`user-select-none vh-100 overflow-hidden ${ !serType ? 'd-none' : 'd-block'} `} >
    <div className=' mt-5 d-flex justify-content-center'><div><img src={pkImg} className='size-Pk' /></div></div>
    <div className='px-3 d-flex justify-content-start flex-column mt-5'>
  <div className=''>Let's Get Delivered</div>
  
  <div className='py-2'><h2>Pickup from your location</h2></div>
</div>
    <div className='d-flex justify-content-center  align-items-start h-50 mt-5 d-sm-block d-md-none   pkCon'>
<div className='d-flex flex-column  justify-content-center w-100'>


<div className='d-flex flex-column h-100  w-100 p-4'>

<div className={`cardPk p-3  w-100 d-flex justify-content-between align-items-end mt-5  ${tap1} ${props.rst1} `}  onClick={gpsLoc}>
<div className=' text-nowrap  d-flex align-items-center  d-flex justify-content-between' onClick={getLoc}> <div className='fName2'>Use my current location</div></div>
<div><MdOutlineMyLocation size='25' /> </div>
</div>


<div className='text-center py-3'>OR</div>


<div className='locSearch d-flex align-items-center' onClick={() => manuall()} >
<div className='ps-1'><BsSearch size='15' /></div>
  <div className='ps-2 srchPlaceholder user-select-none'>Search your pickup Location</div>
 </div>
  </div>
</div>


    </div>

    <div className='d-none d-md-block '>
      <div className='pkCon py-5 px-3 '>
        <div className='srchLoc d-flex justify-content-between align-items-center p-2'  >
        <div className='srchPlaceholder1' onClick={() => manuall()}>Search Pickup Location</div>
        
        <div className={`d-flex align-items-center  pcBtn ${tap} ${props.rst} `}  onClick={gpsLoc}>
        <div><MdOutlineMyLocation size='20' /> </div>
        <div className='fName2 ps-2 text-nowrap' onClick={getLoc} >Using GPS Location</div>
       
        </div>
        </div>
      
      </div>
    </div>
    </div>

    <div className = {` ${!serType ? 'd-block' :'d-none'} `} >
      <div> 
    

      <Header name="Choose pickup address" back ={back} bottomBtn="no" Bar={true} inputFocus={'yes' }  Placeholder="Search for area,street name..." typAhead={typeAhead} show={'props.show'}   Loading={load} >

   {pkLoc.length > 0 &&
   <div className=''>{pkLoc.map( x => <div className='locCon'  onClick={ () => getLatLng1(x.place_id,x.description)}    key={a=a+1}>  <div className='locHead'> {x.description}</div>  
    {/* <div className='desc text-truncate'>{x.description}</div> */}
    <hr className='my-2 px-0 divider'/></div>)}
    </div>
  }
      {pkService === 'Sorry' && <div  ><BottomMsg name = 'Sorry, no pickup service available at this location' /></div>}
      </Header>

      </div>
    </div>

{ hide && <Spinner />}

    </>
  )
}

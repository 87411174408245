import React from 'react'
import { Footer } from './Footer'
import Navbar from './Navbar'

export const Prohibited = () => {
  return (
    <div>
        <Navbar />
        <div className='pt-3'>
        <div className='container py-4'>
            <div className='row'></div>
        <div className='text-left spaceH d-flex align-item-center ' > <div className='highB '> </div > <div className='ps-2'><h2> Prohibited Item</h2></div> </div>
       
        <div className='pt-5'><ul>
            <li>Stamped and prepaid postal envelopes and parcels</li>
            <li>Precious stones, gems and jewelry</li>
            <li>Uncrossed (bearer) drafts / cheque, currency and coins</li>
            <li>Poison</li>
            <li>Firearms, explosives and military equipment</li>
            <li>Hazardous substance and radioactive material</li>
            <li>Foodstuff and liquor</li>
            <li>Pornographic material</li>
            <li>All items that infringe the Indian Postal Act Of 1898 and all restricted items as per the guidelines of IATA</li>

            </ul></div>

            <div className='py-4'><h5>Dangerous Goods (DGR)</h5></div>

            <div>
                <ul>
                    <li>Acetone</li>
                    <li>Acetylene (Liquid Or Dissolve)</li> 
                    <li>Acids (Except Citric & Tartic Ammonium Per Sulphate</li>   
                    <li>Ammonium Nitrate</li>
                    <li>Ammunition (Except Safety Cateridges)</li>
                    <li>Attack Benzine</li>
                    <li>Benzoline</li>
                    <li>Batteries Charged</li>
                    <li>Calcium Carbide</li>
                    <li>Camphor oil</li>
				<li>Carbon bi sulphide</li>
				<li>Carbolic acid (in steel cylinder)</li>
				<li>Carbolic acid</li>
				<li>Gas</li>
				<li>Cartridges of any kind (except safety cartridges) celluloid &amp; xsionite or other similar substances </li>
				<li>++ Chlorate of potassium </li>
				<li>Chlorate of soda </li>
				<li>Colloidal cordites </li>
				<li>Dynamite </li>
				<li>Ether eua oxygen </li>
				<li>Explosives of all kinds (including percussion caps </li>
				<li>Film unless packed in fireproof metal containers </li>
				<li>Firework &amp; crackers </li>
				<li> Photographic fulminating powder</li>
				<li> Gases of any description (under pressure)</li>
				<li>Gun powder </li>
				<li>Air gun </li>
				<li>Pistols </li>
				<li>Hydrogen peroxide </li>
				<li>Kerosense </li>
				<li>Matches luciter &amp; percussion </li>
				<li>All kinds meal worms methylated spirit naphtha </li>
				<li> Natrium peroxide nitrate of soda</li>
				<li>Nitro glycerin </li>
				<li>Nitro of lime calcium </li>
				<li>Paraffin peticum &amp; its products (excepting when used as fuel and contained in tank provide for the purpose) </li>
				<li>Phosphorous </li>
				<li>Pyridine pyrotechnics (other than those required for the navigation of a/c, rocket safety dynamite) </li>
				<li>Fuses (with or without detonatorm saltpeter) </li>
				<li>Spirits or wine </li>
				<li>Varnish etc containing of gases </li>
				<li>Explosive rivets permanent  </li>
				<li> Magnets</li>
				<li>Mercury oxide </li>
				<li>Metallic mercury radium &amp; radio active materials having a flash point below 79 f </li>
                <div className='mt-4'>Anything dangerous or obnoxious or which may cause inconvenience to passengers and all such goods which are prohibited for the carriage under the Indian aircraft rules 1934. For restricted articles its packaging & labeling etc., please refer to the book named IATA regulation relating to the carriage of restricted articles by air.</div>
                </ul>
            </div>
       
            <div className='py-4'><h5>Prohibited Articles</h5></div>

            <div >
            <ul>
			<li> Acetyl benzoly peroxide</li>
				<li>Solid or solutions </li>
				<li> Aerolein uninhibited aerosol products class a poisons a.o.s. air liquid non pressurized aluminium di ethyl mono chloride aluminium dross</li>
				<li>Wet arsine black powder boms explosive caps blasing </li>
				<li>Chloropicrin and methyl chloride mixtures </li>
				<li>Cigarettes self-uninhibited explosives or explosives devices new fire extinguishers charges </li>
				<li> Fuses</li>
				<li> Detonating grenade</li>
				<li>Hydrogen sulphide isopropyl uninhibited </li>
				<li>Hand pistol or rifle with bursting charges </li>
				<li> Grenades</li>
				<li> Tear gas</li>
				<li>Hydrogen liquid </li>
				<li>Per carbonated stabilized isoprophyl unstabilized </li>
				<li>Low explosives </li>
				<li> Nickel carpony</li>
				<li>Nitric oxide </li>
				<li> Oxide spent</li>


			</ul>
            </div>

            <div className='py-4'><h5>Restricted Articles</h5></div>
            <div>
            <ul>
			<li> Acetel</li>
				<li> Acetone</li>
				<li> Ammonium fluoride</li>
				<li>Argo </li>
				<li>Gaseous </li>
				<li> Arsenic acid solid barrium chlorate benzene</li>
				<li>Bornesol </li>
				<li>Calcium </li>
				<li>Chlorate carbon dioxide gaesous or liquefied </li>
				<li>Charcoal activated </li>
				<li>Charcoal actived </li>
				<li> Chloroform</li>
<li>Copper cyanide </li>
				<li> Crude oil petroleum</li>
				<li> Discetyl</li>
				<li> Diethylamide</li>
				<li>Ether </li>
				<li> Ferric chloride</li>
				<li>Formic acid </li>
				<li> Fuel aviation</li>
				<li> Turbine engine</li>
				<li>Helium gaseous </li>
				<li> Hydrochloric &amp; mixtures lead nitrate matches</li><li> safety (book card &amp; strike-box) malathion perfumes</li>
				<li>Methanol nitrogen gaseous &amp; silver netrate </li>

			</ul>
            </div>
        </div>
        </div>
        <Footer/>
    </div>
  )
}

import React, { useEffect, useRef, useState } from 'react';
import { NormalButton } from '../../Components/NormalButton.jsx';
import './Pickup.css';

import { ServiceSelection } from '../../Components/ServiceSelection1.jsx';
import { PickDrop } from '../../Components/PickDrop.jsx';
import { useNavigate } from 'react-router-dom';
import { AiOutlineEdit } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import { FaUserAlt } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";
import { BsCheckCircle } from "react-icons/bs";
import { BsChatLeftText } from "react-icons/bs";
import { BsBoxSeam } from "react-icons/bs";
import { MdCall } from "react-icons/md";
import { FaBuilding} from "react-icons/fa";
import { BsChevronLeft } from "react-icons/bs";



export const PickupReq = () => {
  const navigate = useNavigate();
  const [delType,setDelType] = useState('Domestic')
  const [hide,setHide] = useState('one')
  const [pkDetail,setPkdetail] = useState('')
  const [pkInf,setPkInf] = useState('')

  const login =() => {
    // navigate('/login');
  setHide('three')
  }
  const edit =() =>setHide('one')
  const login1 =() => {
    setHide('one')
    let pkInfs =[{
      name: name.current.value,
      mobile : mob.current.value,
      add : add.current.value
    }]

    setPkInf(pkInfs)
    setPkdetail(pkInf)
    setHead(true)
  }

 

  const serType =(e)=> {
    setDelType(e)  }

  const name = useRef()
  const mob = useRef()
  const  add = useRef()

  useEffect(() => {
    setHead(false)

    setTimeout(() => {
      name.current.focus()
    },500)
  })

  const hides =(e) => setHide(e)
  const [head,setHead] = useState(true)

  const pkLoc =() => setHide('two')
  return (
    <>

      {/* top shipping service */}
      <div className='position-relative'>


       
        {/* <hr className='mt-3' style={{color:'#ccc',padding:0,margin:0}} /> */}

        {/* hearder  */}
        <div className={`pickup mt-2 ms-3 ${head ? 'd-block' :'d-none'} `}>

          <p className='head1'>Request for</p>
          <p className='head2'>Parcel Pickup</p>
        </div>
        <div style={{ marginTop: 60 }}  className={`cardPickup position-relative pb-5 ${hide==='three' ?'bgGreen' :'bg-white'} `}>
          <div className={`${hide==='one'  ?'d-block' :'d-none' }`}>
        <div className="shipping-ser position-absolute top-0 start-50 translate-middle"  >
          <ServiceSelection serType={serType} />
        </div>
     <div className= {`pt-5 ${hide==='one' ? 'd-block':'d-none'} `}>   <PickDrop delType={delType} pkLoc ={pkLoc} pkInf ={pkInf} hides={hides}  /></div>

      {/* <div className='shipT text-muted ps-5 mt-2'>Shipment Type</div> */}

   

     <div className='position-absolute top-100 start-50 translate-middle w-100 px-3' >
         <div onClick={login} > <NormalButton name="Pickup Now" /></div>
        </div>
        </div>

          {/* pickup details */}
    <div className={`${hide==='two' ? 'd-block' :'d-none'} `}>
    <div className='d-flex justify-content-start  align-items-center '><span className='cursor' onClick={()=> 
    {
      setHide('one')
      setTimeout(() => {
        setHead(true)
      },500)
    }} > <BsChevronLeft size='20' color='#000' /></span> <span className='ps-2'>Pickup Details</span></div>
      <div><hr className='divide1' /></div>
      {/* person name details */}
      <div className='d-flex justify-content-start ms-1 position-relative' >
          <div className='d-flex flex-column  align-items-center mt-3' >
            <div >
              <FaUserAlt size="22" color="#AE030E" />

            </div>
            <div className='trans'></div>
            <div>
              <BsTelephone size="24" color="#008000" />
            </div>
             <div className='trans2 '></div>

            <FaBuilding size="20"  /> 

         
          </div>
          {/* textbox  */}
          <div className='ms-3  w-100 position-relative' >
          {/* <div className='pd-label'>Home</div> */}
           
        
         
       
         <div className='input_pick  d-flex mt-2 position-relative'  >
        
        <input  className='input_sizs' placeholder='Contact person' ref={name}   />
     
        {/* <span><FiCrosshair  color="gray" /></span> */}
        
        <div className='float-lbl'>Full name</div>
     
        </div>
       
        

              {/* <hr/> */}
            <div style={{ marginTop: 20 }}></div>

            <div className='input_pick  d-flex mt-2 position-relative '>
              <span className='mobIn'>              <input ref={mob} type='number'  className='input_sizs' placeholder='10 digit mobile number' /></span>
              {/* <span><FiHome  color="gray" /></span> */}
            
              <div className='float-lbl'>Contact number</div>
              <div className='position-absolute start-0 top-50 translate-middle'><span className='ps-5 fName'>+91</span></div>
              </div>

                 {/* <hr/> */}
             <div style={{ marginTop: 20 }}></div>

<div className='input_pick  d-flex mt-2 position-relative '>
  <input ref={add}  className='input_sizs' placeholder='enter street, house no, flat no' />
  {/* <span><FaBuilding  color="gray" /></span> */}

  <div className='float-lbl'>Pickup address</div>

  </div> 

         
           
           {/* <div className=' reverse '><RiArrowUpDownFill size="20" color='#fff' /></div> */}
        
      

          </div>


          </div>

     {/* end pickup person name */}
     
    </div>
          {/* end pickup details */}

            
      <div className= {`${hide ==='three' ? 'd-block':'d-none'} `}>
      <div className=' position-absolute top-0 start-50 translate-middle checkIndex '><BsCheckCircle color='#008000' size='50' /></div>

        <div className='mt-5 d-flex flex-column justify-content-center ms-3'>
          <div className='my-3  d-flex justify-content-center ps-1'><h4>Pickup successfully sent !!!</h4></div>
          <div><hr className='divide1' /></div>
          <div className='btn btnlogin'><span className='pe-2'><BsChatLeftText  /></span>Chat with us</div>
          <div className='btn btnlogin1 mt-2'> <span className='pe-2'><BsBoxSeam /></span> Direct Book</div>
          <div className='btn btnlogin2 mt-2'><span className='pe-2'> <MdCall /></span><span className='telPhone'><a href="tel:7814002400">+91 78 1400 2400</a></span>
</div>
          </div>

      </div>
          <div className= {`position-absolute top-100 start-50 translate-middle w-100 px-3 ${hide==='two' ?'d-block':'d-none'} mb-3 `} >
         <div onClick={login1} > <NormalButton name="Next" /></div>
        </div>
        </div>
        
      </div>

    </>
  )
};



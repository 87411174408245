import React, { useState,useEffect }  from 'react'
import { Header } from '../../Components/Header.jsx'
import { BsThreeDotsVertical } from "react-icons/bs";
import { SpinnerCircular } from 'spinners-react';

import './Pickup.css'
import { ImHome } from "react-icons/im";
import { GrClose } from "react-icons/gr";
import { BsCheckCircleFill } from "react-icons/bs";
import { Local } from './Local.js';
import { HiOfficeBuilding } from "react-icons/hi";
import { RiMapPinRangeFill } from "react-icons/ri";
import { BsPlusCircleDotted } from "react-icons/bs";
import axios from "axios";
import { Input } from '../../Components/Input.jsx';
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { RiMapPin2Line } from "react-icons/ri";
import { RiHome5Line } from "react-icons/ri";
import { NormalButton } from '../../Components/NormalButton.jsx';
import { Spinner } from '../../Components/Spinner';
import Autocomplete from "react-google-autocomplete";
import { MdWork } from "react-icons/md";
import Drawer from "react-bottom-drawer";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Helmet } from "react-helmet";


export const Pickup = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [del,setDel] = useState(false)
  const[ishide,setHide] =useState(true)
  const [isType,setType] =useState('')
  const [Load,setLoad] = useState('')
  const [pikLoc,setpikLoc] = useState([])
  const [pos,setPos] =useState(null)
  const[resp,setResp] =useState(false)
  const[pik,setPik] =useState('')
  const [save,setSave] =useState('')
  const [home,setHome] =useState('')
  const[office,setOffice] =useState('')
  const [value, setValue] = useState(null);
 const [loadHide,setLoadHide] = useState(false)
 const [delSr,setDelSr] = useState('')


    const back =() => {
        props.back('close')
        props.mapRefresh('false')
        setHide(true)
 }

 

 const location =() => {
  props.tagName('Home')
  props.person('')
  props.add('')
  props.locSr('')
props.Location('location')
}

 const deleted = (name,person,add,sr) =>{
  props.tagName(name)
  props.person(person)
  props.add(add)
  props.locSr(sr)
  setDelSr(sr)
  setIsVisible(true)
}

 useEffect ( () => {


  const loadLocation = async () => {
      const response = await axios.get(`https://www.queekdelever.com/queekApi/pic_location.php?custcode=${props.user}`)
  if(response.data.length > 0){
    setpikLoc(response.data)
    setResp(true)
    props.newUserr(false)
  
   for(let i=0;i< pikLoc.length;i++)
   {
    if(pikLoc[i].name ==='Home')
    {
      setHome('Home')
    }
    
    else if(pikLoc[i].name ==='Office')
    {
      setOffice('Office')
    }
    if(pikLoc[i].active)
    {
     setPos(pikLoc[i].sr)
    }
   }
  
 
  }
  else {
    props.newUserr(true)
    props.back('location')
    props.tagName('Home')
    props.person('')
    props.add('')
    props.locSr('')
  }
  }
  loadLocation()
 },[pikLoc.length <= 0])

 const typeAhead = (e) => {


 if(e.length > 1)
 {
   setHide(false)
   setType(e)
 }
 else if (e.length <= 0)
 {
   setHide(true)
   setType('')
 }

 }
 const desc =(e) => {
props.desc(e)

 }

 const placeId = (e) => {
   props.placeId(e)
  
 }

 const loading =(e) => {
  setLoad(e)
 }




 const addPickup =() => {
   setPik('hide')
   if(home !=='Home')
 {return   setSave('Home')}
 if(office !=='Office')
 {return   setSave('Office')}
setSave('other')

 }

 const saveAs =(e) => {
   setSave(e)
  //  setPikAdd({name:"",address:"",city:"",person_name:"",company_name:'',pincode:"",state:"",landmark:"",GSTIN:""})
  //  setWait(true
 }

 
const updateLoc =(e) => {
if(pos === e)
{back()}
else {
  const response =  axios.post(`https://www.queekdelever.com/queekApi/update_pickup.php?custcode=${props.user}&sr=${e}`)

  props.back('close')
  props.mapRefresh('true')
  setHide(true)

 
}

}

const dropDown =() => {}
const cancel =() => setIsVisible(false)

const Delete = async(e) => {
    setDel(true)

    const response=  await axios.get(`https://www.queekdelever.com/queekApi/deleLoc.php?sr=${delSr}&custcode=${props.user}`)
      if(response.status === 200)
    {  setIsVisible(false) 
        setDel(false)
        props.tagName('')
        props.person('')
        props.add('')
        props.locSr('')
        // if(shipment.length === 1 )
        // back()
        setpikLoc([])
    }

   }

//    useEffect(() => {
// console.log(props.bar)
//    },[])
  
const wait =(e) => {
  setLoadHide(e)
}

const edit =()=> {

  props.back('location')}

let a=0;
  return (
   
  <>
    {/* <Helmet>
   <script disable-devtool-auto src='https://cdn.jsdelivr.net/npm/disable-devtool@latest/disable-devtool.min.js'></script>
</Helmet> */}
  <div className='position-relative '>
    
  <Header name="Choose pickup address" back ={back} bottomBtn="no" Bar={props.bar} inputFocus={props.bar ? 'yes' :'no' }  Placeholder="Search for area,street name..." typAhead={typeAhead} show={'props.show'}   Loading={Load} >




  {pik ==='hide'? "": <>
  <div className={` ${ishide ? "d-block" : "d-none"} m-0 p-0 `}>
        {/* current location div */}
        {/* onClick={addPickup} */}
        <div onClick={location} className='currentLoc p-2 user-select-none d-none1 ' >
          <div className='d-flex align-items-center '><BsPlusCircleDotted size="22"/></div>
        <div className='currentCaption ps-3'>
            <div>Current Location</div>
            <div className='locCaption'>Using GPS Location</div>
        </div>
      </div>
      <hr className='my-0 px-0 divider'/>

    {/* pickup address list */}
    <div className={`${props.bar=='Search' ? 'd-none':'d-block'} `} >
    <div className="saveStyle">Saved address</div>

    
      { resp?
      
      pikLoc.map(x =>
    
        <div key={a=a+1} className='addCon  user-select-none' >
    <div className='position-relative ' onClick={()=> updateLoc(x.sr)}  >
      
      <div  className={`${x.name ==='Home'? "d-block" : "d-none"}` }  ><ImHome size="24"/></div>
      <div className={`${x.name ==='Office'? "d-block" : "d-none"}`} ><HiOfficeBuilding size="24"/></div>
      <div className={`${x.name ==='Work'? "d-block" : "d-none"}`} ><MdWork size="24"/></div>
    
      <div className={`${x.name !=='Office' && x.name !=='Home'  && x.name !=='Work' ? "d-block" : "d-none"}`} ><RiMapPinRangeFill size="26"/></div>
    <div className={`${x.active ?"d-block" : "d-none"} activeLoc` } ><BsCheckCircleFill color="#008000" size="15"   /> </div>
    
   
    </div>
    <div className='addreess position-relative w-100' >
     
      <div  onClick={()=> updateLoc(x.sr)}  className='title'>{x.name}</div> <div  onClick={()=> updateLoc(x.sr)} className=' addDetail '>{x.address}</div>
       <div onClick={() =>deleted(x.name,x.personName,x.address,x.sr)} className='cursor p-1    position-absolute start-100 top-50 translate-middle delte'><BsThreeDotsVertical color='black' /></div>
  
      </div>
      <hr className='my-0 px-0 divider'/>
       </div>

 
       ):""}
   {/* loading placehoderl */}
   
 
 {resp?"": <>
  <div className="card-body">
    <div className="avatar-placeholder placeholder rounded-circle"></div>
    <div className="text-placeholder placeholder w-50"></div>
    <div className="text-placeholder placeholder w-75"></div>
</div>
   
<div className="card-body">
    <div className="avatar-placeholder placeholder rounded-circle"></div>
    <div className="text-placeholder placeholder w-50"></div>
    <div className="text-placeholder placeholder w-75"></div>
</div>
</>}


    </div>
    </div>
    {/* search location div */}

    <div className={`${ishide ? "d-none" : "d-block"}  `}>
     <Local   typeAhead={isType} apiKey ={props.apiKey} Loading={loading} lat={props.lat} lng={props.lng}  placeId={placeId} Desc={desc} pCity ={props.pCity} location={location}  serType={'pickup'} pickup={''} wait={wait} />
   
    </div>

   </> }

   {pik ==='hide'?
<>

{/* *************************************************************SAve As location***************************************************************** */}
<div className={`saveAs ${office ==='Office' && home ==='Home'?"":"mb-4"} user-select-none`}>

            <div className={`col-sm-3 px-2 border  rounded d-flex align-items-center py-2  pe-3   ${save ==='Home'? "bg-dark":"bg-white"} ${home === 'Home'? "d-none":"d-block"} cursor `} onClick={() => home ==='Home'?"":saveAs('Home')} >
              <RiHome5Line size="16" color={`${save==='Home'?"#fff":"#000" }`} />
              <div className={`ps-3 SaveFnt  ${save ==='Home'?"text-white":"text-black"} `}>Home</div>
            </div>
            <div className={`col-sm-3 px-2  border rounded d-flex align-items-center py-2 pe-3  Savebtn" ${save ==='Office'? "bg-dark":"bg-white"} ${office ==='Office'?"d-none":"d-block cursor"}`} onClick={() => saveAs('Office')}>
              <HiOutlineOfficeBuilding size="16" color={`${save==='Office'?"#fff":"#000" }`} />
              <div className={`ps-3 SaveFnt  ${save ==='Office'?"text-white":"text-dark"} `}>Office</div>
            </div>
            <div className={`col-sm-3 px-2  border rounded d-flex align-items-center py-2 pe-3 Savebtn" ${save ==='other'? "bg-dark":"bg-white"} cursor  ${office ==='Office' && home ==='Home'?"d-none":"d-block cursor"}`}  onClick={() => saveAs('other')}>
              <RiMapPin2Line size="16" color={`${save==='other'?"#fff":"#000" }`} />
              <div className={`ps-3 SaveFnt  ${save ==='other'?"text-white":"text-dark"} `}>Other</div>
            </div>
          </div>
{/* ************************************************************************************************************************************************************************************************** */}

       
<Input  label="Contact person" type="text"    />
{ save ==='Office'? <Input  label="Company name (optional)"  /> :""}
<Input  placeholder="House no, flat no, street, avenue etc" label="Pickup address" type="text"  />

{/* <Local serType='pickup'  /> */}
{/* <Input  className="position-relative" placeholder="Pincode" type="number" len='6'/> */}
{/* <span className='floatCity'>Ludhiana <span className='ps-2 pe-0'> <BsCheck2Circle color="#008000" size="18" /></span></span> */}
{save ==='Office'? <Input  label="GSTIN (optional)" type="text"  />:""}
{save ==='other'? <Input  label="Save as" placeholder="eg. New office" type="text"/>:""}


</>

 
   :""}

<Drawer
      isVisible={isVisible}
     
    >
       <div className='d-flex align-items-center deleteHad justify-content-between'>
       <div className=' my-3 pe-2 pb-1 d-none'><RiDeleteBin6Line size="20" color='#d9534f' /></div>
       <div className='  text-dark '>Pickup Location ?</div>
       <div className='d-flex justify-content-end' onClick={() => cancel()} ><GrClose size='20' /></div>
     
       </div>
       {/* <hr className='my-0 py-0'/> */}

       <div className='text-muted fs-6 text-center my-3 d-none'>Are you sure you want to Edit or Delete</div>

  <div className='d-flex justify-content-start align-items-center mt-3'>
 
  <div className='d-flex justify-content-end w-100 mb-3'>
  <div className='mt-2 w-50' onClick={() => edit()}><button type="button" className="btn btn-outline-secondary">Edit</button></div>
 
  <button onClick={()=>Delete()} className="btn btn-danger w-50 mt-2" type="button" disabled={del? true : false}>
<div className={`${del ? 'd-block d-flex justify-content-center align-items-center' : 'd-none'}`} >
<div className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></div>
 <div className='ps-2'>Delete</div>
 </div>
<div className={`${del ? "d-none": 'd-block' }`}>Delete</div>
</button>
 
 </div>
  </div>
      </Drawer>
     
  </Header>
 
  {pik ==='hide'?<><div className='bottomBtn'><NormalButton name="Add Location" /> </div></> :""}


{ loadHide &&  <Spinner />}
  </div>
  </>
  )
}

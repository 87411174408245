import React from 'react';
import Navbar from './Navbar';
import { GoLocation } from "react-icons/go";
import { MdCall } from "react-icons/md";
import { HiMail } from "react-icons/hi";
import { Footer } from './Footer';
import { BiCurrentLocation } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import { AiOutlineClockCircle } from "react-icons/ai";
import localDel from './contact.jpg'



import { BsTelephoneFill} from "react-icons/bs";

import './Contact.css'


const Contact =() => {
    return (

<>
<div>
        <Navbar/>
        <div className='localBg pt-3  '>
        <div className='container  py-4'>
            <div className='row'>
                <div className='col-sm-6 d-flex flex-column justify-content-start '>
              <div className='text-left spaceH d-flex align-item-center ' > <div className='highB '> </div > <div className='ps-2 '><h2>Contact us</h2></div> </div>
             
             <div className='pt-5'>
                <div className='pt-5 d-flex '> <div><BiCurrentLocation size='30' color='black' /></div><div className='px-3 conFnt'> Opp. Kutia Sahib Gurudwara Chandigarh Road, Ludhiana 141010 Punjab, India</div></div>
             </div>

             
             <div className='pt-2'>
                <div className='pt-5 d-flex '> <div><BsTelephoneFill size='25' color='black' /></div><div className='px-3 conFnt'> +91 78 1400 2400</div></div>
             </div>
             

             <div className='pt-2'>
                <div className='pt-5 d-flex '> <div><HiOutlineMail size='28' color='black' /></div><div className='px-3 conFnt'>support@queekdelever.com</div></div>
             </div>

             <div className='pt-2'>
                <div className='pt-5 d-flex '> <div><AiOutlineClockCircle size='28' color='black' /></div><div className='px-3 conFnt'>09:30 am - 08:00 pm  (Mon-Sat)</div></div>
             </div>

                </div>


                <div className='col-sm-6 d-flex justify-content-end'>
                    <div cla    ssName='d-flex justify-content-center'><img src={localDel} className='locSiz' /></div>
                </div>
            </div>

            </div>
        </div>
       <Footer /> 
    </div>

</>

    )
}

export default Contact;
/*global google*/
import React, { Component ,useRef, useState } from "react";
import { withGoogleMap, withScriptjs, GoogleMap, DirectionsRenderer , Marker ,Polyline  } from "react-google-maps";
import pick from './origin.svg'
import { faMotorcycle,faMapPin } from "@fortawesome/free-solid-svg-icons";
import './Map.css'
import { isVisible } from "@testing-library/user-event/dist/utils";

const exampleMapStyles = [
  {
    featureType: "administrative.province",
    elementType: "labels",
    stylers: [
      { visibility: "off" }
    ]
  },
  
  {
    featureType: "poi",
    stylers: [
      { visibility: "on" }
    ]
  },
  {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
          {
              color: "#eeeeee",
          },
      ],
  },
  {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
          {
              visibility: "on",
          },
      ],
  },
  {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
          {
              color: "skyblue",
          },
      ],
  },
  {
    featureType: "poi.medical",
    elementType: "all",
    stylers: [
      { visibility: "off" }
    ]
  },
  {
    featureType: "landscape.man_made",
    elementType: "all",
    stylers: [
      { visibility: "off" }
    ]
  },{
    featureType: "poi.attraction",
    elementType: "all",
    stylers: [
      { visibility: "off" }
    ]
  },{
    featureType: "poi.business",
    elementType: "labels",
    stylers: [
      { visibility: "off" }
    ]
  },{
    featureType: "poi.government",
    elementType: "all",
    stylers: [
      { visibility: "off" }
    ]
  },{
    featureType: "poi.medical",
    elementType: "all",
    stylers: [
      { visibility: "off" }
    ]
  },{
    featureType: "poi.place_of_worship",
    elementType: "all",
    stylers: [
      { visibility: "off" }
    ]
  },{
    featureType: "poi.school",
    elementType: "all",
    stylers: [
      { visibility: "on" }
    ]
  },{
    featureType: "poi.sports_complex",
    elementType: "labels",
    stylers: [
      { visibility: "off" }
    ]
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color : "gray"
      }
    ]
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#fdfcf8"
      }
    ]
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#dfd2ae"
      }
    ]
  },

  {
    featureType: "administrative.neighborhood",
    elementType: "labels",
    stylers: [
      {
       visibility :"off"
      }
    ]
  },

  
  
];


class Map extends React.Component {
  constructor(props) {
    super(props);
  this.state = {
    directions: null,
    isVisible : false,
  };
  }


  shouldComponentUpdate(nextProps) {
    // return nextProps.boy === this.props.boy;
        return this.props.update !=='false'
   } 

   UNSAFE_componentWillMount() {
    

    const directionsService = new google.maps.DirectionsService();
    const AnyReactComponent = ({ text }) => <div>{text}</div>;
    const origin =this.props.origin; 
    const destination =  this.props.serType ==='International' ? '' : this.props.dest

    // const destination = { lat: 30.3398, lng: 76.3869};
      // const destination =''
    if (destination)
    {
     directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING  
        
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {

          this.setState({
            directions: result, 
          });
          this.props.route('ok')
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
    }

   

  }
      render() {

        const pathCoordinates = [
          { lat: 36.05298765935, lng: -112.083756616339 },
          { lat: 36.2169884797185, lng: -112.056727493181 }
      ];
  
    // console.log(this.props.origin)
    const GoogleMapExample = withGoogleMap(props => (
      <GoogleMap

      
        ref={this.mapComponentRef}
        defaultCenter={this.props.origin}
        
         defaultZoom ={15}
         onTilesLoaded={()=> { this.props.route('ok')}}
         options={{
            styles: exampleMapStyles,
            disableDefaultUI:true,
            gestureHandling: 'none',
        }}
      >
        
      {this.props.dest && this.state.directions && this.props.serType !=='Internatinal' && <> <DirectionsRenderer
          directions={this.state.directions}
          
          options={{suppressMarkers: true,
            polylineOptions: {
                strokeOpacity: 0.7,
                strokeColor: '#000',
                strokeWeight: 3,
                geodesic: false,
                // offset: "5%",
            },
      
        }}
        />
        </> 
      }
       
    
        
{this.props.localDeliver ==='yes' ? "" : this.props.dest ? <Marker position={this.props.origin}  
label={{
  text: "Pickup Point",
  fontFamily: "Arial",
  fontSize: "14px",
  backgroundColor:'black'
  
}}
          icon = {{
            path: faMapPin.icon[4] ,
            fillColor: "#EB4335",
            fillOpacity: 1,
            anchor: new google.maps.Point(
              faMapPin.icon[0] / 2, // width
              faMapPin.icon[1] // height
            ),
            strokeWeight: 1,
            strokeColor: "#ffffff",
            scale: 0.075,
         
          }}
       />:"" }

{this.props.localDeliver ==='yes' ?   <Marker position={this.props.boy}  
          icon = {{
            path: faMotorcycle.icon[4] ,
            fillColor: "#000",
            fillOpacity: 1,
            anchor: new google.maps.Point(
              faMotorcycle.icon[0] / 2, // width
              faMotorcycle.icon[1] // height
            ),
            strokeWeight: 1,
            strokeColor: "#ffffff",
            scale: 0.050,
            rotation: 30
          }}

       />:"" }

{ this.props.dest? this.props.localLoc ? "": <>
  <Marker position={this.props.dest} 
 label={{
  text: "Delivery Point",
      color: "black",
      // fontWeight: "bold"
}}
          icon = {{
            
            path: faMapPin.icon[4] ,
            fillColor: "green",
            fillOpacity: 1,
            // labelOrigin: new google.maps.Point(53,115),
            anchor: new google.maps.Point(
              faMapPin.icon[0] / 2, // width
              faMapPin.icon[1] // height
            ),
            strokeWeight: 1,
            strokeColor: "#ffffff",
            scale: 0.075,
            
            
          }}

       />
       
       </> :""}
        
 <Marker position={this.props.boy} />

 <Polyline path={[{ lat: -34.397, lng: 150.644 }, { lat: -35.397, lng: 151.644 }]}/>
  
     
      </GoogleMap>
      
    ));

    return (
      <>
      <div className="position-relative">
        <div className=""><GoogleMapExample

containerElement={<div style={{ height: `calc(100vh - ${this.props.height})`, width : `100%` }} />}
mapElement={<div style={{ height: `100%`}} />}
loadingElement={ <div style={{height: `100%` , width: `100vw`}}/>}
defaultZoom ={17}
/></div>
{/* custom marker  */}
       {this.props.route && <div className={` marker-pos  ${this.props.dest ?"d-none": "d-block"} ${this.props.marker? "d-block" :"d-none"} `}>
          <div className="d-flex justify-content-center align-items-end">
            <img src={pick}  alt='pickup' style ={{height:50}} />
          </div>
        </div>}






      </div>

      </>      
    );
  }
}

export default Map;
import React, {useState, useRef, useEffect} from 'react';
import { BsChevronLeft } from "react-icons/bs";
import './Header.css';
import { BsSearch } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Search } from './Search';


export const Header = (props) => {

  const SerchInput =useRef(null)
  const [Bar,setBar] = useState('Search')
  const [find,setSearch] = useState('') 
  const [Load,setLoad] = useState('')



  const btn =() => {
    props.Btn('btn')
  }
    
  const Back =() => 
  {
    setBar('Search')
    props.back('close');

  }


  const close =(e) => {
    props.typAhead(e.target.value)

    if (e.target.value.length > 0)
    {
   setSearch('close')
   setLoad('loading')
 
    }
    else if (e.target.value.length <= 0) {
setSearch('Search')
setLoad('done')
    }
  }

  const clear =() => {
   
  
    SerchInput.current.value=''
  
setSearch('Search')
    setLoad('done')
    props.typAhead('')
    
  }
  
useEffect ( () => {
clear()
},[props.show])


useEffect(() => {
  if(props.serType !=='Domestic')
 {autofs()}
})

const autofs = () => {

   if(props.inputFocus ==='yes')
    {
    
      setTimeout(() => { SerchInput.current.focus()},1000)
    }
}

useEffect ( () => {
  setLoad(props.Loading)
},[props.Loading])


  return (
    <>
      <div className="SearchBox ">
        <div className={` ${props.Bar === "no" ? "d-block" : "d-none"} `}>
          <div className="SearchHead">
            <div onClick={Back} className= {`SearIcon ${props.reverse ? 'd-none' :'d-block'} `}>
              <BsChevronLeft size="24" />
            </div>
            <div className="contentFont user-select-none ps-4">{props.name }</div>
            <div className="pe-2 Search">
              {/* <BsSearch size="18" /> */}
            </div>
          </div>
        </div>
<div className={`${props.Bar ==='no'? "d-none":"d-block"}  `}>
        <div className={`{ ${Bar === "Search" ? "d-block" : "d-none"}  `}>
          <div className={`contentFont SearchHeadNew ps-4 pe-2 }`}>
            <div className="pe-2 SearIcon mb-1" onClick={Back}>
              <BsChevronLeft size="20" color="#000" />
            </div>
            <div className="SearchBarStyle ">
              {" "}
              <input
                placeholder={props.Placeholder}
                onChange={close}
                ref={SerchInput}
                
              />
              <div
                className={`SearIcon ${
                  find === "Search" ? "d-block" : "d-none"
                }`}
              >
                <BsSearch />
               
              </div>
              <div className={`SearIcon ${find === "" ? "d-block" : "d-none"}`}>
                <BsSearch />
              </div>
              <div
                className={`SearIcon ${
                  find === "close" ? "d-block" : "d-none"
                }`}
                onClick={clear}
              >
               <div className={`${Load === 'done'? "d-block" : "d-none"}`}> <AiOutlineCloseCircle /></div>
                <div className={`spinner-border text-dark  spinner-border-sm  m-1 ${Load ==='loading' ? "d-block": "d-none"}  `} role="status"></div>
              </div>
            </div>
          </div>
          <div className="Searchbottom"></div>
        </div>
         </div>
        
        <div className="SeachCon">
          {/* Search Box  */}
          {/* <div className={`mb-3 ${props.Bar === 'yes' ? "d-block":"d-none"}`}> 
      <div className='contentFont SearchBorder'>
      <input placeholder="Search"/>
      <div className="SearIcon" ><BsSearch/></div>
      </div></div> */}
      

          <div className=' w-100 scroll-y '>{props.children}</div>
        </div>
        <div className={`${props.bottomBtn === "yes" ? "d-block" : "d-none"}`}>
          <div className="">
            <button className="AddNew" onClick={btn}>
              Add New
            </button>
          </div>
        </div>
    
      </div>
    </>
  );
};

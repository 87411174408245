import React ,{useEffect, useState} from 'react';
import './WhatDeliver.css';
import document from '../Images/Dox.png'
import ppx from '../Images/ppx.svg'
import cloth from '../Images/clothes.jpg'
import gift from '../Images/Gifts_Icon_256.png'
import food from '../Images/fast-food-icon.png'
import medicine from '../Images/drug.webp'
import { CourierSer } from './CourierSer';
import {DropInput} from './DropInput'
import { BsFillCheckCircleFill } from "react-icons/bs";
import cakee from '../Images/cake-icon.png'

import  '../pages/Ebook/Local.css'


export const WhatDeliver = (props) => {

    const [isActive,setActive] =useState('d-none')
    const [isType,setType] = useState('dox')
    const [EnterWgt,SetWgt] =useState('')
    const[WgtValidate,SetWgtValidate] =useState('0.000')
    const [WgtBorder, SetWgtBorder] =useState('none')
    const [drawload,setDrawLoad] = useState('d-block')
    const [wgtType,setWgtType] = useState('')

    let name ='Document';
    let icon=document;

   useEffect(() => {
     if(props.active)
     setActive(false)
     else
     setActive(true)

     if(props.whatDel)
     reset()
   },[])

    useEffect(() => {
     
    if(props.draw)
  setTimeout(() => {  setDrawLoad('d-none')},100)
    else
setDrawLoad('d-block')
},[props.draw])

   
    useEffect( () => {

        if(props.Wgt > 0)
        {
   
         setActive(false)
        }
    },[props.Wgt])


    const clickhandler =(e) =>{
        setActive(!isActive);
         setType(e);
         SetWgt('')
         props.item(e)
         }

const actWgt = (e) => {
 SetWgtValidate(e)
 
   
}

const parcel =(e) => {
props.awb(false)
    if(props.dest === '' || props.dest === null)
    {
      props.dstErr(true)
  }
  else {
   
    props.dstErr(false)
    props.desc('parcel')
props.item('parcel')
setType('parcel')
SetWgt('0.500')
SetWgtValidate('0.000')
SetWgtBorder('none')
  }

}


if(isType ==='cake'){name='Cake'; icon=cakee;}
else if(isType ==='parcel'){name='Package'; icon=ppx;}
else if(isType ==='food'){name='Food'; icon=food;}
else if(isType ==='medicine'){name='Medicine'; icon=medicine;}
else if(isType ==='gift'){name='Gift'; icon=gift;}
    const reset =() => {
        setActive(true)
        setType('')
        SetWgt(0)
        SetWgtBorder('')
        
    }


    const Validate =() => {

      if(props.dest === '' || props.dest === null)
      {
        props.dstErr(true)
    }
    else {
      props.dstErr(false)
      props.wgt(WgtValidate)
      props.next('rate')
    }

// console.log(WgtValidate)

 if ((WgtValidate) <= 0) 

{  SetWgtBorder('Red')  
    }
      else if ((WgtValidate) > 0)
      {
    
        SetWgtBorder('none')
      }
    }

    const dox =() =>
    {
        
      if(props.dest === '' || props.dest === null)
      {
        props.dstErr(true)
    }
    else{props.dstErr(false)
    props.desc('parcel')
        props.item('Documents')
       
        setType('parcel')  
       
    }


    }

    const cake =() =>
    {

        if(props.dest === '' || props.dest === null)
        {
          props.dstErr(true)
      }
      else
        {props.desc('parcel')
        props.item('Cake')
        setWgtType('Cake')
        setType('parcel')  
        props.dstErr(false)   }
    }

    const med =() =>
    {
        if(props.dest === '' || props.dest === null)
        {
          props.dstErr(true)
      }
else {
    props.desc('parcel')
    props.item('Medicine')
    setType('parcel')  
    props.dstErr(false)
}
         
    }

    const foods =() =>
    {
        if(props.dest === '' || props.dest === null)
        {
          props.dstErr(true)
      }
      else {
        props.dstErr(false)
        props.desc('parcel')
        props.item('Food Item')
        setType('parcel')   
      }
        
    }


    return (
        <>
        <div className={`container-fluid pe-0 ps-2 position-relative user-select-none  `}>
        <div className={`my-2 border rounded border-0 `} style={{ backgroundColor: "#fff" }}>

            <div className={`d-flex flex-column  mt-2 ${isActive ? "d-blcok": "d-none"} `}>
              {/* <hr className='divide1 my-0 mt-1'/> */}
                <div className='ps-1  text-dark fs-normal text-danger ps-2 mt-1'><p className='pack-type'>Select package type for delivery?</p></div>


                {/* Courier service type */}
                <div className={`d-flex justify-content-around  ${drawload !=='d-none' ? 'd-none':'d-block'} `}  >
                    <div onClick={() =>clickhandler('document')}><CourierSer active={isType === 'document' ? true : false} icon={document} name="Document" /></div>
                    {/* <div onClick={() => clickhandler('gift') }   className={`${props.serType ==='Local' ? 'd-block' : 'd-none'}`} ><CourierSer  icon={gift} name="Gift" /></div> */}

                    <div onClick={parcel} > <CourierSer    icon={ppx} name="Parcel" /></div>
                    {/* () =>clickhandler('cake') */}
                
                    <div onClick={() =>clickhandler('gift')}     ><CourierSer  icon={gift} name="Gift" /></div>
                    <div onClick={() =>clickhandler('medicine')}><CourierSer  icon={medicine} name="Medicine" /></div>
                    {/* foods */}
                        </div>

                     </div>  

                     <div className={`card-body d-flex justify-content-around ${ drawload }`}>
    <div className="avatar-placeholder placeholder rounded-circle "></div>
    <div className="avatar-placeholder placeholder rounded-circle"></div>
    <div className="avatar-placeholder placeholder rounded-circle"></div>
    <div className="avatar-placeholder placeholder rounded-circle"></div>
    <div className="avatar-placeholder placeholder rounded-circle"></div>
   
</div> 
             </div>

             {/* selct product with input */}
             <div className={`${isActive ? "d-none": "d-block"} ${drawload !=='d-none' ? 'd-none':'d-block'} d-flex px-0     position-relative  align-items-center` }>
            
              <div onClick={reset} className='col-2 ms-3 '>
              <div className='position-relative'><CourierSer icon={icon} name={name}  />
            <div className='sel-pdt' ><BsFillCheckCircleFill color="#008000" size="18"/></div>
           
            </div></div>

              <div className='col me-2 ms-3' >
               
                <DropInput type={isType} CharWgt={actWgt} WgtError={WgtBorder} />
             
            </div>
            <div className='Return' onClick={reset}>x</div>

      

        </div>
        <div className={`${isActive ? "d-none":"d-block"} ${drawload !=='d-none' ? 'd-none':'d-block'} quote `} onClick={Validate}>Continue</div>
      {/* <div><button onClick={Validate}  className='quote'>Get Queek Delever</button></div> */}
        </div>

      

        </>
    )
}

import React from 'react'
import { Footer } from '../Footer'
import Navbar from '../Navbar'

export const RefundPolicy = () => {
  return (
    <div>
        <Navbar/>
        <div className='container'>
        <h2 className='mt-4'>Refund Policy</h2>
        <p className='txt-clr1 text-dark mt-3'>
            <ul>
                <li >We don't have any Refund Policy.</li>
            </ul>
        </p>
            

        </div>
        <Footer/>
    </div>
  )
}
   <div className='container'>
        <h2 className='mt-4'>Refund Policy</h2>
        <p className='txt-clr1 text-dark mt-3'>
            <ul>
                <li >We don't have any Refund Policy.</li>
            </ul>
        </p>
            

        </div>
import React from 'react'
import './Spinner.css'

export const Spinner = () => {
  return (
   <>
   <div className='container-fluid px-0 spinOverlay'>
  <div className='loaderCon'>
      <div className='loader'>
  <div className="spinner-border text-warning">

</div>
<div className='ps-3'>please wait...</div>
</div>
</div>
  </div>
   </>
  )
}

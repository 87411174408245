import React from 'react'
import { Footer } from './Footer'
import Navbar from './Navbar'

export const Term = () => {
  return (
    <div>
        <Navbar/>

        <div className='container'>
        <h2 className='mt-4'>Terms & Conditons</h2>

        <h6 className='mt-3'>Introduction</h6>
      <p className='txt-clr1 text-dark mt-3'>Please read these Web Site Terms of Use carefully before using this Web Site (The "Web Site"). These Web Site Terms of Use (The "Terms Of Use") govern your access to and use of the Web Site. The Web Site is available for your use only on the condition that you agree to the Terms of Use set forth below. If you do not agree to all of the Terms of Use, do not access or use the Web Site. By accessing or using the Web Site, you and the entity you are authorized to represent ("You" Or "Your") signify your agreement to be bound by the Terms of Use.</p>
       
       <h6 className='mt-3'>1. User Eligibility</h6>
       <p className='txt-clr1 text-dark mt-3'>The Web Site is provided by Queek Delever and available only to entities and persons over the age of majority and who can form legally binding agreement(s) under applicable law. If You do not qualify, You are not permitted to use the Web Site.</p>
       
       <h6 className='mt-3'>2. Scope of Terms of Use</h6>
       <p className='txt-clr1 text-dark mt-3'>These Terms of Use govern Your use of the Web Site and all applications, software and services (collectively, "Services") available via the Web Site, except to the extent such Services are the subject of a separate agreement. Specific terms or agreements may apply to the use of certain Services and other items provided to You via the Web Site ("Service Agreement(s)"). Any such Service Agreements accompany or are listed in association with or through a hyperlink associated with the applicable Services.</p>
      
      
       <h6 className='mt-3'>3. Modifications</h6>
       <p className='txt-clr1 text-dark mt-3'>Queek Delever may revise and update these Terms of Use at any time. Your continued usage of the Web Site after any changes to these Terms of Use will mean You accept those changes. Any aspect of the Web Site may be changed, supplemented, deleted or updated without notice at the sole discretion of Queek Delever. Queek Delever may also change or impose fees for products and services provided through the Web Site at any time in its sole discretion.</p>
        
        <h6 className='mt-3'>4. Queek Delever Rate and Service Guide and Tariff</h6>
        <p className='txt-clr1 text-dark mt-3'>In addition to any other terms and conditions that may be applicable to such transaction as provided in these Web Site Terms of Use and the Service Agreement(s), the Queek Delever Rate and Service Guide applicable to You and the Queek Delever Couriers Tariff (to the extent it is applicable to You) in effect at the time of shipment govern Your use of Queek Delever Couriers transportation and related services.</p>
        
        <h6 className='mt-3'>5. Queek Delever Privacy Policy</h6>
        <p className='txt-clr1 text-dark mt-3'>The Queek Delever Privacy Policy governs the use of information collected from or provided by You at the Web Site. With respect to any individual whose personal information is provided by You to Queek Delever, You represent to Queek Delever Couriers that You have obtained all necessary consents for the processing of such personal information contemplated by the Services You are using and by the Queek Delever Privacy Policy, including the transfer of such data to other countries whose laws may not provide the same level of protection for the personal data as the laws of the country of origin of such individual.</p>


        <h6 className='mt-3'>6. License and Ownership</h6>
        <p className='txt-clr1 text-dark mt-3'>Any and all intellectual property rights ("Intellectual Property") associated with the Web Site and its contents (the "Content") are the sole property of Queek Delever. The Content is protected by copyright and other laws in both the India and other countries. Elements of the Web Site are also protected by trade dress, trade secret, unfair competition, and other laws and shall not be copied or imitated in whole or in part. All custom graphics, icons, and other items that appear on the Web Site are trademarks, service marks or trade dress ("Marks") of Queek Delever. Except as otherwise expressly authorized by these Terms of Use, You shall not copy, reproduce, modify, lease, loan, sell, create derivative works from, upload, transmit, or distribute the Intellectual Property of the Web Site in any way without Queek Delever prior written permission.</p>
        
        <h6 className='mt-3'>7. Restrictions on Use of the Web Site</h6>
        <p className='txt-clr1 text-dark mt-3 '>In addition to other restrictions set forth in these Terms of Use, You agree that:</p>
       <ul>
        <li className='txt-clr1 text-dark'> You shall not disguise the origin of information transmitted through the Web Site.</li>
        <li className='txt-clr1 text-dark '> You will not place false or misleading information on the Web Site.</li>
        <li className='txt-clr1 text-dark '>You will not use or access any service, information, application or software available via the Web Site in a manner not expressly permitted by Queek Delever.</li>
       <li className='txt-clr1 text-dark '>You will not input or upload to the Web Site any information which contains viruses, Trojan horses, worms, time bombs or other computer programming routines that are intended to damage, interfere with, intercept or expropriate any system, the Web Site or Information or that infringes the Intellectual Property (defined below) rights of another.</li>
       <li className='txt-clr1 text-dark '>Certain areas of the Web Site are restricted to customers of Queek Delever.</li>
    <li className='txt-clr1 text-dark '>You shall not use or access the Web Site or the Queek Delever Systems or Services in any way that, in Queek Delever's judgment, adversely affects the performance or function of the Queek Delever Systems, Services or the Web Site or interferes with the ability of authorized parties to access the Queek Delever Systems, Services or the Web Site.</li>
      <li className='txt-clr1 text-dark '>You shall not frame or utilize framing techniques to enclose any portion or aspect of the Content or the Information, without the express written consent of Queek Delever.</li>
       </ul>

       <h6 className='mt-3'>8. Submissions</h6>
        <p className='txt-clr1 text-dark'>Queek Delever does not accept ideas, concepts, or techniques for new services or products through the Web Site ("Comments"). If such Comments are received, You acknowledge that (a) they will not be considered confidential or proprietary, (b) Queek Delever and its affiliates are under no obligation to keep such information confidential, and (c) Queek Delever will have an unrestricted, irrevocable, world-wide, royalty free right to use, communicate, reproduce, publish, display, distribute and exploit such Comments in any manner it chooses.</p>


        <h6 className='mt-3'>9. Termination</h6>
        <p className='txt-clr1 text-dark'>You agree that Queek Delever, in its sole discretion, may terminate or suspend Your use of the Web Site, the Queek Delever Systems, Information, Services and Content at any time and for any or no reason in its sole discretion, even if access and use continues to be allowed to others. Upon such suspension or termination, You must immediately (a) discontinue use of the Web Site, and (b) destroy any copies You have made of any portion of the Content. Accessing the Web Site, the Queek Delever Systems, Information or Services after such termination, suspension or discontinuation shall constitute an act of trespass. Further, You agree that Queek Delever shall not be liable to You or any third party for any termination or suspension of Your access to the Web Site, the Queek Delever Systems, Information and/or the Services.</p>


        <h6 className='mt-3'>10. Disclaimer of Warranties</h6>
        <p className='txt-clr1 text-dark'>Queek Delever makes No Representations About The Results To Be Obtained From Using The Web Site, The Queek Delever Systems, The Services, The Information Or The Content. The Use Of Same Is At Your Own Risk. The Web Site, The Queek Delever Systems, The Information, The Services And The Content Are Provided On An "As Is" Basis. Queek Delever, Its Licensors, And Its Suppliers, To The Fullest Extent Permitted By Law, Disclaim All Warranties, Either Express Or Implied, Statutory Or Otherwise, Including But Not Limited To The Implied Warranties Of Merchantability, Non-Infringement Of Third Parties' Rights, And Fitness For A Particular Purpose. Queek Delever And Its Affiliates, Licensors And Suppliers Make No Representations Or Warranties About The Accuracy, Completeness, Security Or Timeliness Of The Content, Information Or Services Provided On Or Through The Use Of The Web Site Or The Queek Delever Systems. No Information Obtained By You From The Web Site Shall Create Any Warranty Not Expressly Stated By Queek Delever In These Terms Of Use. Some Jurisdictions Do Not Allow Limitations On Implied Warranty, So The Limitations And Exclusions In This Section May Not Apply To You. If You Are Dealing As A Consumer, Your Statutory Rights That Cannot Be Waived, If Any, Are Not Affected By These Provisions. You Agree And Acknowledge That The Limitations And Exclusions Of Liability And Warranty Provided In These Terms Of Use Are Fair And Reasonable</p>
       
        <h6 className='mt-3'>11.  Limitation of Liability</h6>
       <p className='txt-clr1 text-dark'>To The Extent Permitted By Applicable Law And To The Extent That Queek Delever Is Otherwise Found Responsible For Any Damages, Queek Delever Is Responsible For Actual Damages Only. To The Extent Permitted By Law, In No Event Shall Queek Delever, Its Affiliates, Its Licensors, Its Suppliers Or Any Third Parties Mentioned At The Web Site Be Liable For Any Incidental, Indirect, Exemplary, Punitive And Consequential Damages, Lost Profits, Or Damages Resulting From Lost Data Or Business Interruption Resulting From The Use Of Or Inability To Use The Web Site, The Queek Delever Systems, Information, Services Or The Content Whether Based On Warranty, Contract, Tort, Delict, Or Any Other Legal Theory, And Whether Or Not Queek Delever Is Advised Of The Possibility Of Such Damages. To The Extent Permitted By Law, The Remedies Stated For You In These Terms Of Use Are Exclusive And Are Limited To Those Expressly Provided For In These Terms Of Use.</p>
       
       
       <h6 className='mt-3'>12.  Jurisdiction</h6>
       <p className='txt-clr1 text-dark'>To The Fullest Extent Permitted By Law, You Hereby Expressly Agree That Any Proceeding Arising Out Of Or Relating To Your Use Of The Web Site, The Queek Delever Systems, Information, Services And Content Shall Be Instituted in courts in Ludhiana, India, And You Expressly Waive Any Objection That You May Have Now Or Hereafter To The Laying Of The Venue Or To The Jurisdiction Of Any Such Proceeding. You Agree That Any Claim Or Cause Of Action Arising Out Of Or Related To Your Use Of The Web Site, The Queek Delever Systems, Information, Services And/Or Content Must Be Filed Within One (1) Year After Such Claim Or Cause Of Action Arose. </p>


       <h6 className='mt-3'>13.  General</h6>
       <p className='txt-clr1 text-dark'>You shall not assign these Terms of Use or any of Your interests, rights or obligations under these Terms of Use. If any provision of these Terms of Use is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms of Use, which shall remain in full force and effect. No waiver of any of these Terms of Use shall be deemed a further or continuing waiver of such term or condition or any other term or condition.</p>
   

       <h6 className='mt-3'>14. Written Document  </h6>
       <p className='txt-clr1 text-dark'>You may preserve these Terms of Use in written form by printing them for Your records, and You waive any other requirement that these Terms of Use be evidenced by a written document.</p>
   

       <h6 className='mt-3'>15. Complete Agreement  </h6>
       <p className='txt-clr1 text-dark'>Except As Expressly Provided In A Separate License, Service Or Other Written Agreement Between You And Queek Delever Or In The Applicable Queek Delever Rate And Service Guide Or Queek Delever Tariff, These Terms Of Use Constitute The Entire Agreement Between You And Queek Delever With Respect To The Use Of The Web Site, The Queek Delever Systems, And Any Software Or Service, Information And Content Contained Therein, And Supersede All Discussions, Communications, Conversations And Agreements Concerning The Subject Matter Hereof.</p>
       
       <h6 className='mt-3'>16. Customer Service  </h6>
       <p className='txt-clr1 text-dark'>Questions or comments regarding the Web Site may be submitted to Queek Delever Customer Service.</p>
       
        </div>

        <Footer/>
    </div>
  )
}

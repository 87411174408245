import React,{useState,useRef} from 'react';
import { Header } from '../../Components/Header';
import { SmallButton } from '../../Components/SmallButton';
import phone from './logo.svg'
import './Verify.css'
import { BiArrowBack } from "react-icons/bi";
import { useNavigate} from 'react-router-dom';
import { NormalButton } from '../../Components/NormalButton';
import { BsPhone} from "react-icons/bs";
import { useEffect } from 'react';
import newOTP from 'otp-generators';
import { Helmet } from "react-helmet";
import axios from 'axios';

export const Verify = () => {


    const [err,setErr] = useState('form-control inputBtm')
    const [hide,setHide] = useState(false)
    const [load,setLoad] = useState(false)
  
    const cell = useRef(null)
    const navigate = useNavigate();

    useEffect (() => {
        let retrievedData = localStorage.getItem("custData");
        if(retrievedData?.length > 0)
        {
        let custDta = (JSON.parse(retrievedData))
        let mobile = custDta[0].custId
        
        let type = custDta[0].type
         navigate('/pickup', {
             state:{mobile,type}
         });
        }
        else 
        {
            setTimeout(() => {
                cell.current.focus()
            },500)
        }
      
    },[])
    
    

    const next =() => {
        setLoad(true)
  let otp =    newOTP.generate(4, { alphabets: false, upperCase: false, specialChar: false })

      let mob = ''
        let phone =''

        if(cell.current.value.length !== 10)
        {
            setErr('form-control inputBtmErr')
        }
        else
        {
           mob = cell.current.value
           phone = cell.current.value
            setErr('form-control inputBtm')  
         
            setTimeout(() => {console.log('waiting')},1000)
            fetch(`https://www.queekdelever.com/queekApi/Queek_login.php?mob=${mob}`)
            .then(res => res.json())
            .then (data => {
                if(data[0].loginType ==='Employee')
              { 
               phone = data[0].mob}
                let type = data[0].loginType
                 navigate('/otp',{
                state: {mob,otp,type,phone}
                 })
                 whatsMsg(otp,phone) 

            })
            
        }
        setLoad(false)
    }


    const back =() => {
        window.location.replace ('/')
    }

    const whatsMsg =(otp,mob) => {
        
      fetch(`https://queekdelever.com/queekApi/whatsOtp.php?mob=${mob}&otp=${otp}`)
      .then(res => res.json())
      .then (data => data)
      

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    
    }
  return (
      <>
         <Helmet>
  <script disable-devtool-auto src='https://cdn.jsdelivr.net/npm/disable-devtool@latest/disable-devtool.min.js'></script>
</Helmet> 
      <div className='container-fluid justify-content-cener bg-blue vh-100'>
          <div className='row px-0'>
<div className='col-md-4'></div>
<div className='col-md-4 position-relative bgVerifty px-0  vh-100 d-flex align-item-center flex-column '>
    <div className=''>
<div className='d-flex align-item-center  py-3 headLogin px-3' ><div className='cursor' onClick={back} ><BiArrowBack size='22' color='black' /></div><div className='ps-3 '><h6 className='text-dark ps-2 pt-1 loginTxt'></h6></div></div>

<div className='d-flex justify-content-between px-3 pt-4 pb-3 headLogin'>
    <div>
        <div><h4>Continue for parcel pickup</h4></div>
        <div className='loginTxt'>Enter Whatsapp number for OTP</div>
    </div>
    <div className='me-3'> <BsPhone size='45'  /></div>
</div>


<div className='d-none d-flex justify-content-center logoCenter'>
    <img src={phone} className='img-fluid' alt="phone" />
     </div>
{/* label 4 digit */}
<div className=''>
<div className='text-left   d-none  singUpTxt  fs-4 text-white head1'></div>
{/* verify phone number */}
<div className='  mx-4  pt-4 '>
<div className='verify-font d-none1  text-white '></div>

<div className={`d-flex   justify-content-between align-item-center   mt-2  `}>
    <div className={`d-flex  ${err} `}>
    <div className='me-3'>+91</div>
    <div><input ref={cell} type="number" placeholder='Enter mobile number' onChange={() => setErr('form-control inputBtm') }   className='inputTxt'/></div>
    </div>
    <div className='d-none'><SmallButton name="Get OTP"/></div>
</div>

<div className={`d-flex  d-none justify-content-between align-item-center   bgInput  `}>
    <div className='d-flex'>
    <div className='me-3'></div>
    <div><input type="text" placeholder='Enter 6 digit OTP'  className='verify-input'/></div>
    </div>
</div>

{ err ==='form-control inputBtmErr' && <div className='loginTxt text-danger mt-2'>Enter valid mobile number</div>}
 <div className='py-2 my-3  ' onClick={next}><NormalButton name='Get Started' loading={load}  /></div>
{/* {mob && <div className='py-2'><NormalButton name='Login'  /></div>} */}

</div>
</div>
<div className='accTerm cursor  ps-4'><span className='text-muted'> By clicking, I accept the </span><span onClick={()=>navigate('/term')}>Terms & Conditions </span><span className='text-muted'>&</span>  <span onClick={() => navigate('/privacy')}>Privacy Policy</span></div>
</div>
</div>
<div className='col-md-4'></div>

          </div>
      </div>
     
      </>

  )
};

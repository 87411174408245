import React, { useEffect } from 'react'
import { useBarcode } from '@createnextapp/react-barcode';

export const Barcode1 = (props) => {
  
    const  {inputRef}  = useBarcode({
        value: props.awb,
        options: {
          background: '#fff',
          width: 3,
          displayValue: false,
            height:100
        
        }
      });

      useEffect(() => {
          const shipAutomation =() => {
          window.location='printerplus://send?text='+document.getElementById('report').innerHTML;
        }
        shipAutomation()
      },[])
  return (
    
  <>
 <div id='report' className={`barWidth`}  >
  <h5 align='1' className='text-capitalize'>{props.network}</h5>
{/* <barcode align="1" height="90" size='3' hri="0" >18198210000280</barcode> */}
<img align='1' ref={inputRef} className='img-fluid ps-1' />

<p size='1' align='1' className='text-capitalize' > {props.awb}</p>

 </div>
  </>
  )
}

import React,{useEffect, useRef, useState} from 'react'
import sLogo from './sLogo.svg'
import './FoodStore.css'
import { FaRegDotCircle } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlinePinDrop } from "react-icons/md";
import ToggleButton from 'react-toggle-button'
import {useParams,useNavigate} from 'react-router-dom'
import { Initilation } from '../../Components/Initilation';
import { BsCheckAll } from "react-icons/bs";



export const FoodStroeApp = () => {
  const [cod,setCod] = useState(false)
  const [mobErr,setMobErr] = useState(false)
  const mob = useRef(null)
  const [store,setStore] = useState('')
  const [isHide,setIsHide] = useState(false)
  const[sent,setSent] = useState(false)
  const wgt = useRef(null)
  const {id} = useParams()
  const [wgtErr,setWgtErr] = useState(false)
  const qty =useRef(null)

  useEffect(() => {
fetch(`https://queekdelever.com/queekApi/storeName.php?storeId=${id}`)
.then(response => response.json())
.then(data => {
  setTimeout(() => {setStore(data)},1000)
})
  },[])



  const pickupReq =() => {
let payment=''
    if(!cod)
    payment ='COD'
    else
    payment='Store'
    
    const requestOptions = {
      method: 'POST',
     };

  fetch(`https://www.queekdelever.com/queekApi/foodDellivery.php?custID=${mob.current.value}&storeId=${id}&method=${payment}&qty=${qty.current.value}&wgt=${wgt.current.value}`,requestOptions)
 
// assignBoy()
}

  const submit =() => {
    setWgtErr(false)
    
    
    if(mob.current.value.length !== 10)
  {  mob.current.focus()
   return setMobErr(true)}
    else
    setMobErr(false) 

    if(wgt.current.value <= 0)
   { wgt.current.focus()
    return  setWgtErr(true)}
    {
    pickupReq()
    whatsMsg(mob.current.value,store[0].name)
    initPickup()  

    setIsHide(true)
    setTimeout(() => {setSent(true)},2000)
        // setIsHide(false)
        // setSent(false)
        setTimeout(() => {
          setSent(false)
          setIsHide(false)
          mob.current.value=''
          wgt.current.value=''
          qty.current.selectedIndex=0
        },4000)

    
  }
  }

const initPickup = () => {
fetch(`https://queekdelever.com/queekApi/pickupInit.php?storeId=${id}`)
.then(response => response.json())
.then(data => {
  // console.log(data)
  WhatsMsgInit(data[0].storeName,data[0].storeAdd,data[0].boyID)
})
}

const WhatsMsgInit =(name,add,boyId) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' ,
    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3YWxsZXRJZCI6IjYyYWExMmZiMDA2NmQzN2M5OWIxNDMxNSIsInVzZXJJZCI6IjYyYWExMmZiMDA2NmQzN2M5OWIxNDMxNiIsImRldmljZUlkIjoiNjJiNDU5ODczZDhhMzEwYTRlYmE4NDUyIiwiaWF0IjoxNjU1OTg2Njg0LCJleHAiOjE4Nzc0Mjk4ODR9.CTTbtkuTLfOYWWXYVGfQMN0uEY_vb_ck0ivRFHzx_LU' ,
    'Access-Control-Allow-Origin':'origin-list',
    'Access-Control-Allow-Headers': '*'
  },

    body: JSON.stringify({
      "numbers": `91${boyId}`,
      "message": {
        "text": `*Pickup Request Generated* : ${name}, ${add}  sent a pickup request. Call to customer to accept delivery location`,
        "footer": "Thaks for Shipping with Queek Delever",
        "templateButtons": [
          {
            "callButton": {
              "displayText": "Call Now",
              "phoneNumber": `${mob.current.value}`
            }
          },
          
         
         
        ]
      }
    }
     )
};
fetch('https://api.whatsspot.in/api/message/fast/text', requestOptions )
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(err => console.log('Error occur '+ err))
    

}


  const whatsMsg =(sender,storeName) => {
    

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' ,
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3YWxsZXRJZCI6IjYyYWExMmZiMDA2NmQzN2M5OWIxNDMxNSIsInVzZXJJZCI6IjYyYWExMmZiMDA2NmQzN2M5OWIxNDMxNiIsImRldmljZUlkIjoiNjJiNDU5ODczZDhhMzEwYTRlYmE4NDUyIiwiaWF0IjoxNjU1OTg2Njg0LCJleHAiOjE4Nzc0Mjk4ODR9.CTTbtkuTLfOYWWXYVGfQMN0uEY_vb_ck0ivRFHzx_LU' ,
        'Access-Control-Allow-Origin':'origin-list',
        'Access-Control-Allow-Headers': '*'
      },

        body: JSON.stringify({
          "numbers": `91${sender}`,
          "message": {
            "text": `*Order Delivery* : Dear Customer, ${storeName} wants to deliver Food/Order at your door step, please click on below button to confirm your delivery location,i.e we can locate your delivery address.`,
            "footer": "Thanks for Shipping with Queek Delever",
            "templateButtons": [
              {
                "urlButton": {
                  "displayText": "Get a Order Delivery",
                  "url": `https://queekdelever.com/#/QueekDelivery/${sender}`
                }
              },
              
             
             
            ]
          }
        }
         )
    };
    fetch('https://api.whatsspot.in/api/message/fast/text', requestOptions )
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(err => console.log('Error occur '+ err))
        

// empty dependency array means this effect will only run once (like componentDidMount in classes)

}

const borderRadiusStyle = { borderRadius: 2,padding:1 }

const handleWgt =() => {  
let wt = typeof(wgt.current.value) ==='string' ? parseFloat(wgt.current.value) : wgt.current.value
  if(wt > 0) 
wgt.current.value= wt.toFixed(3)
}

  return (
  <div>

{!store &&   <Initilation />}


<div className= {`${store ?'d-block' :'d-none'}`}>
    <div className='storeBg pb-2 d-flex justify-content-center '><img src={sLogo} className='storeLogo' /></div>

    
    <div className='d-flex mt-2 align-items-center  px-2 mt-5 mx-2'>
<div className='pe-4 position-relative'><FaRegDotCircle size='23' color='#EB4335' />
<div className='tran mt-1'> < BsThreeDotsVertical size='30' color='black' /></div>
</div>
<div className='pickupCard1 p-2 position-relative '>
<div className='floatLbl px-1 '>Store Name</div>
<div className='ps-2 pikName2 mt-1'>{store && store[0].name}</div>
<div className='pikAdd2 ps-2 mt-1'>{store && store[0].add}</div>
</div>
</div>

<div className='d-flex mt-4 align-items-center px-2 mx-2 '>
<div className='pe-3 '><MdOutlinePinDrop size='29' color='#008000' /></div>
<div className={` ${mobErr ? 'pickupCardErr' :'pickupCard1'} p-2 position-relative   `}>
<div className='floatLbl px-1 '>Delivery Location</div>
{/* <div className='ps-2 pikName mt-1 text-dark'>{props.data[0].dname}</div> */}
<div className='ms-2  d-flex align-items-center mobTop '><div className='pe-2 mobStd '>+91</div>
<div className='d-flex justify-content-between  w-100'>
<div className='mb-1'>  <input ref={mob} placeholder='Enter mobile number' type='number' maxLength='10' className='inputTxt mobStd ' disabled={!isHide ? false :true} /></div>
<div><ToggleButton   thumbStyle={borderRadiusStyle}
  trackStyle={borderRadiusStyle} value={ cod || false }  inactiveLabel={'COD'}    activeLabel={'Store'} onToggle={(value) => {
    setCod(!value)
    }}
   />

  </div>
</div>
</div>
<div className='pikAdd ps-2 mt-1 '></div>
</div>

</div>

<div className={`ms-5 ps-3 mt-2 mobErr ${mobErr ?'d-block':'d-none'}`}>Invalid customer moblile number</div>


{/* *************************Quntity and Weigth ************************************ */}

<div className='d-flex mt-4 align-items-center px-2 mx-2 '>
<div className='pe-3 '><MdOutlinePinDrop size='29' color='white' /></div>
<div className={` p-2 position-relative  ${wgtErr ? 'pickupCardErr' : 'pickupCard1'} `}>
<div className='floatLbl px-1 '>Product Details</div>

<div className='d-flex pt-2'>

<div>
<select ref={qty} className="form-select form-select-sm border-0" aria-label=".form-select-lg example">
  <option defaultValue={'1'}  value="1">1</option>
  <option value="2">2</option>
  <option value="3">3</option>
  <option value="4">4</option>
  <option value="5">5</option>
<option value="6">6</option>
  <option value="7">7</option>
  <option value="8">8</option>
  <option value="9">9</option>
  <option value="10">10</option>
</select>
</div>

<div className='ps-1'><input ref={wgt} onBlur={handleWgt} placeholder='Product weight (In Kg)' className='inputTxt' /></div>

</div>

</div>
</div>


{/* ************************************************************************************** */}

<div className={`ms-5 ps-3 mt-2 mobErr ${wgtErr ?'d-block':'d-none'}`}>Enter Product weight in KG</div>


<div className='d-flex mt-4 align-items-center px-2 mx-2 '>
<div className='pe-3 '><MdOutlinePinDrop size='29' color='white' /></div>
<div className='pickupCard p-2 position-relative border-0'>
{/* <div className='ps-2 pikName mt-1 text-dark'>{props.data[0].dname}</div> */}

{isHide && !sent && <div className="d-flex justify-content-center">
  <div className="spinner-border" role="status">
  </div>
</div>}


{sent && <div className={` d-flex justify-content-between align-items-center  `}><div className='sent ms-4 '>Delivery Request Sent...!!! </div> <div className='me-4'><BsCheckAll size='22' color='#008000' /></div></div>}

{ !isHide && !sent && <div  onClick={submit} ><button  className='btn btn-warning'>Submit</button> </div>}
</div>
</div>
</div>

  </div>
  )
}

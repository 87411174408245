import React from 'react'
import './Checkout.css'
import marker from './marker.png'
import emotional from './emtion_bond.svg'
import { MdCall } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdRadioButtonChecked} from "react-icons/md";
import { useEffect } from 'react';
import { useState } from 'react';
import { PaymentGateway } from './PaymentGateway';
import { ReadytoShip } from '../../Components/ReadytoShip';
import { FiHome } from "react-icons/fi";
import { MdWork } from "react-icons/md";
import { HiOfficeBuilding } from "react-icons/hi";
import { FaMapMarkerAlt} from "react-icons/fa";
import { GiTrumpet } from 'react-icons/gi';
import Drawer from "react-bottom-drawer";
import axios from 'axios';
import { SpinnerRoundFilled	 } from 'spinners-react';
import { Spinner } from '../../Components/Spinner';
import newOTP from 'otp-generators';



export const Chheckouts = (props) => {
  
const [mode,setMode] = useState('CASH')
const [checkData, setCheckData] = useState('')
const [pikLoc,setPikLoc] = useState('')
const [load,setLoad] = useState(false)
const [hide,setHide] = useState(false)
const [delAwb,setDelAwb] = useState('')
const [upi,setUpi] = useState(false)
const [totAmt,setTotAmt] = useState('')

let a=0;
let b=0;
  const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumSignificantDigits: 5
    }).format(value);
  const back = (e) => {
    props.back(e)
  }

  const ReayToShip = () => {
    fetch(`https://www.queekdelever.com/queekApi/ReadyPickup.php?sender=${props.user}`)
    .then(res => res.json())
    .then( data => {
      setCheckData(data)
      setTotAmt(data[data.length-1].total)
      if(!props.emp)
      curPickLoc()
      
    })
  }


  const curPickLoc =() => {
    
    fetch(`https://www.queekdelever.com/queekApi/curPickLoc.php?custcode=${props.user}`)
    .then(res => res.json())
    .then( data => {
      setPikLoc(data)
    })
  }

    const PickfromHome =() => {
      fetch(`https://www.queekdelever.com/queekApi/pickupFromHome1.php?sender=${props.userID}&loc=${props.loc}`)
      .then(res => res.json())
      .then(data => {
        setCheckData(data)
        setTotAmt(data[data.length-1].payment >0 ? data[data.length-1].total-data[data.length-1].payment : data[data.length-1].total)
      } )
    }

  useEffect(() => {
    setTimeout(() => {
    if(props.finalCheck && props.emp)
    {PickfromHome()}
    else
   { ReayToShip()}
  },500)
  },[hide])
  

  useEffect(() => {
    window.onpopstate = function(event)  {
      props.back('close')
    }
  })

  const success =(e) => props.back(e)
  const payId =(e) => props.payId(e)
  const custMob =(e) => props.custMob(e)
  const SenId =(e) => props.SenId(e)
  const backToHome =(e) => props.bookAnother(e)

  const Delete =(e) => {
    setHide(true)
    setDelAwb(e)
  }

  const BookNow =(awb) => {

    let otp =    newOTP.generate(4, { alphabets: true, upperCase: false, specialChar: false })

    let payment =''
    if(checkData[checkData.length-1].payment)
     payment = checkData[checkData.length-1].total- checkData[checkData.length-1].payment
    else
    payment = checkData[checkData.length-1].total
    if(mode ==='UPI')
    {
      setUpi(true)

      checkData.map((x) => {
        setTimeout(() => {
          fetch(`https://queekdelever.com/queekApi/paymentInt.php?awb=${x.awb+otp }&refId=${checkData[0].awb+otp}&empId=${props.empId}`)

        },500)
      })

      fetch(`https://queekdelever.com/queekApi/PaymentRequest.php?ref=${checkData[0].awb+otp}&payment=${payment}&mob=${props.user}&loc=${props.loc}`)
    .then(res => res.json()
    .then(data => {
      setTimeout(() => {
        props.rstDst('Dashboard')
        props.back('close')
      },2000)
      
    } )
    ) 
  } 

    if(mode === 'CASH')
    {
      checkData.map((x) => {
        setTimeout(() => {shipNow(props.user,props.loc)},300)
        whatsMsg(props.user,x.awb,x.city,x.country)
      })
    }
  }

  const shipNow=() => {
    fetch(`https://www.queekdelever.com/queekApi/pickup_detail.php?sender=${props.user}&empId=${props.empId}&loc=${props.loc}`)
    props.rstDst('Dashboard')
    props.back('close')
    
  }


  const conDel = () => {

   fetch(`https://www.queekdelever.com/queekApi/DeleteShipment.php?awb=${delAwb}`)
   .then(res => res.json())
   .then (data =>
   {
    if(data[0].res ==='Deleted')
    {
      if(checkData.length ===1)
      {
        if(props.emp)
        props.rstDst('Dashboard')
        else
        props.rstDst('main')
        props.back('close')
      }
    }
    setHide(false)
 } )
  }

  const whatsMsg =(sender,awb,city,country) => {
 // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' ,
    //     'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3YWxsZXRJZCI6IjYyYWExMmZiMDA2NmQzN2M5OWIxNDMxNSIsInVzZXJJZCI6IjYyYWExMmZiMDA2NmQzN2M5OWIxNDMxNiIsImRldmljZUlkIjoiNjJiNDU5ODczZDhhMzEwYTRlYmE4NDUyIiwiaWF0IjoxNjU1OTg2Njg0LCJleHAiOjE4Nzc0Mjk4ODR9.CTTbtkuTLfOYWWXYVGfQMN0uEY_vb_ck0ivRFHzx_LU' ,
    //     'Access-Control-Allow-Origin':'origin-list',
    //     'Access-Control-Allow-Headers': '*'
    //   },

    //     body: JSON.stringify({
    //       "numbers": `91${sender}`,
    //       "message": {
    //         "text": `*Booked* : Your Shipment with tracking ID  ${awb} for ${country ==='India' ? city : country} has been booked and Ready to Dispatch. You'll be notified when the shipment is delivered. 
    //         \n *Track a Shipment* : You can track your shipment using this link https://www.queekdelever.com/tracking/${awb}
    //         \n *E-Receipt* : Click on this link for E-receipt https://www.queekdelever.com/receipt/${awb}
    //         \n *Thanks for Shipping with Queek Delever*`,
    //       }}
        
    //      )
    // };
    fetch(`https://queekdelever.com/queekApi/whatsBooking.php?mob=${sender}&awb=${awb}&city=${country ==='India' ? city : country}`)
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(err => console.log('Error occur '+ err))
        

// empty dependency array means this effect will only run once (like componentDidMount in classes)

}

    const rstDst =() => {
      if(props.finalCheck && props.emp)
      props.back('HomePickup')
      else
     { props.rstDst('main')
      props.back('close') }
      }

  const edit =(e,f) => {
   props.back('parcel')
   props.isBox(f)
   props.updateAwb(e)
  }

  const curDate =(e) => {
    const current = new Date();
    let month = current.getMonth()+1
     if (month <= 9)
      month = '0'+ month
    let day= current.getDate()+e
      if ( day <= 9)
      day = '0'+ day
    
    return `${current.getFullYear()}-${month}-${day}`;
  }
  

  const pickNow =() => {
    setLoad(true)
    let today = new Date(),
    time = today.getHours();
    
  time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    fetch(`https://queekdelever.com/queekApi/cutoffTime.php?time=${time}`)
    .then( res => res.json())
    .then (data =>  pickReq(data[0].shedule))
  }

  const pickReq =(pkDate) => {
    fetch(`https://queekdelever.com/queekApi/paymentTrans.php?custId=${props.user}&pkDate=${pkDate}`)
    .then(res => res.json())
    .then(data => 
      setTimeout(() => {
        props.back('pickReq')
      })
      )
  }

  return (
    <>
      <div className='vh-100 '>
      <div className={`user-select-none parcelHeader py-4   ps-4`}>Payment Checkout </div>
      
      {checkData && <div className=''>
    {/* SHIPMENT CARD */}
    <div className=' user-select-none checkScroll'>
      {checkData && checkData.map((x) =>  <div key={a= a+1}  className= {`${a === 1 ? 'mt-3' :'mt-4'} `}>
        <div className={`d-flex `}>
<div className='d-flex px-1   align-items-center   w-100'>
            <div className='ps-1 pe-3 '><img src={marker} className='chekLoc' /></div>
            <div className='checkAddCon p-0 m-0 ' onClick={() => {props.emp  && edit(x.awb,x.type)}}>
              <div className='check-name'>{x.name}</div>
              <div className='check-add  pe-0'>{x.address1}</div>
              <div className='check-city'><span className={`${x.state.length > 0 ? 'pe-1' :'' }`}> {x.city}</span> {x.state} <span className=''>{x.pincode}</span></div>
              <div className='d-flex align-items-center text-nowrap'>
                <div className='pe-1'><MdCall size='14' color='gray' /></div>
                <div className='check-person'>+91 <span className='ps-1'>{x.receiver}</span></div>

                <div className='ps-3 pe-1 '><FaUserAlt size='14' color='gray' /></div>
                <div className='check-person personWdith text-truncate'>{x.name}</div>

              </div>
            </div>
          

          <div className='d-flex  flex-column justify-content-center  checkRate    align-items-center '>
            <div onClick={() => Delete(x.awb)} ><RiDeleteBin6Line size='18' color='#e72231' /></div>
            <div className='mt-2 check-rate'>{numberFormat(x.charges)}</div>
          </div>
        </div>
        </div>
        <div><hr className='divide' /></div>
      </div>)}
      {/* SHIPMENT CARD END */}

      {/* payment methond */}
     {props.emp && <div className='ps-3'>
      <div className='user-select-none'>Payment method</div>
      <div className={`mt-3 px-3 d-flex justify-content-between border ${mode ==='CASH' ? 'border-success' : ''} paymentOption `} onClick={() => setMode('CASH')}><div >CASH</div> <div><MdRadioButtonChecked color={mode ==='CASH' ? '#008000' :'gray' } size='22' /></div> </div>
      <div className={`mt-3 px-3 d-flex justify-content-between paymentOption  border ${mode ==='UPI' ? 'border-success' : ''} `} onClick={() =>{
         
         if((checkData[checkData.length -1].total - checkData[checkData.length -1].payment) === 0 )
         setMode('CASH')
          else
         setMode('UPI')}
         
         }><div className='user-select-none'>UPI/Credit/Debit/Wallet</div> <div><MdRadioButtonChecked color={mode ==='UPI' ? '#008000' :'gray' } size='22' /></div> </div>
      </div>}

 {/* payment methond end */}

    {/* pickup location address */}
    {!props.emp && <div className='ps-3'>
      <div className=''>Pickup address</div>
      {pikLoc && pikLoc.map((x) => <div key={b=b+1} className='pk-con px-3 mt-3 d-flex justify-content-between align-items-center user-select-none'>
        <div>
        <div className='pk-caption pt-1 user-select-none'>{x.name}</div>
      <div className='pk-add pb-1  pe-1 user-select-none'><div>{x.address}</div></div>
      </div>
     {x.name ==='Home' && <div className='locRound '><FiHome size='20' /></div>}
     {x.name ==='Work' && <div className='locRound'><MdWork size='20' /></div>}
     {x.name ==='Office' && <div className='locRound'><HiOfficeBuilding size='20' /></div>}
     {x.name !=='Home' && x.name !=='Work' && x.name !=='Office'  && <div className='locRound'><FaMapMarkerAlt size='18' /></div>}
      </div>)}
      </div>}
        {/* pickup location address end */}

    {/* promotional ads */}
    <div className='my-2'> <hr className='divide'/></div>

      <div className=''><img src={emotional} className='ads' /></div>

      <div><hr className='divide ' /></div>
      {/* promotional ads end */}

      {/* payment details  */}
      <div className='ps-3 user-select-none '>
        <div>Payment Details</div>
          <div className='d-flex justify-content-between promo'>
          <div className='promo-input'><input className='inputTxt' placeholder='Have a promo code ?' /></div>
          <div className='apply'>Apply</div>
            </div>
      </div>

      <div className='px-5 pamentBtm '>

        <div className='d-flex justify-content-between mt-4 text-muted user-select-none'>
          <div className='small-f'>Sub Total</div>
          <div>{numberFormat(checkData && checkData[checkData.length-1].total)}</div>
        </div>

        <div className='d-flex justify-content-between mt-1 text-muted user-select-none'>
          <div className='small-f'>Discount</div>
          <div>{numberFormat(0)}</div>
        </div>

       {checkData && checkData[checkData.length-1].payment > 0 && <div className='d-flex justify-content-between mt-1 text-muted user-select-none'>
          <div className='small-f'>Online payment (payId:){checkData && checkData[checkData.length-1].transId}</div>
          <div className='text-danger'><b>{numberFormat(checkData &&  checkData[checkData.length-1].payment)}</b></div>
        </div>}

        <div className='d-flex justify-content-between mt-1 user-select-none'>
          <div className='total'><b>Total</b></div>
          <div><b>{numberFormat(totAmt)}</b></div>
        </div>

      </div>
      </div>
      {/* payment details end */}

      {props.emp && !hide && !upi && <div className='fixed-bottom1 d-flex '>
        <div className='payment-btn1 user-select-none ' onClick={() => rstDst()}>{props.finalCheck && props.emp  ? 'Back' : 'Book Another'}</div>
        <div className='payment-btn  text-nowrap user-select-none' onClick={() => BookNow(checkData[0].awb)} >{mode ==='CASH' ? 'Book Now' :'Send Payment Link' }</div>
      </div>}
     

{/* *********************CASH PAYMENT PAYMENT GATEWAY ******************* */}
     {!props.emp && !hide && !upi && checkData && checkData[checkData.length-1].total <500 && <div className='d-flex fixed-bottom1'>
      
      <PaymentGateway checkData ={checkData}  emp={props.emp} amount={checkData && checkData[checkData.length-1].total} back ={back} user={props.user} success={success} payId={payId} SenId={SenId}  custMob ={custMob} backToHome={backToHome} />
         
      </div>}

      {!props.emp && !hide && !upi  && !load && checkData && checkData[checkData.length-1].total >= 500 && <div className='fixed-bottom1 d-flex '>
        <div className='payment-btn1  user-select-none ' onClick={() => rstDst()}>{props.finalCheck && props.emp  ? 'Back' : 'Book Another'}</div>
        <div className='payment-btn  user-select-none text-nowrap' onClick={() => pickNow()} >Request for Pickup</div>
      </div>}

{/* ************************************************************************************* */}

      </div>}

      {/* placeholder for loading */}

     {!checkData && <div className=" mt-5">
    
      <div className="card-body mt-3">
    <div className="avatar-placeholder placeholder rounded-circle"></div>
    <div className="text-placeholder placeholder w-50"></div>
    <div className="text-placeholder placeholder w-75"></div>
</div>

<div className="card-body mt-2">
    <div className="avatar-placeholder placeholder rounded-circle"></div>
    <div className="text-placeholder placeholder w-50"></div>
    <div className="text-placeholder placeholder w-75"></div>
</div>

    <div className='fixed-bottom d-flex justify-content-between'>
    <div className='text-placeholder placeholder3 w-50'></div>
    <div className='text-placeholder placeholder2'></div>
    <div></div>
    </div>
    </div>}

      {/* placeholder for loading end */}

      {/* delete drawer  */}
      <Drawer  isVisible={hide} >

      <div className='text-danger user-select-none d-flex  align-items-center'><div className='pe-2'><RiDeleteBin6Line size='18' color='#e72231' /></div><div className='pt-1'> Delete Shipment ?</div></div>
     <div><hr className='divide1' /></div>
    <div className='my-2 deleteHad  text-muted text-center user-select-none '>Are you sure you want to delete this shipment?</div>
     <div className='mt-3 d-flex pb-3 user-select-none'>
      <div className='w-50' onClick={() => setHide(false)}><button type="button" className="btn btn-outline-secondary">Cancel</button></div>
      <div className='w-50' onClick={() => conDel()}><button type="button" className="btn btn-danger">Delete</button></div>
     </div>
      </Drawer>

      {/* *************************************************************** */}

         {/* PAYMENT link loader */}
        {upi && <Drawer  isVisible={true}>
          <div className='user-select-none my-3 d-flex justify-content-center'> <SpinnerRoundFilled size='100'	 color='#ffe202'	 /></div>
         <div className='user-select-none text-center py-2 pb-5'>Please wait, while sending a payment link</div>
         </Drawer>}

         {/* *********************************************************************** */}

         {/* *******************Pickup Request Waiting ************************* */}
         {load && <Drawer  isVisible={true}>
          <div className='user-select-none my-3 d-flex justify-content-center'> <SpinnerRoundFilled size='100'	 color='#ffe202'	 /></div>
         <div className='user-select-none text-center py-2 pb-5'>Please wait, while Sending your pickup request</div>
         </Drawer>}
          {/* ******************************************************* */}

          </div>
    </>
  )
}

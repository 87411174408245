import React, {Component} from 'react';
import { Footer } from './Footer';
import Navbar from './Navbar';
import localDel from './profile.png'
import vision from './Vision.png'
import mission from './Mission.png'
import { Helmet, HelmetProvider } from 'react-helmet-async';


class About extends Component {
    state = {
        data: null
      };
    
    
      componentDidMount() {
        this.callBackendAPI()
          .then(res => this.setState({ data: res.express }))
          .catch(err => console.log(err));
      }
        // fetching the GET route from the Express server which matches the GET route from server.js
      callBackendAPI = async () => {
        const response = await fetch('/express_backend');
        const body = await response.json();
    
        if (response.status !== 200) {
          throw Error(body.message) 
        }
        return body;
      };
    
      render() {
        
        return (
          <div>
          <Navbar/>
     
          <HelmetProvider>
            
          <Helmet>
        <title>Hello World</title>
        <link rel="canonical" href="https://www.tacobell.com/" />
      </Helmet>
      
          <div className='localBg pt-3'>
          <div className='container  py-4'>
              <div className='row'>
                  <div className='col-sm-7 d-flex flex-column justify-content-start'>
                  <div className='text-left spaceH d-flex align-item-center' > <div className='highB '> </div > <div className='ps-2'><h2>About us</h2></div> </div>
                  <div className='pt-4  text-left  text-dark  mb-2 serTxt ' >Queek delever is online courier company for delivering your orders to Local, across Country and Globe. Queek delivers to more than 200 countries and delivers to more than 16,000 destinations  .
                  <div className='my-4'>Queek was founded by Dinesh Sharma who saw a need for a reliable Domesitc delivery service for consumers who wanted their packages delivered quickly, securely, and on-time.</div>
               
                <div className='my-3 e-heading'><h5>It's queek delever</h5></div>
                <div className='mt-4 mb-4'>Queek Delever offers a wide range of courier services to suit your needs: express, standard, collect and more. We also offer door-to-door Free Pickup Service with Packaging Solution.</div>

                <div className='my-4 text-center'><h6 className='bgHt p-2'>- No matter where you're located in the world, we'll get your parcel to its destination -</h6></div>



                <div className='my-3 e-heading'><h5>How we are different</h5></div>
                <div className='my-4'>We are the only courier company that  offers international, Domestic and local  free Pickup Service with 100% Money Back Guarantee. Our system is designed to be as streamlined as possible, saving you time and money.</div>


                <div className='my-3 e-heading'><h5>How we work</h5></div>

                <div className='mt-4 mb-4'>Signing up with Queek Delever is simple, just enter your address and contact information, choose the delivery service you prefer (standard or express), fill out your order details, and we'll take care of the rest.</div>

                <div className='my-3 e-heading'><h5>Why Queek Delever?</h5></div>
<ul>
             <li>   <div className='mt-4 mb-4'>We provide a wide range of delivery services - from Local to International - One Stop Solution for all your logistics needs</div></li>

              <li> <div className='mt-4 '>Free Packaing Solution from 0.500 kg to 100 kg at your door Step pickup </div></li> 
              <li> <div className='mt-4 '>Fast and easy way to get your parcel Picked up or delivered to where you need it</div></li> 

              <li>  <div className='my-4'>We always deliver on time, every time, because our team is made up of professionals who are experts in their field.</div></li>

               
                             <li>   <div className='my-4'>Offers a variety of shipping options and offers the most competitive prices in the industry.</div></li>

                                </ul>
                <div className='pt-3 highlight px-2 pb-2 d-none'>One Stop Solution for all your logistics needs</div>
                
                 </div>
                 <div className=' p-3'>
  
                 
                  </div>

               <div className='container-fluid mt-4'>
                <div className='row'>
                <div className='col-sm-6  text-center px-3'>
                  <div className='visBg'>Vision</div>
                  <div className='bgLight d-flex'>
                    <div><img  src={vision} /> </div>
                    <div className='visCap pt-2'>Providing technological solutions and customer oriented innovation</div>
                  </div>
                </div>


              
                <div className='col-sm-6  text-center px-3'>
                <div className='visBg'>Mission</div>
                  <div className='bgLight d-flex '>
                    <div className='px-2'><img  src={mission} /> </div>
                    <div className='visCap pt-4 text-center '>From Idea to Innovation</div>
                  </div>

                </div>
                </div>
               </div>

                  <div className='btnShip mt-3 text-center ms-3 d-none'  >Ship Now  </div>
                  </div>
  
  
                  <div className=' col-sm-5 d-flex justify-content-center d-none d-md-block'>
                    <div className=''>  <img src={localDel} className=' locSiz1' /></div>
                  </div>
              </div>
  
              </div>
          </div>
        
     
     <Footer />
     </HelmetProvider>
      </div>
        );
      }
    }

export default About;
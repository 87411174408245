import React, {useRef, useState} from 'react';
import { Header } from '../../Components/Header';
import { NormalButton } from '../../Components/NormalButton';
import './OTP.css'
import OtpInput from 'react-otp-input';
import { useEffect } from 'react';
import {useNavigate,useLocation} from 'react-router-dom'
import { BsChevronLeft } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import { BottomMsg } from '../../Components/BottomMsg';
import { Helmet } from "react-helmet";


export const OTP = () => {
const login =useLocation()
const [flag,setFlag] = useState(false)
const navigate = useNavigate()
    useEffect(() => {
        input1.current.focus()
    },[])

    const back =() => {
        navigate('/login')
    }

   const input1 = useRef(null)
   const input2 = useRef(null)
   const input3 = useRef(null)
   const input4 = useRef(null)

   const inp1 =(e) => {
    if(e.target.value.length > 0)
    {input2.current.focus()}
     }


   const inp2 =(e) => {
    if(e.target.value.length > 0)
    {input3.current.focus()}
     
     else
     {input1.current.focus()}
}

const inp3 =(e) => {
    if(e.target.value.length > 0)
    {input4.current.focus()}
     
     else
     {input2.current.focus()}
}

const inp4 =(e) => {
    if(e.target.value.length > 0)
    {input4.current.focus()
        if(e.target.value.length >1)
        {input4.current.value= e.target.value.s}
    }
     
     else
     {input3.current.focus()} 
}

    const otpLogin =() => {
        

       let otp1 =(input1.current.value+input2.current.value+input3.current.value+input4.current.value)
        let mobile = login.state.mob
        let type = login.state.type
       console.log(type)
        if(otp1 === login.state.otp)
        {
          const  custData = [{
            custId : mobile,
            type : type
          }]

            localStorage.setItem ('custData', JSON.stringify(custData))

            navigate('/pickup',{
                state:{mobile,type}
            });
            setFlag(true)
        }
        else 
        {
            setFlag(true)
            setTimeout(() => {setFlag(false)},2000)
        }
    }
  
  return(
      <>

<Helmet>
   <script disable-devtool-auto src='https://cdn.jsdelivr.net/npm/disable-devtool@latest/disable-devtool.min.js'></script>
</Helmet> 

      <div className='container-fluid justify-content-cener bg-blue vh-100'>
          <div className='row'>
              <div className='col-md-4'></div>
              <div className='col-md-4 px-0 bgVerifty vh-100'>
              {/* <Header name="Verify OTP" /> */}
              {/* text send label */}
              <div className='d-flex align-item-center  py-3 headLogin px-3' ><div className='cursor' onClick={back} ><BsChevronLeft size='22' color='black' /></div> </div>


            <div className=' px-3 pt-4 pb-3 headLogin'>
                <div><h4>Verify OTP</h4></div>
                <div className=''><h6 className='text-dark ps-2 pt-1 loginTxt' ><span className='pe-2'><BsWhatsapp size='20' color='#075E54' /></span>WhatsApp OTP Sent to +91 {login.state.type ==='Employee' ? login.state.phone : login.state.mob}</h6></div>
            </div>
             
             {/* otp textbox */}
             <div className='d-flex justify-content-around mt-5 mx-4 '>
                {/* textbox 1  */}
             <div className='otp-border'>
            <input ref={input1} className='otp-input' type='number' maxLength="1" onChange={inp1} />
             </div>

              {/* textbox 2  */}
              <div className='otp-border'>
            <input ref={input2} className='otp-input' type='number' maxLength="1" onChange={inp2} />
             </div>

             {/* textbox 3  */}
             <div className='otp-border'>
            <input ref={input3} className='otp-input' type='number' maxLength="1" onChange={inp3} />
             </div>

             {/* textbox 4  */}
             <div className='otp-border'>
            <input ref={input4} className='otp-input' type='number' maxLength="1" onChange={inp4} />
             </div>

            </div>
                {/* send again label */}
            <div className='mt-4 d-flex justify-content-end me-5'>
                <span className=' ms-auto  send-font text-white'>Didn’t receive code? </span>
                <span className='send-font text-white ps-1' >Resend again</span>
            </div>
            {/* verify btn */}
            <div className='mx-3' onClick={()=> otpLogin()}>
            <NormalButton name="Verify" />
            </div>
           
              </div>
              <div className='col-md-4'></div>
          </div>
      </div>
     
{  flag &&   <div><BottomMsg name='Please enter correct 4 digit OTP' /> </div>}     
 </>
  )
};

import React from 'react';
import './CourierSer.css';

export const CourierSer = (props) => {



  return (
      <>
      
<div className={` ${props.active ?'act-border' :'ser-border'}  d-flex flex-column justify-cotent-center align-items-center `}>
<div>
<img src={props.icon} alt="document" className= {`img-fluid   ${props.name ==='Gift' ? 'icon-size' :''}`}   style={{height:35,width: 35}}  />
</div>
<div className='icon-fnt'>{props.name}</div>

</div>
      </>
  )
};

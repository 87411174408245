import React from 'react';
import { Heading } from '../../Components/Heading';
import Impact from './Impact.svg';
import './OurImpact.css';
import people from './People.svg'
import globe from './Globe.svg'
import box from './smartBox.png'

import './EmotionalBond.css'
import { NormalButton } from '../../Components/NormalButton';

export const OurImpact = () => {
  return (
      <>
        <Heading heading="Smart Parcel Station" />
   <div className='container   mt-5 pt-4'>
  
   
      <div className='row justify-content-center align-item-center'>
<div className='text-dark  text-center text-light  mb-5 impact-txt' >Presence of Smart Parcel Station in the country will changed the concept of intra-city shipping and reduce  traffic & emission in our Local area</div>

<div className='d-md-none col-md-4 d-sm-block d-md-block  impact-marg '>
<div className='d-flex justify-content-center'>
    <img src={box} alt="people" className='smartBox' />
  </div>
</div>

<div className='col-sm-8 mt-5'>
  <div className='h-75   d-flex flex-column  justify-content-center  '>
<div className=' d-flex flex-column justify-content-center'><div className='pb-4 mt-4 ps-3 '><h2 className='text-nowrap'> Next Generation Delivery</h2></div>
<div>
  <div className=''>
    <ul>
      <li>Get Same day delivery at affordable price</li>
      <li>Collect or Book Parcel anytime  by 24 x 7 </li>
      <li>No need to wait for delivery boy for delivery</li>
      <li>Traffic & Co2 emission free delivery</li>
    </ul>
  </div>
</div>
<div className='mt-3'>
<div className=' d-flex justify-content-start ComingBtn'>
                    <button type="button" className="btn btn-dark btn-size bgYellowBtn"><span className='coming-font text-nowrap   '>Coming Soon</span></button>

                </div>
</div></div>
</div>
</div>

<div className='d-none col-md-4 d-md-block impact-marg '>
<div className='d-flex justify-content-center'>
    <img src={box} alt="people" className='smartBox' />
  </div>
</div>

</div>
</div>

<div className='col-sm-12 mt-n5 d-none'>
<div className='d-flex align-items-center justify-content-end'>
  <div>
    <img src={globe} alt="people" className='people-1' />
  </div>
  <div className='cust-font'>
    <div className='cust-font'>10,000+   </div>
    <div className='impt-font'>Daily Global Shipments</div>
  </div>


</div>
</div>
      </>
  )
};



import React from 'react';
import './SmallButton.css';
import { useNavigate } from 'react-router-dom';

export const SmallButton = (props) => {
    const navigate = useNavigate();
  return <div>
      <button type="button" className="btn  btn-sm btn-primary text-nowrap" onClick={() => navigate("/login")} >{props.name}</button>
       </div>;
};



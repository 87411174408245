import React,{useEffect, useState} from 'react'
import './QueekPickup.css'
import Route from './Route.png'

import { BiRupee } from "react-icons/bi";
import { FaRegDotCircle } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlinePinDrop } from "react-icons/md";
import Drawer from "react-bottom-drawer";
import axios from "axios";
import { Helmet } from "react-helmet";



export const QueekPickup = (props) => {
    const [pick,setPick] = useState(false)

    useEffect(() =>{
      console.log(props.data)
    },[])
    const accept =() => setPick(true)

const updateStatus =async(e) => {
 await axios.post(`https://queekdelever.com/queekApi/FoodStatus.php?Id=${e}&boyId=${props.empId}&status=Confirm`)
 setPick(false)
 props.pickRefresh(true)
}

  return (
      <>
             <Helmet>   <script disable-devtool-auto src='https://cdn.jsdelivr.net/npm/disable-devtool@latest/disable-devtool.min.js'></script></Helmet> 

    <div>
    <div className='d-flex justify-content-center '><div className='dash'></div></div>
        <div className='d-flex align-items-center justify-content-between mt-3'>
          <div className='d-flex '>  <img src={Route} alt="route"/>
         
            <div className='km pt-2 ps-2'>{ props.data[0].km && props.data[0].km.substring(0,3)} Kms</div></div>
            <div className='d-flex align-items-center'><div className='mb-1'><BiRupee size="18" /></div>
            <div className='paise'>{props.data[0].payment}</div> 
            </div>
        </div>

        <hr className='divide1' />

<div className='d-flex mt-2 align-items-center'>
<div className='pe-4 position-relative'><FaRegDotCircle size='23' color='#EB4335' />
<div className='tran'> < BsThreeDotsVertical size='30' color='gray' /></div></div>
<div className='pickupCard p-2 position-relative '>
<div className='floatLbl px-1 '>{props.data[0].fname}</div>
{/* <div className='ps-2 pikName mt-1'>{props.data[0].fadd}</div> */}
<div className='pikAdd ps-2 mt-1'>{props.data[0].fadd}</div>
</div>
</div>

<div className='d-flex mt-4 align-items-center'>
<div className='pe-3'><MdOutlinePinDrop size='29' color='#008000' /></div>
<div className='pickupCard p-2 position-relative '>
<div className='floatLbl px-1 '>{props.data[0].dname}</div>
{/* <div className='ps-2 pikName mt-1 text-dark'>{props.data[0].dname}</div> */}
<div className='pikAdd ps-2 mt-1'>{props.data[0].dadd}</div>
</div>
</div>

<div className='mt-4 ms-5' onClick={()=>accept()} ><button className='btn text-white accept'>Accept</button></div>
      
    </div>

{/* // accept pickup */}
{/* {pick && <div className=''>
<div className='d-flex justify-content-center '><div className='dash'></div></div>
<div className='w-25'> <input className='inputTxt' placeholder='Code' /></div>
</div>} */}

<Drawer  isVisible={pick}> 

<div className='mt-3 mb-2 '>

 <div className='d-flex justify-content-between'>
 <div className='w-50'><button className='btn btn-primary' onClick={()=> updateStatus(props.data[0].id)} disabled={pick ? false:true} >Confirm</button></div>
  <div className='w-50'onClick={() => setPick(false)} ><button className='btn btn-outline-dark'>Close</button></div>
 </div>
</div>

</Drawer>

</>
  )
}

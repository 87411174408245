import React, { useEffect, useRef, useState } from 'react';
import './PickDrop.css';
import { FaRegDotCircle } from "react-icons/fa";
import { FiMapPin, FiCrosshair, FiHome } from "react-icons/fi";
import { BsBoxSeam } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { BiBuildings } from "react-icons/bi";
import { MdCall } from "react-icons/md";
import { FaUserCheck} from "react-icons/fa";
import { FaEdit} from "react-icons/fa";



export const PickDrop = (props) => {
  const [frmData,setFrmData] = useState('')
  const [dstData,setDstData] = useState('')
  const navigate = useNavigate();
  const pkCity = useRef();
  const dsCity = useRef()
 let placeholder=''
 let a=0;
 let b=0;
  if(props.type == 'Local')
{
placeholder='Enter Delivery Address';
}
else if(props.type == 'Domestic')
{
placeholder='Enter 6 Digit Pincode';
}
else if(props.type == 'International')
{
placeholder='Select Destination Country';
}

 const login = () => {
  // navigate('/login');
 }

  const getLatLng1 =(e) => {
  pkCity.current.value=e
  setFrmData('')
  }
  

  const getLatLng =(e) => {
    dsCity.current.value=e
    setDstData('')
    }

 const orgin =(e) => {
  props.pkLoc(true)
  return
  if(e.length > 0)
{fetch(`https://queekdelever.com/queekApi/dom_search.php?search=${e}`)
.then(res=> res.json())
.then(data => setFrmData(data))}
else {
  setFrmData('')
}
 }


 const dest =(e) => {
  let url= 'https://queekdelever.com/queekApi/dom_search.php?search='
  if(props.delType ==='Domestic')
  url= 'https://queekdelever.com/queekApi/dom_search.php?search='
  else
  url= 'https://queekdelever.com/queekApi/country_new.php?search='

  if(e.length > 0)
{fetch(`${url}${e}`)
.then(res=> res.json())
.then(data => setDstData(data))}
else {
  setDstData('')
}
 }

 useEffect(() => {
dsCity.current.value=''
 },[props.delType])

 const [dflag,setDflag]= useState(false)
 const [pflag,setPflag]= useState(false)

 const ppx =() => {
 setDflag(false)
 setPflag(true)
  }

const dox =() => {
  setDflag(true)
  setPflag(false)
}

useEffect (() => {
  if(props.pkInf.length > 0)
pkCity.current.value = props.pkInf[0].name
},[props.pkInf])
  return(
      <>
      <div className='d-flex justify-content-start ms-1 position-relative' >
          <div className='d-flex flex-column  align-items-center mt-4' >
            <div >
              <FaRegDotCircle size="22" color="#AE030E" />

            </div>
            <div className='trans'></div>
            <div>
              <FiMapPin size="24" color="#008000" />
            </div>
            <div className='trans2'></div>
            <div>
              <BsBoxSeam size="22" color="#000" />
            </div>
          </div>
          {/* textbox  */}
          <div className='ms-3  w-100 position-relative' >
          {/* <div className='pd-label'>Home</div> */}
           
           <div className={`${!props.pkInf.length > 0 ? 'd-none':'d-block'} pkCon2 position-relative`}>
            <div className='d-flex'><div className='pe-2 '><BiBuildings size='15' /></div> <div className='text-truncate w-75'>{props.pkInf.length > 0 && props.pkInf[0].add}</div></div>
            <div className='d-flex justify-content-between mt-2'>
              <div><span className='pe-2'> <MdCall size='16' /></span> 7814002400</div>
              <div><span className='pe-2'><FaUserCheck size='16' /></span>Dinesh Sharma</div>
            </div>

            <div className='position-absolute top-0 end-0 translate-middle-y ' onClick={() => {props.hides('two')} }><span className='editBk cursor'><FaEdit size='20' color='#000' /></span></div>
           </div>
        
         
       
         <div className= {`input_pick  d-flex mt-2 position-relative  ${props.pkInf.length > 0 ? 'd-none':'d-block'}`}  >
        
        <input ref={pkCity} className='input_sizs' placeholder='Enter pickup city' readOnly  onClick={()=> orgin() }  />
     
        <span><FiCrosshair  color="gray" /></span>
        
        <div className='float-lbl'>Pickup from</div>
      {frmData.length >0 &&  <div className='position-absolute top-100 start-0 autoCompletes'>
         {frmData.length >0 && <>
   
   <div className='ps-2 pt-2'>{frmData.map( x => <div className='locCon' onClick={ () => getLatLng1(x.name)}    key={a=a+1}>  
   <div className='locHead1 twoLine '> {x.name}</div>  
   {/* <div className='desc text-truncate text-dark'>{x.description}</div> */}
 <span className= {`${a+1 === frmData.length ? 'd-none' :'d-block'}`}>  <hr className='my-2 px-0 divider'/></span>
   </div>)}</div>
   </>}
        </div>}
        </div>
       
        

              {/* <hr/> */}
            <div style={{ marginTop: 20 }}></div>

            <div className='input_pick  d-flex mt-2 position-relative '>
              <input ref={dsCity} className='input_sizs' placeholder='Enter delivery city' onChange={(e) => dest(e.target.value) }/>
              <span><FiHome  color="gray" /></span>
            
              <div className='float-lbl'>Deliver to</div>
              
              {dstData.length >0 &&  <div className='position-absolute top-100 start-0 autoCompletes'>
         {dstData.length >0 && <>
   
   <div className='ps-2 pt-2'>{dstData.map( x => <div className='locCon'     key={b=b+1}   onClick={ () => getLatLng(x.name)}>  
   {/* <div className='locHead1 '> {x.description}</div>   */}
   <div className='desc text-truncate text-dark'>{x.name}</div>
 <span className= {`${b+1 === dstData.length ? 'd-none' :'d-block'}`}>  <hr className='my-2 px-0 divider'/></span>
   </div>)}</div>
   </>}
        </div>}
              </div>

              <div className='d-flex justify-content-between mt-4 ' >
        <div className='doxBox d-flex align-items-center justify-content-center cursor' onClick={() => dox()}><div className='pe-2 pt-1'><input  type='radio' checked={dflag} /></div> <div className=''>Document</div></div>
        <div className='doxBox  d-flex align-items-center justify-content-center cursor'onClick={() => ppx()} ><div className='pe-2 mt-1'><input  type='radio' checked={pflag} /></div> <div>Parcel</div></div>

      </div>
           
           {/* <div className=' reverse '><RiArrowUpDownFill size="20" color='#fff' /></div> */}
          </div>
          </div>
        
      </>
  )
};

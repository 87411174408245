import About from './About.jsx';
import React, { useEffect } from 'react';
import Home from './pages/Home/Home.jsx';
import Contact from './Contact.jsx';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Login } from './pages/Login/Login.jsx';
import { SignUp } from './SignUp.jsx';
import Service from './Service.jsx';
import { Verify } from './pages/Login/Verify.jsx';
import {OTP} from './pages/Login/OTP.jsx';
import { CreatePwd } from './pages/Login/CreatePwd.jsx';
import { Profile } from './pages/Login/Profile.jsx';
import { UnderStand } from './pages/Ebook/UnderStand.jsx';
import  {QueekHome}  from './pages/Ebook/QueekHome.jsx';
import './App.css'
import Navbar from './Navbar.jsx';
import { Receipt } from './Components/Receipt.jsx';
import { Tracking } from './Components/Tracking.jsx';
import { FoodDelivery } from './pages/Ebook/FoodDelivery.js';
import { FoodStroeApp } from './pages/Ebook/FoodStroeApp.js';
import { AcceptPickup } from './pages/Ebook/AcceptPickup.js';
import { PaymentDone } from './pages/Ebook/PaymentDone.jsx';
import { Kyc } from './Components/Kyc.js';
import { Local } from './Local.js';
import { Domestic } from './Domestic.js';
import { International } from './International.js';
import { Prohibited } from './Prohibited.js';
import { Privacy } from './Components/Privacy.js';
import { Term } from './Term.js';
import { Label } from './Components/Label.js';
import { RefundPolicy } from './Components/RefundPolicy.js';
import { Helmet } from "react-helmet";


const App=(props)=> {

 

  let api_key = '';

  return (
  <>

<Router>

 <Routes>
    <Route path="/pickupReq" element={<Home />} />
      {/* <Route path="about/" element={<About /> }/> */}
      <Route path="contact" element={<Contact />} />
      <Route path="service" element={<Service />} />
      <Route path="/singup" element={<SignUp />} />
      <Route exect path="/login" element={<Verify />} />
      {/* <Route path="/verify" element={<Verify />} /> */}
      <Route path="/otp" element={<OTP />} />
      <Route path="/pwd" element={<CreatePwd/>} />
      <Route path="/profile" element={<Profile/>} />
      <Route path="/receipt/:awb" element={<Receipt/>} />
      <Route path="/tracking/:awb" element={<Tracking/>} />
      <Route path="/foodstoreApp/:id" element={<FoodStroeApp/>} />
      <Route path ='/QueekDelivery/:id' element ={<FoodDelivery />}  />
      <Route path ='/acceptPickup/:id/:emp' element ={<AcceptPickup />}  />
      <Route path='/kyc/:awb' element ={<Kyc/> } />
      <Route path='/label/:custId' element ={<Label/> } />

      <Route path="/understand" element={<UnderStand/>} />
      <Route path="/pickup"   element={<QueekHome   apiKey={api_key}/> } /> 
      <Route path='/PaymentSuccessful/:id/:loc' element={<PaymentDone /> }  />
      <Route path='/localDelivery' element={<Local /> }  />
      <Route path='/Domestic' element={<Domestic /> }  />
      <Route path='/International' element={<International /> }  />
      <Route path='/prohibited' element={<Prohibited  /> }  />
      <Route path='/privacy' element={<Privacy  /> }  />
      <Route path='/term' element={<Term  /> }  />
      <Route path='/refundpolicy' element={<RefundPolicy /> } />
    </Routes>
     </Router>
  
  </>
  );
}



export default App;

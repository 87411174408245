import React from 'react';
import { Heading } from '../../Components/Heading.jsx';
import { NormalButton } from '../../Components/NormalButton.jsx';
import { BsArrowRightCircle} from "react-icons/bs";
import { useNavigate } from 'react-router-dom';

import './About.css'

export const About = () => {
  const navigate = useNavigate()
  return (
      <>
      <div ><Heading heading="About us"/></div>
    <div className=' my-5 text-center   about-font'>Queek delever is online courier booking company for delivering your orders to Local, across Country and Globe. We delivers to more than 200 countries and  across Nation more than 16,000 destinations .</div>
<div className=' my-4 text-center  about-font'>No matter where you are in the world. .We provide same day, urgent and scheduled deliveries. Our services include international door-to-door service to the US, Canada, Australia and other Countries.</div>
   
   <div className=' d-flex justify-content-center mt-5' onClick={()=> navigate('/about')}><div className='aboutBtn'><NormalButton name='know more' /></div></div>
      </>
  )
};

import React from 'react'
import { DeliveryAdd } from '../../Components/DeliveryAdd'
import { Header } from '../../Components/Header.jsx'



export const DeliveryAddress = (props) => {

    const typAhead =() => {}
    const back =() => props.back('close')
  return (
   <>
   <Header name="Delivery address" back ={back} bottomBtn="no" Bar={'no'}  Placeholder="Search new location for pickup" typAhead={typAhead} show='' Loading={'done'} >
<DeliveryAdd/>

    </Header>
   </>
  )
}

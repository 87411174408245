import React from 'react'
import { AiOutlineCloseCircle } from "react-icons/ai";



export const Cancel = () => {
  return (
    <div className='rightIcon1 position-absolute bottom-0 end-50 translate-middle-y mb-2 ps-5 cursor'>
        <div className=' text-white px-2   rounded' style={{backgroundColor:'#c01919'}}>X</div>
    </div>
  )
}

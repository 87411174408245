import React, { useState, useRef,useEffect }  from 'react';
import './DropInput.css';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { FaUserAlt } from "react-icons/fa";
import { RiBuilding4Fill } from "react-icons/ri";
import { FaMapMarkerAlt } from "react-icons/fa";


export const DropInput = (props) => {

  const [isQty,setQty] =useState('0');
  const Weight = useRef(null);
  const decVal = useRef(null)
  const WgtSlab = useRef(null);
  const [Wgt,setWeight] =useState('')
  const [decValue, setValue] = useState('')
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


let active=true;
let in_type='slab';



useEffect( () => {
  WgtSlab.current.selectedIndex=0;
  setWeight(WgtSlab.current.value) 
  props.CharWgt(WgtSlab.current.value);

 },[props.type])

const Slab =(e) =>{
  setWeight(WgtSlab.current.value)
  // props.CharWgt(Wgt);
  props.CharWgt(WgtSlab.current.value);

}


if (props.type == 'parcel')
{ 

active=false; 
in_type ='wgt' 
WgtSlab.current.selectedIndex=0;
}
else if (props.type == 'food')
{in_type='qty';
WgtSlab.current.selectedIndex=0;
}


const changeHandler =(e) => 
{
if(isNaN(e.target.value))
{}
else { 
  setQty(e.target.value)
}

}

const PutWgt = (e) =>
{
  setWeight(Weight.current.value)
  props.CharWgt(Weight.current.value);
  
}


const parcelValue =(e) => {
  setValue(decVal.current.value)
}

const incrase =(e) =>
{
  if(e.target.value <= 1)
  {
    let qty = isQty
    qty ++;
    setQty(qty)}
}

const decrease =(e) =>
{
 if( isQty >1)
 {
  let qty= isQty;
  qty --;
  setQty(qty);
 }
}


  return(
      <>
    
      <div  className={`${in_type == 'qty'? "d-none":"d-block"} ${props.WgtError=='Red' ?'WgtError':''}  `}  >
        
        <div className={`d-flex justify-content-between ${in_type == 'wgt'? "d-block":"d-none"}`}>
        <div className={`d-flex drop-border mt-2 `}>
              <div className={`container-fluid px-1 `} >
              <div className='d-flex justify-content-between align-items-center'>
              <div><input  className='d-input_sizs py-1' type="text" placeholder='weight' ref={Weight} onClick={handleShow} onChange ={PutWgt} value={Wgt}  /></div>
             <div>KG</div>
              </div>
              </div>
           </div>

           {/* <div className={`d-flex drop-border mt-2 parceInput `}>
              <div className={`container-fluid px-0  `} >
              <div className='d-flex justify-content-between align-items-center'>
              <div><input  className='d-input_sizs py-1' type="text" placeholder='Declared value' ref={decVal}    onChange ={parcelValue} value={decValue}  /></div>
             <div><BiRupee/></div>
              </div>
              </div>
           </div> */}
           </div>

           
          <div className={`drop-border d-flex  d-flex mt-2  ${in_type =='slab'? "d-block":"d-none" }`}>
  <select   ref={WgtSlab} onClick={Slab} className={`form-select form-select-sm  border-0   `} aria-label="Default select example">
{/* <option value="0" defaultValue="" >Select Weight Slab</option> */}
{props.type === 'medicine' || props.type === 'document' ? 
 <>
<option value="0.500">0 - 0.500 gms</option>
  <option value="1.000">0.501 - 1.000 kg</option>
  <option value="1.500">1.001 - 1.500 kg</option>
  <option value="2.000">1.501 - 2.000 kg</option>
 </> :
 <>
  <option value="0.500">0 - 1.000 kg</option>
  <option value="1.500">1.001 - 2.000 kg</option>
  <option value="0.500">2.001 - 3.000 kg</option>
  <option value="1.500">3.001 - 4.000 kg</option>
  <option value="2.000">4.001 - 5.000 kg</option>
  </>
}
</select>
<div className='position-absolute top-0 start-0 translate-middle-y ms-2 bg-white px-1 labelWt text-muted'>Select Estimated Weight</div>
</div>        
            

</div>

              {/* <div className='d-float-lbl' style={{background:`${props.color}`}}>{props.name}</div> */}
              

     {/* product quantity */}
     <div className={`${in_type =='qty' ? "d-block": "d-none"}  position-relative  `  }>
      <div className={`d-flex justify-content-center  align-items-center mt-1`}>
     <div className='ps-3 pb-3 position-relative'>
     <button className=' pdt-btn me-3 ms-2'  onClick={decrease}>-</button>
          <input id="wgt" className='pdt-input' onChange={changeHandler} value={isQty} readOnly maxLength="3" />
          <button className=' pdt-btn ms-3' onClick={incrase}>+</button>
     </div>
     <div className=' ps-2 qty-size position-absolute top-100 start-100   text-nowrap w-100 '>Number of order or piece</div>

      </div>
           </div>

   <div></div>

   <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        
        </Modal.Header>
        <Modal.Body>
          <div className='container'>
            <div className='col-sm-12'><div className='d-flex align-items-center fromInput'><span><FaUserAlt size="14"/></span><input placeholder='Enter Parcel Weight'className='locInput'/></div></div>
            <div className='col-sm-12'><div className='d-flex align-items-center fromInput'><span><RiBuilding4Fill size="14" /></span><input placeholder='Decalred value'className='locInput'/></div></div>
            {/* <div className='col-sm-12'><div className='d-flex align-items-center fromInput'><span><FaMapMarkerAlt size="14" /></span><input placeholder='Landmark (optional)'className='locInput'/></div></div> */}
         
        
        
          </div>
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
  
      </>
  )
};  


import React from 'react'
import { Header } from '../../Components/Header.jsx'
import {PickupAddress} from  '../../Components/PickupAddress.jsx'

export const PickupLocation = (props) => {
    const typAhead =() => {}
    const back =() => props.back('close')
    const edit =(e) => props.edit(e)
    const loc =(e) => props.loc(e)
  
  return (
  <>
  <Header name="Pickup address" back ={back}  bottomBtn="no" Bar={'no'}  Placeholder="Search new location for pickup" typAhead={typAhead} show='' Loading={'done'} >
<PickupAddress edit={edit} loc={loc} />

</Header>
  </>
  )
}

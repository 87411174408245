import React, {useEffect, useState} from 'react';
import { Local } from '../pages/Ebook/Local.js';
import './Input.css'

export const Input = (props) => {
const [text,setText] =useState('')

useEffect(()=> 
{
   if (props.value ==='undefined')
   setText('')
  setText(props.value)

},[props.value])


  
  return (
      <>

      {/* <div className='ms-3 pb-1 floatingLbl'>{props.label}</div>
      <div className='input-border d-flex position-relative'>
          <span className='me-2 ext'>{props.ext}</span>
          

    <input placeholder={props.placeholder} className='input-style' type={parseInt(props.type)} maxlength={props.len} value={props.value}/>
      </div> */}


    {/* <div className="form-floating ">
  <input type={props.type} className="form-control  " id="floatingInput" value={text}  onChange={e => setText(e.target.value)} />
  <label htmlFor="floatingInput">{props.placeholder}</label>
</div> */}


<div className="">
  <label forhtml="exampleFormControlInput1" className="form-label control-label col-sm-3 small mb-0 text-nowrap">{props.label}</label>
  <input type={props.type} className="form-control form-control mb-2" id="exampleFormControlInput1" placeholder={props.placeholder} value={text}  maxlength={props.len} onChange={e => setText(e.target.value)} />
</div>

{props.search ==='yes' ?
 text !=='' ?
<Local typeAhead = {text} serType='pickup' /> :""
:""}
 </>
  )
};

import React,{useEffect , useRef, useState} from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import { BsChevronLeft } from "react-icons/bs";
import { FaCalendarAlt } from "react-icons/fa";
import { FiCreditCard } from "react-icons/fi";
import { BsBookmarkCheck} from "react-icons/bs";
import { GiConsoleController, GiReceiveMoney} from "react-icons/gi";
import './Cash.css'
import { BiRupee } from "react-icons/bi";
import { BsThreeDotsVertical} from "react-icons/bs";
import { Spinner } from '../../Components/Spinner';
import { GiCash } from "react-icons/gi";
import Drawer from "react-bottom-drawer";
import { GrClose} from "react-icons/gr";
import {MdArrowForwardIos} from "react-icons/md";
import { NormalButton } from '../../Components/NormalButton';
import { number } from 'prop-types';


export const Cash = (props) => {
    const [printType,setPrintType] = useState('Today')
    const [printData,setPrintData] = useState('')
    const [book,setBook] = useState('')
    const [query,setQuery] = useState(false)
    const [load,setLoad] = useState(false)
    const [hideCal,setHideCal] = useState(false)
    const [hide,setHide] = useState(false)
    const [from, onFrom] = useState(new Date());
    const [to, onTo] = useState(new Date());
    const [branch,setBranch] = useState('')
    const [emp,setEmp] = useState('')
    const [today,setToday] = useState()
    const [err,setErr] = useState(false)
    const [eType,setEType] = useState('')
    const empCode = useRef(null)
    const [payments,setPayments] = useState('')
    const [trasFlag,setTransFlag] = useState(false)

    let b=0;
    let c=0;
    let d=0;

  useEffect(() => {
    let month = to.getMonth()+1
    if (month <= 9)
     month = '0'+ month
   
     let day= to.getDate()
     if ( day <= 9)
     day = '0'+ day
    let start = `${to.getFullYear()}-${month}-${day}`
    setToday(start)
    onFrom(start)
    onTo(start)
    setLoad(true)
    
    cashInHand(start)
 
  },[])

  const paymentQuery =(awb,transId,transId2,payment1,payment2,net) => {
    
    const payData = [{
      awb : awb,
      transId : transId,
      transId2 : transId2,
      payment1 : payment1,
      payment2: payment2,
      total : net
     
    }]
      setTransFlag(true)
      setPayments(payData)
  }

  const loginType =() => {
    fetch(`https://queekdelever.com/queekApi/loginType.php?empId=${props.empId}`)
    .then(res => res.json())
    .then(data => {setEType(data)
      // Branch()
    } )
  }

  const cashInHand1 =() => {
    if(from > to)
    {setErr(true)
      return
    }
    else
    {setErr(false)
    fetch(`https://queekdelever.com/queekApi/cashBook.php?empId=${empCode.current.value}&from=${from}&to=${to}`)
    .then( res => res.json())
    .then(data =>{ setBook(data)
      setLoad(false)
      setHideCal(false)
    })
  }}

  const cashInHand =(e) => {
    setLoad(true)
    setTimeout (()=> {
      fetch(`https://queekdelever.com/queekApi/cashBook.php?empId=${props.empId}&from=${e}&to=${e}`)
      .then( res => res.json())
      .then(data =>{ setBook(data)
        loginType()
        setTimeout(()=>{setLoad(false)},500)
      })
    },400)
  }

    const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumSignificantDigits: 5 
    }).format(value);

    const Branch =() => {
      setLoad(true)
      fetch(`https://queekdelever.com/queekApi/Branch.php`)
      .then( res => res.json())
      .then(data => {
        setLoad(false)
        setBranch(data)
       setTimeout(() => { employeeId(data[0].branch)},200)
      } )
    } 

    const employeeId =(e) => {
      setLoad(true)
      fetch(`https://queekdelever.com/queekApi/EmpId.php?branch=${e}`)
      .then (res => res.json())
      .then (data => {
        setLoad(false)
        setEmp(data)
        
      })
    }

    
    const fetchApi =(e) => {
    
        const url =`https://queekdelever.com/queekApi/printSticker.php?empId=${props.empId}&type=${e}`
        
              fetch(url)
              .then(response => response.json())
              .then(data => setPrintData(data))
            }
        
            useEffect(() => {
              fetchApi('')
            },[])

            const back=() => {
                props.back('close')
              }

          const fromDate =() => {
            return 
          }
  return (
   
         <div className='bgCash vh-100'>
          
{ eType && eType[0].loginType ==='Employee' &&   <div className={` d-flex justify-content-between parcelHeader align-items-center`}><div className="px-2 pe-4 back" onClick={back}><BsChevronLeft size="18" /> <span className='ps-2'>Cash In Hand</span> </div> <div className='pe-2 d-none'><BsThreeDotsVertical size='20' /></div></div>
}

        {eType && eType[0].loginType ==='Admin'  && <div className='d-flex parcelHeader'> 
            <div className='px-2 pe-4 back' onClick={back }><BsChevronLeft size="18" /> </div>
            <div className='p-2 bg-white cashSearch rounded d-flex justify-content-between' >
              <div> <input className='inputTxt' placeholder='Search by Branch' /></div>
              <div onClick={() => {
                
                setHideCal(true)}}> <FaCalendarAlt size='18' /></div>
             </div>
            </div>}
          



{ book.length > 0 && <div className='scrollable-div2 scroll-y  my-3'>
{ book && book.map((x) =>    <div key={d=d+1} className='m-3'>
  {x.bkDate && <div className='text-center py-1 fName text-dark'>{eType && eType[0].loginType ==='Admin'? x.bkDate :'Today Sale'}</div>}
    <div onClick={() =>paymentQuery(x.awb,x.transId,x.transId2,x.payment1,x.payment2,x.net) } className={`${props.active ? "Rate-con1" :"Rate-con"} d-flex justify-content-between pe-3 mb-2 cursor `}  >
    <div className='d-flex align-items-center '>
    <div className='cahsRound'>{x.mop === 'off' ? <BiRupee size={28} color='000' />  :  x.mop ==='on' ? <FiCreditCard size={24} color='000' /> :<BsBookmarkCheck size={24} color='000' /> }</div>
    <div className='ps-4 d-flex flex-column '><span className='ServiceName '>{x.city}</span>
    <div><div className='delivery-lab '><span className='twoLine pe-1 text-muted '> <span className='text-dark text-nowrap'>{x.remark}</span> </span></div>
    </div>
    </div>
   </div>
   <div className=' user-select-none' >
   <div>{numberFormat(x.charges)}</div>
    </div> 

   </div>
    </div> )}
</div>}

   {book.length === 0 && !load &&  <div className='vh-100 d-flex flex-column justify-content-center align-items-center'>
      <div>< GiReceiveMoney size='80' color='gray' /></div>
      <div className='py-3'>Today,No Cash Booking Found</div>
      </div>}


   

    

     {/* today Sales  */}
    { book.length > 0 && !hide && !hideCal && !trasFlag && <div className='fixed-bottom p-3 '>
      <div className='cashBtm p-2 rounded'>
      <div className='d-flex justify-content-end small-f py-1'>Cash In Hand: {numberFormat(book && book[book.length-1].cash)}</div>
      <div className='d-flex justify-content-end small-f py-1'>Online Payment: {numberFormat(book && book[book.length -1].online)}</div>
      <div><hr  className='m-1' /></div>
      <div className='d-flex justify-content-end total py-1'>Total Sale: {numberFormat(book && book[book.length -1].total)}</div>
      </div>

     </div>}

     { load &&  <Spinner />}

    
    <Drawer isVisible={hideCal} >

    <div className=''>

    </div>

    {/* ************** Date period *********** */}
      <div className=''>
        <div className='d-flex justify-content-between'>
        <div className='py-2'>Select Date Period</div>
        <div onClick={() => setHideCal(false) }> <GrClose  /> </div>
        </div>

          <div>

        </div>

        <div className='my-3 '>

        <div className='p-2 card'>
        <div className='d-flex justify-content-between px-2 align-items-center'>
            <div className='d-flex justify-content-between w-100'>
           
           <div className='px-1  w-50'>
           <div className='fNam'>From Date</div>
            <div className='pt-2 ' > <input type ='date'    onChange={(e)=> onFrom(e.target.value)} value={from}  /> </div>
        </div>


        <div className='px-1'>
           <div className='fName mt-2'>To Date</div>
           <div className='pt-2 ' > <input type ='date'    onChange={(e)=> onTo(e.target.value)} value={to}  /> </div>

        </div></div></div> 
         { err && <div className='text-danger fName ps-3 mt-1'>Invalid Date Period</div>}
 
          </div>

                <div className="form-floating mt-3">
        <select onChange={(e) => employeeId(e.target.value) }  className="form-select" id="floatingSelect" aria-label="Floating label select example">
         {branch && branch.map((x) =>  <option key={b=b+1} value={x.branch} >{x.branch}</option> )}
         
        </select>
        <label htmlFor="floatingSelect">Select Branch Name</label>
      </div>
       <div className="form-floating mt-3">
        <select  ref={empCode}  className="form-select" id="floatingSelect" aria-label="Floating label select example" disabled ={load ? true : false}>
         {emp && emp.map((x) =>    <option key={c=c+1} value={x.empId} >{x.empId} -{x.name}</option> )}
         
        </select>
        <label htmlFor="floatingSelect">Select Employee Id</label>
      </div>
      </div>
        <div className='mt-5 mb-3' onClick={() => cashInHand1() }><NormalButton name='Submit'  /> </div>
        

      </div>
    {/* *********************************************** */}
    </Drawer>

    <Drawer isVisible={trasFlag && payments.length > 0 }  >
      
       <div>
   <div className='d-flex justify-content-between'>
        <div className='py-2 '>#<b>{payments && payments[0].awb}</b></div>
        <div onClick={() => setTransFlag(false) }> <GrClose  /> </div>
        </div>
   </div>

        
       {payments && payments[0].transId  &&  <div className='card p-2 my-3'>
              <div className='d-flex justify-content-between align-items-center '>
                <div>
                  <div>Online Transaction</div>
                  <div className='check-add'>Ref Id : {payments && payments[0].transId}</div>
                </div>
                <div>{numberFormat(payments && payments[0].payment1)}</div>
              </div>
        </div>}

        {/* trans 2 */}
        {payments && payments[0].transId2  &&  <div className='card p-2 mb-3'>
              <div className='d-flex justify-content-between align-items-center '>
                <div>
                  <div>Online Transaction</div>
                  <div className='check-add'>Ref Id : {payments && payments[0].transId2}</div>
                </div>
                <div>{numberFormat(payments && payments[0].payment2)}</div>
              </div>
        </div>}

        {payments && payments[0].total > 0 &&  <div className='card p-2 mb-3'>
              <div className='d-flex justify-content-between align-items-center '>
                <div>
                  <div>Cash Payment Received</div>
                  <div className='check-add'></div>
                </div>
                <div>{numberFormat(payments && payments[0].total)}</div>
              </div>
        </div>}

    </Drawer>

    </div>
    
  )
}

import React, { useRef, useState } from 'react'
import Map from '../../Components/Map.jsx';
import { BsChevronLeft } from "react-icons/bs";
import { withScriptjs } from "react-google-maps";
import '../../Components/GoogleMap.css'
import "./Location.css";
import { useEffect } from 'react';
import axios from 'axios';
import { BsBoxSeam } from "react-icons/bs";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { RiMapPin2Line } from "react-icons/ri";
import { NormalButton } from "../../Components/NormalButton";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { FaUserAlt } from "react-icons/fa";
import { RiBuilding4Fill } from "react-icons/ri";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdOutlineLocationOff } from "react-icons/md";
import { HiLocationMarker } from "react-icons/hi";
import { GrClose } from "react-icons/gr";
import { LoadScript} from "@react-google-maps/api";
import { useCurrentPosition } from 'react-use-geolocation';
import pick from './origin.svg'
import { PickupMap } from '../../Components/PickupMap';
import { getAllByPlaceholderText } from '@testing-library/react';
import { BottomMsg } from '../../Components/BottomMsg.js';
import { LocationOption } from './LocationOption.js';
import Drawer from "react-bottom-drawer";

class MapView extends React.Component {


  
  shouldComponentUpdate(nextProps) {
// return nextProps.org !== this.props.org;
    return this.props.update !=='false'
  }


  render(){

   
    const MapLoader = withScriptjs(Map);

    const route =(e) => {
if(e === 'ok')
this.props.routes(true)
else
this.props.routes(false)
}
  return  <MapLoader
  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBah2C34AUXeAESdNgB5GoXKgk6XhlYhMU"
  loadingElement={<div style={{ height: `100%` }}  />}
  origin = {this.props.org}
height={this.props.height}
  marker={false}
  route={route}
/>
  }
}

export const Location = (props) => {

  var stripchar = require('stripchar').StripChar;

const [org,setOrg] = React.useState(null)
const [loadd, setLoad] = React.useState(false) 
const [loc,setLoc] = React.useState({placeName:"",short_name:""})
const [lat,setLat] = useState('30.901649625');
const [lng,setLng] = useState('76.90586687500002');
const [show, setShow] = useState(false);
const [shows,setShow1] = useState('d-none')
const [locOff,setLocOff] = useState(false)
const [Name, setName] = useState(false)
const [addLine, setAddLine] = useState(true)
const [ht,setHt] = useState('380px')
const [tag,setTag] = useState('Home')
const [update,setUpdate] = useState('true')
const [tagName,setTagName] = useState()
const [err,setErr] = useState('')
const [press,setPress] = useState(false)
const [names,setNames] = useState('')
const [adds,setAdds] = useState('')
const [city,setCity] = useState('')
const [pincode,setPincode] = useState('')
const [state,setState] = useState('')
const saveas = useRef(null)
const [perErr,setPerErr] = useState('form-control')
const [addErr,setAddErr] = useState('form-control')
const [saveErr,setSavErr] = useState('form-control')
const [tryMAp,setTry] = useState(0)
const [pkService,setPkService] = useState(false)
const [loads,setLoads] = useState(false)
const [closeAlert,setAlert] = useState(false)
const [rst,setRst] = useState('')
const [rst1,setRst1] = useState('')
const [position, error] = useCurrentPosition();
    const [live,setLive] = useState(false)
   
   
    const capitalizeFirst = str => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };
  

const gpsLoc = () => {
if(position && props.placeId ==='')
{setOrg({lat:position.coords.latitude,lng:position.coords.longitude})
setLat(position.coords.latitude)
setLng(position.coords.longitude)
setLoad(true)
setAlert(false)
setLocOff(false)
loadLocation(position.coords.latitude,position.coords.longitude)
}
else {
 setLoad(false)
  setOrg(props.placeId)
  let short_name= props.desc.place
  let placeName=  props.desc.desc
  setLoc({placeName,short_name})
  setLocOff(true)
  setAlert(true)
  setTimeout(() => {setShow1('d-block')},500)
 
}

}
const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 

  const back = () => {
    props.back("pickup");
    props.search('no')
  };

  const MapLoader = withScriptjs(Map);

 
const loadLocation =  (lat,lng) => {
      // const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBah2C34AUXeAESdNgB5GoXKgk6XhlYhMU`)
     fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&zoom=18&addressdetails=1`)
      .then(res => res.json())
      .then(data => {
   
        let placeName =(data.display_name)
        let short_name=(data.address.city)
    // let ads= (placeName.split(','))
    // let adsLen = placeName.split(',').length-1

    // setCity(ads[adsLen-2])
    if(data.address.city)
    setCity(data.address.city)
    else
    setCity(data.address.village)


    // let ads1= (ads[adsLen-1].trim().split(' '))
        setState(data.address.state)
    // setState(ads1[0])
    // setPincode(ads1[1])
    setPincode(data.address.postcode)
      setLoc({placeName,short_name})
    pkPoint(data.address.postcode)
    setTimeout(() => {setShow1('d-block')},500)
  })
    
    }



    const pkPoint =(e) => {
      fetch(`https://queekdelever.com/queekApi/pkPoint.php?pincode=${e}`)
      .then(res => res.json())
      .then (data => setPkService(data))
    }
   
    const change =()=> {
     
      props.search('Search')
      props.back("pickup");
      props.lat(lat)
      props.lng(lng)
    }

    const fullName = useRef(null)
    const add = useRef(null)
    const landmark = useRef(null)

    const save =() => {
      setErr('')
     

    }

    const perName =(e) => {
      if(e.target.value > 0)
      setName(true)
      else
      setName(false)
    }

    const details =() => {
      setUpdate('true')
      setAddLine(true)
      setTimeout(() => {
        setUpdate('false')},100)
      setHt('430px')
    }

    const tagBtn =(e) => {
      setUpdate('false')
      setTag(e)
      setSavErr('form-control')

    if(e === 'Other')
    {
      setHt('450px')
    
    setTimeout(() => {
      // saveas.current.value=''
      saveas.current.focus()
    
    },100)
  }
  }

  useEffect(() => {
 if(org)
 setUpdate('false')
  setTimeout(() => {
         setUpdate('false')},100)
  },[])

const new_loc =async() => {
  if(tag === 'Other')
  await axios.get(`https://www.queekdelever.com/queekApi/new_pickupLoc.php?custcode=${props.user}&person_name=${capitalizeFirst(names)}&name=${capitalizeFirst(saveas.current.value)}&address=${capitalizeFirst(add.current.value)}&actAddress=${loc.placeName}&lat=${lat}&lng=${lng}&city=${city}&pincode=${pincode}&state=${state}`)
 else
 await axios.get(`https://www.queekdelever.com/queekApi/new_pickupLoc.php?custcode=${props.user}&person_name=${capitalizeFirst(names)}&name=${tag}&address=${capitalizeFirst(adds)}&actAddress=${loc.placeName}&lat=${lat}&lng=${lng}&city=${city}&pincode=${pincode}&state=${state}&address=${capitalizeFirst(add.current.value)}`)
  props.mapRefresh('true')
  props.back('close')
}

  const fetch1 =async(e) =>{
    let response =''


    if(tag === 'Other')
    { 
       response = await axios.get(`https://queekdelever.com/queekApi/new_pickupAdd.php?custcode=${props.user}&name=${saveas.current.value}`)
      }
    else 
    {
response = await axios.get(`https://www.queekdelever.com/queekApi/new_pickupAdd.php?custcode=${props.user}&name=${tag}`)
       }

      if(response.data !=='empty')
    {
    setErr('error')
    setTimeout(() => {setErr('')},3000)
    }
    else{
      setErr('')
     new_loc()
    }
    setPress(false)
  }

    useEffect(() => {
      setTimeout(() => {
      fullName.current.value = props.person
      add.current.value = props.add
        if(!props.tag)
      return  tagBtn('Home')

      if(props.tag != 'Home' && props.tag !=='Work' && props.tag !=='Office'  )
    {  tagBtn('Other')
    saveas.current.value= props.tag
  }
      else
      tagBtn(props.tag)
      },500)

    },[])

  useEffect(() => {
setErr('')
  },[tag])

  const saveBtn =(e) => {
   

    if(fullName.current.value ==='' || fullName.current.value.length <= 3) 
   { setPerErr('form-control is-invalid')}
    else
   { setPerErr('form-control')}
  
    if(add.current.value ==='' || add.current.value.length <= 3) 
  {  setAddErr('form-control is-invalid')}
    else
  {  setAddErr('form-control')}

  if(tag ==='Other')
  {
    if(saveas.current.value ==='' || saveas.current.value.length < 3) 
    {
      setSavErr('form-control is-invalid')
    }
    else 
    {
      setSavErr('form-control')
    }
  }
    
  if(tag !== 'Other')
  {
  if(fullName.current.value.length > 3 && add.current.value.length > 3)
{  setPress(true)
  if(props.sr)
  updateLoc()
  else
  fetch1()

}
  }
  else {
    if(fullName.current.value.length > 3 && add.current.value.length > 3 && saveas.current.value.length > 3)
{setPress(true)
 if(!props.sr)
  fetch1()
  else
  updateLoc()
}
  }
  }

  const updateLoc = () => {
   fetch(`https://www.queekdelever.com/queekApi/update_pickup.php?custcode=${props.user}&sr=${props.sr}&person=${capitalizeFirst(fullName.current.value)}&add=${capitalizeFirst(add.current.value)}&lat=${lat}&lng=${lng}&city=${city}&pincode=${pincode}&state=${state}&actAddress=${loc.placeName}`)
   setTimeout(() => {   props.back('pickup')
   },500) 
    return

  }

  const enbFalse =() => {
    setAlert(false)
    setRst('btn btn-warning text-dark')
    setRst1('cardPk')
  }

  const home =() => {
    window.location.replace("/");
}

  const cancel =() =>{
     setTag(false) 
     setTag('Home')
    }

    const gps =(e) => {
      gpsLoc() 
       }

    const rfrsh =() => {
      setLoads(true)
      window.location.replace('/pickup')
      setTry(tryMAp+1)
    }

    const specialChar =(e) => {
    let  string = e.replace(/[&\\\#+()$~%'":*?<>{}]/g,'');
     add.current.value = string
    }

   
    const routes =() => {}
    
    const getLoc =(f) => {
    console.log(f)
      let xyz= [{
        Serivce : 'Serviceable'
      }]
   
      setOrg({lat:f[0].lat,lng:f[0].lng})
      setLat(f[0].lat)
      setLng(f[0].lng)
      setLoad(true)
      setAlert(false)
      setLocOff(false)
      loadLocation(f[0].lat,f[0].lng)

    }

  return (
   <>
   
   <div className={`${pkService && pkService[0].Service === 'Serviceable' ? 'd-block' :'d-none' }`} >
<div  className={`p ${shows} user-select-none `}>
         {/* <GoogleMap height='65vh'/> */}
        <div className='position-relative '> 

    
        {/* <MapLoader 
  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBah2C34AUXeAESdNgB5GoXKgk6XhlYhMU"
  loadingElement={<div style={{ height: `100%` }}  />}
  // orig in = {{ lat: 30.9109, lng: 75.8793 }}
  origin ={org} 
  height={ht}
  update={'false'}
/> */}

{org && <MapView org={org}  update={update} height={ht} routes={routes}  />}

{/* <LoadScript googleMapsApiKey="">
    <PickupMap zoom={5} center={{ lat: -25.0270548, lng: 115.1824598 }}/>
  </LoadScript> */}


{!locOff &&  <div className={` marker-pos1 `}>
          <div className="d-flex justify-content-center align-items-end">
            <img src={pick}  alt='pickup' style ={{height:50}} />
          </div>
        </div>}

        </div>
   
         {!locOff && <div className={`${props.newUser ?"d-none":"d-block"} MapHead `}>
            <div onClick={back} className="curBack">
              <BsChevronLeft color="#fff" size="20" />
            </div>
          </div>}
       
         {/* ***************************loading place holder ********************************** */}
       { loadd?"": (  <>
        <div className="container mt-4">
  <div className="row align-items-start">
    <div className="col-10">
    <div className="text-placeholder placeholder w-100"></div>
    </div>
    
    <div className="col-2">
    <div className="avtar-button placeholder rounded"></div>
    </div>


    <div className='col-6 mt-2'>
    <div className="text-placeholder placeholder w-100"></div>
    </div>
    <div className='col-6 mt-2'>
    <div className="text-placeholder placeholder w-100"></div>
    </div>

    <div className='col-12 mt-2'>
    <div className="text-placeholder placeholder w-100"></div>
    </div>
        <div className='mt-2'></div>
    <div className='col-4 mt-2'>
    <div className=" avtar-card placeholder w-100"></div>
    </div>
    <div className='col-4 mt-2'>
    <div className="avtar-card placeholder w-100"></div>
    </div>
    <div className='col-4 mt-2'>
    <div className="avtar-card placeholder w-100"></div>
    </div>

    <div className='col-12 mt-3'>
    <a href="#" tabIndex="-1" className="btn btn-primary disabled placeholder col-12" aria-hidden="true"></a>
    </div>

    </div>
    </div>
      </>
        )
}
{/* ********************************************************************************************* */}

      {/* local place name with short name  */}

    {loadd && !locOff ?  (
      <>
      <div className="LocCard d-block position-relative ">
        <div className='gps text mb-2 text-dark'><span className='pe-2'><HiLocationMarker size='20' /></span>Pickup Location</div>
          <div className="d-flex justify-content-between mt-4">
            {/* <div className="ShortName"> {loc.short_name} </div> */}
            <div className='locLabel text-muted'>YOUR CURRENT LOCATION</div>
            <div className='d-none'>
              <button className="btn btn-dark btn-sm py-0 mb-2" onClick={change} >Change</button>
            </div>
          </div>
          <div className="locDetail d-none">
            <div className='pdLoc text-dark fs-6'> {loc && loc.placeName}</div>
          </div>

          <div className= {`form-floating border-none my-3 ${addLine ? 'd-block':'d-none'}`}>
  <input ref={fullName} type="text" className={`${perErr} text-capitalize `} id="floatingInput" onChange={(e) => {
  fullName.current.value = fullName.current.value.length > 0 ? stripchar.RSExceptUnsAlpha(fullName.current.value) :''
  setNames(e.target.value.length > 0 ? stripchar.RSExceptUnsAlpha(e.target.value) :'' )
  }
  }   placeholder="Enter person name"/>
  <label htmlFor="floatingInput">Person /Company name</label>
</div>

<div className={`form-floating border-none my-3 ${addLine ?'d-block':'d-none'} `}>
  <input ref={add} type="text" className={`${addErr} text-capitalize`} id="floatingInput" onChange={(e) => {specialChar(e.target.value)}} placeholder="Enter person name"/>
  <label htmlFor="floatingInput">House no/Flat no/Floor/Building</label>
</div>


      

<div className={`tagSav text-muted my-0  ${tag ==='Other' ? 'd-none':'d-block'} ${addLine ? 'd-block':'d-none'} `}>Tag this location for save</div>

<div className={`form-floating border-none my-3 ${tag ==='Other' ?'d-block':'d-none'} position-relative`}>
  <input ref={saveas} type="text" className={`${saveErr} text-capitalize`} id="floatingInput" onChange={(e) => {
     saveas.current.value = saveas.current.value.length > 0 ? stripchar.RSExceptUnsAlpha(saveas.current.value) :''
     setAdds(e.target.value.length > 0 ? stripchar.RSExceptUnsAlpha(e.target.value) :'' )

    }} placeholder="Enter person name"  disabled={!props.sr ? false : true} />
  <label htmlFor="floatingInput">Save tag as</label>

<div className='cancel' onClick={()=> cancel()}><span className='  cursor'>Cancel</span></div>
<div className={`position-absolute top-100 start-0 alertBtm ${err ? 'd-block':'d-none'} `}>Already save location with {adds}</div>
  
</div>
         {addLine && tag !=='Other' ? <div className={`  saveAs mt-3 user-select-none  mb-3 justify-content-start  position-relative  `}>
          

            <div className={`col-sm-2    d-flex justify-content-center  align-items-center Savebtn py-1 ${tag==='Home' ?'bg-dark text-white':'bg-white text-dark'}  me-2`}  onClick={()=> { !props.sr && tagBtn('Home')}} >
         <div className=" SaveFnt px-2  ">Home</div>
            </div>

            <div className={`col-sm-2    d-flex justify-content-center  align-items-center Savebtn py-1 ${tag==='Work' ?'bg-dark text-white':'bg-white text-dark'} me-2 `} onClick={()=> {!props.sr && tagBtn('Work')}} >
         <div className=" SaveFnt px-2">Work</div>
            </div>

            <div className={`col-sm-2 d-flex justify-content-center  align-items-center Savebtn py-1 ${tag==='Office' ?'bg-dark text-white':'bg-white text-dark'} `} onClick={()=> {!props.sr && tagBtn('Office')}} >
          <div className=" SaveFnt px-2">Office</div>
            </div>

            <div className= {`col-sm-2    d-flex justify-content-center  align-items-center Savebtn ms-2  ${tag==='Other' ?'bg-dark text-white':'bg-white text-dark'}`}  onClick={()=> {!props.sr &&  tagBtn('Other')}} >
<div className=" SaveFnt px-2">Other</div>
            </div>
            <div className={`position-absolute top-100 start-0 alertBtm ${err ? 'd-block':'d-none'} `}>Already save location with {tag}</div>
          </div> :''}


          <hr className= {`mt-2 ${addLine ?'d-none' :'d-block'}`} />
          <div className= {`SaveLoc d-flex justify-content-center align-items-center ${addLine ? 'd-none' :'d-block'} `}><button className='quote w-100 ' onClick={() =>details()} >Confirm Location</button></div>
         <div>
   {  !props.sr && <div className= {`d-flex justify-content-center align-items-center  ${addLine ? 'd-block' :'d-none'} ${tag ==='Other' ?'topMar' :'mt-3'} `}><button className='quote w-100' onClick={() => saveBtn('new') } disabled={ press ? true : false}  >Save address</button></div>}
        
   {  props.sr && <div className= {`d-flex justify-content-center align-items-center  ${addLine ? 'd-block' :'d-none'} ${tag ==='Other' ?'topMar' :'mt-3'} `}><button className='quote w-100' onClick={() => saveBtn('update') } disabled={ press ? true : false}  >Update address</button></div>}
          
          </div>
          

        </div>
        </>
    ):""
}
      {/* ******************************************************************************************************* */}
         
  {/* sender name detail for pickup parcel */}

  <div className='d-block'>
    {/* <div className='container-fluid'>
      <div className='senderCon mt-2'>
<div className='d-flex align-items-center'><RiHome5Line size="25"/> <span className='ps-2 fs-5'>Home</span></div>
<div className='fs-5'>x</div>
      </div>
    <div className='mt-3' ><input placeholder='Name / Company name'className='fromInput'/></div>
    <div><input placeholder='House no, flat no, stree no.' className='fromInput'/></div>
    <div><input placeholder='Landmark' className='fromInput'/></div>
  <div className='mt-2'>  <NormalButton name="Save"/></div>
    </div> */}


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Pickup Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container'>
            <div className='col-sm-12'><div className={`d-flex align-items-center ${err === 'fullName' ? "inputErr" :"fromInput"}`}><span><FaUserAlt size="14"/></span><input ref={fullName} placeholder='Person name or company name'className="locInput"/></div></div>
            <div className='col-sm-12'><div className={`d-flex align-items-center ${err ==='add'? "inputErr" :"fromInput"} `}><span><RiBuilding4Fill size="14" /></span><input ref={add} placeholder='Street address, apt,floor etc'className="locInput"/></div></div>
            <div className='col-sm-12'><div className={`d-flex align-items-center ${err ==='landmark' ? "inputErr" :"fromInput"}`}><span><FaMapMarkerAlt size="14" /></span><input ref={landmark} placeholder='Landmark (optional)'className="locInput"/></div></div>
           </div>
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" onClick={save}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
  
  </div>

{/* **************************************************************************** */}

          </div>
         
          <div className= {`${shows === 'd-none'? "d-block" :"d-none" } ${locOff ? 'd-none' :'d-block'} vh-100 d-flex justify-content-center align-items-center`} >
         
          <div className='d-flex flex-column justify-content-center align-items-center'>
          <div className='py-3'><RiMapPin2Line size='40'/></div>
            <div>Please wait, while fetching your location</div>
          <div>{props.isGeolocationAvailable}</div>

          </div>
          </div>

      </div> 

          {/* { !pkService && !locOff  &&  <div className= {` vh-100 d-flex justify-content-center align-items-center`} >
          <div className='d-flex flex-column justify-content-center align-items-center'>
          <div className='py-3'><RiMapPin2Line size='40'/></div>
            <div>Please wait, while fetching your location</div>
          <div>{props.isGeolocationAvailable}</div>

          </div>
          </div>} */}

         {locOff  && <div className= {` {${locOff   ? 'd-block':'d-none'}} vh-100 d-flex justify-content-center align-items-center d-none`}>
        
        {/* <div className='fixed-top pt-3 ps-2'>
         <div onClick={back} className="curBack p-3">
          <div className=''><BsChevronLeft color="#fff" size="20" /></div>
            </div>
            </div> */}
          <div className='d-flex flex-column justify-content-center align-items-center '>
          <div className='py-3'><MdOutlineLocationOff size='40'/></div>
          <div className='fs-4'>Enable your location</div>
            <div className='ptext1 text-muted text-center px-4 mt-3'>Your GPS Location is off, please Turn on  GPS Location & Tap Refresh Button</div>
          {/* <div>{props.isGeolocationAvailable}</div> */}
          <div className='mt-4 w-50' onClick={rfrsh} ><NormalButton name='Refresh' loading={loads} /></div>
        
          </div>
          </div>}

         { !pkService &&   <LocationOption gps={gps} getLoc={getLoc}  rst={rst} rst1={rst1} />}

         { pkService && pkService[0].Service === 'Sorry'   && <div className='bgGray '>
            <div className='vh-100 d-flex flex-column justify-content-center align-items-center'>
      <div className=''><BsBoxSeam size='80'  /> </div>
      <div className='px-2 py-3 text-center text-muted'>Sorry, Pickup service is not available at this location</div>
{ props.newUser  && <div className='fixed-bottom d-flex justify-content-center' onClick={() => home()  } > <div className='py-2 w-75  '><NormalButton name='Back to Home' /> </div></div>
}     

{ !props.newUser  && <div className='fixed-bottom d-flex justify-content-center'onClick={() => props.back('close')} > <div className='py-2 w-75  '><NormalButton name='Back to Home' /> </div></div>}
    
       </div>
          </div>}

  <Drawer isVisible={closeAlert && locOff } >
    <div className='d-flex justify-content-end pe-1' onClick={enbFalse}><GrClose size='20' /></div>
  <div className='d-flex flex-column justify-content-center align-items-center mb-3'>
          <div className='py-3'><MdOutlineLocationOff size='40'/></div>
          <div className='fs-4'>Enable your location</div>
            <div className='ptext1 text-muted text-center px-4 mt-3'>Your GPS Location is off, please Turn on  GPS Location & Tap Refresh Button</div>
          {/* <div>{props.isGeolocationAvailable}</div> */}
          <div className='mt-4 w-50' onClick={rfrsh} ><NormalButton name='Refresh' loading={loads} /></div>
        
          </div>
  </Drawer>

   </>
  )
}





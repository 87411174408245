import React from 'react';
import parcel from './Images/Parcel.svg'
import { BsCheckCircle } from "react-icons/bs";
import './UnderStand.css'
import { NormalButton } from '../../Components/NormalButton';


export const UnderStand = () => {
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-4'></div>
                    <div className='col-md-4'>
                        <img src={parcel} alt="parcel" className='center' />

                        {/* condition -1  */}
                        <div className='under-border mt-5'>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <BsCheckCircle size="24" color="#008000" />
                                </div>

                                <div className='ps-3 u-font'>
                                    This parcel does not contain Gems, Jewellery, Currency, Liquid, illegal drugs, any prohibited items and commoditie stricly not accepted
                                </div>
                            </div>
                        </div>

                         {/* condition -2  */}
                         <div className='under-border mt-4'>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <BsCheckCircle size="24" color="#008000" />
                                </div>

                                <div className='ps-3 u-font'>
                                Parcel should be in open condition, Booking person has right to check and open the parcel.
                                </div>
                            </div>
                        </div>

                         {/* condition -3  */}
                         <div className='under-border mt-4'>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <BsCheckCircle size="24" color="#008000" />
                                </div>

                                <div className='ps-3 u-font'>
                                Actual or volumetric weight whichever is highter will be considered.
                                </div>
                            </div>
                        </div>

                        {/* Understand button */}
                        <div className='mt-5'><NormalButton name="I understand"/></div>

                    </div>
                    <div className='col-md-4'></div>
                </div>
            </div>
        </>
    )
};

import React,{useEffect, useRef, useState} from 'react'
import { Header } from '../../Components/Header.jsx'
import { GoLocation } from "react-icons/go";
import { FaUserAlt } from "react-icons/fa";
import { RiPhoneFill } from "react-icons/ri";
import { MdWrongLocation } from "react-icons/md";
import { BsPlusCircleDotted } from "react-icons/bs";
import { AiOutlineEdit} from "react-icons/ai";


import { Local } from './Local.js';
import {Input} from '../../Components/Input.jsx'
import axios from 'axios';
import './Delivery.css'
import { NormalButton } from '../../Components/NormalButton';
import { Spinner } from '../../Components/Spinner.jsx';


export const Delivery = (props) => {
    const [Load,setLoad] = useState('')
    const[ishide,setHide] =useState(true)
    const [isType,setType] =useState('')
    const [placeHolder,setPlaceHolder] = useState('')
    const [newAdd,setAddress] =useState('')
    const [newDel,setNewDel] = useState('no')
    const[notSer,setSer] = useState(false)
    const [locationId,setLocationId] = useState(null)
  const [ caption,setCaption] = useState(null)
  const [serBar, setSerBar] = useState(null)
const pincode = useRef(null)
const [showSpinner,setSpinner] = useState(null)
const [Valid,setValid] = useState('form-control')
const [city,setCity] = useState('')
const [delAddress,setDelAddress] = useState('')
const [autofocus,setFocus] = useState(false)
const [nearByLoc, setNearByLoc] = useState('')
const [dest,SetDest] = useState('')
const [loadHide,setLoadHide] = useState(false)

let a =0

    const back =() => {
      props.back('close')

  if(serBar ==='yes')
  {
  if(delAddress)

  { setHide(true)
      setNewDel('no')
      setSerBar('no')
      setFocus(true)
    }
      else{
        props.back('close')
        setNearByLoc('')
      }
  }
      else
       { 
         props.back('close')
         setNearByLoc('')
       setNewDel('yes')
       setSerBar('yes')
      }
    }

 const nearBy = () => {
fetch(`https://queekdelever.com/queekApi/nearBy.php?custcode=${props.user}`)
  .then(response => response.json())
  .then(data => {setNearByLoc(data)})
}

 useEffect(() => {


// if(!delAddress)
if(isType.length ===0)
delAdd()


 },[isType])

 const delAdd = async() => {
  // const response = await axios.get(`https://www.easy-ship.in/delivery_address.php?custcode=${props.user}&type=${props.serType}`)
  let response = await axios.get(`https://www.queekdelever.com/queekApi/delivery_address.php?custcode=${props.user}&type=${props.serType}`)
  
  // let res = typeof(response.data) ==='string' ? response.data.trim() : response.data
  
  if(response.data[0].status !== 'noService')
  {
  setDelAddress(response.data)
    setHide(true)

    setFocus('no')
  } 
  else {
    if(props.serType ==='International')
   { setSerBar('yes')
    setCaption('Search delivery address by location or name')
  }
     else
   {    setCaption('Delivery area pincode')
 
    setSerBar('no')
      setFocus('yes')
}
      setHide(false)

      
      if(props.serType === 'Local')
  //  { setDelAddress('')
  //  if(!nearByLoc)
  //   nearBy()}
 
   if(props.serType === 'Domestic')
  { setSerBar('no')
  setFocus('yes')
  pincode.current.focus()
  setHide(false)
  }
   else
   setSerBar('yes')
  }
  
  }

 const loadLocation = async (e) => {
 const response = await axios.get(`https://www.faasos.com/v1/api/autocomplete?place_id=${e}`)
 if(response.data){
  
  const lat =response.data.data.search_result[0].latitude
  const lng =response.data.data.search_result[0].longitude
   let loc=({lat,lng})

 props.localId(loc)
}
}

 const loading =(e) => {
    setLoad(e)
   }

   const desc =(e) => {
    props.localAddress(e)
     }
    
     const placeId = (e,f,g,h,i,j) => {

      //  loadLocation(e)
     
      
      if(props.serType === 'Local')
      {

      if(props.pCity.trim() !== f.trim())
      {
        setSer(true)
        props.localAddress('')
        
        return;
      }
      else{
        setSer(false)
        props.localId(e)
        props.localAddress(g)
        props.pincode(h)
        props.state(f)
        props.country(j)
        // props.country(j)
      }
   // props.mapRefresh('true')
     }
     else {
      setSer(false)
      props.localId(e)
      
     }
    }

 const typeAhead = (e ) => {

  

    if(e.length > 0)
    {
      
      setType(e)
      setAddress('hide')
      setHide(false)

    }
    else if (e.length === 0)
    {
      
   setHide(true)
      setDelAddress('')
    setType('')
      setAddress('')
 delAdd()
 if(props.serType === 'International')
 setNewDel('no')
    }
    
   
    // if(serBar)
    // else
    // setHide(true)

    }


   
useEffect(()=> {
 

if(props.serType === 'Local')
{ setPlaceHolder('Search for area, street name...')
setCaption('Choose delivery address')
setSerBar('no')
}
else if(props.serType === 'Domestic')

{ setPlaceHolder('Search address by location or name')
setCaption('Search delivery address by name...')
setSerBar('yes')
}
else if(props.serType === 'International')
{ setPlaceHolder('Search country name...')
setSerBar('yes')
setCaption('Search delivery address by name...')}
},[props.serType])

//  const btn =() => {
    
//  props.Destination('destination')
//  }

 const newAddress =() => {

if(props.serType === 'Domestic')
{
setTimeout(() => {pincode.current.focus()},200)
  setHide(false)
  setSerBar('no')
  setCaption('Delivery area pincode')
  setNewDel('yes')
  }
else {
  setFocus('no')
  // props.search('yes')
  setSerBar('yes')
  setHide(false)
  setNewDel('yes')
  
 if(props.serType === 'Local')
 {
  setDelAddress('') 
  setSerBar('yes')
  nearBy()
}
}
props.action('')
 }

//  useEffect(() => {
//   if(props.lType !== 'Employee')
//  {  if(!autofocus)
//   setTimeout(() => {pincode.current.focus()},1000)}
//  })

 const alertHide =() => setSer(false)

 const checkCity =(e) => {

  if(props.serType === 'Domestic')
  {
    if(e.target.value.length > 6) 
  {
    pincode.current.value= e.target.value.substring(0,6)
  return false
  }

   if(e.target.value.length ==6)
   {
    setSpinner(true)
    fetchPincode(e.target.value)
   }

   if(e.target.value.length < 6) {
    setSpinner(false)
    setCity('')
    setValid('form-control')
   }
   
  }
 }
   
 const fetchPincode =(e) => {
  
  fetch(`https://queekdelever.com/queekApi/check_pincode.php?pincode=${e}`)
  .then(response => response.json())
  .then(data => {
   
    if(data[0].status !== 'noService')
    {
      setValid('form-control is-valid')
      setSpinner(false)
      setCity(data[0].city)
       let lat = parseFloat(data[0].lat)
        let lng =parseFloat(data[0].lng)
        SetDest({lat,lng})
      }
      else {
        setValid('form-control is-invalid')
        setSpinner(false)
        setCity('Location is not serviceable')
      }
  })
   
  //  const response = await axios.get(`https://queekdelever.com/queekApi/check_pincode.php?pincode=${e}`)
  //  if(response.data !=='noService')
  //  {
  // setValid('form-control is-valid')
  // setSpinner(false)
  // setCity(response.data[0].city)
  //  let lat = parseFloat(response.data[0].lat)
  //   let lng =parseFloat(response.data[0].lng)
  //   SetDest({lat,lng})
  // }
  // else {
  //   setValid('form-control is-invalid')
  //   setSpinner(false)
  //   setCity('Location is not serviceable')
  // }
 }

 const getAddress =(e,pincode,city,state, country,receiver,lats,lngs) => {

 props.skip(receiver)

 let lat =parseFloat(lats)
 let lng =parseFloat(lngs)
 
 setSer(false)
if(props.serType === 'Local')
{props.localId({lat,lng}) 
// props.route(false) 
props.mapLoad(true)
}
else
props.localId({lat,lng}) 
props.localAddress(e)
 props.pincode(pincode)
props.state(state)
 props.country(country)
//  if(props.serType ==='Domestic')
// // props.route(false)
props.mapLoad(true)

 }
 
 const getAddressNearBy =(name,address,pincode,city,state,country,lat,lng) => {

  props.skip('')


 if(props.serType === 'Local')
{ props.localId({lat,lng})

}
 else
 props.localId('')
 if(props.serType==='Domestic')
 props.localId({lat,lng})
 if(props.serType ==='International')
 props.placeId({lat,lng})

 console.log(country)
  props.localAddress(name+','+address)
  props.pincode(pincode)
 props.state(state)
  props.country(country)
  // props.route(false)
  props.mapLoad(true)
   }
 

 const BookNow =() => {
   if(city ==='Location is not serviceable' || city ==='')
   return
  //  console.log(pincode.current.value)
  props.localId(dest)
  // console.log(dest)
  // props.localId('')
  props.pincode(pincode.current.value)
  props.state(city)
  props.country('India')
 props.localAddress(city+'-'+pincode.current.value)
props.skip('')
props.mapLoad(true)
// props.route(false)
 }

 const country =(e) =>{
  props.country(e)}

  const skip =(e) => props.skip(e)
  const route =(e) => props.route(e)
  const wait =(e) => {
    setLoadHide(e)
  }

  const edit =(name,company,add1,add2,cnt_code,pincode,receiver,country,city,state) => {
    const booking =[{

      name : name
      ,company : company,
      add1: add1,
      add2: add2,
      cntCode: cnt_code,
      country: country,
      pincode : pincode,
      receiver: receiver,
      city: city,
      state: state,
      dstLocation : country !== 'India' ? country : ''
    }]
    
    props.action('edit')
    props.booking(booking)
    props.delivery('delAddress')  
      }

      const localId =(e) => props.localId(e)
      const localAddress =(e) => props.localAddress(e)
      const pincodes =(e) => props.pincode(e)
      const state =(e) => props.state(e)
      const mapLoad =(e) => props.mapLoad(e)
     const action  = (e) =>  props.action(e)
     const bookings = (e) => props.booking(e) 
     const deliverys =(e) => props.delivery(e) 

  return (
   
   <>
<div className='h-100 position-relative text-select-none'>

 <Header name={caption}  back ={back} bottomBtn="no" Bar={serBar}  Placeholder={placeHolder} typAhead={typeAhead} show={'delivery'} inputFocus={autofocus && autofocus ==='yes' ? 'yes' :'no'} serType={props.serType} Loading={Load} >
 {/* onClick={newAddress} */}

 {delAddress && <div className={` ${newDel ==='yes' ? 'd-none' :'d-block'} ${props.serType === 'Domestic' && !delAddress ? "d-none": "d-block" }  `}>
<div onClick={newAddress}  className={` currentLoc p-2 user-select-none`} >
        <div className='d-flex align-items-center '><BsPlusCircleDotted size="22"/></div>
        <div className='currentCaption ps-3'>
            <div>Add new address</div>
            <div className='locCaption'>{props.serType === 'Local'?'For local deivery' : props.serType ==='Domestic' ?"Delivery anywhere in India" : props.serType ==='International' ?'World Wide Delivery':"" }</div>
        </div>
      </div>
      <div className= {`${delAddress ?'d-block':'d-none'} pt-2`}>
  <hr className={`my-0 px-0  divider ${props.serType === 'Domestic' ?"d-block":"d-block"}   ${newDel ==='yes' ?"d-none" :"d-block"}   `} />
  </div>
</div>}
  { newAdd==='hide' ? "":<>

  {/* for new address add */}
<div className= {`${delAddress ? 'd-block' : 'd-none'} pb-4}`}>
{/* fixedNew */}
{/* ${props.serType ==='Domestic'  ?'d-block' :'d-none'} */}

</div>

<div className={`${props.serType ==='Domestic'  ?'d-block':'d-none'} `}>
<div className={`${newDel ==='yes' || autofocus==='yes' ? 'd-block' :'d-none'} `  }>
<div className='w-100 position-relative '>
      <div className="form-floating mb-3">
  <input type="number" ref={pincode} className={`${Valid} position-relative`} id="floatingInput" placeholder="Enter 6 Digit pincode" onChange={checkCity}  maxLength={5} />
  <label htmlFor="floatingInput">Enter 6 digit Pincode</label>
</div>

{/* spinner loader */}
<div className={`position-absolute top-50 end-0 translate-middle-y pe-2 ${showSpinner ? "d-block":"d-none"} `}>
<div className="spinner-border spinner-border-md"  id="spinnerr" role="status">
  <span className="visually-hidden"></span>
</div> </div> 


{/* city name */}
<div className={`position-absolute top-50 end-0 translate-middle-y city ${city !== 'Location is not serviceable' ? 'text-success':'text-danger'}` }>{city}</div>


</div>
<div className='mb-5 ms-2   w-100' onClick={BookNow} ><NormalButton name="Book Now"  disable ={ city === 'Location is not serviceable' || city ===''? true:false}/></div>
</div>
</div>

{/* *****************************near by location *********************************/}
{ nearByLoc &&  <div className={`${props.serType === 'Local' ? 'd-block':'d-none'}  ${ delAddress ?'d-none' :'d-block'}`}>
{/* <hr className={`my-0 px-0  divider ${newDel ==='yes' ?'d-none' :'d-block' }`} /> */}
 
<div className="saveStyle ">Suggested Near by Location</div>

{nearByLoc && nearByLoc.map((x) => 
   <div key={a=a+1} className='addCon user-select-none p-0'>
    <div className='position-relative p-2'><GoLocation size="24"/>
    {/* <div className='activeLoc' ><BsCheckCircleFill color="#008000" size="0"/> </div> */}
    </div>
    
    <div className='addreess ' onClick={() => {getAddressNearBy(x.name,x.address,x.pincode,x.city,x.state,'India', parseFloat(x.lat),parseFloat(x.lng))}}>
        <div className='title '>{x.company?x.company:x.name}</div>
<div className='addDetail'>{x.address}</div>
    {/* <div className='addDst d-flex '>{x.pincode} <div className=''>-{x.city}</div> <div className=''>{x.state}</div>, <div className='ps-1' >{x.country}</div></div> */}
   {/* <div className='PersonalFont ps-0'><div><RiPhoneFill size="12" color="gray"/></div>+{x.cnt_code}<div className='px-1'>{x.receiver}</div></div> */}
    {/* <div className='PersonDetail bg-danger d-none'> */}
{/* <div className={`PersonalFont  bg-info   w-25`}><div><FaUserAlt size="12" color="gray"/></div><div className='ps-1 col-3 text-truncate'>{x.name}</div> </div> */}
{/* <div className='PersonalFont d-none text-nowrap'><div><RiPhoneFill size="16" color="gray"/></div><div className=' '>+<span>{x.cnt_code}</span><span className='ps-1'>{x.receiver}</span></div></div> */}
{/* </div> */}
</div>
<div><hr className={`my-2 px-0 divider  `}/></div>
 </div>
    
  )}

</div> }
{/* ******************************************************************************************************** */}
   { ishide ? 
     <>
     
    <div className={`${delAddress?'d-none':'d-block'}`}>
    <div className="card-body">
    <div className="avatar-placeholder placeholder rounded-circle"></div>
    <div className="text-placeholder placeholder w-50"></div>
    <div className="text-placeholder placeholder w-75"></div>
</div>
   
<div className="card-body">
    <div className="avatar-placeholder placeholder rounded-circle"></div>
    <div className="text-placeholder placeholder w-50"></div>
    <div className="text-placeholder placeholder w-75"></div>
</div>
    </div>

     <div className={`${delAddress?'d-block':'d-none'} `}>
     <div className="saveStyle ">Saved address </div>

{delAddress? delAddress.map((x) => 
<div key ={a =a+1}>
   <div className='addCon user-select-none p-0'>
    <div className='position-relative p-2'><GoLocation size="24"/>
    {/* <div className='activeLoc' ><BsCheckCircleFill color="#008000" size="0"/> </div> */}
    </div>
    
    <div className='addreess ' onClick={() => {getAddress( props.serType ==='International' ? x.name+', '+ x.add1+', '+ x.add2+', '+x.pincode +', '+x.city +',' +x.state + ', '+ x.country : props.serType ==='Local' ? x.add1+', '+x.add2+','+x.city+','+x.pincode : x.name+', '+x.add1+','+x.add2+','+ x.city+'-'+x.pincode ,x.pincode,x.city,x.state, x.country,x.receiver,x.lat,x.lng)}}>
        <div className='title '>{x.company?x.company:x.name}</div>
<div className='addDetail'>{x.add1+', '+x.add2}</div>
    <div className='addDst d-flex '>{x.pincode} <div className='px-1'> {x.city}</div> <div className=''>{x.state ?', '+ x.state :''}</div> <div className='ps-1' >{x.country === 'India' ? '' : x.country}</div></div>
    
    <div className='d-flex '>
   <div className='PersonalFont ps-0 '><div><RiPhoneFill size="12" color="gray"/></div>+{x.cnt_code}<div className='px-1'>{x.receiver}</div></div>
   {/* <div className={`PersonalFont  bg-primary`}><span><FaUserAlt size="12" color="gray"/></span><span className='ps-1  text-truncate col-3'>{x.name}</span> </div> */}
 </div>

    <div className='PersonDetail bg-danger d-none'>
<div className={`PersonalFont  bg-info`}><div><FaUserAlt size="12" color="gray"/></div><div className='ps-1 col-3 text-truncate'>{x.name}</div> </div>
<div className='PersonalFont d-none text-nowrap'><div><RiPhoneFill size="16" color="gray"/></div><div className=' '>+<span>{x.cnt_code}</span><span className='ps-1'>{x.receiver}</span></div></div>
</div>
</div>
<div onClick={() => edit(x.name,x.company,x.add1,x.add2,x.cnt_code,x.pincode,x.receiver,x.country,x.city,x.state)} > < AiOutlineEdit size='20' /> </div>
 </div>

    <hr className={`my-2 px-0 divider ${a+1 === delAddress.length ? 'd-none':'d-block'} `}/>
    </div>
    ):""}
    </div>
    </> :""
    }
    
  
  
    </>}

    <div className={`${ishide ? "d-none" : "d-block"}`}>
    { <Local   typeAhead={isType} apiKey ={props.apiKey} pCity ={props.pCity} checkCity={checkCity} Loading={loading} lat={props.lat} lng={props.lng}  placeId={placeId}  Desc={desc} serType={props.serType} country={country} skip={skip} draw= {props.draw} serBar={serBar} route={route}  wait={wait}  localId={localId} localAddress={localAddress} pincode={pincodes} state ={state} mapLoad={mapLoad} user={props.user} newAdd={newDel} action={action} booking={bookings} delivery={deliverys} />}
    
    </div>
    <div className={`position-absolute mt-5 top-0 start-0 w-100 ${notSer ? "d-block":"d-none"}`}>
    <div className="alert alert-danger" role="alert">
  <div className='d-flex align-items-center'>
    <div className='mb-2'><MdWrongLocation size='26' color='#000' /></div>
  <div><h4 className="alert-heading  ps-2"> Unable to deliver</h4></div>
  </div>

  <p>Sorry, there is no service available </p>
  <hr/>
  <div className='d-flex justify-content-between'><p className="mb-0">You can click on <b>Domestic</b> tab for delivery</p>
  <button type="button" className='btn-close' onClick={alertHide} ></button>
  </div>
</div>
    </div>
{ newAdd ==='hide1' ? 

<>
<div className='mt-2 position-relative scroll'>
<Input label="Contact person"  type="text"/>
{props.serType ==='Local'? "":
  <><Input  label="Country" type="text"/>
 </>
}
<Input  label="Pincode/Zipcode"  type="text" len="6"/>
<Input  label="Mobile number" ext="+91" type="text"/>
<Input  label="Company name (optional)" type="text"/>
<Input  placeholder="house no, flat no, street, avenue etc " label="Delivery address" type="text"/>
<Input  label="Landmark"  placeholder="eg. mukesh sweets etc" type="text"/>





</div>


</>
:""
}

   </Header>
   {newAdd ==='hide1'?<><div className='bottomBtn'><NormalButton name="Next" /> </div></> :""}

{Load ==='j' && <Spinner />}

{ }
   </div>

 

   </>
  )
}

import React,{useState} from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import Web from '../src/Images/logo.svg';
import { AiOutlineMenu } from "react-icons/ai";
import { GrClose } from "react-icons/gr";

import { NavLink, Outlet, Link } from "react-router-dom";
import './Navbar.css'
import { BsSearch } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";

import { NormalButton } from './Components/NormalButton';
import { SideMenu } from './pages/Ebook/SideMenu1';

const Navbar = (props) => {
    const navigate = useNavigate();
    const [trk,setTrack] = useState('')
    const [hide,setHide] = useState(false)
    const [ opens, setOpen] = useState(false)

    const about =() => {
        navigate('/about');}
    
        const local =() => {
            navigate('/localDelivery');}
    
        const domestic =() => {
                navigate('/domestic');}
    
        const international =() => {
                    navigate('/international');}

     const prohibited =() => {
    navigate('/prohibited');}

    const contact =() => {
        navigate('/contact');}

    const hideBar =() => {
        setHide(true)
        // else
        // setHide(true)
    }
    const event =(e) => {
        setHide(e)
        setOpen(false)
       }
  

       const menuBar =() => {
       setOpen(true)
            }

    const barClose =(e) => {
        setOpen(false)
      }
      
      const close =(e) => {
        setOpen(e)
    }

    
    const openNav =() => {
        document.getElementById("mySidenav").style.width = "100%";
      }
      
      const closeNav =() => {
        document.getElementById("mySidenav").style.width = "0";
      }
    return (
        <div className=''>

            
{/* <!-- start navbar  --> */}
    <div className="">

  
      <div className="bgBlue sticky-top pb-1 position-relative">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
                <a>
                  <div className="me-4 cursor"  onClick={() => window.location.replace('/')}>
                    <img className="logo-img" src={Web} alt="Queek Delever logo" />
                    {/* <!-- logo --> */}
                  </div>
                </a>
                
                <div className="d-none">
                  <div className="d-flex justify-content-start position-absolute top-50 start-50">
                    {/* <!-- primary nav --> */}
                    <div className="dropdown show">
                    <a className="nav-link menu_bar text-white NavFont text-nowrap ps-3 dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >Company</a>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink3">
                      <a className="dropdown-item" href="/about.html">About</a>
                      <a className="dropdown-item" href="/vision.html">Mission & Vision</a> 
                      </div> 
                  </div>
                  <div className="dropdown show">
                    <a className="nav-link menu_bar text-white NavFont ps-3 dropdown-toggle"  role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Shipping</a>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <a className="dropdown-item" href="/login">Ship Now</a>
                      <a className="dropdown-item" href="/pickuprequest.html">Shedule a Pickup Request</a>
                      <a className="dropdown-item" href="/calculateshippingcost.html">Calculate Time & Shipping Cost</a>
  
                      <a className="dropdown-item" href="/packagingSolution.html">Packaging Solution</a>
  
                    </div> 
                 </div>
                    <div className="dropdown show">
                      <a className="nav-link menu_bar text-white NavFont ps-3 dropdown-toggle"  role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Service</a>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a className="dropdown-item" href="/sameCitySameDay.html">Same city Same Day</a>
                        <a className="dropdown-item" href="/NextBusinessDay.html">Next Business Day</a>
                       <a className="dropdown-item" href="/ExpressService.html">Domestic Service</a>
                        <a className="dropdown-item" href="/internationalservice.html">International Service</a>
                      </div> 
                   </div>
                
                   <a className="nav-link menu_bar text-white NavFont px-3 text-nowrap cursor" href="/tracking.html">Tracking</a>

                    <a className="nav-link menu_bar text-white NavFont px-3 text-nowrap cursor" href="/contactUs.html">Contact us</a>
                    <a className="nav-link menu_bar text-nowrap NavFont text-white  cursor" href="/login"><span className="px-1"></span> Login</a>
                  </div>
                </div>
               
             
             
              
          
  
            {/* <!-- <div className='d-block d-xl-none pb-4  cursor menuBarI' >
                     <div className='menuBarI' onClick={menuBar}><AiOutlineMenu size='30' color='white'  /></div>
                     <div className='' > <SideMenu  open={opens}  barClose={barClose} event={event}  close={close} /></div>
                  </div>  --> */}
  
            <div className="d-flex flex-column d-none d-xl-block ms-3 d-none">
              <div className="d-flex pb-3 ms-4">
                {/* <!-- <NavLink className="nav-link menu_bar text-white NavFont pe-2" to="/login">Login</NavLink> --> */}
                <div className="d-flex p-2 align-items-center justify-content-end">
                  {/* <!-- <div className="btn btn-warning px-3">Pickup Now</div> --> */}
                  {/* <!-- <div className="ps-2 cursor"><a className="nav-link menu_bar text-white text-nowrap NavFont "> <span className="px-2"><i className="fa fa-user-circle-o fa-lg" aria-hidden="true"></i></span> Login</a></div> --> */}
  

  
                  <div className="cursor"></div>
                </div>
              
              </div>
              {/* <!-- se condary nav --> */}
            </div>
            <div className="d-sm-block d-xl-none text-white fs-5 d-none " onClick={() => openNav()} >&#9776;</div>
  
            <div id="mySidenav" className="sidenav">
              <a  className="closebtn" onClick={() => closeNav()} >&times;</a>
              <div className=" dropdown show">
                <a className="nav-link menu_bar  NavFont text-nowrap ps-3 dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >Company</a>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink3">
                  <a className="dropdown-item" href="/about.html">About</a>
                  <a className="dropdown-item" href="/vision.html">Mission & Vision</a> 
                  </div> 
              </div>
              <div className="dropdown show">
                <a className="nav-link menu_bar  NavFont ps-3 dropdown-toggle"  role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Shipping</a>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <a className="dropdown-item" href="/login">Ship Now</a>
                  <a className="dropdown-item" href="/pickuprequest.html">Shedule a Pickup Request</a>
                  <a className="dropdown-item" href="/calculateshippingcost.html">Calculate Time & Shipping Cost</a>
  
                  <a className="dropdown-item" href="/packagingSolution.html">Packaging Solution</a>
  
                </div> 
             </div>
                <div className="dropdown show d-none">
                  <a className="nav-link menu_bar  NavFont ps-3 dropdown-toggle"  role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Service</a>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a className="dropdown-item" href="/sameCitySameDay.html">Same city Same Day</a>
                    <a className="dropdown-item" href="/NextBusinessDay.html">Next Business Day</a>
                   <a className="dropdown-item" href="/ExpressService.html">Domestic Service</a>
                    <a className="dropdown-item" href="/internationalservice.html">International Service</a>
                  </div> 
               </div>
            
         
                <a className="nav-link menu_bar  NavFont px-3 text-nowrap cursor" href="/contactUs.html">Contact us</a>
                <a className="nav-link menu_bar  NavFont px-3 text-nowrap cursor" href="/login"> Login</a>
              </div>
            </div>
            
            </div>
            {/* <div className='position-absolute end-0 top-50 '><div className='px-3'><NormalButton name='Direct Book' /></div></div> */}
            </div>
        </div>

    {/* <!-- end navbar --> */}
        </div>
    );
}

export default Navbar;
import React, { useEffect,useState} from 'react'
import axios  from 'axios'
import './Local.css'
import { GoLocation } from "react-icons/go";
import { FaUserAlt } from "react-icons/fa";
import { RiPhoneFill } from "react-icons/ri";
import { AiOutlineEdit} from "react-icons/ai";

import {
   useGooglePlacePredictions,
   useGooglePlaceDetails,
 } from 'use-google-places-api'

 import AutoComplete from 'react-google-autocomplete'

export const Local = (props) => {
    const [location,setLocation] =useState('')
    const [Load,setLoad] =useState("")
    const [text,setText] =useState('')
    const [countries,setCountries] = useState('')
    const[notSer,setSer] = useState(false)
    const [delAddress,setDelAddress] = useState('')


    let a=0;

    const [cnt, setCnt] = useState([{name:'Australia',lat: '-33.865143',lng:'151.209900'},{name:'Canada',lat:'50.0000',lng:'-85.00000'},{name:'Italy',lat:'41.8719',lng:'12.5674'},{name:'United Kingdom',lat:'55.3781',lng:'3.436'},{name:'United States',lat:'47.751076',lng:'-120.740135'}])

  useEffect(() => {
   setCnt([{name:'Australia',lat: '-33.865143',lng:'151.209900'},{name:'Canada',lat:'50.0000',lng:'-85.00000'},{name:'Italy',lat:'41.8719',lng:'12.5674'},{name:'United Kingdom',lat:'55.3781',lng:'3.436'},{name:'United States',lat:'47.751076',lng:'-120.740135'}])
 
  },[])

    const clickHandler1 =(id,desc,lat,lng,place) => {
     
      if(lat && lng)
      {
         props.location('location')
         props.Desc({desc,place})
         props.placeId({lat,lng})
      }
      else {
         props.Desc({desc,place})
         getLatLng(id)
         props.wait(true)
      }

    }

// for current location api fetch************************************************

    const getLatLng = (id) => {
      fetch(`https://www.eatsure.com/v1/api/autocomplete?place_id=${id}`)
      .then(res => res.json())
      .then(data => {
     
       let lat =(data.data.search_result[0].latitude)
       let lng =(data.data.search_result[0].longitude)
      props.location('location')
         props.placeId({lat,lng})
      })
   }
// ****************************************************************************************

// ***************************delivery local api for data fetch*************************************
const getLatLng1 = async(id,f) => {

   fetch(`https://queekdelever.com/queekApi/local_placeId.php?placeId=${id}`)
   .then(res => res.json())
   .then(data => {
 

     
    let lat =(data.results[0].geometry.location.lat)
    let lng =(data.results[0].geometry.location.lng)

  let strLen =(data.results[0].address_components.length)

 
    props.checkCity(data.results[0].address_components[strLen-5].long_name)
    props.Desc(f)
    let city =data.results[0].address_components[strLen-5].long_name
    let pincode = data.results[0].address_components[strLen-1].long_name
    let state = data.results[0].address_components[strLen-3].long_name
    let country ='India'
   //  let f=response.data.data.search_result[0].description
    props.placeId({lat,lng},city,f,pincode,state,country)
   
  })
}
// ****************************************************************************************


const  clickHandler =(e,f,lat,lng,city,pincode,state,country) => {
   //  props.placeId(e)
   getLatLng1(e,f)
   return
   
if(lat && lng)
{
props.Desc(f)
props.checkCity(city)
props.placeId({lat,lng},city,f,pincode,state,country)
 props.skip('')
 props.route(false)
}
else{
   props.wait(true)
   getLatLng1(e,f)
  }}


const RestLoc = (e,lat,lng) => {
props.Desc(e)
  props.placeId({lat,lng})
  props.country(e)
props.skip('')
}



   useEffect ( () => {
const loadLocation = async () => {
 
        // api for pickup locaton***********************************
        
 if (props.serType ==='pickup')
   {
  
   if(props.typeAhead.length > 0)
   {
    
   
   //   https://www.easy-ship.in/local_autocomplete.php?places=
   let search = props.typeAhead.replace(/\s+/g, '%20').toLowerCase()

   const response =   await axios.get(`https://www.eatsure.com/v1/api/autocomplete?places=${search}`)

    if(response.data.data.search_result){
      setLocation(response.data.data.search_result)
      props.Loading('j')
      }
     else
     {
         props.Loading('done')   
      }
   }
    }
    // ************************************* Local Search *******************************************************
    
     if (props.serType ==='Local')
   {
      
      // console.log(props.pCity)
      // setTimeout(()=> {console.log('')},1000)
      let search=''
      // if(props.pickup ==='store')
      // search = props.typeAhead.replace(/\s+/g, '%20').toLowerCase()
      // else
      search = props.typeAhead.replace(/\s+/g, '%20').toLowerCase()+'%20'+props.pCity.trim().toLowerCase()

    const response =   await axios.get(`https://www.queekdelever.com/queekApi/local_autocomplete.php?places=${search}`)
   //   console.log(`https://www.faasos.com/v1/api/autocomplete?places=${search}`) 
     if(response.status === 200){
         setLocation(response.data.predictions)
         props.Loading('j')
     
      }
      else
      { props.Loading('done')  }
     }

   //  **************************************** Domestic Search *********************************************

    if((props.serType === 'Domestic' || props.serType  ==='International') && props.newAdd ==='no')
    {
      props.Loading('j')

    fetch(`https://www.queekdelever.com/queekApi/delivery_address.php?custcode=${props.user}&type=${props.serType}&name=${props.typeAhead}`)
    .then(res =>res.json())
    .then(data => {
      if(data[0].status !=='noService')
      setLocation(data)
      else
      setLocation('')

    })
    props.Loading('done') 

   //  let response = await axios.get(`https://www.queekdelever.com/queekApi/delivery_address.php?custcode=9888446111&type=Domestic&name=${props.typeAhead}`)
   


 
    }
// ************************************************************************************************


if( props.serType  ==='International' )
{   props.Loading('j')

   setLocation('')
fetch(`https://www.queekdelever.com/queekApi/country_new.php?search=${props.typeAhead}`)
.then(res => res.json())
.then(data => {
   
  setCountries(data) 
  props.Loading('done') 

})

// **********************************************************************************************************
    }

    }    
    props.Loading('loading')
   //  if(!location)
setTimeout(() => {loadLocation()
},600)


   },[props.typeAhead,props.newAdd])

   // ,

   const edit =(name,company,add1,add2,cnt_code,pincode,receiver,country,city,state) => {
      const booking =[{
  
        name : name
        ,company : company,
        add1: add1,
        add2: add2,
        cntCode: cnt_code,
        country: country,
        pincode : pincode,
        receiver: receiver,
        city: city,
        state: state,
        dstLocation : country !== 'India' ? country : ''
      }]
      props.action('edit')
      props.booking(booking)
      props.delivery('delAddress')  
        }

        const getAddress =(e,pincode,city,state, country,receiver,lats,lngs) => {

         props.skip(receiver)
        
         let lat =parseFloat(lats)
         let lng =parseFloat(lngs)
         
         setSer(false)
        if(props.serType === 'Local')
        {props.localId({lat,lng}) 
        // props.route(false) 
        props.mapLoad(true)
        }
        else
        props.localId({lat,lng}) 
        props.localAddress(e)
         props.pincode(pincode)
        props.state(state)
         props.country(country)
        //  if(props.serType ==='Domestic')
        // // props.route(false)
        props.mapLoad(true)
        
         }

  return (
   <>
{/* <div className="position-relative">
  <label forhtml="exampleFormControlInput1" className="form-label control-label col-sm-3 small mb-0">Landmark</label>
  <input type="text" className="form-control form-control-sm mb-2" id="exampleFormControlInput1" placeholder="near by store or location for pickup" value={text}  onChange={e => setText(e.target.value)} />
</div> */}

   <div className={`  ${ props.typeAhead.length  > 0 ?"d-block":"d-none"}`}>
   {props.serType ==='pickup'? <><div className='localList'>{location && location.map( x =><div className='locCon' onClick={ () => clickHandler1(x.place_id,x.description,x.latitude,x.longitude,x.placeName)}    key={a=a+1}>  <div className='locHead'> {x.placeName}</div>  <div className='desc text-truncate'>{x.description}</div><hr className='my-2 px-0 divider'/></div>)}</div></> :""}
   {props.serType ==='Local'? <>
   
   <div className=''>{location.map( x => <div className='locCon' onClick={ () => getLatLng1(x.place_id,x.description)}    key={a=a+1}>  <div className='locHead'> {x.description}</div>  
   {/* <div className='desc text-truncate'>{x.description}</div> */}
   <hr className='my-2 px-0 divider'/></div>)}</div>
   </> :""}
   {props.serType ==='Domestic'? <><div className='localList'>
      
{location? location.map((x) => 
<div key ={a =a+1}>
   <div className='addCon user-select-none p-0'>
    <div className='position-relative p-2'><GoLocation size="24"/>
    {/* <div className='activeLoc' ><BsCheckCircleFill color="#008000" size="0"/> </div> */}
    </div>
    
    <div className='addreess ' onClick={() => {getAddress( props.serType ==='International' ? x.name+', '+ x.add1+', '+ x.add2+', '+x.pincode +', '+x.city +',' +x.state + ', '+ x.country : props.serType ==='Local' ? x.add1+', '+x.add2+','+x.city+','+x.pincode : x.name+', '+x.add1+','+x.add2+','+ x.city+'-'+x.pincode ,x.pincode,x.city,x.state, x.country,x.receiver,x.lat,x.lng)}}>
        <div className='title '>{x.company?x.company:x.name}</div>
<div className='addDetail'>{x.add1+', '+x.add2}</div>
    <div className='addDst d-flex '>{x.pincode} <div className='px-1'> {x.city}</div> <div className=''>{x.state ?', '+ x.state :''}</div> <div className='ps-1' >{x.country === 'India' ? '' : x.country}</div></div>
    
    <div className='d-flex '>
   <div className='PersonalFont ps-0 '><div><RiPhoneFill size="12" color="gray"/></div>+{x.cnt_code}<div className='px-1'>{x.receiver}</div></div>
   {/* <div className={`PersonalFont  bg-primary`}><span><FaUserAlt size="12" color="gray"/></span><span className='ps-1  text-truncate col-3'>{x.name}</span> </div> */}
 </div>

    <div className='PersonDetail bg-danger d-none'>
<div className={`PersonalFont  bg-info`}><div><FaUserAlt size="12" color="gray"/></div><div className='ps-1 col-3 text-truncate'>{x.name}</div> </div>
<div className='PersonalFont d-none text-nowrap'><div><RiPhoneFill size="16" color="gray"/></div><div className=' '>+<span>{x.cnt_code}</span><span className='ps-1'>{x.receiver}</span></div></div>
</div>
</div>
<div onClick={() => edit(x.name,x.company,x.add1,x.add2,x.cnt_code,x.pincode,x.receiver,x.country,x.city,x.state)} > < AiOutlineEdit size='20' /> </div>
 </div>

    <hr className={`my-2 px-0 divider ${a+1 === delAddress.length ? 'd-none':'d-block'} `}/>
    </div>
    ):""}
      </div></> :""}
   
   {props.serType ==='International' && props.newAdd ==='no'  ? <><div className='localList'>
      
   {location? location.map((x) => 
<div key ={a =a+1}>
   <div className='addCon user-select-none p-0'>
    <div className='position-relative p-2'><GoLocation size="24"/>
    {/* <div className='activeLoc' ><BsCheckCircleFill color="#008000" size="0"/> </div> */}
    </div>
    
    <div className='addreess ' onClick={() => {getAddress( props.serType ==='International' ? x.name+', '+ x.add1+', '+ x.add2+', '+x.pincode +', '+x.city +',' +x.state + ', '+ x.country : props.serType ==='Local' ? x.add1+', '+x.add2+','+x.city+','+x.pincode : x.name+', '+x.add1+','+x.add2+','+ x.city+'-'+x.pincode ,x.pincode,x.city,x.state, x.country,x.receiver,x.lat,x.lng)}}>
        <div className='title '>{x.company?x.company:x.name}</div>
<div className='addDetail'>{x.add1+', '+x.add2}</div>
    <div className='addDst d-flex '>{x.pincode} <div className='px-1'> {x.city}</div> <div className=''>{x.state ?', '+ x.state :''}</div> <div className='ps-1' >{x.country === 'India' ? '' : x.country}</div></div>
    
    <div className='d-flex '>
   <div className='PersonalFont ps-0 '><div><RiPhoneFill size="12" color="gray"/></div>+{x.cnt_code}<div className='px-1'>{x.receiver}</div></div>
   {/* <div className={`PersonalFont  bg-primary`}><span><FaUserAlt size="12" color="gray"/></span><span className='ps-1  text-truncate col-3'>{x.name}</span> </div> */}
 </div>

    <div className='PersonDetail bg-danger d-none'>
<div className={`PersonalFont  bg-info`}><div><FaUserAlt size="12" color="gray"/></div><div className='ps-1 col-3 text-truncate'>{x.name}</div> </div>
<div className='PersonalFont d-none text-nowrap'><div><RiPhoneFill size="16" color="gray"/></div><div className=' '>+<span>{x.cnt_code}</span><span className='ps-1'>{x.receiver}</span></div></div>
</div>
</div>
<div onClick={() => edit(x.name,x.company,x.add1,x.add2,x.cnt_code,x.pincode,x.receiver,x.country,x.city,x.state)} > < AiOutlineEdit size='20' /> </div>
 </div>

    <hr className={`my-2 px-0 divider ${a+1 === delAddress.length ? 'd-none':'d-block'} `}/>
    </div>
    ):""}      
      </div></> :""}
 
 </div>

   <div className={`${ props.serType ==='International' && countries ? 'd-block' :'d-none' }`} >
      <div className={`mst ${props.typeAhead ===''  ?'d-block':'d-none'} `}>Most Search Country</div>

      {props.serType ==='International'  ? <><div className={`localList ${props.typeAhead ===''?'d-block' :'d-none' }`}>{cnt.map( x =><div className='locCon' onClick={ () => RestLoc(x.name,parseFloat(x.lat),parseFloat(x.lng))}    key={a=a+1}>  <div className='locHead'> {x.name}</div>  <div className='desc text-truncate'></div><hr className='my-2 px-0 divider'/></div>)}</div></> :""}

      { countries && <div className='mst'>Country</div>}

   {props.serType ==='International' && countries ?  <><div className='localList'>{countries && countries.map( x =><div className='locCon' onClick={ () => RestLoc(x.name,parseFloat(x.lat),parseFloat(x.lng))}    key={a=a+1}>  <div className='locHead'> {x.name}</div>  <div className='desc text-truncate'></div><hr className='my-2 px-0 divider'/></div>)}</div></> :""}




   </div>
   </>
        
  )
}

import React,{useEffect, useState} from 'react'
import Button  from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas'
import OffcanvasHeader from 'react-bootstrap/OffcanvasHeader'
import OffcanvasTitle from 'react-bootstrap/OffcanvasTitle'
import user1 from './User.svg'
import ppx from './ppx.svg'
import pikAdd from './pikAdd.svg'
import delAdd from './delAdd.svg'
import billAdd from './billAdd.svg'
import notiIcons from './notiIcon.svg'
import share from './Share.svg'
import logout from './logout.svg'
import { AiOutlineDropbox } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { MdLocationSearching } from "react-icons/md";
import { MdOutlineReceiptLong } from "react-icons/md";
import { BiUser } from "react-icons/bi";
import { BsPrinter } from "react-icons/bs";
import { BsDownload } from "react-icons/bs";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { FiShare2 } from "react-icons/fi";
import { BiLogOutCircle } from "react-icons/bi";
import { BsChatLeftText } from "react-icons/bs";





import './SideMenu.css';
import { MdOutlineArrowForwardIos } from "react-icons/md";




export const SideMenu = (props) => {
    const [show, setShow] = useState(false);
      const handleClose = () => {
        setShow(false);
        props.barClose(false)
      }
  const handleShow = () => setShow(true) ;

  useEffect(() => {
    setShow(props.open)
  })

  const event =(e) => {

    if(e === 'logout')
    {
      localStorage.setItem ('custData', '')

    }

    if(e === 'shipLabel')
   { window.open(`/label/${props.user}`)
   return
  }

  if(e==='chat')
  {
  window.open(`whatsapp://send?text=Hi`)
  return
  }

    props.event(e)
  }
  
 
  return (
   <>

<Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        
 {/* user login with name */}
        <div className='d-flex align-items-center user-select-none '>
      <div><BiUser size='60' /></div>
      
      <div className='d-flex flex-column px-2'>
    <div className='Sidebar-h' > Welcome back...!!!</div>
    <div className='fs-5 text-capitalize'>{props.contact}</div>
      </div>

        </div>

        {/* menubar  */}
        <div className={`meneCon user-select-none ${props.type ==='login'?'d-block':'d-none'}`}>
          {/* my shipment */}
<div className='d-flex align-items-center cursor' onClick={() => {event('myShipment')} }>
  <div className='p-2'><AiOutlineDropbox size='24'  /></div>
  <div className='d-flex justify-content-between w-100 pe-1'>
  <div className='menuFnt ps-3'>My Pickups</div>
  <div><MdOutlineArrowForwardIos/></div>
  </div>
</div>

{/* my delivery address  */}
{/* <div className='d-flex align-items-center mt-2 cursor' onClick={() => {event('deliveryAddress')} }>
  <div className='p-2'><GoLocation size='24' /></div>
  <div className='d-flex justify-content-between w-100 pe-1'>
  <div className='menuFnt ps-3'>Delivery address</div>
  <div><MdOutlineArrowForwardIos/></div>
  </div>
</div> */}

{/* my pickup address  */}
{/* <div className='d-flex align-items-center mt-2 cursor'  onClick={() => {event('pickupAddress')} }>
  <div className='p-2'><MdLocationSearching size= '24' /></div>
  <div className='d-flex justify-content-between w-100 pe-1'>
  <div className='menuFnt ps-3'>Pickup address</div>
  <div><MdOutlineArrowForwardIos/></div>
  </div>
</div> */}

<hr className='divide'/>

{/* Billing address  */}
<div className='d-flex align-items-center mt-2 cursor d-none' onClick={() => {event('billAddress')} }>
  <div className='p-2'><MdOutlineReceiptLong size='24' /></div>
  <div className='d-flex justify-content-between w-100 pe-1'>
  <div className='menuFnt ps-3'>Billing address</div>
  <div><MdOutlineArrowForwardIos/></div>
  </div>
</div>

{/* Notification   */}
<div className='d-flex align-items-center mt-2 cursor' onClick={() => {event('Notification')} }>
  <div className='p-2'><MdOutlineMarkEmailUnread size='24' /> </div>
  <div className='d-flex justify-content-between w-100 pe-1'>
  <div className='menuFnt ps-3'>Notification</div>
  <div><MdOutlineArrowForwardIos/></div>
  </div>
</div>

{/* Shipping Label   */}
<div className='d-flex align-items-center mt-2 cursor' onClick={() => {event('shipLabel')} }>
  <div className='p-2'><BsPrinter size='24' /> </div>
  <div className='d-flex justify-content-between w-100 pe-1'>
  <div className='menuFnt ps-3'>Shipping Label</div>
  <div className='prtRound d-flex justify-content-center align-items-center '><BsDownload size='18' color='black' /></div>
  </div>
  </div>

{/* chat with us   */}
<div className='d-flex align-items-center mt-2 cursor' onClick={() =>{event('chat')} }>
  <div className='p-2'><BsChatLeftText size='24' /></div>
  <div className='d-flex justify-content-between w-100 pe-1'>
  <div className='menuFnt ps-3'>Chat with us</div>
  <div><MdOutlineArrowForwardIos/></div>
  </div>
</div>

{/* Share   */}
<div className='d-flex align-items-center mt-2 cursor'>
  <div className='p-2'><FiShare2 size='24' /></div>
  <div className='d-flex justify-content-between w-100 pe-1'>
  <div className='menuFnt ps-3'>Share</div>
  <div><MdOutlineArrowForwardIos/></div>
  </div>
</div>





{/* Logout   */}
<div className='fixed-bottom'>
<div className='d-flex align-items-center mt-2 px-4 pb-2 cursor' onClick={() => {event('logout')} }>
  <div className='p-2'><BiLogOutCircle size='24' /></div>
  <div className='d-flex justify-content-between w-100 pe-1'>
  <div className='menuFnt ps-3'>Log Out</div>
  
  </div>
</div>
</div>

</div>


<div className={`meneCon user-select-none ${props.type !=='login'?'d-block':'d-none'}`}>
          {/* my pickup */}
<div className='d-flex align-items-center cursor' onClick={() => {event('HomePickup')} }>
  <div className='p-2'><AiOutlineDropbox size='24'  /></div>
  <div className='d-flex justify-content-between w-100 pe-1'>
  <div className='menuFnt ps-3'>My Pickups</div>
  <div><MdOutlineArrowForwardIos/></div>
  </div>
</div>
<hr className='divide'/>

      {/* my booking */}
      <div className='d-flex align-items-center cursor' onClick={() => {event('myShipment')} }>
  <div className='p-2'><AiOutlineDropbox size='24'  /></div>
  <div className='d-flex justify-content-between w-100 pe-1'>
  <div className='menuFnt ps-3'>Booking</div>
  <div><MdOutlineArrowForwardIos/></div>
  </div>
</div>

<div className='d-flex align-items-center cursor' onClick={() => {event('myShipment')} }>
  <div className='p-2'><AiOutlineDropbox size='24'  /></div>
  <div className='d-flex justify-content-between w-100 pe-1'>
  <div className='menuFnt ps-3'>Inovice & KYC</div>
  <div><MdOutlineArrowForwardIos/></div>
  </div>
</div>

<div className='d-flex align-items-center cursor' onClick={() => {event('myShipment')} }>
  <div className='p-2'><AiOutlineDropbox size='24'  /></div>
  <div className='d-flex justify-content-between w-100 pe-1'>
  <div className='menuFnt ps-3'>Operation</div>
  <div><MdOutlineArrowForwardIos/></div>
  </div>
</div>

<div className='d-flex align-items-center cursor' onClick={() => {event('myShipment')} }>
  <div className='p-2'><AiOutlineDropbox size='24'  /></div>
  <div className='d-flex justify-content-between w-100 pe-1'>
  <div className='menuFnt ps-3'>Print</div>
  <div><MdOutlineArrowForwardIos/></div>
  </div>
</div>

<div className='d-flex align-items-center cursor' onClick={() => {event('myShipment')} }>
  <div className='p-2'><AiOutlineDropbox size='24'  /></div>
  <div className='d-flex justify-content-between w-100 pe-1'>
  <div className='menuFnt ps-3'>Kilo meter</div>
  <div><MdOutlineArrowForwardIos/></div>
  </div>
</div>

</div>



        </Offcanvas.Body>
      </Offcanvas>
   </>
  )
}

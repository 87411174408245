import React from 'react';
import { Header } from '../../Components/Header';
import { Input } from '../../Components/Input';
import { NormalButton } from '../../Components/NormalButton';

export const Profile = () => {
  return (
      <>
      <div className='container-fluid' >
          <div className='row'>
              <div className='col-md-4'></div>
              <div className='col-md-4'>
                <Header name="Create profile" />

                <div className='mt-4'>

                <div><Input label="Person name" type="text" /></div>
                <div className='mt-3'><Input label="Company name" type="text"  /></div>
                <div className='mt-3'><Input label="Address" type="text"  /></div>
                <div className='mt-3'><Input label="Pincode" type="text"  /></div>
                <div className='mt-3'><Input label="GSTIN (optional)" type="text"  /></div>
                <div className='ms-3 mt-3'><span><input type="checkbox"/> </span><span className='pe-4' >We aggree terms and conditions</span></div>
               <div className='mt-4'><NormalButton name="Get Started" /></div>
                </div>

              </div>
              <div className='col-md-4'></div>
          </div>
      </div>

      </>
  )
};

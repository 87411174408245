import React,{useState,useEffect} from 'react'
import { BsRecordCircle } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { BsCheck2Circle } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";
import { BsFillBookmarkCheckFill } from "react-icons/bs";
import { MdArrowForwardIos } from "react-icons/md";
import { FaTruck } from "react-icons/fa";
import Drawer from "react-bottom-drawer";
import { BsThreeDotsVertical } from "react-icons/bs";
import axios from 'axios';

export const MyShipment = (props) => {
    const [active,setActive] = useState(false)
 

   
    const pickup =() => {
        setActive(false)
        }
        
        const shipped =() => {
        setActive(true)
        }
        

  return (
   <>
    {/* Pickup & Shipped header */}
{/* <div className='TabSelect px-5 user-select-none  d-none' >
    <div className='w-50 cursor' onClick={pickup}><div className={`d-flex justify-content-center ${!active ? "activefnt" :"inActivefnt"} `}>Pickup</div></div>
    <div className='w-50  cursor' onClick={shipped}><div className={`d-flex justify-content-center  ${active ? "activefnt" :"inActivefnt"} `}>Shipped</div></div>
 </div>
<div className='position-relative w-100'>
<hr className='divide'/>
<div className={`${active ? "underLine1" :"underLine" }  `}></div>
</div> */}

 {/* my shipment details */}

 <div className='d-flex w-100 align-items-center mt-2 position-relative  cursor'>
 {/* <div className='Out'>
     <div><BiBarcodeReader size='20' /></div>
 </div> */}

<div className='d-flex justify-content-between w-100 pb-2 cursor'>
<div className='ps-3 awb d-none'>ID# {props.awb}</div>
<div className=' d-flex text-nowrap status d-none'>{props.status}<div className='ps-2'><MdArrowForwardIos/></div></div>
 </div>


 </div>

 {/* **********************************/ }
 <div className='d-flex mt-3 cursor d-none'>


{/* ******************************************* */}
<div className='d-flex flex-column position-relative'>
<div className='d-flex'>
    <div><BsRecordCircle size='16' color='#C61E10'  /></div>
 <div className='d-flex flex-column'> 
<div className='ps-3 destHead head pdLoc1 '>{props.pLoc}</div>
<div className='ps-3 destStat text-muted'>{props.pCity}</div>
</div>  
</div>
<div className='verticlaTran'> <div><BsThreeDotsVertical/></div> </div>
<div className='d-flex '> 
<div><HiLocationMarker size='19' color='green' /></div>
<div className='d-flex flex-column' >
<div className='ps-3 destHead head pdLoc1 '>{props.dLoc}</div>
<div className='ps-3 destStat text-muted'>{props.dCity}</div>
</div>
</div>

</div>
</div>

<div className='d-flex justify-content-start align-items-center'>

<div className={` d-flex align-items-center justify-content-center ${props.status==='Delivered' ?'myShipRnd' : props.status ==='In Transit' ? 'myShipRndOfd'   :'myShipRndLable' } `}>
  {props.status ==='Delivered' &&  <BsCheck2Circle size='25' color='#008000' />}
   {props.status ==='Label Created' && <BsFillBookmarkCheckFill  size='25'  />}
   {props.status ==='In Transit' && <FaTruck  size='25' color='#000' />}

   
</div>

<div className='d-flex justify-content-between w-100 align-items-center'>
<div className=' ps-2 d-flex flex-column'>
<div className='ps-2 awb'>{props.awb}</div>
<div className='d-flex align-items-center mt-1'>
<div className=' ps-2 destStat text-muted'>{props.pCity}</div>
<div className='px-1'><BsArrowRight size='15' color='gray'  /> </div>
<div className='  destStat text-muted'>{props.dCity}</div>

</div>
</div>
<div className={`text-nowrap status pe-2 ${props.status ==='Label Created'?'text-secondary'  : props.status=== 'In Transit' ?'text-primary' : 'text-success'}`}>{props.status}</div>
</div>
</div>


{/* <hr className='divider' /> */}

   </>
  )
}

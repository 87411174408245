import React from 'react'
import gps from './GpsError.svg'
import './GpsError.css'

export const GpsCheck = () => {
  return (
    <div>
       <div className='d-flex justify-content-center align-items-center '><div className='dash'></div></div>
     <div className='mt-5'> <img src={gps} alt='' className='gpsSize' /></div>
     <div className='awb1 text-center mt-2'>We need to know your location for near by pickup request</div>
    <div className='d-flex justify-content-center mt-4 '> <div className='gpsBtn  '>Enable Gps Location </div></div>
    </div>
  )
}

import React from 'react'
import { Header } from '../../Components/Header.jsx'
import { NormalButton } from '../../Components/NormalButton.jsx'

export const BillAddress = (props) => {
    const typAhead =() => {}
    const back =() => props.back('close')
  return (
    <Header name="Billing address" back ={back} bottomBtn="no" Bar={'no'}  Placeholder="Search new location for pickup" typAhead={typAhead} show='' Loading={'done'} >

<div class="form-floating mb-3">
  <input type="text" class="form-control" id="floatingInput" placeholder="Person or Company name"/>
  <label for="floatingInput">Name or company name</label>
</div>

<div class="form-floating mb-3">
  <input type="text" class="form-control" id="floatingInput" placeholder="Billing address"/>
  <label for="floatingInput">Billing Address</label>
</div>

<div class="form-floating mb-3">
  <input type="text" class="form-control" id="floatingInput" placeholder="Pincode"/>
  <label for="floatingInput">Pincode</label>
</div>
<div class="form-floating mb-3">
  <input type="text" class="form-control" id="floatingInput" placeholder="Pincode" value='India' disabled />
  <label for="floatingInput">Country</label>
</div>

<div class="form-floating mb-3">
  <input type="text" class="form-control" id="floatingInput" placeholder="GSTIN"/>
  <label for="floatingInput">GSTIN</label>
</div>

<div className='mt-5'><NormalButton name="Update" /></div>
    </Header>

  )
}

import React , {useEffect, useState} from 'react'
import { Header } from './Header'
import { ImHome } from "react-icons/im";
import { HiOfficeBuilding } from "react-icons/hi";
import { RiMapPinRangeFill } from "react-icons/ri";
import './PickupAddress.css'
import Modal from 'react-bootstrap/Modal'
import ModalDialog from 'react-bootstrap/ModalDialog'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import axios from "axios";
import Drawer from "react-bottom-drawer";
import { RiDeleteBin6Line } from "react-icons/ri";

export const PickupAddress = (props) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [save,setSave] =useState('')
  const [home,setHome] =useState('')
  const[office,setOffice] =useState('')
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [pick,setPick] =useState('')
  const [del,setDel] = useState(false)
  const [shipment,setShipments] = useState('')

useEffect(() => {
const fetch = async () => {
  const resp = await axios(`https://www.easy-ship.in/pic_location.php?custcode=7814002400`)
  if(resp.data)
  {
    setPick(resp.data)
    console.log(resp.data)
  }
  }
  if(!pick)
fetch()
})

    


const close =() => {
  setShow(false)
}

const cancel =() => setIsVisible(false)
const Delete = async(e) => {
    setDel(true)
   const response=  await axios.get(`https://www.easy-ship.in/DeleteShipment.php?awb=${isVisible}`)
      if(response.status === 200)
    {  setIsVisible(false) 
        setDel(false)
        // if(shipment.length === 1 )
        // back()
       
      setShipments('')}

   }
const deleted = (e) =>setIsVisible(e)
const edit =(lat,lng) => {
  props.edit('location')
 
  props.loc({lat,lng})
}
  return (
 <>

{pick?"": <>
  <div className="card-body">
    <div className="avatar-placeholder placeholder rounded-circle"></div>
    <div className="text-placeholder placeholder w-50"></div>
    <div className="text-placeholder placeholder w-75"></div>
</div>
   
<div className="card-body">
    <div className="avatar-placeholder placeholder rounded-circle"></div>
    <div className="text-placeholder placeholder w-50"></div>
    <div className="text-placeholder placeholder w-75"></div>
</div>
</>}

 { pick ? pick.map((x) => <> 
  <div className='addCon user-select-none position-relative' >
    <div className='position-relative p-2 ' >
      
      <div  className={`pb-2 ${x.name === 'Home' ?'d-block' :'d-none' }`} ><ImHome size="24"/></div>
      <div className={`pb-2  ${x.name=== 'Office' ? 'd-block' :'d-none' }`}><HiOfficeBuilding size="24"/></div>
      <div className={`pb-2 ${x.name !=='Home' && x.name !=='Office' ?'d-block':'d-none' }`}><RiMapPinRangeFill size="26"/></div>
    {/* <div ><BsCheckCircleFill color="#008000" size="15"   /> </div> */}
    </div>
    <div className='addreess'>
     
      <div  className='title'>{x.name}</div> 
      <div className='addDetail1 col-10 text-truncate text-muted '>{x.address}</div>
      
      <div className='d-flex mt-3  justify-content-between w-75'>
      <button onClick={() => edit(x.lat,x.lng)} type="button" class="edit-btn me-2">Edit</button>
      <button type="button" class="delete-btn ms-2" onClick={() =>deleted(true)} >Delete</button>
     </div>
    </div>
    </div>
    <div className='mt-3'>    <hr className='my-0 px-0 divider'/></div> </> ) : ""}



    <Drawer
      isVisible={isVisible}
     
    >
       <div className='d-flex align-items-center deleteHad'>
       <div className=' my-3 pe-2 pb-1'><RiDeleteBin6Line size="20" color='#d9534f' /></div>
       <div className='  text-danger'>Delete Shipment ?</div>
     
       </div>
       <hr className='my-0 py-0'/>

       <div className='text-muted fs-6 text-center my-3'>Are you sure you want to delete this shipment</div>

  <div className='d-flex justify-content-start align-items-center'>
 
  <div className='d-flex justify-content-end w-100 mb-3'>
  <div className='mt-2 w-50' onClick={() => cancel()}><button type="button" className="btn btn-outline-secondary">Cancel</button></div>
 
  <button onClick={()=>Delete()} className="btn btn-danger w-50 mt-2" type="button" disabled={del? true : false}>
<div className={`${del ? 'd-block d-flex justify-content-center align-items-center' : 'd-none'}`} >
<div className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></div>
 <div className='ps-2'>Delete</div>
 </div>
<div className={`${del ? "d-none": 'd-block' }`}>Delete</div>
</button>
 
 </div>
  </div>
      </Drawer>


 </>
  )
}

import React from 'react'
import { useBarcode } from '@createnextapp/react-barcode';

export const Barcode = (props) => {
    const { inputRef } = useBarcode({
        value: props.awb,
        options: {
          background: '#fff',
          width: 4,
          displayValue: false,
            height:70
        
        }
      });
  return (
    
    <div className='d-flex  flex-column justify-content-center w-75'>
       <div className='d-flex justify-content-center'>  <img ref={inputRef} className='img-fluid ' /></div>
       <div className='text-center '>{props.awb}</div>
    </div>
  )
}

import React from 'react'
import { Header } from '../../Components/Header.jsx'
import './Notification.css'

export const Notification = (props) => {
    const typAhead =() => {}
    const back =() => props.back('close')
  return (
    <>
  
    <Header name="Notification setting" back ={back} bottomBtn="no" Bar={'no'}  Placeholder="Search new location for pickup" typAhead={typAhead} show='' Loading={'done'} >



<div className='d-flex justify-content-between mt-4 notCon'>
   <div className='not-head'>Pickup Notification</div>
    <div className="form-check form-switch">
  <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
 
</div>
</div>

<div className='d-flex justify-content-between mt-4 notCon'>
   <div className='not-head'>Delivery Notification</div>
    <div className="form-check form-switch">
  <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
 
</div>
</div>

    </Header>
    </>
  )
}

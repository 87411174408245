import React from 'react';
import { Header } from '../../Components/Header';
import { Input } from '../../Components/Input';
import { NormalButton } from '../../Components/NormalButton';
import './CreatePwd.css'

export const CreatePwd = () => {
  return (
      <>
      <div className='container-fluid'>
        <div className='row'>
            <div className='col-md-4'></div>
            <div className='col-md-4'>
               <Header name="Create password" />

               {/* create password */}
               <div className='mt-5'>
              <Input label="Create password" />
              <div className='ms-3 btm-lbl'>Password must be atlest 8 character</div>
            </div>

             {/* confirm password */}
             <div className='mt-3'>
              <Input label="Confirm password" />
              <div className='ms-3 btm-lbl'>Both must be match</div>
            </div>

            {/* Confirm btn */}

           <div className='mt-5'> <NormalButton name="Continue" /></div>

            <div className='col-md-4'></div>
        </div>
      </div>
      </div>
      </>
  )
};

import React from 'react';
import './NormalButton.css'

export const NormalButton = (props) => {
  return <div className='d-flex justify-content-center align-items-center'>

<button type="button" disabled={props.loading ? true : props.dim ? true :false} className="btn btn-warning  text-nowrap ">{!props.loading && props.name}
{ props.loading && <>
  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  <span className="sr-only px-2">Loading...</span>
</> 
}

</button>

  </div>;
};


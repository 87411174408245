import React from 'react'
import './DashBoard.css'
import { BsBoxSeam } from "react-icons/bs";
import { MdOutlineLocalShipping } from "react-icons/md";
import { BsRecycle } from "react-icons/bs";
import { AiOutlineCalculator } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { BsPrinter } from "react-icons/bs";
import { BiRupee } from "react-icons/bi";
import { Offline } from '../../Components/Offline.jsx';




export const DashBoard = (props) => {
  
  const Task =(e) => props.back(e)

    const page =(e) => props.page(e)

  return (
    <div className=''>
<div className='d-flex justify-content-center '><div className='dash'></div></div>
       {/* booking div */}
      <div className={!props.duty ? 'd-block':'d-none'}>
      <div className='d-flex justify-content-between px-4 mt-4'>
         {/* booking */}
        <div className='d-flex flex-column justify-content-center align-items-center bg bg-white ' onClick={()=>Task('newBooking')}>
          <div className='mt-2'><BsBoxSeam size='25' color='black'/></div>
          <div className='py-2 text-dark'>Booking</div>
        </div>
        <div className='lines'></div>

        <div className='d-flex flex-column justify-content-center align-items-center bg  bg-white' onClick={() => Task('HomePickup')}>
          <div className={`mt-2 position-relative } `}><MdOutlineLocalShipping size='28' color='black' /><div className={` ${props.parcelPk ? 'd-block':'d-none'} position-absolute top-0 start-50 translate-middle-y  alerts rounded ms-1`}>{props.parcelPk}</div></div>
          <div className='py-2 text-black'>Pick & Drop</div>
     
        </div>
     </div>
     <div className='horizontal'></div>

 {/* Tracking adn div */}
 <div className='d-flex justify-content-between px-4 mt-3'>
         {/* booking */}
        <div className='d-flex flex-column justify-content-center align-items-center bg bg-white ' onClick={()=> Task('Operation')}>
          <div className='mt-2'><BsRecycle size='25' color='black' /></div>
          <div className='py-2 text-black'>Operation</div>
        </div>
        <div className='lines'></div>
        <div className='d-flex flex-column justify-content-center align-items-center bg bg-white ' onClick={() => Task('Calculator')}>
          <div className='mt-2'><AiOutlineCalculator size='25' color='black' /></div>
          <div className='py-2  text-black'>Rate Calculator</div>
        </div>
     </div>

     <div className='horizontal'></div>
     {/* Tracking adn div */}
     <div className='d-flex justify-content-between px-4 mt-3'>
         {/* booking */}
        <div className='d-flex flex-column justify-content-center align-items-center bg bg-white ' onClick={()=>Task('trackEmp')}>
          <div className='mt-2'><BsSearch size='25'  color='black'  /></div>
          <div className='py-2 text-black'>Tracking</div>
        </div>
        <div className='lines'></div>
        <div className='d-flex flex-column justify-content-center align-items-center bg  bg-white' onClick={() => Task('cash')} >
          <div className='mt-2'><BiRupee size='26' color='black' /></div>
          <div className='py-2 text-black'>Cash Book</div>
        </div>
     </div>
     </div>

{props.duty && <Offline /> }
    </div>
  )
}

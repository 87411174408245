import React, { useEffect,useState } from 'react'
import { FiCheckCircle } from "react-icons/fi";
import { BiRupee } from "react-icons/bi";
import paymentDone from './paymentDone.svg'
import {useParams,useNavigate} from 'react-router-dom'
import { Spinner } from '../../Components/Spinner';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { useSearchParams } from "react-router-dom"


import './paymentDone.css'
import { GiConsoleController } from 'react-icons/gi';
import { NormalButton } from '../../Components/NormalButton';



export const PaymentDone = (props) => {
  const {id} = useParams()
  const {loc} = useParams()
  const navigate = useNavigate()
  const [hide,setHide] = useState(false)
  let [searchParams, setSearchParams] = useSearchParams()


  useEffect(() => {
        const config ={
      method :'post'
    }
    console.log(id)
    fetch(`https://www.queekdelever.com/queekApi/ReadyPickup.php?refId=${id}`)
    .then(response => response.json())
    .then(data =>{ data.map((x) => {
     if(x.status === 'Pickup' || x.status ==='Confirm')
     {whatsMsg(x.sender,x.awb,x.city,x.country)}
    })
    fetch(`https://queekdelever.com/queekApi/pickup_detail.php?refId=${id}&transId=${searchParams.get("razorpay_payment_id")}`, config)
    .then(response => response.json())
    .then(data => setHide(true))
  }

    )


        
  },[])


  const whatsMsg =(sender,awb,city,country) => {

      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' ,
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3YWxsZXRJZCI6IjYyYWExMmZiMDA2NmQzN2M5OWIxNDMxNSIsInVzZXJJZCI6IjYyYWExMmZiMDA2NmQzN2M5OWIxNDMxNiIsImRldmljZUlkIjoiNjJiNDU5ODczZDhhMzEwYTRlYmE4NDUyIiwiaWF0IjoxNjU1OTg2Njg0LCJleHAiOjE4Nzc0Mjk4ODR9.CTTbtkuTLfOYWWXYVGfQMN0uEY_vb_ck0ivRFHzx_LU' ,
          'Access-Control-Allow-Origin':'origin-list',
          'Access-Control-Allow-Headers': '*'
        },

          body: JSON.stringify({
            "numbers": `91${sender}`,
            "message":  {
              "text": `*Booked* : Your Shipment with tracking ID  ${awb} for ${country ==='India' ? city : country} has been booked and Ready to Dispatch. You'll be notified when the shipment is delivered. 
              \n *Track a Shipment* : You can track your shipment using this link https://www.queekdelever.com/tracking/${awb}
              \n *E-Receipt* : Click on this link for E-receipt https://www.queekdelever.com/receipt/${awb}
              \n *Thanks for Shipping with Queek Delever*`,
            }
          }
           )
      };
      fetch('https://api.whatsspot.in/api/message/fast/text', requestOptions )
          .then(response => response.json())
          .then(data => data)
          .catch(err => console.log('Error occur '+ err))
          
  
  // empty dependency array means this effect will only run once (like componentDidMount in classes)
  
  }
  
  return (
    <div className='d-flex  flex-column justify-content-center align-items-center bgQueek'>

      { hide && <div className='cardPayment'>
        <div className='topHd'><BsFillCheckCircleFill size='85' color='#008000' /></div>
        <div className='fs-4 text-center mt-4'>Payment Success!</div>

      
        <div className='px-3 paymentHistory '>
        {/* <div className='mt-4 pamentDetail'><p>Amount : 120</p></div> */}
         {/* <div className='pamentDetail'><p>Transcation Date : 12,Jul 2022</p></div> */}
         <div className='pamentDetail text-center'><p>Payment Id : {searchParams.get("razorpay_payment_id")}</p></div>
      </div>

    <div className='px-2 mt-4'><hr className='divide1' /></div>

    <div className='receiptSent mx-3 mt-4' >E-Receipt sent by WhatsApp </div>
    <div className='mt-3 text-center' onClick={()=> navigate('/')} ><u>Go to Home</u> </div>

          </div>
}
     

        {/* <div className='cardPayment d-none'><FiCheckCircle size='80' color='green' /></div>
        <div className='my-3'>Payment Successful </div>
        <div className='d-flex mt-4 align-items-center justify-content-center mt-3  text-white fs-5'>
            <img src={paymentDone} alt='' className='paymentDone' />
        </div> */}

  
   {!hide &&  <Spinner />}
    </div>
  )
}

import React, { useState } from 'react'
import { BiRupee } from "react-icons/bi";
import { AiFillStar } from "react-icons/ai";
import { NormalButton } from '../../Components/NormalButton';
import { FaShippingFast } from "react-icons/fa";
import {GiScooter} from "react-icons/gi";
import { FaPlaneDeparture } from "react-icons/fa";



export const RateList = (props) => {

  const [hide,setHide] = useState(false)

  const showRates =() =>{
    hide? setHide(false) : setHide(true)
    // props.showRates(hide)
  }
  return (
    <div className={`${props.active ? "Rate-con1" :"Rate-con"} d-flex justify-content-between pe-3 mb-2 cursor `} onClick={showRates} >
    <div className='d-flex align-items-center '>

      {/* radio button option */}
       {/* <div className={`selecOpt `}>
         <div className='selecOpt1'>
           <div className='radio'></div>
         </div>
       </div> */}
     {props.iconType ==='Scooter' && <div><FaShippingFast size={30} color='000' /></div>}
     {props.iconType ==='By Road' && <div><FaShippingFast size={30} color='000' /></div>}
     {props.iconType ==='By Air' && <div><FaPlaneDeparture size={30} color='000' /></div>}

     {/* <div><img src={props.iconType} className='Plogo' /></div> */}
       {/* {props.delTime} */}
<div className='ps-4 d-flex flex-column '><span className='ServiceName'>{props.service}</span>
<div><div className='delivery-lab'><span className='twoLine pe-1 text-muted '> <span className='text-dark '>{props.delTime}</span> </span></div>
{/* <div className='d-flex pe-3 pt-2'>
<div><AiFillStar color={'ffe500'}/></div>
</div> */}

</div>
</div>
   </div>
   <div className='rate-ls user-select-none' >
     <div className='ru-mar '><BiRupee size={18} /></div>
   <div>{props.price}</div>
   {/* <div><MdArrowDropDown size="28"/></div> */}
     </div> 

   </div>
  )
}

import React from 'react';
import './EBooking.css'

export const EBooking = (props) => {
  return (
      <>
      <div>
        <p className='e-heading '>{props.name}</p>
        <p className='desc text-muted'>{props.desc}</p>
      </div>
      </>
  )
};

import React, { useState } from 'react';
import './SmartSolution.css';

import { Heading } from '../../Components/Heading.jsx';
import { BsFillCheckCircleFill } from "react-icons/bs";
import localDel from './local.png';
import domDel from './domDel.jpg';
import intDel from './intDel.svg';



export const SmartSolution = (props) => {
  const [trk,setTrack] = useState('')

  return (
    <>

      <div className='moneyBack'>
      <div className=' text-center '>
        <div className='resSmart'><Heading heading="Our Smart Solution" /></div>

      </div>
      <div className='container smart-space'>
      <div className='row justify-content-between smart-space'>

        {/* local sevice */}
        <div className='col-md-4'>
        <div className="card border-0">
  <img className="img-size-smart img-fluid mx-auto" src={localDel} alt="card"/>
  <div className="card-body">
    <h5 className="card-title text-center service-font">Instant Delivery</h5>
    <p className="card-text text-muted text-center desc-ser">Get instant pickup from door step and delivery you need to be</p>
    <a href="#" className="btn btn-outline-warning d-flex justify-content-center align-items-center text-center"><span><BsFillCheckCircleFill  color="#008000" /><span className='smart-font px-2'>Within 1 hour</span></span></a>
  </div>
</div>
        </div>
        {/* domestic service */}
        <div className='col-md-4'>
        <div className="card border-0 ">
  <img className="img-size-smart-1 img-fluid mx-auto" src={domDel} alt="Card image cap"/>
  <div className="card-body">
    <h5 className="card-title text-center service-font img-fluid">Across India</h5>
    <p className="card-text text-muted text-center desc-ser">We offer a domestic services for time critical deliveries within India</p>
   
   <a href="#" className="btn btn-outline-warning d-flex justify-content-center  align-items-center smart-font"><span ><BsFillCheckCircleFill color="#008000" /><span className='smart-font px-2'>Next Business Day</span></span></a>

      </div>
</div>
        </div>
        {/* international service */}
        <div className='col-md-4' >
        <div className="card border-0">
  <img className="img-size-smart-2 img-fluid mx-auto" src={intDel} alt="Card image cap"/>
  <div className="card-body">
    <h5 className="card-title text-center mt-2 service-font">Ship to Abroad</h5>
    <p className="card-text text-muted text-center desc-ser">The best way of send your parcel at any destination in the world</p>
    <a href="#" className="btn btn-outline-warning d-flex justify-content-center align-items-center smart-font"><span><BsFillCheckCircleFill  color="#008000" /><span className='smart-font px-2'>4-7 Working Days</span></span></a>
  </div>
</div>
</div>
        </div>
        </div>
        </div>
    </>
  )
};

import firebase from "firebase/app";
import "firebase/storage";

var firebaseConfig = {
   apiKey: "AIzaSyDscwuhkoixvXKnfkgimUgaWaLq6X-aXBA",
  authDomain: "easyship-70493.firebaseapp.com",
  databaseURL: "https://easyship-70493.firebaseio.com",
  projectId: "easyship-70493",
  storageBucket: "easyship-70493.appspot.com",
  messagingSenderId: "518173088699",
  appId: "1:518173088699:web:16a7a824ab8aa989cc829e",
  measurementId: "G-GB07MKYLF2"
};  

// var firebaseConfig = {
//   apiKey: "AIzaSyDSCkrfaPXW8fnqSoMnb09c8_c8_rhYihg",
//   authDomain: "test-upload-aae5e.firebaseapp.com",
//   databaseURL: "https://test-upload-aae5e.firebaseio.com",
//   projectId: "test-upload-aae5e",
//   storageBucket: "test-upload-aae5e.appspot.com",
//   messagingSenderId: "132111242676",
//   appId: "1:132111242676:web:5084295055cf2d4ff68ee1"
// };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;

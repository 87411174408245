import React from 'react';

import SwitchSelector from "react-switch-selector";

const options = [
//   {
//     label: "Local",
//     value: "Local",
//     selectedBackgroundColor: "#ffe202",
//     selectedFontColor:'#000'
// },
  {
      label: "Domestic",
      value: "Domestic",
      selectedBackgroundColor: "#ffe202",
      selectedFontColor:'#000'
  },
  {
    label: "International",
    value: "International",
    selectedBackgroundColor: "#ffe202",
    selectedFontColor:'#000'
}
];


const initialSelectedIndex = options.findIndex(({value}) => value === "Domestic");

export const ServiceSelection = (props) => {
  
  const onChange = (newValue) => {
  
    props.serType(newValue)
   };
 
 
  return (
      <>
       <SwitchSelector
            onChange={onChange}
            options={options}
            initialSelectedIndex={initialSelectedIndex}
            backgroundColor={"#f2f2f2"}
            fontColor={"#000"}

         
           
        />
      </>
  )
};

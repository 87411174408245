import React from 'react'
import { Header } from '../../Components/Header'
import './Destination.css'
import { BsChevronLeft } from "react-icons/bs";
import { BsSearch } from "react-icons/bs";

export const Destination = (props) => {

    const back =(e) => {
 props.back('delivery')

    }
  return (
   <>

   {/* search bar div */}
  <div className='destination1'>
  <div className='pe-4 SearIcon' onClick={back}><BsChevronLeft size="24" /></div>
      <div className='SearchBarStyle'>
     <div className='pe-3 ps-2'><BsSearch/></div>
       <div className='SearchFont'><input placeholder='Search city name' /></div>
      </div>   
  </div>

  {/* <div className='saveStyle mt-3 px-3'>Popular cities</div> */}
  <div className='dest'>
    <div className='DestFnt'>Amritsar</div>
    <hr className='my-0 px-0 divider'/>
    <div className='DestFnt'>Bangalore</div>
    <hr className='my-0 px-0 divider'/>
    <div className='DestFnt'>Chennai</div>
    <hr className='my-0 px-0 divider'/>
    <div className='DestFnt'>Delhi</div>
    <hr className='my-0 px-0 divider'/>
    <div className='DestFnt'>Faridabad</div>
    <hr className='my-0 px-0 divider'/>
    <div className='DestFnt'>Ghaizabd</div>
    <hr className='my-0 px-0 divider'/>
    <div className='DestFnt'>Gurgaon</div>
    <hr className='my-0 px-0 divider'/>
    <div className='DestFnt'>Hissar</div>
    <hr className='my-0 px-0 divider'/>
    <div className='DestFnt'>Jalandhar</div>
    <hr className='my-0 px-0 divider'/>
    <div className='DestFnt'>Kolkata</div>
    <hr className='my-0 px-0 divider'/>
    <div className='DestFnt'>Lucknow</div>
    <hr className='my-0 px-0 divider'/>
    <div className='DestFnt'>Mumbai</div>
    <hr className='my-0 px-0 divider'/>
    <div className='DestFnt'>Noida</div>
    <hr className='my-0 px-0 divider'/>
    <div className='DestFnt'>Panipat</div>
    <hr className='my-0 px-0 divider'/>
    </div>
   </>
  )
}

import React from 'react'
import coffeBrack from './coffee-break.svg'
import './Offline.css'

export const Offline = () => {
  return (
    <div className='relaxCon'>
        <div><img src={coffeBrack}  className='relx' /></div>
        <div className=' px-2 mt-2 text-center  rounded text-white offline text-nowrap'>you are offline</div>
    </div>
  )
}

import React from 'react';
import './Footer.css';
import { FaGooglePlay } from "react-icons/fa";
import { AiFillApple } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import wLogo from './pages/Home/wLogo.png'
import { useNavigate } from 'react-router-dom';


export const Footer = () => {

    const navigate = useNavigate();
    const about =() => {
    navigate('/about');}

    const local =() => {
        navigate('/localDelivery');}

    const domestic =() => {
            navigate('/domestic');}

    const international =() => {
                navigate('/international');}

    const privacy =() => navigate('/privacy')
    const refund =() => navigate('/refundpolicy')
    const term =() => navigate('/term')



    return (
        <>
               {/* <!-- footer start --> */}
            <div className='bg-color container-fluid footer-mar  py-4'>
              <div className='row'>
        
              <div className='col-md-4 d-flex my-auto'>
        
              {/* <!-- {/* Google play --> */}
        
              <div className='border txt-clr pe-4  my-2 pt-1  ms-5 my-5 d-none' >
                  <div className='d-flex align-items-center'>
                      <div className='mx-3'><FaGooglePlay size="25" />  </div>
                      <div>
                          <div>GEI IT ON</div>
                          <div className=''><h6 className='fs-6'>Google Play</h6></div>
                      </div>
                  </div>
              </div>
        
          {/* <!-- Apple store --> */}
        
           <div className='border txt-clr pe-4  my-2 pt-1  ms-5 my-5 d-none' >
                  <div className='d-flex align-items-center'>
                      <div className='mx-3'><AiFillApple size="30" />  </div>
                      <div>
                          <div>Download on the</div>
                          <div className=''><h6>App Store</h6></div>
                      </div>
                  </div>
              </div> 
              {/* <!-- <img src={play} alt="play-store" className='play-size img-fluid' /> --> */}
        
                  {/* <!-- office address --> */}
        
                  <div className='d-flex flex-column ps-3'>
                  <div><img src={wLogo} className='flogo cursor' alt="Queek Delever logo" /></div>
                  <div className='ps-2 slogan'>You click, we deliver</div>
                  <div className='mt-3 text-white fFont pe-3'>Your one stop solution to all your logistical needs. we have been providing quality and professional Courier and Transport services for over a decade.</div>
                 
                  </div>
        
              </div>
        
              <div className=' txt-clr col-md-2'>
              <div className='  me-4 pt-2'>
                <h6 className='fl-font'>About</h6>
              </div>
              
            <a href="/about.html" className="nav-link menu_bar text-white ps-0 text-muted text-nowrap "> Who we are</a>
            <a href="/vision.html " className="pt-2 nav-link menu_bar text-white ps-0 text-muted text-nowrap "> Vision & Mission</a>
        </div>
        
        <div className=' txt-clr col-md-2'>
        <div className='pt-2'>
        <h6 className='fl-font'>Service</h6>
        </div>
        <a href="/sameCitySameDay.html" className="pt-1 nav-link menu_bar text-white ps-0 text-muted text-nowrap ">Same City Same Day</a>
        <a className="pt-1 nav-link menu_bar text-white ps-0 text-muted text-nowrap " href="/NextBusinessDay.html">Next Business Day</a>
        <a className="pt-1 nav-link menu_bar text-white ps-0 text-muted text-nowrap " href="/ExpressService.html">Doemstic Express</a>
        <a className="pt-1 nav-link menu_bar text-white ps-0 text-muted text-nowrap " href="/internationalservice.html">International Service</a>
        
        </div>
             
        
              <div className=' txt-clr col-md-2'>
              <div className='pt-2'>
                <h6 className='fl-font'>Shipping</h6>
              </div>
              <a href="/login" className="pt-1 nav-link menu_bar text-white ps-0 text-muted text-nowrap ">Ship Now</a>
             <a href="/pickuprequest.html" className="pt-1 nav-link menu_bar text-white ps-0 text-muted text-nowrap ">Shedule a Pickup Request</a>
             <a href="/calculateshippingcost.html" className="pt-1 nav-link menu_bar text-white ps-0 text-muted text-nowrap ">Calculate Time & Shipping Cost</a>
             <a href="/packagingSolution.html" className="pt-1 nav-link menu_bar text-white ps-0 text-muted text-nowrap ">Packaging Solution</a>
            
        
              </div>
              
              
              
        
              <div className=' txt-clr col-md-2'>
              <div className='d-flex justify-content-end me-4 py-4'>
                <h6 className='fl-font'>Follow us</h6>
              </div>
             
              <div className='d-flex justify-content-end me-4 align-items-start'>
                <span className="px-2 cursor"><AiFillInstagram color='white' size='24' /></span>
                <span className="px-2 cursor"><AiFillLinkedin color='white' size='24' /></span>
                <span className="px-2 cursor"><AiOutlineTwitter color='white' size='24' /></span>
                <span className="px-2 cursor"><FaFacebookF color='white' size='24' /></span>
              </div>
              </div>
              </div>
              <div className='row mt-5'>
              <hr className="divide"/>
             </div>
             <div className='container-fluid'>
              <div className='row'>
                  <div className='col-sm-4'><h6 className=' txt-clr1 mx-3'>© 2022 Queek Delever. All rights reserved.</h6></div>
                  <div className='col-sm-4'><h6 className=' txt-clr1 mx-3'>Owned and operated by SRA Corporation</h6></div>
        
                  <div className='col-sm-4'>
                    <div className='d-flex justify-content-end'>
                      <a href="/privacypolicy.html" className="pt-1 nav-link menu_bar text-white   text-nowrap px-2 txt-clr1">Privacy Policy</a>
                      <a href="/termconditions.html" className="pt-1 nav-link menu_bar text-white   text-nowrap px-2 txt-clr1">Terms & Conditions</a>
                      <a href="/refundpolicy.html" className="pt-1 nav-link menu_bar text-white   text-nowrap px-2 txt-clr1">Refund Policy</a>
        
               </div>
                    </div>
        
                   
                 </div>
               </div>
             
          </div>
          <div className="floating_btn">
            <a target="_blank" href="https://wa.me/917814002400?text=I%20want%20to%20book%20a%20Shipment">
              <div className="contact_icon">
                <i className="fa fa-whatsapp my-float"></i>
              </div>
            </a>
            <p className="text_icon">Talk to us?</p>
          </div>
             {/* <!-- footer end --> */}
        </>
    )
};

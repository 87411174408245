import React, {useState,useRef,useEffect} from 'react'
import { BsChevronLeft } from "react-icons/bs";
import { HiOutlineArrowRight } from "react-icons/hi";

import { AiOutlineCamera } from "react-icons/ai";
import  QrReader  from 'react-qr-reader';
import axios from 'axios';
import './NewBooking.css'
import Drawer from "react-bottom-drawer";
import ImageUploader from 'react-images-upload';
import { Buffer } from "buffer";
import firebase from './Firebase.jsx';
import FileUploader from "react-firebase-file-uploader";
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import { AiOutlineCloudUpload } from "react-icons/ai";
import food from './fast-food-icon.png'
import { BsFillCheckCircleFill} from "react-icons/bs";

export const NewBooking = (props) => {
const [image,setImage] = useState()
const [qr,setQr] = useState(false)
const [check,setCheck] = useState(1)
const [percent, setPercent] = useState(0);
const [isHide,setHide] = useState(false)
const [result,setResult] = useState(undefined)
const [wait,setWait] = useState(false)
const[isVisible,setIsVisible] = useState(false)
const [kycType,setKycType] = useState('')
const [progress, setProgress] = useState(0);
const [showF,setShowF] = useState(false)
const [showB,setShowB] = useState(false)
const [imageLoadF,setImageLoadF] =useState(false)
const [imageLoadB,setImageLoadB] =useState(false)
const [open,setOpen] = useState(false)
const [senderDetail,setSenderDetail] = useState('')

const [valid,setValid] = useState('form-control')
const mob =useRef(null)
const pName = useRef(null)
const Add =useRef(null)
const Pincode = useRef(null)
const city = useRef(null)
const state = useRef(null)
const [kyc,setKyc] = useState('')
const [senderId,setSenderID] = useState('')
const [nameErr,setNamErr] = useState('form-control')
const [addErr,setAddErr] = useState('form-control')
const [pinErr,setPinErr] = useState('form-control')
const [cityErr,setcityErr] = useState('form-control')
const [stateErr,setStateErr] = useState('form-control')


    const back=(e) => props.back(e)
    const sender=() => setHide(true)

   useEffect(() => {
setTimeout(() => {
  mob.current.focus()
},500)
   },[])

    
    const next=() => {
        if(mob.current.value.length !==10)
        setValid('form-control is-invalid')
        else
       { setValid('form-control is-valid')
       setSenderID(mob.current.value)
        fetch1()
    }
    }

    const valids =() => {
       if(mob.current.value.length ===10)
       setValid('form-control is-valid')
       else
       setValid('form-control')
    }
    // setQr(true)
    const qrScan =() =>setQr(true)

    const fetch1 = async() => {
      let response = await axios.get(`https://queekdelever.com/queekApi/QueekSender.php?custcode=${senderId ? senderId : mob.current.value }`)
      if(response.status ===200)
      {
        setHide(true)

        setSenderDetail(response.data)
        pName.current.value = response.data[0].name
        Add.current.value =response.data[0].add
        Pincode.current.value =response.data[0].pincode
        city.current.value=response.data[0].city
        state.current.value =response.data[0].state
        setImageLoadF(true)
        setImageLoadB(true)
      }
    }

 
    
  const xmltojson = async(e) => {
    let result = encodeURIComponent(e)
const response = await axios.get(`https://api.factmaven.com/xml-to-json/?xml=${result}`)
if(response.status ===200)
{
  pName.current.value = response.data.PrintLetterBarcodeData.name
  Add.current.value =response.data.PrintLetterBarcodeData.house+', '+response.data.PrintLetterBarcodeData.street+', '+response.data.PrintLetterBarcodeData.loc
  Pincode.current.value =response.data.PrintLetterBarcodeData.pc
  city.current.value =response.data.PrintLetterBarcodeData.dist
  state.current.value=response.data.PrintLetterBarcodeData.state
  setKyc(response.data)
}
setWait(false)
  }

  const onDrop =(e,f) => setImage(f)


const handleUploadSuccess = async filename => {
  const downloadURL = await firebase
    .storage()
    .ref("images")
    .child(filename)
    .getDownloadURL();
    uploadd(downloadURL)
  // console.log(encodeURIComponent(downloadURL));
};

const fetchKyc =async() => {
  
  const response = await axios.get(`https://queekdelever.com/queekApi/kycFetch.php?custcode=${senderId}`)
  if(response.data)
  {
    setKyc(response.data)
 if(!response.data[0].addhar_f)
 setImageLoadF(false)
 if(!response.data[0].addhar_b)
 setImageLoadB(false)
 if(!response.data[0].passport_f)
 setImageLoadF(false)
 if(!response.data[0].passport_b)
 setImageLoadB(false)

  }
}

const uploadd =async(e) =>{
setShowF(false)
setShowB(false)
if(kycType ==='af' || kycType ==='pf')
setImageLoadF(true)
if(kycType ==='ab' || kycType ==='pb')
setImageLoadB(true)

  let link = ''
  link = encodeURIComponent(e)

  const response = await axios.get(`https://queekdelever.com/queekApi/kycUpload.php?custcode=${senderId}&file=${link}&kycType=${kycType}`)
  if(response.data ==='uploaded')
  {
   
    fetchKyc()
  }
}

const handleProgress = progress => {
  setProgress(progress);
};

const handleUploadError = err => {
  console.log(err);
};

const handleUploadStart =() => {
 
console.log(kycType)

if(kycType==='af' || kycType ==='pf')
setShowF(true)
else
setShowB(true)
}

const updateFRom =async () => {
   await axios.post(`https://queekdelever.com/queekApi/updatefrom.php?sender=${senderId}&name=${pName.current.value}&address=${Add.current.value}&pincode=${Pincode.current.value}&city=${city.current.value}&state=${state.current.value}`)
}

const next2 =() => {
  setNamErr('form-control')
  setAddErr('form-control')
  setPinErr('form-control')
  setcityErr('form-control')
  setStateErr('form-control')



if(pName.current.value.length <=2)
setNamErr('form-control is-invalid')

if(Add.current.value.length <=2)
setAddErr('form-control is-invalid')

if(Pincode.current.value.length !==6)
setPinErr('form-control is-invalid')

if(city.current.value.length <=2)
setcityErr('form-control is-invalid')

if(state.current.value.length <=2)
setStateErr('form-control is-invalid')

if(pName.current.value.length > 2 && Add.current.value.length >2 && Pincode.current.value.length ===6 && city.current.value.length >2 && state.current.value.length > 2 )
{
  updateFRom()

  if(props.lType === 'Employee' && props.serType ==='Local')
 { 
  props.next('Dashboard')
  whatsMsg(props.sender,pName.current.value)
  // window.open(`https://api.whatsapp.com/send?phone=91${props.sender}&text=Dear Customer,${pName.current.value} want to deliver  order/Shipment at your DoorStep, Please click below click to cofirm your delivery location, i.e we  can locate your delivery address. https%3A%2F%2Fqueekdelever.com%2F%23%2FQueekDelivery%2F${props.sender}`) 

 }
  else{
  props.mob(senderId)
  props.next('mBooking')
  props.mSender(pName.current.value+', '+Add.current.value+',' +Pincode.current.value+', '+city.current.value+', ' + state.current.value)
 }
}
}


const whatsMsg =(sender,storeName) => {
    

  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' ,
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3YWxsZXRJZCI6IjYyYWExMmZiMDA2NmQzN2M5OWIxNDMxNSIsInVzZXJJZCI6IjYyYWExMmZiMDA2NmQzN2M5OWIxNDMxNiIsImRldmljZUlkIjoiNjJiNDU5ODczZDhhMzEwYTRlYmE4NDUyIiwiaWF0IjoxNjU1OTg2Njg0LCJleHAiOjE4Nzc0Mjk4ODR9.CTTbtkuTLfOYWWXYVGfQMN0uEY_vb_ck0ivRFHzx_LU' ,
      'Access-Control-Allow-Origin':'origin-list',
      'Access-Control-Allow-Headers': '*'
    },

      body: JSON.stringify({
        "numbers": `91${sender}`,
        "message": {
          "text": `*Shipment Delivery* : Dear Customer, ${storeName} wants to deliver Food/Order at your door step, please click on below button to confirm  your delivery location,i.e we can locate your delivery address.`,
          "footer": "Thaks for Shipping with Queek Delever",
          "templateButtons": [
            {
              "urlButton": {
                "displayText": "Get a Delivery Location",
                "url": `https://queekdelever.com/#/QueekDelivery/${sender}`
              }
            },
            
           
           
          ]
        }
      }
       )
  };
  fetch('https://api.whatsspot.in/api/message/fast/text', requestOptions )
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(err => console.log('Error occur '+ err))
      

// empty dependency array means this effect will only run once (like componentDidMount in classes)

}


const backk =() => {
  props.next('mBooking')
}

const getCity =(e) => {
  if(e.length === 6)
{
  fetch(`https://queekdelever.com/queekApi/pincodeFetch.php?pincode=${e}`)
.then(response => response.json())
.then(data => { 
  city.current.value =data[0].city
  state.current.value =data[0].state
})
}
}

  return (
    <div className='position-relative'>
 <div className='tops d-flex justify-content-start ' >
            <span className='px-2'><BsChevronLeft size='22' color='black ' onClick={()=> {
              props.back('close')
              props.pickRefresh('Dashboard')
              }} /></span>
            <span className='from'>Sender Details</span>
        </div>

   


      {qr  && result !==null && <div>
      <QrReader
      delay={300} 
     onError ={error => {
       console.log(error)
     }}
     onScan ={data =>{
       if(data !== null)
      { setResult(data)
       setQr(false)
       setWait(true)
       xmltojson(data)
      }
     }}
     style={{width:'100%'}}
     legacyMode={false}
      />
{/* <p>{result === null ? 'null' :result }</p> */}
    </div> }
     


        {!isHide && !qr && <div className='px-3 mt-3'>
 <div className="form-floating mb-3">
       <input className={`form-control ${valid}`} ref={mob} id="floatingInput"   onChange={()=> {valids()}}  type='number' placeholder='Enter mobile or account number'/>
  <label htmlFor="floatingInput">Enter sender mobile number</label>
</div>

       <div className='d-flex justify-content-center mt-3'><button className='btn-success' onClick={() => {next()}} >Submit</button></div>
     
     <div className='my-5 text-center d-none'>Or</div>
     <div className='foodCnt cursor d-none' onClick={() =>back('foodStore') }>
       <div className='d-flex align-items-center  w-100 '>
       <div className='foodBorder mt- ps-2'> <img src={food} alt='' className='foodImg' /> </div>
      <div className='d-flex justify-content-between w-100'>
      <div className='text-dark ps-3 '>Food Delivery</div>
       <div className='pe-3'><HiOutlineArrowRight size='22' color='dark' /></div>
      </div>
       </div>
     </div>
      </div>
      }


    {isHide && !qr && <div className='px-3 mt-4 user-select-none'>
{/* upload id proff */}

<div className='d-flex justify-content-center align-items-center card   py-3 cursor bgYellow' onClick={()=>qrScan()} >
<div className='d-flex flex-column justify-content-center align-items-center'>
<div><AiOutlineCamera size ='30'/></div>
<div>Scan Addhar card </div>
</div>
</div>



<div className='py-4 text-center'>Or</div>

<div className="form-floating mb-3">
  <input ref={pName} type="text" className={`${nameErr}`} id="floatingInput"  placeholder="name@example.com"  />
  <label htmlFor="floatingInput">Person or company name</label>
</div>

<div className="form-floating mb-3">
  <input ref={Add} type="text" className={`${addErr}`} id="floatingInput" placeholder="name@example.com" />
  <label htmlFor="floatingInput">Address</label>
</div>

<div className="form-floating mb-3">
  <input ref={Pincode} onChange={(e) => getCity(e.target.value)} type="number" className={`${pinErr}`} id="floatingInput" placeholder="name@example.com" />
  <label htmlFor="floatingInput">Pincode</label>
</div>

<div className="form-floating mb-3">
  <input ref={city} type="text" className={`${cityErr}`} id="floatingInput" placeholder="name@example.com" />
  <label htmlFor="floatingInput">City</label>
</div>

<div className="form-floating mb-3">
  <input ref={state} type="text" className={`${stateErr}`} id="floatingInput" placeholder="name@example.com" />
  <label htmlFor="floatingInput">State</label>
</div>


<div className='d-flex justify-content-between position-relative'>
  <div className='card p-3 w-50 bgYellow d-flex justify-content-center align-items-center awb1 text-dark position-relative' onClick={()=>{ setIsVisible('Upload Aadhar Card') 
  setImageLoadF(true)
  setOpen(true)
  fetchKyc()
  } }>
  {  <div>Upload Aadhar Card</div>}
{senderDetail && senderDetail[0].addhar_f && senderDetail[0].addhar_b && <div className='position-absolute top-0 start-100 translate-middle'><span className='me-2 mt-3'><BsFillCheckCircleFill color='#008000' size='22' /></span></div>
}
  </div>
  <div className='px-2'></div>

  <div className='card bgYellow p-3 w-50 text-center d-flex justify-content-center align-items-center awb1 text-dark position-relative' onClick={()=>{ setIsVisible('Upload Pan card or passport')
setImageLoadF(true)
setOpen(true)
fetchKyc()
}}>
  
  <div>Upload Pan Card or Passport</div>
{senderDetail && senderDetail[0].passport_f  && <div className='position-absolute top-0 start-100 translate-middle'><span className='me-2 mt-3'><BsFillCheckCircleFill color='#008000' size='22' /></span></div>
}</div>
</div>

<div className='d-flex justify-content-center mt-4 w-100 '><button className='btn-success w-100' onClick={() => {next2()}} >Submit</button></div>
 
        </div>}
{wait && <div className='plsWait'><div className="spinner-border text-dark" role="status">
  <span className="sr-only"></span>
</div>
Please Wait...</div>}

<Drawer isVisible={open} >
  <div>
<div className='d-flex justify-content-between mt-2 user-select-none'>
{isVisible ==='Upload Aadhar Card' && <div className='text-center  text-muted'>{isVisible}</div> }
{isVisible !=='Upload Aadhar Card' && <div className='d-flex'>
  {/* <div onClick={()=>setCheck(1)}>  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={check ===1 ?true:false}  /><span className='px-2' >Passport</span></div> */}
  {/* <div onClick={() =>setCheck(2)} className='ps-3'>  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={check ===2 ?true:false} /><span className='px-2' >Pan Card</span></div> */}
 <div>Upload passport or pan card</div>
  </div>}
<button onClick={()=> {
  setOpen(false) 
  setImage('')
  fetch1()
}
} type="button" className="btn-close" aria-label="Close"></button>
</div>
 <div className='my-4'>


{!image && <div className='d-flex flex-column  justify-content-between'>
<div className='px-1' onClick={()=> isVisible==='Upload Aadhar Card'?setKycType('af'):setKycType('pf') } >


<div className='kycBorder position-relative'>
{/* <div className='position-absolute top-0 start-0 bg-warning px-1 rounded'>Upload Front Side</div> */}
{kyc && <div className= 'choose'>
 
          <CustomUploadButton
    accept="image/*"
    storageRef={firebase.storage().ref('images')}
    onUploadSuccess={handleUploadSuccess}
    onProgress={handleProgress}
    onUploadError={handleUploadError}
    onUploadStart={handleUploadStart}
  >
   <div className='d-flex flex-column align-items-center justify-content-center upload'>
  <div className={`${isVisible ==='Upload Aadhar Card'? kyc[0].addhar_f ?'d-none':"d-block" :""} ${isVisible ==='Upload Pan card or passport'? kyc[0].passport_f ?'d-none':"d-block" :""} `} >
    <div className='d-flex justify-content-center'><AiOutlineCloudUpload size='45' color='black' /></div>
   <div className=' text-dark my-2'>Upload front side</div></div>
   </div>
  </CustomUploadButton>
        </div>}

{/* prgress bar */}
       {showF &&  <div className=" prgress  ">
        <div className='mt-5'>
         <div className='progress  '>
           <div className="progress-bar progress-bar-striped bg-warning" role="progressbar" style={{width:`${progress}%`}}  aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
         </div>
         
<div className='text-center mt-1'>{progress}%</div>
</div>}

{/* ******************************** */}

{/* image loader */}

{imageLoadF &&  <div className='prgress text-center  bg-white  '> 
<div className="text-center mt-5">
  <div className="spinner-border" role="status">
    <span className="sr-only"></span>
  </div>
</div>
<div>Please wait</div>
</div>}
{/* ********************************************** */}
        
{!kyc && !showB && <div className=' '></div>}

       {/* <div className={`${kyc ?'d-block' :'d-none'}`}><img src={isVisible ==='Upload Aadhar Card'? kyc[0].addhar_f ? kyc[0].addhar_f :''  : kyc[0].passport_f ? kyc[0].passport_f :"" } alt ='' className='kycSize' onLoad={()=>console.log('loadded')} /></div> */}
        
       {isVisible ==='Upload Aadhar Card' && kyc &&  kyc[0].addhar_f && <div><img src={kyc && kyc[0].addhar_f}  className='kycSize' onLoad={()=>setImageLoadF(false)} /></div>}
      
       {isVisible ==='Upload Pan card or passport' && kyc &&  kyc[0].passport_f && <div><img src={kyc && kyc[0].passport_f}  className='kycSize' onLoad={()=>setImageLoadF(false)} /></div>}
      
        </div>
        </div>
      

   
   
  {check ===1 &&  <div className='px-1' onClick={()=> isVisible==='Upload Aadhar Card'?setKycType('ab'):setKycType('pb') } >
 
<div className='kycBorder position-relative mt-3'>
{/* <div className='position-absolute top-0 start-0 bg-warning px-1 rounded'>Upload Back Side</div> */}
{kyc[0] && <div className='choose'>
<CustomUploadButton
    accept="image/*"
    storageRef={firebase.storage().ref('images')}
    onUploadSuccess={handleUploadSuccess}
    onProgress={handleProgress}
    onUploadError={handleUploadError}
    onUploadStart={handleUploadStart}
  >
   <div className={`d-flex flex-column align-items-center justify-content-center upload `}>
   <div className={`${isVisible ==='Upload Aadhar Card' && kyc ? kyc[0].addhar_b ?'d-none':"d-block" :""} ${isVisible ==='Upload Pan card or passport'? kyc[0].passport_b ?'d-none':"d-block" :""} `} >
    <div className='d-flex justify-content-center'><AiOutlineCloudUpload size='45' color='black' /></div>
   <div className=' text-dark my-2'>Upload back side</div></div>
   </div>
  </CustomUploadButton>
        </div>}

{/* prgress bar */}
{showB &&  <div className=" prgress  ">
        <div className='mt-5'>
         <div className='progress  '>
           <div className="progress-bar progress-bar-striped bg-warning" role="progressbar" style={{width:`${progress}%`}}  aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
         </div>
         
<div className='text-center mt-1'>{progress}%</div>
</div>}

{/* ******************************** */}
{/* image loader */}

{imageLoadB &&  <div className='prgress text-center  bg-white  '> 
<div className="text-center mt-5">
  <div className="spinner-border" role="status">
    <span className="sr-only"></span>
  </div>
</div>
<div>Please wait</div>
</div>}
{/* ********************************************** */}
{/* ******************************** */}

        {isVisible ==='Upload Aadhar Card' && kyc &&  kyc[0].addhar_b && <div><img src={ kyc && kyc[0].addhar_b}  className='kycSize' onLoad={()=>setImageLoadB(false)} /></div>}
      
      {isVisible ==='Upload Pan card or passport' && kyc &&  kyc[0].passport_b && <div><img src={kyc && kyc[0].passport_b}  className='kycSize' onLoad={()=>setImageLoadB(false)} /></div>}
    

    
       
</div>


    </div>}
    </div>}

{!image && <div className='mb-3'></div>}
 </div>

 </div>
</Drawer>

    </div>
  )
}

import React ,{useState , useRef, useEffect} from 'react'
import { BsChevronLeft } from "react-icons/bs";
import { NormalButton } from '../../Components/NormalButton';
import { Spinner } from '../../Components/Spinner';
import { RateList } from './RateList.jsx';


export const Calculator = (props) => {
    const [type,setType] = useState('')
    const [valid,setValid] = useState('form-control')
    const pincode = useRef(null) 
    const [city,setCity] = useState('')
    const [showSpinner,setSpinner] = useState(null)
    const [dest,SetDest] = useState('')
    const wgt = useRef(null)
    const [sType,setSType] = useState('Dox')
    const [btn,setBtn] = useState(true)
    const [rate,setRates] = useState('')
    const [cnt,setCnt] = useState('')
    const dstCnt = useRef(null)
    const [dWgt,setDWgt] = useState('0.500')
    const [clicks,setClicks] = useState(false)

    let a=0;
    let b=0;


    const checkCity =(e) => {

      

        if(type === 'Domestic')
        {
          if(e.target.value.length > 6) 
        {
            setBtn(false)
          pincode.current.value= e.target.value.substring(0,6)
        return false
        }
      
         if(e.target.value.length ==6)
         {
          setSpinner(true)
          fetchPincode(e.target.value)
          setBtn(false)
         }
      
         if(e.target.value.length < 6) {
          setSpinner(false)
          setCity('')
          setValid('form-control')
    setBtn(true)
         }
         
        }
       }



       const fetchPincode =(e) => {
  
        fetch(`https://queekdelever.com/queekApi/check_pincode.php?pincode=${e}`)
        .then(response => response.json())
        .then(data => {
         
          if(data[0].status !== 'noService')
          {
            setValid('form-control is-valid')
            setSpinner(false)
            setCity(data[0].city)
             let lat = parseFloat(data[0].lat)
              let lng =parseFloat(data[0].lng)
              SetDest({lat,lng})

              if(sType === 'Dox')
              setBtn(false) 
            if(sType === 'Ppx')
            wgt.current.value > 0 ?   setBtn(false) : setBtn(true)
              }
            else {
              setValid('form-control is-invalid')
              setSpinner(false)
              setCity('Location is not serviceable')
              setBtn(true)
            }
        })
    
       }

    const pinCode =(e) => {
        if(e.length === 6)
       { setValid('form-control is-valid')
        }
        else
        setValid('form-control')
    }

    const back = () => props.back('close')

    const IntCnt =() => {
        fetch(`https://queekdelever.com/queekApi/country_new.php?search=`)
        .then(response => response.json())
        .then(data => setCnt(data))
    }


    const actWgt = (e) => {
       if(e > 0)
       wgt.current.value =  parseFloat(e).toFixed(3)
       else
       wgt.current.value =''
    }

  const submit =() => {
    setClicks(true)
    fetch(`https://www.queekdelever.com/queekApi/domestic_quote.php?swgt=${sType ==='Dox' ? dWgt : wgt.current.value}&pincode=${type ==='Domestic' ? pincode.current.value : dstCnt.current.value}&type=${sType}`)
    .then(response => response.json())
    .then(data => {
      setRates(data)
      setClicks(false)
    })
  }
    return (
        <div className=''>
            <div className={`d-flex justify-content-start parcelHeader  `}><div className="px-2 pe-4 back" onClick={back}><BsChevronLeft size="18" /> <span className='ps-2'>Rate Calculator</span> </div> </div>

          { !type && <div className='mt-5   d-flex flex-column justify-content-start align-items-center'>
                <div onClick={() => setType('Domestic') }  className='bgYellow w-100 mx-3 text-center p-4  rounded'>Domestic</div>
                <div onClick={() => {
                    setType('International')
                    IntCnt()
                    setBtn(false)
                     }} className ='bgYellow w-100 mx-3 text-center ps-4 p-4 rounded mt-3 ' >International</div>
            </div>}
            

           {type && <div className=''>
            {/* <div className='d-flex justify-content-between mt-3 mx-3'>
                <div onClick= {() =>{setSType('Dox')} } ><input type='radio' onChange={(e) => e.target.value} checked={sType ==='Dox' ?true : false}  /><span className='ps-2'>Document</span></div>
                <div onClick={ () => setSType('Ppx') }><input type='radio' checked={sType ==='Ppx' ? true : false } onChange={(e) => e.target.value}  /><span className='ps-2'>Parcel</span></div>
            </div> */}

            <div className="form-floating mx-3 mt-3">
  <select  onChange={(e)=> setSType(e.target.value)} className="form-select" id="floatingSelect" aria-label="Floating label select example">
    <option value="Dox">Document</option>
    <option value="Ppx">Parcel</option>
  </select>
  <label htmlFor="floatingSelect">Shipment Type</label>
</div> 

{type ==='Domestic' &&
                <div className=' position-relative mx-3 mt-3 '>
                <div className="form-floating mb-3">
            <input type="number" ref={pincode} className={`${valid} position-relative`} id="floatingInput" placeholder="Enter 6 Digit pincode" onChange={checkCity}  maxLength={5} />
            <label htmlFor="floatingInput">Enter 6 digit Pincode</label>
            </div>

            {/* spinner loader */}
            <div className={`position-absolute top-50 end-0 translate-middle-y pe-2 ${showSpinner ? "d-block":"d-none"} `}>
            <div className="spinner-border spinner-border-md"  id="spinnerr" role="status">
            <span className="visually-hidden"></span>
            </div> </div> 


            {/* city name */}
            <div className={`position-absolute top-50 end-0 translate-middle-y city ${city !== 'Location is not serviceable' ? 'text-success':'text-danger'}` }>{city}</div>
            </div>}

            {type !=='Domestic' && <div>
            <div className="form-floating mx-3 mt-4">
  <select ref={dstCnt} className="form-select" id="floatingSelect" aria-label="Floating label select example">
  <option value='Australia'>Australia</option>
  <option value='Canada'>Canada</option>
  <option value='Germany'>Germany</option>
  <option value='Italy'>Italy</option>
  <option value='United Kingdom'>United Kingdom</option>
  <option value='United States'>United States</option>

{cnt && cnt.map((x) => <option key={b=b+1} value={x.name}>{x.name}</option>)}  
  </select>
  <label for="floatingSelect">Select Country Name</label>
</div>
                </div>}

 {     sType==='Dox'     &&     <div className="form-floating mx-3 my-3">
  <select onChange={(e)=> setDWgt(e.target.value)} className="form-select" id="floatingSelect" aria-label="Floating label select example">
    <option value="0.500">0-0.500 gms</option>
    <option value="1.000">0.501-1.000 Kgs</option>
    <option value="1.500">1.001-1.500 Kgs</option>
    <option value="2.000">1.501-2.000 Kgs</option>

  </select>
  <label for="floatingSelect">Select weight slab</label>
</div> }

          {sType==='Ppx' && <div className="form-floating mb-3 mx-3 mt-3">
                <input type="number" ref={wgt} className="form-control" id="floatingInput" placeholder="name@example.com"  onChange={(e) => e.target.value > 0 ? setBtn(false) : setBtn(true) }  onBlur={(e) =>  actWgt(e.target.value)}  />
                <label htmlFor="floatingInput">Enter Weight (In Kg)</label>
            </div>}

            <div>


            </div>


            <div className='w-100' onClick={submit} ><NormalButton name='Submit' loading={clicks}   dim={btn} /> </div>

         <div className='mx-3 mt-5 '>
         {  rate && <div className={`ps-1 pe-2  ${rate.length >2 ?'rateWt' :'mt-0'}`}>

{  rate && rate.map((x) => <div  key={a=a+1}   className={` ${rate.length ===2 ? "mb-4" : ""}   `}  ><RateList  iconType={'By Air'} service={x.full_name} delTime={x.del_time} price={x.rate }     /></div>)}


{/* <div className='my-2'>  <button type="button" className="btn btn-outline-secondary">Book again </button></div>  */}

</div> } 
         </div>

            </div>}

        {type ==='International' && !cnt &&    <Spinner />}
        </div>

    )
}

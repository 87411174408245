import React,{useEffect} from "react";
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  DirectionsRenderer,
  Marker
} from "react-google-maps";
import { withProps, compose, lifecycle } from "recompose";
import { faMotorcycle,faMapPin } from "@fortawesome/free-solid-svg-icons";

const exampleMapStyles = [
  {
    featureType: "administrative.province",
    elementType: "labels",
    stylers: [
      { visibility: "off" }
    ]
  },
  
  {
    featureType: "poi",
    stylers: [
      { visibility: "on" }
    ]
  },
  {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
          {
              color: "#eeeeee",
          },
      ],
  },
  {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
          {
              visibility: "on",
          },
      ],
  },
  {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
          {
              color: "skyblue",
          },
      ],
  },
  {
    featureType: "poi.medical",
    elementType: "all",
    stylers: [
      { visibility: "off" }
    ]
  },
  {
    featureType: "landscape.man_made",
    elementType: "all",
    stylers: [
      { visibility: "off" }
    ]
  },{
    featureType: "poi.attraction",
    elementType: "all",
    stylers: [
      { visibility: "off" }
    ]
  },{
    featureType: "poi.business",
    elementType: "labels",
    stylers: [
      { visibility: "off" }
    ]
  },{
    featureType: "poi.government",
    elementType: "all",
    stylers: [
      { visibility: "off" }
    ]
  },{
    featureType: "poi.medical",
    elementType: "all",
    stylers: [
      { visibility: "off" }
    ]
  },{
    featureType: "poi.place_of_worship",
    elementType: "all",
    stylers: [
      { visibility: "off" }
    ]
  },{
    featureType: "poi.school",
    elementType: "all",
    stylers: [
      { visibility: "on" }
    ]
  },{
    featureType: "poi.sports_complex",
    elementType: "labels",
    stylers: [
      { visibility: "off" }
    ]
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color : "gray"
      }
    ]
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#fdfcf8"
      }
    ]
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#dfd2ae"
      }
    ]
  },

  {
    featureType: "administrative.neighborhood",
    elementType: "labels",
    stylers: [
      {
       visibility :"off"
      }
    ]
  },

  
  
];



const MapWithADirectionsRenderer = compose(



  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBah2C34AUXeAESdNgB5GoXKgk6XhlYhMU&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100vh` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap,
  lifecycle({
    
    
    UNSAFE_componentWillMount() {
  
      let DirectionsService = new window.google.maps.DirectionsService();
   
    //  let org = JSON.stringify(this.props.org)
   


      DirectionsService.route(
        {
          origin: new window.google.maps.LatLng(this.props.org[0].lat,this.props.org[0].lng),
          destination: new window.google.maps.LatLng(this.props.dest[0].lat,this.props.dest[0].lng),
          travelMode: window.google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            this.setState({
              directions: result
            });
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  })
)(props => (
  <GoogleMap
    defaultZoom={10}
    defaultCenter={new window.google.maps.LatLng(28.4595,77.0266)}
  
    options={{
      styles: exampleMapStyles,
      disableDefaultUI:true,
      gestureHandling: 'none',
  }}
  >
 {/* <Marker position={props.boy} /> */}

    {/* {props.directions  && props.update && <DirectionsRenderer directions={props.directions}
     options={{suppressMarkers: false,
      polylineOptions: {
          strokeOpacity: 0.7,
          strokeColor: '#000',
          strokeWeight: 3,
          geodesic: false,
          offset: "5%",
      }, }} 
  />} */}

    
{/* <Marker position={props.boy}   

icon = {{
  path: faMotorcycle.icon[4] ,
  fillColor: "  ",
  fillOpacity: 1,
  anchor: new window.google.maps.Point(
    faMotorcycle.icon[0] / 2, // width
    faMotorcycle.icon[1] // height
  ),
  strokeWeight: 1,
  strokeColor: "#ffffff",
  scale: 0.050,
  rotation: 30

}}
/> */}
  </GoogleMap>
));



export default MapWithADirectionsRenderer;

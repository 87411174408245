import React,{useEffect , useState} from 'react'
import { BsChevronLeft } from "react-icons/bs";
import { MdKeyboardArrowDown } from "react-icons/md";
import Dropdown from 'react-bootstrap/Dropdown';
import { RiMapPin2Line } from "react-icons/ri";
import { BsFillPrinterFill } from "react-icons/bs";




export const PrintZone = (props) => {
  const [printType,setPrintType] = useState('All')
  const [printData,setPrintData] = useState('')
  let a=0;

    const fetchApi =(e) => {
    
const url =`https://queekdelever.com/queekApi/printSticker.php?empId=${props.empId}&type=${e}`

      fetch(url)
      .then(response => response.json())
      .then(data => setPrintData(data))
    }

    useEffect(() => {
      fetchApi('')
    },[])
    const prints =() =>
    {
        window.print()
    }

    const back=() => {
      props.back('close')
    }

    const handleChange =(e) => {
      console.log(e)
    }

  return (
    <div className='bgGray vh-100'>
      <div className={`d-flex justify-content-between parcelHeader align-items-center`}><div className="px-2 pe-4 back" onClick={back}><BsChevronLeft size="18" /> <span className='ps-2'>Print</span> </div> <div className='pe-2'><div><Dropdown>
      <Dropdown.Toggle variant="" id="dropdown-basic">
        {printType}
      </Dropdown.Toggle>

      <Dropdown.Menu  >
        <Dropdown.Item  onClick={() =>{ setPrintType('Booking')
        fetchApi('Dispatched')
      }} >Booking</Dropdown.Item>
        <Dropdown.Item onClick={ () => {setPrintType('Pickup')
       fetchApi('Confirm')
      }} >Pickup</Dropdown.Item>
        <Dropdown.Item  onClick={ () => {setPrintType('All')
       fetchApi('')
      }}  >All</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown></div> </div></div>


        {/* <button onClick={prints}>Print</button> */}

        <div>
        <div className='d-flex flex-column justify-content-center mt-4 position-relative '>
        {printData && printData.map((x) =>   <div key={a=a+1} className='mx-auto mb-2 pendingAwb1 bg-white'>
        <div  className=''>
            <div className='d-flex align-items-center'>  <div className='badgeYellow pe-3 mt-1 ms-2'>{x.awb} </div> <div className='fName p-1'>{x.status ==='Dispatched' ?'BKG':'PUP'}</div></div>
              <div className='ps-2 mt-2  d-flex position-relative ' ><div className='px-2 '><RiMapPin2Line size='24' color='black' /></div><div className='twoLine delADS pe-2 '>{x.name === x.company ? x.name : x.name +', '+ x.company} , {x.add1 === x.add2 ? x.add1 : x.add1+', '+x.add2}</div><div className='pe-2'><div className='position-absolute top-50 end-0 translate-middle' ><span className='ps-2'><BsFillPrinterFill size='20' color='black'  /></span></div></div></div>
              <div className='ps-5 fName mt-1'>{x.city} - {x.pincode} {x.state ===''?'' :', '+x.state}, {x.country}</div>

            </div>
          </div>)}
          {/* <div className='position-absolute top-0 end-0  '><span className='pe-1'><BsFillPrinterFill size='20' color='#008000' /></span><span className='me-3 pt-2 fName'>  </span></div> */}

        </div>
        </div>

    </div>
  )
}

import React, { useEffect, useRef, useState } from "react";

import { BsChevronLeft } from "react-icons/bs";
import "./Search.css";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FiAlertTriangle, FiVolume2 } from "react-icons/fi";
import { BiDetail } from "react-icons/bi";
import axios from "axios";
import Drawer from "react-bottom-drawer";
import { RateList } from "../pages/Ebook/RateList.jsx";
import { FoodItem } from "./FoodItem.jsx";
import { HiUserRemove } from "react-icons/hi";
import { Spinner } from "./Spinner";


export const Search = (props) => {
  const [open, setOpen] = useState(false);
  const Serch = useRef(null)
  const item = useRef(null)
  const [content, setContent] = useState('')
  const [Load, setLoad] = useState('')
  const [contSearch, setTypeAhead] = useState('')
  const [hide, setHide] = useState(false)
  const [dis, setDisable] = useState(false)
  const [wgtLabel, setLabl] = useState('Weight')
  const [hideTerm, setTerm] = useState(false)
  const [locals, setLocal] = useState(null)
  const [km, setKms] = useState(0)
  const [select, setSelect] = useState('1')
  const [wgtChg, setWgtChg] = useState('')
  const [inital, setInital] = useState(1)
  const [wgtErr, setWgtErr] = useState('form-control')
  const [cntErr, setCntErr] = useState('form-control')
  const [isVisible, setIsVisible] = useState(false)
  const [Vol, setVol] = useState(false)
  const [isBox, setBox] = useState(false)
  const [boxNumber, setBoxNo] = useState(0)
  const [valErr, setValErr] = useState('form-control')
  const box = useRef(null)
  const desc = useRef(null)
  const [wgtErr2, setWgtErr2] = useState('form-control')
  const [wgtErr3, setWgtErr3] = useState('form-control')
  const [sType,setSType] = useState('')
  const [network,setNetwork] = useState('')
  const [cost,setCost] = useState('')

  const [lErr, setLErr] = useState('form-control')
  const [lErr2, setLErr2] = useState('form-control')
  const [lErr3, setLErr3] = useState('form-control')

  const [wErr, setWErr] = useState('form-control')
  const [wErr2, setWErr2] = useState('form-control')
  const [wErr3, setWErr3] = useState('form-control')

  const [hErr, setHErr] = useState('form-control')
  const [hErr2, setHErr2] = useState('form-control')
  const [hErr3, setHErr3] = useState('form-control')
  const [rateShow, setRateShow] = useState(false)
  const [loader,setLoader] = useState(false)
  const [parDetails,setParDetails] = useState()

  const parVal = useRef(null)
  const rate = useRef(null)
  const actWgt = useRef(null)
  const des= useRef(null)

  let wgt = useRef(null)
  let wgt2 = useRef(null)
  let wgt3 = useRef(null)

  let l1 = useRef(null)
  let l2 = useRef(null)
  let l3 = useRef(null)

  let w1 = useRef(null)
  let w2 = useRef(null)
  let w3 = useRef(null)

  let h1 = useRef(null)
  let h2 = useRef(null)
  let h3 = useRef(null)

  const kms = async () => {

    let org = props.org.lat + '%2C' + props.org.lng
    let dst = props.dst.lat + '%2C' + props.dst.lng

    const response = await axios.get(`https://easy-ship.in/local_kms.php?org=${org}&dst=${dst}`)
    if (response.data) {
      // console.log(response.data)
      setKms(response.data.rows[0].elements[0].distance.value)
    }
  }

let a=0;

  const local =  (e,dst,mode) => {
    let url = ''
  a=0;

  if(!props.awb)
  {
 if (props.serType === 'Local')
      url = (`https://www.easy-ship.in/local_quote.php`)
    if (props.serType === 'Domestic')
      url = (`https://www.queekdelever.com/queekApi/domestic_quote.php?swgt=${e}&pincode=${props.pincode}&type=${props.item === 'documents' ? 'Dox' : 'Ppx'}`)
    if (props.serType === 'International')
      url = (`https://www.queekdelever.com/queekApi/domestic_quote.php?swgt=${e}&pincode=${props.country}&type=${props.item === 'documents' ? 'Dox' : 'Ppx  '}`)
  }
  else {
    url = (`https://www.easy-ship.in/local_quote.php`)
    if (props.serType === 'Domestic')
      url = (`https://www.queekdelever.com/queekApi/domestic_quote.php?swgt=${e}&pincode=${dst}&type=Ppx`)
    if (props.serType === 'International')
      url = (`https://www.queekdelever.com/queekApi/domestic_quote.php?swgt=${e}&pincode=${dst}&type=Ppx`)

  }

  
    fetch(url)
    .then(response => response.json() )
    .then (data => {
      setLocal(data)
      setRateShow(true)
      setWgtChg(e)
  data.map((x) => 
  {
    if(x.network === mode)
    setSelect(x.sr)
  }
  )
    })
  }




  useEffect(() => {
    //   setLocal('')
    if (props.serType === 'Local') { kms() }



  }, [props.serType])

  useEffect(() => {
    item.current.value = props.item
    if (props.item !== '') {
      setDisable(true)

      if (props.item === 'Clothes') {
        // wgt.current.value = "0.100"
        desc.current.value='Clothes'
    
      }

    


      if (props.item === 'Food Item') {
        setLabl('Quantity')
      }

    }
    else {
      setDisable(false)
      wgt.current.value = ""
    }
  }, [props.item])

  useEffect(() => {
    const loadLocation = async () => {
      const response = await axios.get(`https://www.easy-ship.in/content.php?item=${contSearch}`)
      if (response.data !== 'notFound') {

        setContent(response.data)
        setLoad('done')
      }
      else {
        setContent('')

      }
    }
    loadLocation();

  }, [contSearch])

  const Select = (e) => {

    //  props.Weigt(Math.random())
    setHide(false)
    item.current.value = e
    //  props.back('close');
  }

  const Back = () => {
    props.back('close');
    props.Weigt(0)
  }

  const typeAhead = (e) => {

    if (e.target.value.length > 0) {
      setTypeAhead(e.target.value)
      setHide(true)
    }
    if (e.target.value.length <= 0) {
      setTypeAhead('')
      setHide(false)
    }
  }

  const back = () => {
if(props.awb)
{
    props.back('checkout')
  }
    else
    props.back('close')
  }


useEffect(() => {
  console.log(props.item)
if(props.awb)
{ setLoader(true)
  updateParcelDetail()}
  else
  {
    setSType(props.item)}
},[])

const updateParcelDetail =() => {
  fetch(`https://www.queekdelever.com/queekApi/updateShipmentDetails.php?awb=${props.awb}`)
  .then(response => response.json())
  .then(data => {
    setParDetails(data)
    setNetwork(data[0].network)
    setLoader(false)
    setSType(data[0].type)
    parseInt(data[0].box) >  0 ? setBox(true) : setBox(false)
    parseInt(data[0].box) > 0 ? setBoxNo(parseInt(data[0].box)) : setBoxNo(0)
    
    parseInt(data[0].box) > 0 ? packing.current.selectedIndex =1 : packing.current.selectedIndex=0
    parseInt(data[0].box) > 0 ? box.current.selectedIndex = parseInt(data[0].box)-1 :
  

    l1.current.value=data[0].l1
    l2.current.value=data[0].l2
    l3.current.value=data[0].l3

    w1.current.value=data[0].w1
    w2.current.value=data[0].w2
    w3.current.value=data[0].w3

    h1.current.value=data[0].h1
    h2.current.value=data[0].h2
    h3.current.value=data[0].h3

    parVal.current.value= data[0].dec_value
    if(data[0].type ==='parcel' )
    desc.current.value = data[0].content
    
    if(data[0].type ==='gift' )
    des.current.value = data[0].content

    if(data[0].type ==='medicine')
    des.current.value = 'Medicine'

    if(data[0].type ==='document')
    des.current.value = 'Document'

   if( parseInt(data[0].box) > 0 )
   {

    wgt.current.value =parseFloat(data[0].wgt).toFixed(3)
    if(parseInt(data[0].box) > 1)
    wgt2.current.value =parseFloat(data[0].wgt2).toFixed(3)
    if(parseInt(data[0].box) === 3)
    wgt3.current.value =parseFloat(data[0].wgt3).toFixed(3)}
   else
{ actWgt.current.value =parseFloat(data[0].wgt).toFixed(3)}

     local(data[0].tWgt,data[0].dst,data[0].network)

  })
}

  const next = () => {
  

    setWgtErr('form-control')
    setCntErr('form-control')
    setValErr('form-control')

    if (props.userType !== 'Employee') {
      if (actWgt.current.value === '' || actWgt.current.value <= 0)
        setWgtErr('form-control is-invalid')

      if (desc.current.value ===  'What are you sending?')
        setCntErr('form-control is-invalid')

      if(props.serType ==='Domestic' && sType === 'parcel')
     { if (parVal.current.value === '' || parVal.current.value === 0)
        setValErr('form-control is-invalid')}

      if (actWgt.current.value === '' || actWgt.current.value === 0) { return }

      if (desc.current.value === 'What are you sending?') { return }
      if(props.serType === 'Domestic')
     { if (parVal.current.value === '' || parVal.current.value === 0) { return }}
    }

    if (props.userType === 'Employee') {
      validate()
      }

      if(sType === 'parcel')
      {

        if(desc.current.value ==='What are you sending?' || desc.current.value ==='' )
        return
      }

      if(sType === 'gift')
      {
    if(des.current.value ==='What are you sending?' || des.current.value ==='' )
        return
      }
      
      if(props.serType === 'Domestic' && sType !=='document')
      {if((parVal.current.value) ==='' || parVal.current.value ===0 )   
      return}

      if(props.awb)
     return upateShip()


  let freight =((locals[select-1].rate).includes(',') ) ?( locals[select-1].rate).split(',').join('') : locals[select-1].rate

    const booking = [{
      user: props.emp
      , dst: props.dst
      , dstLocation: props.delLocation
      , wgt: packing.current.value ==='Box' ? wgtChg :  actWgt.current.value
      , content: desc.current.value
      , service: props.serType === 'Local' ? locals[select - 1].service : locals[select - 1].network
      , rate: props.serType === 'Local' ? parseFloat(Math.round(km / 1000) * locals[select - 1].per_km) : freight
      , pincode: props.pincode
      , city: props.state
      , country: props.country
      , receiver: props.skip
      ,l1: l1.current.value
      ,l2 :l2.current.value
      ,l3 : l3.current.value
      ,w1: w1.current.value
      ,w2 :w2.current.value
      ,w3 : w3.current.value
      ,h1: h1.current.value
      ,h2 :h2.current.value
      ,h3 : h3.current.value
      ,box : boxNumber
      ,type : props.item
    }]


    props.booking(booking)

    if (props.skip.length > 4) {
      addShipment(booking)
      props.finalCheck(false)
      props.back('checkout')
    }
    else
      props.delivery('delAddress')
  
  }

  const addShipment = async (booking) => {
    if(props.serType === 'Domestic')
{    const response = await axios.get(`https://queekdelever.com/queekApi/addShipment.php?sender=${props.emp}&receiver=${props.skip}&rate=${booking[0].rate}&service=${booking[0].service}&wgt=${booking[0].wgt}&content=${booking[0].content}&l1=${booking[0].l1}&w1=${booking[0].w1}&h1=${booking[0].h1}&l2=${booking[0].l2}&w2=${booking[0].w2}&h2=${booking[0].h2}&l3=${booking[0].l3}&w3=${booking[0].w3}&h3=${booking[0].h3}&box=${booking[0].box}&type=${booking[0].type}&decValue=${parVal.current.value}`)
}  else
{  const response = await axios.get(`https://queekdelever.com/queekApi/addShipment.php?sender=${props.emp}&receiver=${props.skip}&rate=${booking[0].rate}&service=${booking[0].service}&wgt=${booking[0].wgt}&content=${booking[0].content}&l1=${booking[0].l1}&w1=${booking[0].w1}&h1=${booking[0].h1}&l2=${booking[0].l2}&w2=${booking[0].w2}&h2=${booking[0].h2}&l3=${booking[0].l3}&w3=${booking[0].w3}&h3=${booking[0].h3}&box=${booking[0].box}&type=${booking[0].type}`)
}
  }

  const showRates = (e) => {
    setOpen(e)
    setTerm(e)
  }
  const hideCollapse = () => setOpen(false)

  const selectRate = (e,net) => {
  setSelect(e)
    setInital('')
setNetwork(net)
  }

  const wgtRefresh = (e) => {
    wgt.current.value = (parseFloat(e.target.value).toFixed(3))
    // actWeigthCal(e.target.value)
  }

  const parcelDetail = () => {
    setIsVisible(true)
  }
// 
  const packing = useRef(null)

  const packType = () => {
    setRateShow(false)


    if (packing.current.value === 'Box') {
      setBox(true)
      setBoxNo(1)
      parVal.current.value =''

    }
    else {  
      setBoxNo(0)
      setBox(false)
      if(props.awb)
{
  // setLoader(true)
  // updateParcelDetail()
}
    }
  }
  const validate = () => {

    setWgtErr('form-control')
    setWgtErr2('form-control')
    setWgtErr3('form-control')

    setLErr('form-control')
    setWgtErr2('form-control')
    setWgtErr3('form-control')

    setWErr('form-control')
    setWErr2('form-control')
    setWErr3('form-control')

    setHErr('form-control')
    setHErr2('form-control')
    setHErr3('form-control')
    setWgtErr('form-control')

    setValErr('form-control')
    setCntErr('form-control')

    if (props.userType === 'Employee') {
      if (packing.current.value === 'Box') {
        //  box no 1 validation
        if (parseInt(boxNumber) > 0) {
          if (wgt.current.value === '' || wgt.current.value === 0)
            setWgtErr('form-control is-invalid')
          if (l1.current.value === '' || l1.current.value === 0)
            setLErr('form-control is-invalid')
          if (w1.current.value === '' || w1.current.value === 0)
            setWErr('form-control is-invalid')
          if (h1.current.value === '' || h1.current.value === 0)
            setHErr('form-control is-invalid')
        }
        //  box no 2 validation
        if (parseInt(boxNumber) > 1) {
          if (wgt2.current.value === '' || wgt2.current.value === 0)
            setWgtErr2('form-control is-invalid')
          if (l2.current.value === '' || l2.current.value === 0)
            setLErr2('form-control is-invalid')
          if (w2.current.value === '' || w2.current.value === 0)
            setWErr2('form-control is-invalid')
          if (h2.current.value === '' || h2.current.value === 0)
            setHErr2('form-control is-invalid')
        }

        //  box no 3 validation
        if (parseInt(boxNumber) > 2) {
          if (wgt3.current.value === '' || wgt3.current.value === 0)
            setWgtErr3('form-control is-invalid')
          if (l3.current.value === '' || l3.current.value === 0)
            setLErr3('form-control is-invalid')
          if (w3.current.value === '' || w3.current.value === 0)
            setWErr3('form-control is-invalid')
          if (h3.current.value === '' || h3.current.value === 0)
            setHErr3('form-control is-invalid')
        }
      }
    }

    if(sType === 'parcel')
    {
      if (desc.current.value === 'What are you sending?' || desc.current.value ==='')
      setCntErr('form-control  is-invalid')
    }

    if(sType === 'gift')
    {
      if (des.current.value === 'What are you sending?' || des.current.value ==='')
      setCntErr('form-control  is-invalid')
    }

    if(props.serType === 'Domestic' && sType !=='document')
    {
    if (parVal.current.value === '' || parVal.current.value === 0)
      setValErr('form-control is-invalid')
    }
      

    if (props.userType !== 'Employee') {
      if (actWgt.current.value === '' || actWgt.current.value === 0)
        setWgtErr('form-control is-invalid')

    }

  }
  const boxNo = () => {
    // wgt.current.value =''
    wgt2.current.value =''
    wgt3.current.value =''
    // l1.current.value=''
    l2.current.value=''
    l3.current.value=''
    // w1.current.value=''
    w2.current.value =''
    w3.current.value =''
    // h1.current.value =''
    h2.current.value=''
    h3.current.value =''
    setBoxNo(box.current.value)
    setRateShow(false)
  }

  const Desc = () => {
    // console.log(desc.current.value)
  }


  const Rates = (e) => {
    actWgt.current.value =parseFloat(e).toFixed(3)
    setRateShow(false)
    if (e > 0) 
   { if(!props.awb)
      local(actWgt.current.value)
    else
    local(actWgt.current.value,parDetails[0].dst)
    }
    
 }

  const actWeigthCal = () => {
    parVal.current.value  = ''
    setRateShow(false)
    let wt1=0
    let wt2 =0
    let wt3=0
    // if (parseInt(boxNumber) === 1 )
          // Box Number 1 Dimensions
     

      if (wgt.current.value > 0 && l1.current.value > 0 && w1.current.value > 0 && h1.current.value > 0) {
        let vol1 = (l1.current.value * w1.current.value * h1.current.value) / 5000
        if (vol1 > wgt.current.value)   
        wt1= vol1.toFixed(3)
        else
        wt1=wgt.current.value
      }

    

    // if (parseInt(boxNumber) === 2 )
    
       // ************************* box number 1 **************************
    
      
      // ****************************************
      // ************************* box number 2 **************************
      if ((wgt2.current.value) > 0 && parseInt(l2.current.value) > 0 && parseInt(w2.current.value) > 0 && parseInt(h2.current.value) > 0)
       {
        let vol2 = (parseInt(l2.current.value) * parseInt(w2.current.value) * parseInt(h2.current.value)) / 5000
        if (vol2 > wgt2.current.value)
        wt2= (wt1)+(vol2)
        else
        wt2=(parseFloat(wt1)+ parseFloat(wgt2.current.value)).toFixed(3)
      }
          
      // ****************************************
       // ************************* box number 3 **************************
       if ((wgt3.current.value) > 0 && parseInt(l3.current.value) > 0 && parseInt(w3.current.value) > 0 && parseInt(h3.current.value) > 0) {
        let vol3 = (parseInt(l3.current.value) * parseInt(w3.current.value) * parseInt(h3.current.value)) / 5000
        if (vol3 > wgt3.current.value)
          wt3=((wt2)+(vol3)).toFixed(3)
        else
         wt3=(parseFloat(wgt3.current.value)+parseFloat (wt2)).toFixed(3)
      }
    

    if(parseInt(boxNumber) ===1)
{  if(!props.awb)
  wt1 && local(wt1)
  else
  wt1 && local(wt1,parDetails[0].dst)  }

    if(parseInt(boxNumber) ===2)
   { if(!props.awb)
   wt2 && local(wt2)
   else
   wt2 && local(wt2,parDetails[0].dst)  }


    if(parseInt(boxNumber) === 3)
    
    { if(!props.awb)
      wt3 && local(wt3)
      else
      wt3 && local(wt3,parDetails[0].dst)
      }
  } 

  const upateShip = async() => {

    let wtt =''

 if(parseInt(boxNumber) > 0)
 wtt =wgt.current.value
 else 
 wtt = actWgt.current.value


 const resonse =await axios.post(`https://queekdelever.com/queekApi/UpdateShipment.php?awb=${props.awb}&box=${boxNumber}&wgt=${wtt}&l1=${l1.current.value}&w1=${w1.current.value}&h1=${h1.current.value}&rate=${locals[select-1].rate}&mode=${network}&content=${sType ==='parcel' ? desc.current.value : des.current.value}&decValue=${parVal.current.value}&wgt2=${wgt2.current.value}&l2=${l2.current.value}&w2=${w2.current.value}&h2=${h2.current.value}&wgt3=${wgt3.current.value}&l3=${l3.current.value}&w3=${w3.current.value}&h3=${h3.current.value}`)
 if(resonse.status === 200)
 {
  props.back('checkout')
  props.users(parDetails[0].sender)
 }
  }

  return (


    <>
      <div className={`parcelHeader  ${props.item === 'Food Item' ? 'parcelHt' : ''} `}><span className="px-2 pe-4 back" onClick={back}><BsChevronLeft size="18" /></span > <div className="d-flex flex-column"><div className="store text-muted"></div><div className="pdLoc1 fs-6 text-capitalize">{props.item} Details</div></div></div>

      <div className="position-relative background-clr vh-100  scroll1-y ">
        <div className="  px-2  ">
          <div className="d-flex flex-column align-items-center mt-1 ">

            <div className={`w-100  user-select-none ${props.item === 'Food Item' ? '' : 'mt-2'} `}>

              <div className={`d-flex  ${props.userType === 'Employee' ? 'd-block' : 'd-none'}`}>
                <div className={`form-floating  ${isBox ? 'w-50' : 'w-100'} `}  >
                  <select ref={packing} disabled={props.item ==='document' ? true : false}  onChange={packType} className="form-select " id="floatingSelect" aria-label="Floating label select example">
                    {/* <option  selected>Select Packaging type</option> */}
                    <option  defaultValue={'Envelope'} value="Envelope">Envelope/Flyer</option>
                    <option value="Box">Box</option>
                  </select>
                  <label forhtml="floatingSelect">Packaging Type</label>
                </div>


                {isBox && <div className={`form-floating ps-2 ${isBox ? 'w-50' : 'd-none'} `} >
                  <select ref={box} onChange={boxNo} className="form-select " id="floatingSelect" aria-label="Floating label select example">
                    {/* <option  selected>Select Packaging type</option> */}
                    <option defaultValue={'1'} value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>

                  </select>
                  <label forhtml="floatingSelect">Number of Box</label>
                </div>}

              </div>

              {!isBox && <div className={`col-md mt-1 ${props.userType === 'Employee' ? isBox ? 'd-none' : 'd-block' : 'd-block'}`}>
                <div className="form-floating">
                  <input type="number" ref={actWgt} onChange={() => {
                     if(props.serType === 'Domestic')
                     parVal.current.value=''

                  }
                     } onBlur={(e) =>Rates(e.target.value)} className={`${wgtErr}`} id="floatingInputGrid" placeholder="Parcel weight" />
                  <label forhtml="floatingInputGrid">Estimated weight (In KG)</label>
                </div>
              </div>}

              <div className="mt-1">
                <div className={`col-md d-none ${props.item === 'Food Item' ? 'd-none' : 'd-block'}  `}>
                  <div className="form-floating">
                    <input type="number" ref={wgt} onBlur={wgtRefresh} className={`${wgtErr}`} id="floatingInputGrid" placeholder="Parcel weight" />
                    <label forhtml="floatingInputGrid">Actual weight</label>
                  </div>
                </div>

                {/* box number  1 */}
                <div className={`${parseInt(boxNumber) > 0 ? 'd-block' : "d-none"}`}>
                  <div className="d-flex justify-content-between">
                    <div className="boxLabel ps-1">Box No -1 </div>
                    <div className="boxLabel text-white rounded bg-dark px-2">(Dimension in Cms)</div>
                  </div>
                  <div className="d-flex mt-1">
                    <div className={`col-md ${props.item === 'Food Item' ? 'd-none' : 'd-block'}  `}>
                      <div className="form-floating">
                        <input type="number" ref={wgt} onChange={actWeigthCal} onBlur={wgtRefresh} className={`${wgtErr}`} id="floatingInputGrid" placeholder="Parcel weight" />
                        <label forhtml="floatingInputGrid">Weight</label>
                      </div>
                    </div>
                    <div className={`col-md ps-1 `}>
                      <div className="form-floating">
                        <input type="number" ref={l1} onBlur={actWeigthCal} className={`${lErr}`} id="floatingInputGrid" placeholder="Parcel weight" />
                        <label forhtml="floatingInputGrid">Length</label>
                      </div>
                    </div>

                    <div className={`col-md px-1 `}>
                      <div className="form-floating">
                        <input type="number" ref={w1} onBlur={actWeigthCal} className={`${wErr}`} id="floatingInputGrid" placeholder="Parcel weight" />
                        <label forhtml="floatingInputGrid">Width</label>
                      </div>
                    </div>

                    <div className={`col-md  `}>
                      <div className="form-floating">
                        <input type="number" ref={h1} onBlur={actWeigthCal} className={`${hErr}`} id="floatingInputGrid" placeholder="Parcel weight" />
                        <label forhtml="floatingInputGrid">Height</label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Box No 2 */}
                <div className={`${parseInt(boxNumber) >= 2 && isBox ? 'd-block' : 'd-none'}`}>
                  <div className="d-flex justify-content-between">
                    <div className="boxLabel ps-1">Box No -2 </div>
                    <div className="boxLabel text-white rounded bg-dark px-2">(Dimension in Cms)</div>
                  </div>
                  <div className="d-flex mt-1">
                    <div className={`col-md ${props.item === 'Food Item' ? 'd-none' : 'd-block'}  `}>
                      <div className="form-floating">
                        <input type="number" ref={wgt2} onChange={actWeigthCal} onBlur={(e) => { wgt2.current.value = parseFloat(e.target.value).toFixed(3) }} className={`${wgtErr2}`} id="floatingInputGrid" placeholder="Parcel weight" />
                        <label forhtml="floatingInputGrid">Weight</label>
                      </div>
                    </div>
                    <div className={`col-md ps-1 `}>
                      <div className="form-floating">
                        <input type="number" ref={l2} onBlur={actWeigthCal} className={`${lErr2}`} id="floatingInputGrid" placeholder="Parcel weight" />
                        <label forhtml="floatingInputGrid">Length</label>
                      </div>
                    </div>

                    <div className={`col-md px-1 `}>
                      <div className="form-floating">
                        <input type="number" ref={w2} onBlur={actWeigthCal} className={`${wErr2}`} id="floatingInputGrid" placeholder="Parcel weight" />
                        <label forhtml="floatingInputGrid">Width</label>
                      </div>
                    </div>

                    <div className={`col-md  `}>
                      <div className="form-floating">
                        <input type="number" ref={h2}   onBlur={actWeigthCal} className={`${hErr2}`} id="floatingInputGrid" placeholder="Parcel weight" />
                        <label forhtml="floatingInputGrid">Height</label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* box no 3 */}
                <div className={`${parseInt(boxNumber) === 3 && isBox ? 'd-block' : 'd-none'}`}>
                  <div className="d-flex justify-content-between">
                    <div className="boxLabel ps-1">Box No -3</div>
                    <div className="boxLabel text-white rounded bg-dark px-2">(Dimension in Cms)</div>
                  </div>

                  <div className="d-flex mt-1">
                    <div className={`col-md ${props.item === 'Food Item' ? 'd-none' : 'd-block'}  `}>
                      <div className="form-floating">
                        <input type="number" ref={wgt3}  onChange={actWeigthCal}   onBlur={(e) => { wgt3.current.value = parseFloat(e.target.value).toFixed(3) }} className={`${wgtErr3}`} id="floatingInputGrid" placeholder="Parcel weight" />
                        <label forhtml="floatingInputGrid">Weight</label>
                      </div>
                    </div>
                    <div className={`col-md ps-1 `}>
                      <div className="form-floating">
                        <input type="number" ref={l3} onBlur={actWeigthCal} className={`${lErr3}`} id="floatingInputGrid" placeholder="Parcel weight" />
                        <label forhtml="floatingInputGrid">Length</label>
                      </div>
                    </div>

                    <div className={`col-md px-1 `}>
                      <div className="form-floating">
                        <input type="number" ref={w3} onBlur={actWeigthCal} className={`${wErr3}`} id="floatingInputGrid" placeholder="Parcel weight" />
                        <label forhtml="floatingInputGrid">Width</label>
                      </div>
                    </div>

                    <div className={`col-md  `}>
                      <div className="form-floating">
                        <input type="number" ref={h3}  onBlur={actWeigthCal} className={`${hErr3}`} id="floatingInputGrid" placeholder="Parcel weight" />
                        <label forhtml="floatingInputGrid">Height</label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ******************************************************* */}

              </div>

                {<div className={`col-md mt-1 ${props.serType ==='Domestic' && props.item !== 'document' ? 'd-block' :'d-none'}`}>
                  <div className="form-floating">
                    <input type="number" ref={parVal} className={`${valErr}`} id="floatingInputGrid" placeholder="Parcel weight" />
                    <label forhtml="floatingInputGrid">Declared Value for Insurance (In INR)</label>
                  </div>
                </div>}

              <div className={`col-md mt-1 ${props.item === 'Food Item' ? 'd-none' : 'd-block'}  `}>



               { <div className={`form-floating  ${ props.userType ==='Employee' ? sType && sType === 'parcel'? 'd-block' : 'd-none' : 'd-block' }`}>
                  <select className={`${cntErr} `}   ref={desc} onChange={Desc} id="floatingSelectGrid" aria-label="Floating label select example">
                    <option defaultValue={'What are you sending?'}>What are you sending?</option>
                    <option value="Artificial Jewellery">Artificial Jewellery</option>
                    <option value="Auo/ Machine Part">Auto/Machine Parts</option>
                    <option value="Bags">Bags</option>
                    <option value="Body Cover">Body Cover</option>
                    <option value="Bags">Books</option>
                    <option value="Cables / Wires (USB) ">Cables / Wires (USB)</option>
                    <option value="Charger">Charger</option>
                    <option value="Chocolate /Sweets (Dry Only)">Chocolate /Sweets (Dry Only)</option>
                    <option value="Clothes">Clothes</option>
                    <option value="Computer Parts/ Peripherals">Computer Parts/ Peripherals</option>
                    <option value="Credit/ Debit Card (In Active)">Credit/ Debit Card (In Active)</option>
                    <option value="Dry Fruit">Dry Fruit</option>
                    <option value="Earphones/Headphones/EarPods">Earphones/Headphones/EarPods</option>
                    <option value="Electric/Electronic Item">Electric/Electronic Item</option>
                    <option value="Medical Equipment">Medical Equipment</option>
                    <option value="Medicine (Tablets only)">Medicine (Tablets only)</option>
                    <option value="Mobile (Without Battery)">Mobile (Without Battery)</option>
                    <option value="Painting/ Artwork">Painting/Artwork</option>
                    <option value="Pen Drive/Usb storage">Pen Drive/Usb storage</option>
                    <option value="Plastic Item">Plastic Item</option>
                    <option value="Promotional Material (Papers)">Promotional Material (Papers)</option>
                    <option value="Seeds">Seeds</option>
                    <option value="Shoes">Shoes</option>
                    <option value="Slippers">Slippers</option>
                    <option value="Spices">Spices</option>
                    <option value="Stationery">Stationery</option>
                    <option value="Surgical Parts">Surgical Parts</option>
                    <option value="Toys">Toys</option>
                    <option value="Unstitched Fabric / Saree">Unstitched Fabric / Saree</option>

                  </select>
                  <label forhtml="floatingSelectGrid">Description of Content</label>
                </div>

}
                    

                {sType && sType !== 'parcel' && <div className="form-floating mt-1 ">
                  <input type="text"  ref={des}   className={`${cntErr} `} id="floatingPassword"  placeholder="Password"  disabled={sType ==='gift'? false:true} />
                  <label forhtml="floatingPassword">Description of Content</label>
                </div>}
                

                

                <div className="form-floating d-none ">
                  <input onChange={typeAhead} onClick={() => parcelDetail()} ref={item} type='' className="form-control" disabled={dis} id="floatingInput" placeholder="eg clothes" />


                  <label forhtml="floatingInput">{props.item === 'Food Item' ? 'Categories' : 'What are you sending?'}</label>




                  {hide ?
                    <>
                      <div className={` listCont  px-2   ${content === '' ? 'd-none' : ' d-block'}`}>
                        <ul className="listStyle  LocationList" ref={Serch} >

                          {content ? (content.map(x => <div  > <li onClick={() => Select(x.name)} >{x.name}</li>
                            <hr className="my-1" />
                          </div>)) : ""}
                        </ul>
                      </div></> : ""
                  }
                </div>

              </div>

              {/* food item content details */}
              <div className={`${props.item === 'Food Item' ? 'd-block' : 'd-none'}`}>
                {/* <div className="label-fnt mt-4 mb-2 ">
  What do you want to pickup/deliver (eg. burger etc.)
 </div> */}





              </div>

              {props.userType !== 'Employee' && <div className={`mt-3 wgtTerms  ps-1  d-flex align-items-center ${props.serType !== 'Local' ? 'd-block' : 'd-none'}  `}>
                <span className="ps-3"><FiAlertTriangle size='18' /></span>
                <div className="ps-3">Actual or Volumetric weight whichever is HIGHER  should be taken. <br />
                  <div className="mt-2"> <b>Volumetirc Calcuation (in cms): </b> <span className="ps-2">L X W X H /5000  </span></div></div>
                <div className="leftB bg-dark"></div>
              </div>}

              {rateShow && <div className={`ps-2 pt-3  pb-0 label-fnt mt-4 ${props.item === 'Food Item' ? 'd-none' : 'd-block'} `}><span className="px-2">Shipping charges & Transist time</span> </div>
              }

              <div className={`${rateShow ? 'd-block' : 'd-none'}`}>
                
                { props.item !== 'Food Item' ? locals ? locals.map((x) =>  <div key={a=a+1} className="mt-3 " onClick={() => { selectRate(x.sr,x.network) }    }><RateList  iconType={props.serType ==='Local' ? 'Scooter' : props.serType ==='Domestic' ? x.logo :'By Air'}  service={props.serType === 'Local' ? x.service : x.full_name} delTime={x.del_time} price={props.serType === 'Local' ? parseFloat(Math.round(km / 1000) * x.per_km) : x.rate} active={!props.awb ? inital === x.sr ? true : select === x.sr ? true :false : x.network === network ? true :false  }     /></div>
                ) :
                  <>
                    <div className="card-body">
                      <div className="avatar-placeholder placeholder rounded-circle"></div>
                      <div className="text-placeholder placeholder w-50"></div>
                      <div className="text-placeholder placeholder w-75"></div>
                    </div>

                    <div className="card-body">
                      <div className="avatar-placeholder placeholder rounded-circle"></div>
                      <div className="text-placeholder placeholder w-50"></div>
                      <div className="text-placeholder placeholder w-75"></div>
                    </div>

                    <div className="card-body">
                      <div className="avatar-placeholder placeholder rounded-circle"></div>
                      <div className="text-placeholder placeholder w-50"></div>
                      <div className="text-placeholder placeholder w-75"></div>
                    </div>
                  </>
                  : ""}
              </div>
            </div>




            {/* <div className={`d-flex flex-column justify-content-left ${open ? "d-none":"d-block"} d-none`}>
 <div className="instruction mt-4 user-select-none mb-2 mx-4 mt-5">
    <span className="pe-3 instCon"><BsFillCheckCircleFill/></span>
    <span className="instrFont ">This parcel does not contain Gems, Jewellery, Currency, Liquid, illegal drugs, any prohibited items and commoditie stricly not accepted</span>
    </div>
    <div className="instruction user-select-none my-2 mx-4">
    <span className="pe-3 instCon"><BsFillCheckCircleFill/></span>
    <span className="instrFont">Parcel should be in open condition, Booking person has right to check and open the parcel.</span>
    </div>
    <div className="instruction user-select-none mx-4 ">
    <span className="pe-3 instCon"><BsFillCheckCircleFill/></span>
    <span className="instrFont">Actual or volumetric weight whichever is highter will be considered.</span>
    </div>
    
  </div> */}



          </div>

        </div>

        {/* { props.userType ==='Employee' && <div className={` my-4  `} onClick={validate} ><div className="d-flex justify-content-center align-items-center"> <button type="button" className="btn-success"  >Get quote</button></div></div> */}


{ rateShow && !props.awb &&  <div className={` my-4  `} ><div className="d-flex justify-content-center align-items-center"> <button type="button" className=" btn btn-warning" onClick={next} disabled={!rateShow} >Next</button></div></div>
}

{ rateShow && props.awb &&  <div className={` my-4  `} ><div className="d-flex justify-content-center align-items-center"> <button type="button" className=" btn btn-warning" onClick={() => next()} disabled={!rateShow} >Update</button></div></div>
}

{loader &&  <Spinner  />}
      </div>



    </>

  );
};

import React, { useEffect ,useState } from 'react'
import {useParams,useNavigate} from 'react-router-dom'
import './Kyc.css'
export const Kyc = () => {
    const [kyc,setKyc] = useState('')
    const {awb} = useParams()

      const print =() => {
        window.print();
      }

      const numberFormat = (value) =>
      new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumSignificantDigits: 5 
      }).format(value);
    
    

    useEffect (() => {
       fetch(`https://www.queekdelever.com/queekApi/kycPrint.php?awb=${awb}`) 
       .then(response => response.json())
       .then(data => {
        setKyc(data)
        console.log(data)
        
      })
    },[])
  return (
    <div>

  {kyc && !kyc[0].passport_f  && <div className='d-flex justify-content-around h-50 mt-3' >
    
    <div className=' mx-2 ' style={{width:'50%',height:'50%'}}><img src={kyc && kyc[0].addhar_f}  className='kycImges' /></div>
    <div className='mx-2 ' style={{width:'50%',height:'50%'}}><img src={kyc && kyc[0].addhar_b} className='kycImges' /></div>
 </div>}

 {kyc && kyc[0].passport_f  && <div className='d-flex flex-column justify-content-around h-50 mt-3 align-items-center page' >
    
    <div className=' mx-2 my-2' style={{width:'70%',height:'60%'}}><img src={kyc && kyc[0].addhar_f}  className='kycImges' /></div>
    <div className='mx-2 my-2' style={{width:'70%',height:'60%'}}><img src={kyc && kyc[0].addhar_b} className='kycImges' /></div>
    <div className='mx-2 my-2' style={{width:'70%',height:'60%'}}><img src={kyc && kyc[0].passport_f} className='kycImges' /></div>
{kyc && kyc[0].passport_b &&    <div className='mx-2 my-2' style={{width:'70%',height:'60%'}}><img src={kyc && kyc[0].passport_b} className='kycImges' /></div>
}
 </div>}

 {kyc && !kyc[0].passport_f  && <div className='px-3'>
  <div className='text-center text-bold mt-3 '><u><b>CUSTOMER DECLARATION FOR SHIPMENT</b></u></div>
  <div className='textSmall mt-3'> I/We, Mr./Mrs/M/s <b className='px-1'>{kyc && kyc[0].fName}</b> am/are sending a non-document(package) addressed to Mr/Mrs/Ms/M/s <b className='px-1'>{kyc && kyc[0].rName}</b> through DTDC Express ltd. with Consignment No. <u className='px-1'>{kyc && kyc[0].fwdNo}</u>  on dated <u className='px-1'>{kyc && kyc[0].bkDate}</u> <i></i>. I/We further declare that the consignment contains <b className='px-1'>{kyc && kyc[0].content}</b>  being sent <b className='px-1'>for Personal use only</b> and the value of the Consignment is <span className='fName px-1'>{numberFormat(kyc && kyc[0].decValue)}</span>. We declare that above details is correct and true.  </div>
 
  <div className='d-flex justify-content-end mt-5'>Signature of the Consignor</div>

  <div className='mt-3 d-none'><table className='table table-bordered '>

  <thead>
    <tr>
    <th>Sr.No.</th>
    <th>Content Description</th>
    <th>No. of Piece</th>
    <th>Value per Unit</th>
    <th>Total Value</th>
    </tr>
    </thead>
    </table></div>
</div>}


 <div className='d-flex fixed-bottom justify-content-end me-2 non-printable'><div onClick={print}><button>Print</button></div><div><button>Share</button></div></div>
    </div>
  )
}

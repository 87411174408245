import React,{useState,useEffect , useRef} from 'react'
import { Header } from '../../Components/Header'
import './Homepickup.css'
import { BiBarcodeReader } from "react-icons/bi";
import { FiMoreVertical} from "react-icons/fi";
import { BsArrowCounterclockwise} from "react-icons/bs";


import { MdOutlineRadioButtonChecked} from "react-icons/md";
import { MdCall } from "react-icons/md";
import { BsBookmarkFill } from "react-icons/bs";
import { FaWeight } from "react-icons/fa";
import { BsChevronLeft } from "react-icons/bs";
import { AiOutlineCodeSandbox } from "react-icons/ai";
import Drawer from "react-bottom-drawer";
import { FaRupeeSign } from "react-icons/fa";
import axios from 'axios';
import Route from './Route.png'
import { BiRupee } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegDotCircle } from "react-icons/fa";
import { MdOutlinePinDrop } from "react-icons/md";
import OtpInput from 'react-otp-input';

import { Chheckouts } from './Chheckouts.jsx';
import { Spinner } from '../../Components/Spinner';
import { NormalButton } from '../../Components/NormalButton';

export const HomePickup = (props) => {
  const [pickup,setPickup] = useState('')
  const [isVisible,setIsVisible] =useState(false)
  const [hide,setHide] = useState(false)
  const [detail,setDetail] = useState('')
  const[lat,setLat] = useState('')
  const [lng,setLng] = useState('')
  const [ofp,setOfp] = useState(false)
  const [custId,setCustId] = useState('')
  const [loc,setLoc] = useState('')
  const [foodPk,setFoodPk] = useState(false)
  const [Local,setLocal] = useState('')
  const stat = useRef(null)
  const [state,setState] = useState('')
  const [dld,setDld] = useState(false)
  const [OTP,setOtp] = useState('')
  const [otpErr,setOtpErr] = useState(false)
  const [load,setLoad] = useState(false)
  const [task,setTask] = useState(false)
  const [pending,setPending] = useState(false)
  const [reason,setReason] = useState('')
  const [selRea,setSelRea] = useState('')
  const [hold,setHold] = useState('')

let k=0;
let c=0;
const back =(e) => {
  if(e === 'HomePickup')
{setHide(false)
setIsVisible(false)
}
  else
  props.back(e)
  props.rstDst('Dashboard')
}

const curDate =(e) => {
  const current = new Date();
  let month = current.getMonth()+1
   if (month <= 9)
    month = '0'+ month
  
    let day= current.getDate()+e
    if ( day <= 9)
    day = '0'+ day
  
  return `${current.getFullYear()}-${month}-${day}`;
}


const pickupReq =(e,f) => {



  setLoad(true)
  fetch(`https://queekdelever.com/queekApi/pickupRequest.php?empId=${props.empId}&pkDate=${e}&today=${f}`)
  .then(response => response.json())
  .then(data => {
    setPickup(data)
  //   if(data.length ===0)
  // {// props.back('close')}
  setLoad(false)
  })
}

  useEffect(() => {
pickupReq(curDate(0),0)
  },[])


const googleMap =() =>
{
setOfp(true)
  window.open(`https://maps.google.com?q=${lat},${lng}`);
}

const fetchDetails =(id,loc) => {
  setDetail('')
fetch(`https://queekdelever.com/queekApi/ReadyHome.php?sender=${id}&pickLoc=${loc}&today=${task?'1':'0'}`)
.then(response => response.json())
.then(data => setDetail(data) )

}

const FoodDeliver = (e) => {
  fetch(`https://queekdelever.com/queekApi/FoodPickup.php?boyId=${props.empId}&pikId=${e}`)
  .then(response => response.json())
  .then(data => {setLocal(data)
    setFoodPk(true)
  })
}

const viewAll =(a,b,lat,lng,content,pkId) =>{


  if(content ==='Food Delivery')
  {
FoodDeliver(pkId)
  }
  else{
  setLat(lat)
  setLng(lng)
  setIsVisible(true)
  fetchDetails(a,b)
  setCustId(a)
  setLoc(b)
  setOfp(false)
  // console.log('dldlgld')
//  setHide(true)
  }
}


const handleChange =(otp) => {
setState(otp)
}


const otp =() => {
  let min = 1111;
  let max = 9999;
  let rand =(parseInt(min + (Math.random() * (max-min))))
 setOtp(rand)
  whatsOTP(rand)
}

const whatsOTP =(otp) => {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' ,
    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3YWxsZXRJZCI6IjYyYWExMmZiMDA2NmQzN2M5OWIxNDMxNSIsInVzZXJJZCI6IjYyYWExMmZiMDA2NmQzN2M5OWIxNDMxNiIsImRldmljZUlkIjoiNjJhYTEzNDIwMDY2ZDM3Yzk5YjE0MzE3IiwiaWF0IjoxNjU1MzE4MjI5LCJleHAiOjE4NzczNjYyMjl9.VquXn59nrzXYUVjkT0Lc8qxMDENCMBnS7ZY9eV4Ohko' ,
    'Access-Control-Allow-Origin':'origin-list',
    'Access-Control-Allow-Headers': '*'
  },
  body: JSON.stringify({
    "numbers": `91${Local[0].custId}`,
    "message": {
      "text": `${otp} is your delivery code, Share with agent ONLY when he is at your premises- Queek Delever`
    }
  })
  }
  
  fetch('https://api.whatsspot.in/api/message/fast/text', requestOptions )
  .then(response => response.json())
  .then(data => console.log(data))
  .catch(err => console.log('Error occur '+ err))
}



const submit =() => {

  if(stat.current.value ==='Delivered')
{setDld(true)
otp()
}
else
setDld(false)
  
}

const conOTP =() => {

if(OTP ===  parseInt(state))
setOtpErr(false)
else
{
  setOtpErr(true)
  const requestOptions = {
    method: 'POST',
   
};
fetch(`https://queekdelever.com/queekApi/FoodStatus.php?Id=${Local[0].id}&status=${stat.current.value}&boyId=${props.empId}`,requestOptions)

setIsVisible(false)
pickupReq()

}
}

const reaSel =(sr,reason) => {
setSelRea(sr)
console.log(reason)
setHold(reason)
}

const updateReason =() => {
  const current = new Date();
  let month = current.getMonth()+1
   if (month <= 9)
    month = '0'+ month
  
    let day= current.getDate()+1
    if ( day <= 9)
    day = '0'+ day
  
  const today = `${current.getFullYear()}-${month}-${day}`;

  detail.map((x) => 
  fetch(`https://queekdelever.com/queekApi/updatePickup.php?awb=${x.awb}&reason=${hold}&pkDate=${today}`)
)
setIsVisible(false) 
setPending(false)
setSelRea('')
setTimeout(() => {todayPk()},100)
}

const Shedule =() => {
  setLoad(true)
  fetch(`https://queekdelever.com/queekApi/pendingPk.php`)
  .then(res => res.json())
  .then(data =>{setReason(data)
    setLoad(false)
  } )
}

const pendingPk =() => {
  setTask(true)
  pickupReq(curDate(1),1)
}

const todayPk =() => {
  setTask(false)
  pickupReq(curDate(0),0)

}

const updateAwb =(e) =>  {props.updateAwb(e)}

const cart =() => {}
const rstDst= () => {}
  return (
    <>

  {/* { hide &&  <Chheckouts cart={cart} rstDst={rstDst}   finalCheck={true} user={custId} emp={true} updateAwb={updateAwb}  loc={loc} back={back} flag={true}   />} */}
   {!hide && <div className='vh-100 position-relative  user-select-none' style={{background:'#f2f2f2'}} >
    <div className='top '> 
    <div className='px-2 cursor' onClick={ () => back('close')}><BsChevronLeft size='20' /></div>
    <div className='serch position-relative'>
    <div> <input className='inputTxt ' placeholder='Search...'/></div>
   <div className='barcode'><BiBarcodeReader size='20' /></div>
    </div>
    </div>

    <hr className='divide1 my-0 py-1' />
{/* <div className='text-center priority text-muted bg-white pt-2'>Top Priority Pickup</div> */}

<div className='d-flex justify-content-around pb-2'>
<div className={` ${task ? 'taskBtn1' :'taskBtn'}  w-50 text-center py-1`} onClick={() => todayPk()} >Today Pickup</div>
<div className= {`${ task ? 'taskBtn' :'taskBtn1'}  w-50 text-center py-1`} onClick={() => pendingPk() } >Schedule Pickup</div>
</div>

{pickup && !hide && pickup.map((x) =>  <div   key={x.count} className='bg-white py-2  '  onClick={()=> {viewAll(x.sender,x.pickLoc,x.lat,x.lng,x.content,x.pickupId)}}>
  <div className='px-4 mt-2 d-flex justify-content-between'>

 <div className='d-flex flex-column'   >
 <div className='awb1 mb-2'><span className='pdType text-dark'>Pickup # {x.count+1}</span></div>
  <div className='PDname mt-1'>{x.name}</div>
  <div className='add text-muted'>{x.pickLoc}</div>
 </div>



 <div className='d-flex flex-column  '>
<div className='call p-2'><a href={`tel:+91 ${x.custID}`}><span><MdCall color='white'/></span></a></div>
 </div>
 </div>
 <div className='d-flex justify-content-between  ps-4 pe-3'>
{parseInt(x.multiple) ===1  && <div className=' awb1 mt-2 '><BsBookmarkFill color='gray' size='12' /><span className='ps-1'>{x.content}</span> 
 <span className='px-3'>| <FaWeight size='12' />
 <span className='ps-2'>{x.wgt} kg</span>

 <span className='ps-1'>| Prepaid</span>
 </span>
 </div>}
 {x.multiple >1 && <div><AiOutlineCodeSandbox size='18'/><span className='px-2 awb1'>Multiple Shipment : {x.multiple}</span></div>}
<div className='d-flex align-items-center '><div className='pe-1'><FaRupeeSign size='14' /></div><div>{x.amt}</div></div>
</div>
 <hr className='divide1   mx-2 my-2' />

<div className='d-flex mt-2 bg-info justify-content-between d-none'>
<div className='  w-50 py-1 ofp' ><button className='btn text-dark  py-1 awb1 ' onClick={() => googleMap(x.lat,x.lng)} >Out for Pickup</button></div>
<div className=' w-50 py-1 bg-secondary text-white' ><button className='btn text-white py-1 awb1' onClick={() => setIsVisible(true)}>View</button></div>
</div>
</div>)
}


{pickup.length <= 0 && <div className='d-flex flex-column align-items-center justify-content-center mt-5'>
  <div className='mt-5'><BsArrowCounterclockwise size='80'  /></div>
  <div className='mt-3'>No Pickup Request</div>
  <div className='check-add'>At this time, no pickup request, Check after sometime</div>
</div>}

<Drawer isVisible={isVisible}>
  {!pending && <div>
<div className='d-flex justify-content-between '>
  <div>Delivery Details</div>
  <button onClick={()=>setIsVisible(false)} type="button" className="btn-close" aria-label="Close"></button>
</div>

{detail && detail.map((x) =>  <div className='d-flex flex-column my-3 p-2 m-0 detailss ' key={k=k+1}>
<div className='d-flex justify-content-between align-items-center'>
<div className='awb1 '><span className='pdType text-dark'>Awb No # {x.awb}</span></div>
<div className='d-flex align-items-center justify-content-center'><div className='mb-1'><FaRupeeSign size='14' /></div><div className='px-1 '>{x.charges}</div></div>
</div>
  <div className='PDname mt-1'>{x.network}</div>
  <div className='check-name mt-1'>{ x.name } </div>
  <div className='add1  '>{x.city}, {x.pincode} {x.state} {x.country}</div>
  <hr className='divide1 my-1' />
  <div className=' awb1 mt-2 '><BsBookmarkFill color='gray' size='12' /><span className='ps-1'>{x.content}</span> 
 <span className='px-3'>| <FaWeight size='12' />
 <span className='ps-2'>{x.wgt} kg</span>

 <span className='ps-1'>| Prepaid</span>
 </span>
 </div>
 </div>)}

{!ofp && detail &&
//  <div className=' py-1 ofp my-3' ><button className='btn text-dark  py-1 awb1 ' onClick={() => googleMap()} >Out for Pickup</button></div>
<div className='d-flex mt-2 justify-content-between align-items-center pb-3 Rea'>
<div className='  payment-btn bgYellows '  onClick={() => googleMap()} >Out for Pickup</div>
<div className='   optionMen mt-2' onClick={() =>
  { setPending(true)
    Shedule()}
   }> <FiMoreVertical size='23'/></div>
</div>
 }
 
{ofp && <div className=' bg-success  py-1 ofp my-3' ><button className='btn text-white  py-1 awb1  bg-success' onClick={() => {
    props.users(pickup[0].custID)
    props.finalCheck(true)
    props.loc(loc)
props.back('checkout')
  }} >Picked up</button></div>}
 
<div>
  </div>
  </div>}

  {/* pending page */}
   {pending && <div>
    <div className='d-flex justify-content-between '>
  <div>Problem ?</div>
  <button onClick={()=>setPending(false)} type="button" className="btn-close" aria-label="Close"></button>
</div>
   
    <div className='scrollable-div1 mt-3'>
   {reason && reason.map((x) =>  <div key={c=c+1} className={` border ${selRea === x.sr ? 'border-danger bg-danger ':' border-gray  bg-white'} Rate-Con1 p-2 mt-3  rounded `} onClick={() =>reaSel(x.sr,x.reason) } >
     <div className='d-flex align-items-center '>
      <div className='pe-3'><MdOutlineRadioButtonChecked size='19' color={`${selRea ===x.sr ? 'white':'gray '}`} /></div>
      <div className= {` ${selRea === x.sr ? 'check-name text-white' :'check-name' }   text-truncate text-nowrap w-100 text-capitalize`}>{x.reason}</div>
     </div>
    </div>)}
    </div>
  

    <div className='py-3' onClick={() => updateReason()} ><NormalButton name='Submit' /></div>
  {/* end pending page */}
  </div>}
</Drawer>

<Drawer isVisible={foodPk}>
  
  <div className={`${ !dld  ? 'd-block' :'d-none'}`}>
<div className='d-flex justify-content-between px-2'>
  <div>Priority Delivery</div>
  <button onClick={()=>setFoodPk(false)} type="button" className="btn-close" aria-label="Close"></button>
</div>

<div className='d-flex align-items-center justify-content-between mt-3 px-2'>
          <div className='d-flex '>  <img src={Route} alt="route"/>
         
            <div className='km pt-2 ps-2'>{Local && Local[0].km} Kms</div></div>
            <div className='d-flex align-items-center'><div className='mb-1'><BiRupee size="18" /></div>
            <div className='paise'>{Local && Local[0].payment}</div> 
            </div>
        </div>
        <hr className='divide1' />

        <div className='d-flex mt-2 align-items-center  px-2'>
<div className='pe-4 position-relative'><FaRegDotCircle size='23' color='#EB4335' />
<div className='tran'> < BsThreeDotsVertical size='30' color='gray' /></div>
</div>
<div className='pickupCard p-2 position-relative '>
<div className='floatLbl px-1 '>{Local && Local[0].fname}</div>
{/* <div className='ps-2 pikName mt-1'>{l.data[0].fadd}</div> */}
<div className='pikAdd ps-2 mt-1'>{Local && Local[0].fadd}</div>
</div>
</div>

<div className='d-flex mt-4 align-items-center'>
<div className='pe-3'><MdOutlinePinDrop size='29' color='#008000' /></div>
<div className='pickupCard p-2 position-relative '>
<div className='floatLbl px-1 '>{Local && Local[0].dname}</div>
{/* <div className='ps-2 pikName mt-1 text-dark'>{props.data[0].dname}</div> */}
<div className='pikAdd ps-2 mt-1 '>{Local && Local[0].dadd}</div>
</div>
</div>

<div className='mt-3 ms-5 d-flex mb-2 align-items-center' >


<div >
<select ref={stat}  className="form-select" aria-label="Default select example">
  <option   defaultValue={'Out for Pickup'} value="Out for pickup">Out for Pickup</option>
  <option value="Out for delivery">Out for Delivery</option>
  <option value="Delivered">Successfully Delivered</option>
</select>
</div>

<div className='ms-2 px-2' onClick={submit}><button className='btn btn-warning btn-sm text-size' >Submit</button></div>
  </div>
  </div>

{/* ******************************************** OTP FIELD********************************************** */}

  <div className= {`${dld ? 'd-block':'d-none' }`}  >
<div className='my-4 text-center otp'>Enter OTP </div>

<div className='d-flex h-75 justify-content-center '>
<div><OtpInput containerStyle ='' shouldAutoFocus={true}  numInputs={4} onChange={handleChange} value={state} inputStyle='otpInput'   separator={<span>-</span>} /></div>
</div>
<div className={`text-danger text-center ${otpErr ? 'd-block' :'d-none' } `}>Wrong OTP</div>

<div className='mt-4 mb-2'><button className='btn btn-warning' onClick={conOTP}>Submit</button></div>

    </div>
</Drawer>

  {load && <Spinner />}

    </div>}

  
    </>
  )
}

import React, { useEffect,useState } from 'react'
import { BsChevronLeft } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";
import './Rate.css'
import axios from "axios";
import {SphericalUtil, PolyUtil} from "node-geometry-library";
import distance from 'gps-distance'
import { RateList } from './RateList.jsx';




export const Rate = (props) => {
  const [km, setKms] =useState(2)
  const [ locals,setLocal] = useState(null)
  const [select,setSelect] = useState('1')
  const [inital,setInital] = useState(1)
  const [duration,setDuration] = useState()
  const [pBoy,setPBoy] = useState('')
  const [AssignBoy,setAssignBoy] = useState('')
  const [rateLoader,setRateLoader] = useState(false)
  const [orgLat,setOrgLat] = useState('')
  const [orgLng,setOrgLng] = useState('')

  const selectRate =(e) => {
    setSelect(e) 
    setInital('')}

  const kms =async() => {

    let org = props.org.lat+'%2C'+ props.org.lng
    let dst = props.dst.lat+'%2C'+props.dst.lng

 
     const response = await axios.get(`https://easy-ship.in/local_kms.php?org=${org}&dst=${dst}`)
     if(response.data)
     {
   
      setDuration(response.data.rows[0].elements[0].duration.text)
      setKms(response.data.rows[0].elements[0].distance.value)
      setRateLoader(true)
     }
   }


   useEffect(()=>{

     
    setOrgLat(props.org.lat)
    setOrgLng(props.org.lng)
    const local = async() => {
      setLocal('')
      let response =''
  
      if(props.serType ==='Local')
       response = await axios.get(`https://www.easy-ship.in/local_quote.php?custcode=${user}`)
      if(props.serType ==='Domestic' )
      response = await axios.get(`https://www.queekdelever.com/queekApi/domestic_quote.php?swgt=${props.wgtSlab}&pincode=${props.pincode}&type=${props.item ==='document' ?'Dox' : props.item ==='medicine' ? 'Medicine' : 'Ppx' }`)
      if(props.serType ==='International' )
      response = await axios.get(`https://www.queekdelever.com/queekApi/domestic_quote.php?swgt=${props.wgtSlab}&pincode=${props.country}&type=${props.item ==='document' ? 'Dox' :'Ppx  '}`)
  


  
      if(response.data)
      {
        
  setLocal(response.data)
  if(props.serType ==='Local')
     kms()
     else
    setTimeout(()=>{ setRateLoader(true)},500)
      }
    }
  
    if(!locals)
   local()
  
   })

    const back =() => {
      
        props.rates('main')
        props.active(true)
       
    }

    // useEffect(()=>{
    //   //   setLocal('')
    //    if(props.serType ==='Local')
    //    { kms()}
    //   },[props.serType])
      
      let user =''
   
      if(props.lType ==='Employee')
      user = props.mob
      else
      user= props.user

    const next = () => {

      let freight =((locals[select-1].rate).includes(',') ) ?( locals[select-1].rate).split(',').join('') : locals[select-1].rate

     const booking= [{user:user
        ,dst: props.dst
        ,dstLocation : props.delLocation
       ,wgt: props.wgtSlab
       ,content: props.item
       ,service: props.serType ==='Local' ? locals[select-1].service : locals[select-1].network 
       ,rate : props.serType ==='Local' ? parseFloat(Math.round(km/1000)* locals[select-1].per_km) : freight
       ,pincode : props.pincode
       ,city : props.state
       ,country : props.country
       ,receiver : props.skip
       ,pickBoy :AssignBoy
       ,lat : orgLat
       ,lng : orgLng
       ,shipType : props.serType
       ,km: km
       ,type : props.item 
       }]  
       
 
       props.booking(booking)
     
       if(props.skip.length >  4)
      { 
        
     addShipment(booking)
{       props.back('checkout')
        props.finalCheck(false)
}
}       else
           props.delivery('delAddress')
       }
     
       const addShipment =async(booking) => {
         const response = await axios.get(`https://queekdelever.com/queekApi/addShipment.php?sender=${booking[0].user}&receiver=${props.skip}&rate=${booking[0].rate}&service=${booking[0].service}&wgt=${booking[0].wgt}&content=${booking[0].content}&pickId=${AssignBoy}&lat=${orgLat}&lng=${orgLng}&shipType=${props.serType}&km=${km/1000}&type=${booking[0].type}&empId=${props.user}`)
       }

let a=0;


  return (
    <>
    <div className=' user-select-none position-relative rateHt'>
       <div onClick={back} className="cursor"> <BsChevronLeft size={20} /></div>
       {/* <BsArrowRight/> <span>Delhi</span> */}
        <div className='position-absolute top-0 start-50 translate-middle-x text-nowrap  '> <div className='dst-route'><span >Select Delivery Option</span> </div></div>
 
{/* <div className='mt-4'>
<div onClick={fast}><RateList  service={'Time Senstive'} delTime={'Within 20 minute delivery'} price={'95'}  active ={true}/></div>
<div ><RateList  service={'Same Day'} delTime={'Deliver within 8 hour'} price={'78'}  active ={false}/></div>
<div><RateList  service={'Next Business Day'} delTime={'Delivery Next working day'} price={'55'}  active ={false}/></div>
</div> */}
 {locals && rateLoader && <div className={`rateHt1 mt-1 scroll-y  ${locals.length >2 ?'rateWt' :'mt-3'}`}>
{locals? <div className='mt-4'></div> :""}
{/* onClick={fast} */}

{locals && rateLoader &&  locals.map((x) =>  <div  key={a=a+1} onClick={() => {selectRate(x.sr)}}  className={` ${locals.length ===2 ? "mb-4" : ""}   `}  ><RateList  iconType={props.serType ==='Local' ? 'Scooter' : props.serType ==='Domestic' ? x.mode :'By Air'} service={props.serType ==='Local' ? x.service : x.full_name } delTime={props.serType ==='Local' ? x.sr <= 1 ? duration : x.del_time : x.del_time } price={props.serType ==='Local' ? parseFloat(Math.round(km/1000) * x.per_km) : x.rate }  active ={inital === x.sr ? true:  select ===x.sr ? true:false}   /></div>
)
 }
</div>}

{!rateLoader &&
 <>
  <div className="card-body">
    <div className="avatar-placeholder placeholder rounded-circle"></div>
    <div className="text-placeholder placeholder w-50"></div>
    <div className="text-placeholder placeholder w-75"></div>
</div>

<div className="card-body">
    <div className="avatar-placeholder placeholder rounded-circle"></div>
    <div className="text-placeholder placeholder w-50"></div>
    <div className="text-placeholder placeholder w-75"></div>
</div>

<div className="card-body">
    <div className="avatar-placeholder placeholder rounded-circle"></div>
    <div className="text-placeholder placeholder w-50"></div>
    <div className="text-placeholder placeholder w-75"></div>
</div>
 </>
 }

{locals   && rateLoader &&
<div className='btnNextCon'><button className='btn-Next ' onClick={next} >Next</button></div>
 }

    </div>
    </>
  )
}

import React from 'react'
import './Initilation.css'
import sLogo from './sLogo.svg'

export const Initilation = (props) => {
  return (
    <div >
<div className='initBg position-relative '>
        <div className='mt-2 d-flex justify-content-center'><img src={sLogo} alt='log' className='splash1' /></div>
   
        <div className='servi text-nowrap text-center mb-3  '>Local | Domestic | International</div>
        {/* <div className='text-white '>Please Wait ...</div> */}
    </div>

    </div>
    
  )
}

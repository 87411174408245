import React, { useState, useRef, useEffect } from 'react';
import './EBooking.css';
import { FaRegDotCircle } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import { BiRupee } from "react-icons/bi";
import { BiErrorCircle } from "react-icons/bi";
import { FiBox } from "react-icons/fi";
import Drawer from "react-bottom-drawer";
import { BsShop } from "react-icons/bs";
import { ServiceSelection } from "../../Components/ServiceSelection.jsx";
import { WhatDeliver } from '../../Components/WhatDeliver.jsx';
import axios from "axios";
import { AddToHomeScreen } from 'react-pwa-add-to-homescreen';
import {Helmet} from "react-helmet";
import cookie from 'cookiejs';


export const EBooking = (props) => {
  const [isLocal, setLocal] = useState('Domestic')
  const [install,setInstall] = useState(false)
  const [isPlaceholder, setPlaceholder] = useState('Enter delivery address')
  const pickup = useRef('')
  const drop = useRef('')
  const [dstError, setDstError] = useState(false)
  const [rev, setRev] = useState('')
  const [blur, setBlur] = useState('')
  const [pk, setPk] = useState('')
  const [dp, setDp] = useState('')
  const [update, setUpdate] = useState(false)
  const [stat, setStatic] = useState('')
  const [storeId,setStoreId] = useState()
  const [storeName,setStoreName] = useState()
  const [storeLoc,setStoreLoc] = useState()
  const [storeData,setStoreData] = useState()
  const[isVisible,setIsVisible] = useState(false)
  const [senderErr,setSenderErr] = useState('RecMob')
  const [dimm,setDim] = useState(false)
  const [pwa,setPwa] = useState(false)
  const [unReg,setUnReg] = useState(false)
let a=0;

const sender = useRef(null)

const pickupReq =async() => {
  const response = await axios.post(`https://www.queekdelever.com/queekApi/foodDellivery.php?custID=${sender.current.value}&storeId=${storeId}`)
  // window.open(`https://api.whatsapp.com/send?phone=91${sender.current.value}&text=Dear Customer, ${storeName} want to deliver  order/Shipment at your DoorStep, Please click below click to cofirm your delivery location, i.e we  can locate your delivery address. https%3A%2F%2Fqueekdelever.com%2F%23%2FQueekDelivery%2F${sender.current.value}`) 
whatsMsg(sender.current.value,storeName)
  sender.current.value=''
}

useEffect(() => {
setTimeout(() => {
setPwa(true)
},1000)
},[])

useEffect(() => {
  window.onunload = () => {
  return <Helmet>
      <script>
        {`
         if ('serviceWorker' in navigator) {
          navigator.serviceWorker.getRegistrations().then(function (registrations) {
            for (const registration of registrations) {
              // unregister service worker
              console.log('serviceWorker unregistered');
              registration.unregister();
            }
          });
        }
        `}
      </script>
    </Helmet>
  }
})

const getCookie =() => {
  cookie.set('add-to-home-screen-pwa','',{expires:1,priority:'Medium'})
}

const whatsMsg =(sender,awb,dest) => {
    

      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' ,
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3YWxsZXRJZCI6IjYyYWExMmZiMDA2NmQzN2M5OWIxNDMxNSIsInVzZXJJZCI6IjYyYWExMmZiMDA2NmQzN2M5OWIxNDMxNiIsImRldmljZUlkIjoiNjJiNDU5ODczZDhhMzEwYTRlYmE4NDUyIiwiaWF0IjoxNjU1OTg2Njg0LCJleHAiOjE4Nzc0Mjk4ODR9.CTTbtkuTLfOYWWXYVGfQMN0uEY_vb_ck0ivRFHzx_LU' ,
          'Access-Control-Allow-Origin':'origin-list',
          'Access-Control-Allow-Headers': '*'
        },

          body: JSON.stringify({
            "numbers": `91${sender}`,
            "message": {
              "text": `*Shipment Delivery* : Dear Customer, ${storeName} wants to deliver Food/Order at your door step, please click on below button to confirm  your delivery location,i.e we can locate your delivery address.`,
              "footer": "Thaks for Shipping with Queek Delever",
              "templateButtons": [
                {
                  "urlButton": {
                    "displayText": "Get a Delivery Location",
                    "url": `https://queekdelever.com/#/QueekDelivery/${sender}`
                  }
                },
                
               
               
              ]
            }
          }
           )
      };
      fetch('https://api.whatsspot.in/api/message/fast/text', requestOptions )
          .then(response => response.json())
          .then(data => console.log(data))
          .catch(err => console.log('Error occur '+ err))
          
  
  // empty dependency array means this effect will only run once (like componentDidMount in classes)
  
  }




const fetch1 =async() => {
  const response = await axios.get(`https://queekdelever.com/queekApi/StoreLocation.php?empId=${props.user}`)
  if(response.data)
  {
    setStoreData(response.data)
  }
}

// const pickupPoint = async() => {
//   const response = await axios.get(`https://www.queekdelever.com/queekApi/pickupPoint.php?empId=${props.user}`)
// if(response.data)
// {
//   setStoreName(response.data[0].name)
//   setStoreLoc(response.data[0].add)
//   setStoreId(response.data[0].storeId)
// }}


useEffect(() => {
setTimeout(()=> {
  setInstall(true)
},2000)
},[])

  useEffect(() => {
    setStatic('')
  }, [props.update])

  const content = (e) => {
    props.Content(e)
  }

  const actWgt = (e) => {
    props.wgtSlab(e)
  }
  const pickupValidate = () => {
    
  if (props.lType.trim() !== 'Employee')
      props.Pickup('pickup')

      if (props.lType.trim() === 'Employee')
      {
        if(props.bType !=='Local')
        {

          props.Pickup('newBooking')
        }
        else {
          // console.log('queeker')
      if (storeName.trim().split('-')[0]  === 'Queek')
      if(sender.current.value.length != 10)
      setSenderErr('RecMobErr')
      else
     {
      setSenderErr('RecMob')
      props.Pickup('newBooking')
      props.RecMob(sender.current.value)
    }
    
     
      else
     {
      if(sender.current.value.length !== 10)
      setSenderErr('RecMobErr')
      else
     {
      setSenderErr('RecMob')
      setDim(true)
      pickupReq()
      setTimeout(() => {setDim(false)},3000)
     }
    }
  }
    }

    setUpdate(false)
  }
  const dropValidate = () => {
    props.Delivery('delivery')
  }
  const serType = (e) => {
    dstErr(false)
    setLocal(e)
    setRev(false)
    if (e === 'Local') { setPlaceholder('Search delivery address') }
    else if (e === 'Domestic') { setPlaceholder('Search city name') }
    else if (e === 'International') { setPlaceholder('Search country name ') }

    props.serType(e)
  }


  const next = (e) => {
    props.next(e)
  }
  // console.log(props.picName)

  const item = (e) => {
    props.item(e)
  }



  const dstErr = (e) => setDstError(e)



  const Rotate = () => {
    setStatic(true)
    setBlur('')
    dim()

    if (rev) {
      setRev(false)
      setPk('pkPoint-rev')
      setPlaceholder('Enter delivery address')
    }
    else {
      setRev(true)
      setPk('pkPoint')
      setPlaceholder('Search store, resturant...')
    }


  }

  const dim = () => {
    setTimeout(() => {
      setBlur('Rev-btn')}, 1)
  }
 
  



  // picName
  // useEffect(() => {
  //   pickup.current.value= props.picName
  //   drop.current.value =props.dest
  // },[props.pickName,props.dest])

  const awb = (e) => {
     props.awb(e)
  }

  return (
    <>

<Helmet>
<script type="text/javascript">{`
         if ('serviceWorker' in navigator) {
          // Register a service worker hosted at the root of the
          // site using the default scope.
          navigator.serviceWorker.register('/service-worker.js').then(function(registration) {
            console.log('Service worker registration succeeded:', registration);
          },  function(error) {
            console.log('Service worker registration failed:', error);
          });
        } else {
          console.log('Service workers are not supported.');
        }
    `}</script>

</Helmet>

{/* <Helmet> <script disable-devtool-auto src='https://cdn.jsdelivr.net/npm/disable-devtool@latest/disable-devtool.min.js'></script></Helmet> */}



      <div className='q-service user-select-none '><ServiceSelection serType={serType} /></div>


     {isLocal === 'Local' && props.lType.trim() ==='Employee' && <div className='BookingInput user-select-none '>
        <div className='TranIcon'>
          <FaRegDotCircle size="22" color="#AE030E" />
          <div className='TranLine2'></div>
          <FiMapPin size="24" color="#008000" />
          {/* <div className='TranLine'></div>
             <FiBox size="24" color="gray" /> */}
        </div>

             <div className='BookingTextBox position-relative ms-3'>
        <div className='pickupCard2 ' onClick={() => {
           fetch1()
          setIsVisible(true)
         } }>
          <div className='floatLbl px-1 text-muted mx-2'>{storeName && storeName}</div>
          {/* { <div className='ps-2 pikName mt-1 text-muted py-2 fs-6'> Search store name...</div>} */}
          {<div className='d-flex align-items-center '><div className='ms-2 StoreAdd twoLine'>{storeLoc && storeLoc} </div>
            <div className='pikAdd ps-2'></div></div>}
        </div>

        <div className={` ${senderErr} position-relative mt-3 bgTrans `}  >
        <div className='floatLbl px-1 text-muted mx-2'>Receiver Location</div>
        <div className='ms-2  d-flex align-items-center mobTop'><div className='pe-2 mobStd '>+91</div><div className=''>  <input placeholder='Enter mobile number' type='number' maxLength='10' className='inputTxt mobStd ' ref={sender} /></div></div></div>

            {/* <div className='RoundRupee'> <BiRupee size='40' color='white' /></div>  */}
             <div className='mt-4'><button className='btn btn-warning' disabled={dimm ? true:false} onClick={() => 
          pickupValidate()
            }>{dimm ? 'Please Wait' :'Request for Delivery'}</button></div>
             {/* <div className='mt-4'><button className='btn btn-success' onClick={() => pickupValidate() }>Please Wait</button></div> */}
        </div>
      </div>}


      {props.lType.trim() ==='Employee' && isLocal !=='Local' &&  <div className={`BookingInput `}>
        <div className='TranIcon'>
          <FaRegDotCircle size="22" color="#AE030E" />
          <div className='TranLine'></div>
          <FiMapPin size="24" color="#008000" />
          {/* <div className='TranLine'></div>
             <FiBox size="24" color="gray" /> */}
        </div>

 <div className='BookingTextBox position-relative '>


          <div className={`InputStyle  ${stat ? rev ? 'pkPoint' : 'pkPoint-rev' : rev ? 'pkStatic' : 'pkRevStatic'} `}><input className={`TxtInput ${stat ? blur : ''} `} placeholder='Enter pickup location' onClick={pickupValidate} ref={pickup} value={props.picName} onChange={(e) => setPk(e.target.value)} readOnly /></div>
          <div className={` position-relative  ${stat ? rev ? 'drPoint' : 'drPoint-rev' : rev ? 'dpStatic' : 'dpRevStatic'}  ${props.dest ? "InputStyle  " : dstError ? "InputStyle1" : "InputStyle "}  `}><input className={`TxtInput  ${stat ? blur : ''}`} placeholder={isPlaceholder} onClick={dropValidate} value={props.dest} onChange={(e) => setDp(e.target.value)} />
            <div className={` ${dstError && !props.dest ? 'd-block' : 'd-none'} position-absolute top-50 start-100 translate-middle pe-4 `}><BiErrorCircle color='darkred' size='18' /></div>
        </div>
{/* Reverse Booking button */}

          {/* { isLocal ==='Local' ? <div onClick={() => Rotate()} className={`Rev ${stat ? blur :''} `}><RiArrowUpDownFill size="20" color='#fff' /></div> :""} */}

          {/* <div className='pack-type ps-3 packType text-muted'>Select package type</div> */}
          <WhatDeliver active={props.active} desc={content} Wgt={props.Wgt} wgt={actWgt} next={next} item={item} dest={props.dest} dstErr={dstErr} destLoc={drop.current.value} draw={props.draw} serType={isLocal}  awb={awb}  whatDel={props.whatDel} />

        </div>

      </div>}


      {props.lType.trim() ==='login'  &&  <div className={`BookingInput `}>
        <div className='TranIcon'>
          <FaRegDotCircle size="22" color="#AE030E" />
          <div className='TranLine'></div>
          <FiMapPin size="24" color="#008000" />
          {/* <div className='TranLine'></div>
             <FiBox size="24" color="gray" /> */}
        </div>

 <div className='BookingTextBox position-relative '>


          <div className={`InputStyle  ${stat ? rev ? 'pkPoint' : 'pkPoint-rev' : rev ? 'pkStatic' : 'pkRevStatic'} `}><input className={`TxtInput ${stat ? blur : ''} `} placeholder='Enter pickup location' onClick={pickupValidate} ref={pickup} value={props.picName} onChange={(e) => setPk(e.target.value)} readOnly /></div>
          <div className={` position-relative  ${stat ? rev ? 'drPoint' : 'drPoint-rev' : rev ? 'dpStatic' : 'dpRevStatic'}  ${props.dest ? "InputStyle  " : dstError ? "InputStyle1" : "InputStyle "}  `}><input className={`TxtInput  ${stat ? blur : ''}`} placeholder={isPlaceholder} onClick={dropValidate} value={props.dest} onChange={(e) => setDp(e.target.value)} />
            <div className={` ${dstError && !props.dest ? 'd-block' : 'd-none'} position-absolute top-50 start-100 translate-middle pe-4 `}><BiErrorCircle color='darkred' size='18' /></div>

          </div>
{/* Reverse Booking button */}

          {/* { isLocal ==='Local' ? <div onClick={() => Rotate()} className={`Rev ${stat ? blur :''} `}><RiArrowUpDownFill size="20" color='#fff' /></div> :""} */}

          {/* <div className='pack-type ps-3 packType text-muted'>Select package type</div> */}
          <WhatDeliver active={props.active} desc={content} Wgt={props.Wgt} wgt={actWgt} next={next} item={item} dest={props.dest} dstErr={dstErr} destLoc={drop.current.value} draw={props.draw} serType={isLocal} awb={awb} />

        </div>

      </div>}

      <Drawer isVisible={isVisible} >
<div className='d-flex justify-content-between px-3 mt-2 '>
  <div>Select Store for pickup</div>
<div onClick={() => setIsVisible(false)}><button type="button" className="btn-close" aria-label="Close"></button></div>
</div>

{storeData && storeData.map((x) => <div  key={a=a+1}  onClick ={() => {
  setStoreId(x.storeId)
  setStoreName(x.name)
  setStoreLoc(x.add)
  setIsVisible(false)
  }} >
  <div className='px-2  d-flex align-items-center user-select-none mt-3'>
<div className='roundCir p-2'><BsShop  size='20' color='black' /></div>
<div className='d-flex flex-column px-4'>
  <div className='title'>{x.name}</div>
  <div className='addDetail'>{x.add}</div>
</div>

</div>  
<div><hr className='divide1' /></div>
</div>
)}



</Drawer>

<div onClick={() => getCookie()}><AddToHomeScreen   delayNotify={0}  skipFirstVisit={false} cookie={{name:'add-to-home-screen-pwa',expireDays:1}} translate={{headline:'Install app',bottomline:'',safariTapShare:'Tap "Share"',safariAddHomeScreen:'select "Add to Home Screen"',chromiumAddHomeScreen:'Click "Install app" from Browser menu for fast and reliable pickup',chromiumInstall:'Install this app for fast and reliable pickup',buttonInstall:'install'}} /></div>

{/* <AddToHomeScreen /> */}
      </>
  )
}


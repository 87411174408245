import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FiCheckCircle } from "react-icons/fi";


import { Header } from '../../Components/Header';
import { ProgressIndicator } from '../../Components/ProgressIndicator';
import { DeliveryStatus } from './DeliveryStatus';

export const Successful = (props) => {
const typAhead =() => {}
const back =() => {props.back('close')}
const [sr,setSr] = useState(0)
const [sr1,setSr1] = useState(0)
const [clr,setClr] = useState('gray')

const increment =() => {

 setSr(sr+1) 

}

useEffect (() => {
  if(sr < sr1)
setTimeout(() => {increment()},1000)

})

const fetch =async() => {

  const response = await axios.get(`https://queekdelever.com/queekApi/local_pk.php?id=55353883`)
  if(response.data)
  {
    setSr1(response.data[0].status)
    // console.log(response.data[0].status)
  }
}

useEffect(() => {
  if(!sr1)
  fetch()
 
})

  return (
    <Header name="ID #4000103222" back ={back} bottomBtn="no" Bar={'no'}  Placeholder="Search new location for pickup" typAhead={typAhead} show='' Loading={'done'} >
  
  {/* <DeliveryStatus/> */}

<div className='d-none1'>
<div className='position-relative'>
 <div className='liveDate text-muted'>Wed,12  Sep</div>
<div className='liveDate text-muted'>Pickup ID : 55359 3583538 953935</div>
<div className='text-dark mt-2'>ETA: 15 min</div>
<div className='position-absolute top-50 start-100 translate-middle pe-5'><div className='pe-5 text-muted'>Prepaid</div></div>
 </div>

    <div className='mt-5'>
      
      
  

   <ProgressIndicator status={'Pickup request received'} dateTime={"Keep an eye on your order"} curLoc={ sr === 1 ? true: false   } last={false}  active={sr === 2 ? true : false }  />
     <ProgressIndicator status={'Awaiting for pickup boy'} dateTime={""} last={false}  curLoc={ sr ===2 ? true : false }  active={ sr === 3 ? true : false} />
     <ProgressIndicator status={'On the Way for Pickup'} dateTime={""} last={false}  curLoc={sr === 3  ?true: false } active={sr === 4 ? true: false} />
     <ProgressIndicator status={'Successfully Picked up'} dateTime={""} last={false} curLoc={ sr === 4?true: false } active={ sr === 5 ? true: false} />
    
     {/* <ProgressIndicator status={'On the way for delivery'} dateTime={""} last={false}  curLoc={sr > 4 && false } active= {sr > 5 ? true : false} />
     <ProgressIndicator status={'Successfully Delivered'} dateTime={""} last={true} curLoc={ sr > 5 && false } active={sr > 6 ? true :  false} />
    */}


    </div>
</div>
     </Header>
    
  )
}

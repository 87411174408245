import React,{Suspense} from 'react'
import localDel from './domDel.jpg'
import { Footer } from './Footer'
import { BsCheck2Circle } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';


export const Domestic = () => {
  const Navbar = React.lazy(() => import('./Navbar'))

  const navigate = useNavigate();
  const login =() => {
    navigate('/login');
  }
  return (
    <div>

<Suspense fallback={<div>Loading...</div>}>
        <Navbar />
      </Suspense>   
        <div className='localBg pt-3'>
        <div className='container  py-4'>
            <div className='row'>
                <div className='col-sm-7 d-flex flex-column justify-content-start'>
                <div className='text-left spaceH d-flex align-item-center' > <div className='highB '> </div > <div className='ps-2'><h2>Domestic</h2></div> </div>
                <div className='pt-4  text-left  text-dark  mb-2 serTxt ' >Speedy and safe delivery across the Country through its Domestic Express Service, a guarateed service given top priority dispatched at the first  promptly  available commercial airline & service transport
              <div className='pt-3 highlight px-2 pb-2'>Don't wait for a package, Queek it today!</div>
               </div>
               <div className=' p-3'>

                <div className='d-flex py-2'><div className='pe-2'><BsCheck2Circle size='24' color='black' /></div> <div> Reliable Door Step pickup & Delivery </div> </div> 
                <div className='d-flex py-2'><div className='pe-2'><BsCheck2Circle size='24' color='black' /></div> <div> Real Time Tracking </div> </div> 
                <div className='d-flex py-2'><div className='pe-2'><BsCheck2Circle size='24' color='black' /></div> <div> On-Demand Packaging Solution</div> </div> 

                </div>
                <div className='btnShip mt-3 text-center ms-3 ' onClick={login} >Ship Now  </div>
                </div>


                <div className=' col-sm-5 d-flex justify-content-center '>
                  <div className=''>  <img src={localDel} className=' locSiz1' /></div>
                </div>
            </div>

            </div>
        </div>
   
   <Footer />
    </div>
  )
}

import React,{useState} from 'react';
import { faMotorcycle,faMapPin } from "@fortawesome/free-solid-svg-icons";

const exampleMapStyles = [
  
  {
    featureType: "administrative.province",
    elementType: "labels",
    stylers: [
      { visibility: "off" }
    ]
  },
  
  {
    featureType: "poi",
    stylers: [
      { visibility: "off" }
    ]
  },
  {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
          {
              color: "#eeeeee",
          },
      ],
  },
  {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
          {
              visibility: "off",
          },
      ],
  },
  {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
          {
              color: "skyblue",
          },
      ],
  },
  {
    featureType: "poi.medical",
    elementType: "all",
    stylers: [
      { visibility: "off" }
    ]
  },
  {
    featureType: "landscape.man_made",
    elementType: "all",
    stylers: [
      { visibility: "off" }
    ]
  },{
    featureType: "poi.attraction",
    elementType: "all",
    stylers: [
      { visibility: "off" }
    ]
  },{
    featureType: "poi.business",
    elementType: "labels",
    stylers: [
      { visibility: "off" }
    ]
  },{
    featureType: "poi.government",
    elementType: "all",
    stylers: [
      { visibility: "off" }
    ]
  },{
    featureType: "poi.medical",
    elementType: "all",
    stylers: [
      { visibility: "off" }
    ]
  },{
    featureType: "poi.place_of_worship",
    elementType: "all",
    stylers: [
      { visibility: "off" }
    ]
  },{
    featureType: "poi.school",
    elementType: "all",
    stylers: [
      { visibility: "off" }
    ]
  },{
    featureType: "poi.sports_complex",
    elementType: "labels",
    stylers: [
      { visibility: "off" }
    ]
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color : "gray"
      }
    ]
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#fdfcf8"
      }
    ]
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#dfd2ae",
        visibility:'off'
      }
    ]
  },

  {
    featureType: "administrative.neighborhood",
    elementType: "labels",
    stylers: [
      {
       visibility :"off"
      }
    ]
  },

  
  
];





const { compose, withProps, lifecycle } = require('recompose');
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polyline,
  Marker,
} = require('react-google-maps');




const {
  SearchBox,
} = require('react-google-maps/lib/components/places/SearchBox');

export const MapWithControlledZoom = compose(
  withProps({
    googleMapURL:'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBah2C34AUXeAESdNgB5GoXKgk6XhlYhMU',
    loadingElement: <div style={{ height: `100%`,backgroundColor:`skyblue` }} />,
    containerElement: <div style={{ height: `calc(100vh - 300px)`,backgroundColor:`skyblue` }} />,
    mapElement: <div style={{ height: `100%`,backgroundColor:`skyblue` }} />,
  }),
 

  lifecycle({

    
    
    componentWillMount() {
      const refs = {};

    let dest =  this.props.dst


      this.setState({
        bounds: null,
        polyLines: [
          { lat: 30.9011, lng: 75.9098 },
        
         dest ,
       
        ],
        center: {
          lat: 30.902339,
          lng:75.904118,
        },
        markers: [],
        onMapMounted: (ref) => {
          refs.map = ref;
        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
     
          });
        
        },
      });
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    ref={(map) => {
      const bounds = new window.google.maps.LatLngBounds();
      props.polyLines.map((x) => {
        bounds.extend(new window.google.maps.LatLng(x.lat, x.lng));
      });
      
      map && map.fitBounds(bounds);
      props.onMapMounted(map);
    }}
    center={props.center}
    onBoundsChanged={props.onBoundsChanged}
   
    defaultZoom={20}
    onTilesLoaded={()=> {props.route('ok')}}
   
    options={{
      styles: exampleMapStyles,
      disableDefaultUI:true,
      gestureHandling: 'none',
     
  }}
  >


    <Polyline
      path={props.polyLines}
      options={{
        suppressMarkers: false,
        strokeColor: 'black',
        strokeOpacity: 1,
        strokeWeight: 2,
    
        // radius:10000,
        geodesic: true,
  zIndex: 2,
        icons: [
          {
            icon: 'hello',
            offset: '0',
            repeat: '10px',
          },
        ],
      }}
    />

<Marker position ={{lat: 30.9011, lng: 75.9098 }}
  icon = {{
            
    path: faMapPin.icon[4] ,
    fillColor: "#EB4335",
    fillOpacity: 1,
    // labelOrigin: new google.maps.Point(53,115),
    anchor: new window.google.maps.Point(
      faMapPin.icon[0] / 2, // width
      faMapPin.icon[1] // height
    ),
    strokeWeight: 1,
    strokeColor: "#ffffff",
    scale: 0.075,
    }}
/>  

<Marker position={props.dst}  

icon = {{
            
  path: faMapPin.icon[4] ,
  fillColor: "green",
  fillOpacity: 1,
  // labelOrigin: new google.maps.Point(53,115),
  anchor: new window.google.maps.Point(
    faMapPin.icon[0] / 2, // width
    faMapPin.icon[1] // height
  ),
  strokeWeight: 1,
  strokeColor: "#ffffff",
  scale: 0.075,
  }}
/>


  </GoogleMap>
));




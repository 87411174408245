import React, { useEffect, useState } from 'react'
import { FiMenu } from "react-icons/fi";
import { BsFillBellFill } from "react-icons/bs";
import { TiWeatherShower } from "react-icons/ti";
import { BsBoxSeam } from "react-icons/bs";
import ToggleButton from 'react-toggle-button'
import { MdOutlineArrowForwardIos} from "react-icons/md";
import { AiOutlineExclamation} from "react-icons/ai";



import axios from 'axios';


export const TopHead = (props) => {
 const [duty,setDuty] = useState(false)
 const [imgLoad,setImgLoad] = useState(true)
 const [hReason,setHReason] = useState('')
 const [sText,setSText] = useState('')
 
 useEffect(() => {

	// always round down
  const current = new Date();
  let month = current.getMonth()+1
   if (month <= 9)
    month = '0'+ month
  
    let day= current.getDate()
    if ( day <= 9)
    day = '0'+ day
  
  const today = `${current.getFullYear()}-${month}-${day}`;

if(today >= props.pkDate)
   {
    setHReason('Today Pickup')
   setSText('04:30pm-07:30pm')}
   else 
   {
    setHReason(props.reason)
    setSText('Shedule for next day Pickup')
  
   }
 
},[props.pkDate])

 
useEffect(()=>{

fetch(`https://queekdelever.com/queekApi/empAtt.php?empId=${props.id}`)
.then(res=>res.json())
.then(data=>
   {

  data[0].status && data[0].status1 ? setDuty(false)  : setDuty(true)
data[0].status && data[0].status1 ? props.dutyStatus(true)  : props.dutyStatus(false)
  }

  )

},[])


 const empStatus =async() => {
const response = await axios.get(`https://queekdelever.com/queekApi/dutyStatus.php?empId=${props.emp[0].id}&status=${!duty}`)
if(response.status === 200)
{
  
}
 }

    const menuBar =() => {
props.open(true)
    }

    const Track =() => {
     if(props.awb)
     {props.back('LocalDel')
    props.pickId(props.awb)}
    }
  return (
  <>
  {/* top menu bar, notification, etc */}
 {props.type ==='login' && <div className="MapHead user-select-none mt-2">

      <div className="Bar1" onClick={menuBar}><FiMenu size="20" /></div>
      {/* recent activites */}
     {hReason && <div className={`notificationCon d-flex mt-2  ${props.awb ? 'd-block':'d-none'} `}>
        <div className={`   ${hReason !=='Today Pickup' ? 'ParcelBox1':'ParcelBox p-2' }`}> { hReason === 'bad weather' ? <TiWeatherShower size='40' color='white' /> : hReason !=='Today Pickup' ? <AiOutlineExclamation size='30' color='white' /> : <BsBoxSeam size='30' color='black' /> }</div>
        <div className="d-flex justify-content-between w-100 align-items-center">
        <div className="d-flex flex-column ps-3">
        <div className="Heading text-nowrap text-capitalize">{hReason}</div>
        <div className="small-ft text-nowrap">{sText}</div>
      </div>
      <div className=" cursor " onClick={Track} ><MdOutlineArrowForwardIos size='20' /></div>
      </div>
        
      </div>}

      <div className="Bell1"><BsFillBellFill size="20" />
      <div className="red-notificaton1"></div>
      </div>
        </div>}

     {props.type ==='Employee'  && props.emp && <div className={`MapHead user-select-none  ${!imgLoad ?'d-block':'d-none'} `}>   
     <div className="notificationCon1 d-flex   ">
        <div className="p-0"><img src={props.emp ?props.emp[0].img:""} alt='emp' className='empImg' onLoad={()=> {setImgLoad(false)}} /> </div>
        <div className="d-flex justify-content-between w-100 ">
        <div className="d-flex flex-column px-3 w-100">
        <div className="Heading  ">{props.emp ?props.emp[0].name :""}</div>
        <div className="small-ft text-muted">Emp Id# {props.id }</div>
      </div>
      {/* <div className="trc cursor">Track</div> */}
      <ToggleButton
  value={ duty || false }
//   onToggle={(value) => {
//     setDuty(!value)
//     props.dutyStatus(duty)
//     empStatus()
//   }
// }
     />
      </div>
        
      </div>
     </div>}

    {props.type ==='Employee' && <div className={`MapHead mt-1 ${imgLoad ? 'd-block':'d-none'}`}>
       <div className='notificationCon1 dashBoardLoader'></div>
       </div>}
       
        </>
  )
}

import React from 'react'
import { MdOutlineDeliveryDining } from "react-icons/md";

import './ReadytoShip.css'
export const ReadytoShip = (props) => {

  return (
   <div className= {`${props.total > 0 ? 'd-block' :'d-none' }`}>
      <div className='rightIcon position-absolute bottom-0 end-0 translate-middle-y mb-4 me-2 cursor'><MdOutlineDeliveryDining size="40" color="white" />
   <div className="badge rounded-pill bg-danger cursor ">{props.total}</div>
    </div>
   </div>
  
    )
}

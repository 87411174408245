import React from 'react'
import Navbar from './Navbar'
import './Local.css'
import localDel from './local.png'
import { Heading } from './Components/Heading'
import { Footer } from './Footer'
import { NormalButton } from './Components/NormalButton'
import { BsCheck2Circle } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';



export const Local = () => {
  const navigate = useNavigate();
  const login =() => {
    navigate('/login');
  }
  return (
    <div>
        <Navbar/>
        <div className='localBg pt-3  '>
        <div className='container  py-4'>
            <div className='row'>
                <div className='col-sm-8 d-flex flex-column justify-content-start'>
              <div className='text-left spaceH d-flex align-item-center' > <div className='highB '> </div > <div className='ps-2'><h2>Same City Same Day</h2></div> </div>
              <div className='pt-4  text-left  text-dark  mb-2 serTxt' >Low-cost & convenient deliveries, get Same day before 05:00 pm, 
              <div className='pt-3 highlight px-2 pb-2'>Best for Boutique, E-commerce Seller & Cloth Store</div>
               </div>

              <div className=' p-3'>

              <div className='d-flex py-2'><div className='pe-2'><BsCheck2Circle size='24' color='black' /></div> <div> Reliable Door Step pickup & Delivery </div> </div> 
              <div className='d-flex py-2'><div className='pe-2'><BsCheck2Circle size='24' color='black' /></div> <div> Real Time Tracking </div> </div> 
              <div className='d-flex py-2'><div className='pe-2'><BsCheck2Circle size='24' color='black' /></div> <div> On-Demand Packaging Solution</div> </div> 

              </div>
             
                <div className='btnShip mt-3 text-center ms-3 ' onClick={login} >Ship Now  </div>
                </div>


                <div className='col-sm-4 d-flex justify-content-end'>
                    <div className='d-flex justify-content-center'><img src={localDel} className='locSiz' /></div>
                </div>
            </div>

            </div>
        </div>
       <Footer /> 
    </div>
  )
}

import React, { useEffect } from "react";
import success from "./success.svg";
import { FaBox } from "react-icons/fa";
import { FcShipped } from "react-icons/fc";
import { NormalButton } from "../../Components/NormalButton";
import './paymentDone.css'
import { Label } from "../../Components/Label";
import fileDownload from 'js-file-download'
import axios from 'axios'
import { Link } from "react-router-dom";


export const PaymentOk = (props) => {



   
    const back =() => props.back('close')

    const automation= () => {
      window.open('/label/3o353o5')
      
    }
 
  return (
    <div className="p-3 mt-5">
      <div className="mt-4 d-flex justify-content-center mt-5">
        <img src={success} />
      </div>
      <div className="text-center mt-4 text-success">
        <h4>{props.payId ? 'Transaction Completed successfully!' :'Your Pickup Request has been received'}</h4>
      </div>
      {props.payId && <div className="text-center text-muted">
        <h6>your Transaction reference is {props.payId}</h6>
      </div>}

      <div className="mt-5">
        <div className="card p-3">
          <div>
            <div className="position-relative w-100">
              <div className="epl">Estimated Pickup Time</div>
              <div className="d-flex mt-2">
               
                <div className="ps-2 ept">04:30 pm - 07:30 pm</div>
              </div>
              <div className="rightPk"><FcShipped size="35" /></div>

            </div>
          </div>
        </div>
      </div>

     

      <div className="fixed-bottom " >
      <div className="d-flex n justify-content-center" onClick={automation}><div className="w-75"><button type="button" className="btn btn-primary  my-2 text-nowrap">Download Shipping Label</button></div></div>

       <div className="d-flex n justify-content-center" onClick={back}><div className="w-75"><button type="button" className="btn btn-outline-secondary  my-2 text-nowrap">Back to Home</button></div></div>
      </div>
    </div>
  );
};

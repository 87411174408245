import React from 'react'
import dhl from './network.png'

export const Booking = () => {
  return (
    <div>
        <div className='container mt-5'>
    <div className='row'>
        <div className='col-md-8'>
        <div><h2>Grow your business with World Wide Network </h2></div>
        </div>

        <div className='col-md-4'>
        <img src={dhl} className='img-fluid buisImg' />

        </div>
    </div>
        </div>
       
    </div>
  )
}

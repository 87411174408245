import React, { useEffect ,useState,useRef } from 'react'
import {useParams,useNavigate} from 'react-router-dom'
import { BsShieldFillCheck } from "react-icons/bs";
import { BsShieldFillExclamation } from "react-icons/bs";
import { BiRupee } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegDotCircle } from "react-icons/fa";
import { MdOutlinePinDrop } from "react-icons/md";
import './AcceptPickup.css'
import OtpInput from 'react-otp-input';
import Route from './Route.png'
import { TopHead } from '../../Components/TopHead';
import { Spinner } from '../../Components/Spinner';
import { IoIosCall } from "react-icons/io";



export const AcceptPickup = (props) => {
    const {id} = useParams()
    const {emp} = useParams()
    const [hide,setHide] = useState('')
    const stat = useRef(null)
    const [cOtp,setCOTP] = useState('')
    const [dld,setDld] = useState(false)
    const [OTP,setOtp] = useState('')
    const [otpErr,setOtpErr] = useState(false)
    const [Local,setLocal] = useState('')
    const [foodPk,setFoodPk] = useState(false)
    const [imgLoad,setImgLoad] = useState(true)
  const [load,setLoad] = useState(false)
    const [oDld,setODld] = useState(false)


    const FoodDeliver = (e) => {
      fetch(`https://queekdelever.com/queekApi/FoodQueekDel.php?pikId=${e}&boyId=${emp}`)
      .then(response => response.json())
      .then(data => {setLocal(data)
        setFoodPk(true)
        if(data[0].status ==='Delivered')
        setODld(true)
      })
    }

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
           };
      
        fetch(`https://www.queekdelever.com/queekApi/AcceptPickup.php?Id=${id}&storeId=${id}&empId=${emp}`,requestOptions)
        .then(response => response.json())
        .then(data => 
            {
                 if(data[0].status === 'Enter')
              {  setHide('assign')
             setTimeout(() =>{ FoodDeliver(id)},1000)
            }
                else
              { 
              if(data[0].status === emp)
             {
              FoodDeliver(id)}
              else
              setHide('not')
            }
                
            }
             )
    },[])

    const employee = [{id:emp }]

const submit =() => {

  if(stat.current.value === 'Out for pickup')
  window.open(`https://maps.google.com?q=${Local[0].fLat},${Local[0].fLng}`);

  if(stat.current.value === 'Out for delivery')
  window.open(`https://maps.google.com?q=${Local[0].dLat},${Local[0].dLng}`);

  if(stat.current.value ==='Delivered')
  {setDld(true)
  otp()
  }
  else
 { setDld(false)
  updateStatus()}

}

const whatsOTP =(otp) => {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' ,
    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3YWxsZXRJZCI6IjYyYWExMmZiMDA2NmQzN2M5OWIxNDMxNSIsInVzZXJJZCI6IjYyYWExMmZiMDA2NmQzN2M5OWIxNDMxNiIsImRldmljZUlkIjoiNjJiNDU5ODczZDhhMzEwYTRlYmE4NDUyIiwiaWF0IjoxNjU1OTg2Njg0LCJleHAiOjE4Nzc0Mjk4ODR9.CTTbtkuTLfOYWWXYVGfQMN0uEY_vb_ck0ivRFHzx_LU' ,
    'Access-Control-Allow-Origin':'origin-list',
    'Access-Control-Allow-Headers': '*'
  },
  body: JSON.stringify({
    "numbers": `91${Local[0].custId}`,
    "message": {
      "text": `${otp} is your delivery code, Share with agent ONLY when he is at your premises- Queek Delever`
    }
  })
  }
  
  fetch('https://api.whatsspot.in/api/message/fast/text', requestOptions )
  .then(response => response.json())
  .then(data => console.log(data))
  .catch(err => console.log('Error occur '+ err))
}




const otp =() => {
  let min = 1111;
  let max = 9999;
  let rand =(parseInt(min + (Math.random() * (max-min))))
 setOtp(rand)
  whatsOTP(rand)
}

const updateStatus =() => {
  const requestOptions = {
    method: 'POST',
   
};
fetch(`https://queekdelever.com/queekApi/FoodStatus.php?Id=${id}&status=${stat.current.value}&boyId=${emp}`,requestOptions)

}

const conOTP =() => {
  if(OTP ===  parseInt(cOtp))
 { setOtpErr(false)
  setODld(true)
updateStatus()
}
  
  else
  setOtpErr(true)
   
  }

  const handleChange =(otp) => {
    setCOTP(otp)
    }

  return (
    <div className='accHt position-relative' >

     {hide ==='assign'   && !foodPk && <div className='d-flex flex-column justify-content-center align-items-center h-100 '>
        <div><BsShieldFillCheck size='70' color='#008000' /></div>
        <div className='mt-3'>Your are Assign for Pickup</div>
        <div className="spinner-border mt-2" role="status">
</div>
      </div>}

     { hide ==='not' && !foodPk && <div className='d-flex flex-column justify-content-center align-items-center h-100'>
        <div><BsShieldFillExclamation size='70' color='#DC3545' /></div>
        <div className='mt-3'>Sorry, Already pickup boy Assign</div>
      </div>}


      {Local && Local[0].status ==='Delivered'  || oDld && <div className='d-flex flex-column justify-content-center align-items-center h-100 '>
        <div><BsShieldFillCheck size='70' color='#008000' /></div>
        <div className='mt-3'>Order Deliverd...!!!</div>
      </div>}


      {!Local && hide==='' && <Spinner /> }
    


      
{/* ******************************************Delivery App*********************************** */}
{Local && Local[0].status !=='Delivered' && <div className={`${ foodPk && !dld ? 'd-block' :'d-none'} px-2 `}>

{/* **********************************Emp Details****************************************************** */}
{foodPk && <div className={` MapHead1 user-select-none my-4 `}>   
     <div className="notificationCon1 d-flex   ">
        <div className="p-0"><img src={Local ?Local[0].img:""} alt='emp' className='empImg' onLoad={()=> {setImgLoad(false)}} /> </div>
        <div className="d-flex justify-content-between w-100 ">
        <div className="d-flex flex-column px-3 w-100">
        <div className="Heading  ">{Local ?Local[0].boyName :""}</div>
        <div className="small-ft text-muted">Emp Id# {emp }</div>
      </div>
      {/* <div className="trc cursor">Track</div> */}
    
      </div>
        
      </div>
     </div>}

     {/* **************************************************************************************** */}


{/* <div className='d-flex justify-content-between px-2 d-none mt-4'>
  <div>Priority Delivery</div>
  <button onClick={()=>setFoodPk(false)} type="button" className="btn-close" aria-label="Close"></button>
</div> */}

<div className='d-flex align-items-center justify-content-between  px-2  accTop'>
          <div className='d-flex '>  <img src={Route} alt="route"/>
         
            <div className='km pt-2 ps-2'>{Local && Local[0].km} Kms</div></div>
            <div className='d-flex align-items-center'><div className='mb-1'><BiRupee size="18" /></div>
            <div className='paise'>{Local && Local[0].payment}</div> 
            </div>
        </div>
        <hr className='divide1' />

        <div className='d-flex mt-2 align-items-center  px-2'>
<div className='pe-3 position-relative'><FaRegDotCircle size='23' color='#EB4335' />
<div className='tran'> < BsThreeDotsVertical size='30' color='gray' /></div>
</div>
<div className='pickupCard1 p-2 position-relative '>
<div className='floatLbl px-1 '>{Local && Local[0].fname}</div>
{/* <div className='ps-2 pikName mt-1'>{l.data[0].fadd}</div> */}
<div className='pikAdd ps-2 mt-1'>{Local && Local[0].fadd}</div>
</div>
</div>

<div className='d-flex mt-4 align-items-center pe-2'>
<div className='pe-3'><MdOutlinePinDrop size='29' color='#008000' /></div>
<div className='pickupCard1 p-2 position-relative'>
<div className='floatLbl px-1 '>{Local && Local[0].dname}</div>
{/* <div className='ps-2 pikName mt-1 text-dark'>{props.data[0].dname}</div> */}
<div className='pikAdd ps-2 mt-1 '>{Local && Local[0].dadd}</div>
<div className='position-absolute top-0 start-100 translate-middle  mt-4 pe-4'> <div className='pe-3 pb-2'><a href={`tel: ${Local && Local[0].custId}`} > <div className='call cursor '> <IoIosCall color='white'  /> </div></a></div> </div>

</div>
</div>

<div className='mt-2 pikAdd ms-5 rounded bg-warning ps-2 text-dark py-1'>Weight: 1kg | Qty: 2</div>

<div className='mt-3 ms-5 d-flex mb-2 align-items-center' >


<div >
<select ref={stat}  className="form-select" aria-label="Default select example">
  <option   defaultValue={'Out for Pickup'} value="Out for pickup">Out for Pickup</option>
  <option value="Out for delivery">Out for Delivery</option>
  <option value="Delivered">Successfully Delivered</option>
</select>
</div>

<div className='ms-2 px-2' onClick={submit}><button className='btn btn-warning btn-sm text-size' >Submit</button></div>
  </div>
  </div>}

{/* **************************************************************************************************8 */}


<div className= {`${dld ? 'd-block':'d-none' }`}  >
<div className='my-4 text-center otp'>Enter OTP </div>

<div className='d-flex h-75 justify-content-center '>
<div><OtpInput containerStyle ='' shouldAutoFocus={true}  numInputs={4} onChange={handleChange} value={cOtp} inputStyle='otpInput'   separator={<span>-</span>} /></div>
</div>
<div className={`text-danger text-center ${otpErr ? 'd-block' :'d-none' } `}>Wrong OTP</div>

<div className='mt-4 mb-2 ps-3'><button className='btn btn-warning' onClick={conOTP}>Submit</button></div>

    </div>

    </div>
  )
}

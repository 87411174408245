import React from 'react';
import { Heading } from '../../Components/Heading.jsx';
import birthday from './moneyBack.svg';
import { MdOutlineDeliveryDining } from "react-icons/md";
import './EmotionalBond.css'


import './EmotionalBond.css'
import { NormalButton } from '../../Components/NormalButton.jsx';

export const EmotionalBond = () => {
    return (
        <>
            <div className='pb-5'>  <Heading heading="Money Back Guarantee" /></div>

            <div className='container-fluid'>
                <div className='row'>
                <div className='col-xl-7'>
            <div className='d-flex flex-column justify-content-between mt-4  align-items-center ' >

                <img src={birthday} alt="Emotional Bond" className='img-fluid emotoional_back' />

            </div>
            <div className='container em-btn w-75'>
                <div className=' d-flex justify-content-center '>
                    <button type="button" className="btn btn-dark btn-size bgYellowBtn"><span className='em-font text-nowrap   '>Book Now </span></button>

                </div>
            </div>
            </div>
            {/* why you pay for late delivery parcel */}
            <div className='col-xl-5 '>
                <div className='h-100  d-flex flex-column align-items-center justify-content-center rounded  my-5  '>
            <div className='text-center  delCap'><h1>Why pay for <b className='text-danger'>Delay</b> Delivered Parcel ?</h1> </div>
            <div className='mt-4'>

               
                <ul>
                    <li>Book with us, Get Assured and safe delivery</li>
                    <li>Whatsapp Notification upon shipment Delivered</li>
                    <li>Free Parcel Packaging from  0.500gm to 100 kg</li>
                    <li>No hidden charges or requried minimum wallet balance</li>
                </ul>
                
                </div>
            </div>
            </div>
            </div>
            </div>
        </>
    )
};


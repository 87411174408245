import React,{useEffect,useState} from 'react';
import { EBooking } from './EBooking.jsx';
import { PickupReq } from './PickupReq.jsx';
import { Slider } from './Slider.jsx'
import { Tracking } from './Tracking.jsx';
import ebooking from './ebooking.svg';
import doorStep from './doorStep.svg';
import safeDelivered from './safeDelivered.svg'
import './EBooking.css'
import { SmartSolution } from './SmartSolution.jsx';
import { Footer } from '../../Footer';
import {EmotionalBond} from './EmotionalBond.jsx';
import { About } from './About.jsx';
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { BiArrowBack } from "react-icons/bi";

 import whatsapp from './whatsapp.png'
 import FloatingWhatsApp from 'react-floating-whatsapp'

import './Home.css';
import { OurImpact } from './OurImpact.jsx';
import { SmallTrack } from './SmallTrack.jsx';
import Navbar from '../../Navbar.jsx';
import { InternationalPartner } from './InternationalPartner.js';
import { useNavigate } from 'react-router-dom';
import { QueekService } from '../../Components/QueekService.js';
import { Packaging } from '../../Components/Packaging.js';
import { Network } from '../../Components/Network.js';
import { Helmet } from "react-helmet";
import { Strength } from './Strength.js';
import { Booking } from './Booking.js';
import { NormalButton } from '../../Components/NormalButton.jsx';


const Home = () => {

    const navigate = useNavigate();
  const login =() => {
    navigate('/login');
  }

  const [hide,setHide] = useState(false)

  const chat =() => {
    window.open("https://wa.me/917814002400?text=I'm%20interested%20in%20Parcel%20Booking")
  }
    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://use.typekit.net/foobar.js";
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);
    return (
        
        
        <div className='position-relative '>
             {/* <Helmet>
                <meta charSet="utf-8" />
                <title>Queek Delever</title>
                <link rel="canonical" href="http://mysite.com/example" />

                <script>
                    {`
                     if ('serviceWorker' in navigator) {
                        navigator.serviceWorker.getRegistrations().then(function (registrations) {
                          for (const registration of registrations) {
                            // unregister service worker
                            console.log('serviceWorker unregistered');
                            registration.unregister();
                          }
                        });
                      }`}
                </script>
            </Helmet> */}
       {/* <Helmet> <script disable-devtool-auto src='https://cdn.jsdelivr.net/npm/disable-devtool@latest/disable-devtool.min.js'></script></Helmet> */}
        <div className={`homeBack vh-100 scroll-hidden ${hide ? 'd-none':'d-block'} `}>
         <Navbar/>
        
     <div className='container-fluid' >  
        <div className='container  ' style={{ marginTop: 50, }}>
          {/* pickup and slider */}
         
       

<div className='row justify-content-center '>
    <div className='col-md-auto' >
    <PickupReq />
    {/* <div className='head3'>Smart Delivery</div> */}
    {/* <div className='smallHead d-none mt-3'>Fed up of waiting for your delivery boy? </div> */}
    {/* <div className='smallHead1 mt-5'>Get Fast Parcel Delivery without waiting of delivery boy. We are a safe and reliable service that offers cost-effective delivery without the worry of fake delivery.</div> */}
    {/* <div className='smallHead1 mt-4'>Get in touch with us today to get your parcel delivered at the earliest.</div> */}

        {/* <div className=' d-flex justify-content-center align-items-center bookBtn cursor' onClick={login} ><div className=''>Book Now</div><div className='ps-3'><BsChevronRight /></div></div> */}
</div>
<div className='col'>
    <Slider />
</div>
</div>

{/* Tracking  */}
</div>
</div>  
 {/* network */}
 <div className='mt-3 d-none'>
    {/* <Network/> */}
 </div>

<div className='container-fluid d-flex justify-content-center  mt-5 px-3 d-none'>
    {/* <Tracking /> */}
{/* <QueekService /> */}
</div>


{/* your click we deliver */}



{/* e-booking */}
<div className='e-space d-none'>
<div className='d-flex justify-content-between align-items-center' >
    <div className='col-4' ><img className='img-fluid banner' src={ebooking} alt="aborad" /></div>
    <div className='col-8 p-3'><EBooking name="E-Booking" desc="One Plateform for all your shipping needs, Local, Domestic, International." /></div>
</div>

<div className='e-margin'>
<div className='d-flex justify-content-between align-items-center' >
<div className='col-8 p-3'><EBooking name="Door Step Pickup" desc="One Plateform for all your shipping needs,Local, Domestic, International." /></div>
    <div className='col-4' ><img className='img-fluid banner cust-size' src={doorStep} alt="aborad" /></div>

</div>
</div>

<div className='e-margin'>
<div className='d-flex justify-content-between align-items-center' >
    <div className='col-4' ><img className='img-fluid banner cust-size' src={safeDelivered} alt="aborad" /></div>
    <div className='col-8 p-3'><EBooking name="Safe Delivery" desc="One Plateform for all your shipping needs, Local, Domestic, International." /></div>
</div>
</div>
</div>

{/* smart packaging */}
<div>
    {/* <Packaging/> */}
</div>

{/* outr smart solution */}
<div className='container-fluid moneyBack d-none' >
<SmartSolution/>
</div>

{/* emotional bond */}
<div className='container mb-5 mt-3 d-none'>
<EmotionalBond />
</div>

 {/* about us */}

 <div className=' text-center about-size mt-5 moneyBack mt-3 d-none'>
     <About />
 </div>

 {/* International channel partner */}
 {/* <div>
<InternationalPartner/>
</div> */}


 {/* Impact  */}

 <div className='mt-3 d-none'>
     <OurImpact />
 </div>

    {/* strength & technology */}
    {/* <div className='BusinB mt-3 d-done'>
     <Strength />
 </div> */}

    {/* network and partner */}

    <div className=' mt-3 d-none'>
        <Booking />
 </div>

{/* bottom tracking fixed */}

<div className="fixed-bottom small-trc  d-none" >
 
<SmallTrack/>

</div>

</div>

 
{/* footer  */}

<div className='position-relative d-none'>
<Footer/>
{/* <div className='d-flex justify-content-end  fixed-bottom bg-info  pb-3 '><div className='pe-2'  onClick={chat}  > <img src={whatsapp}  className='whastImg' /></div> </div> */}

</div>

{/* <FloatingWhatsApp phoneNumber={'917814002400'} accountName ={'Queek Delever'} statusMessage={'You click, we Deliver'} notificationSound={true} /> */}

<div className={`container-fluid vh-100 p-0 m-0 ${hide ?'d-block':'d-none'} `}>
<div className='row vh-100 m-0 p-0 '>
<div className='col-4 bgGray'></div>
<div className='col-4 m-0 p-0'>
  <div className=' topH m-0 p-2 '>
    <div><BiArrowBack size='24' /> </div>
    <div className='pt-3 d-flex justify-content-between w-100 '> 
    <div>Ludhiana</div>
     <div className='px-2 d-flex justify-content-center'><BsFillArrowRightCircleFill size='25' /></div>
    <div className='d-flex justify-cotent-end'>Delhi</div> 
     </div>
    <div className='mt-4'>
    </div>
  </div>

  <div class="form-floating mb-3 mt-3 mx-3">
  <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" />
  <label for="floatingInput">Contact person name</label>
</div> 

<div class="form-floating mb-3 mt-3 mx-3">
  <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" />
  <label for="floatingInput">Mobile Number</label>
</div> 

<NormalButton name='Sent Request' />

</div>
<div className='col-4 bgGray'></div>
</div>
</div>

</div>

        

    )
}

export default Home;


import React,{useState,useRef,useEffect} from 'react'
import { BsChevronLeft } from "react-icons/bs";
import { BsShop } from "react-icons/bs";
import { FaRegDotCircle } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlinePinDrop } from "react-icons/md";
import './FoodStore.css'
import axios from "axios";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Drawer from "react-bottom-drawer";
import { Spinner } from '../../Components/Spinner';



export const FoodStore = (props) => {
 const [isType,setIsType] = useState()
 const [Load,setLoad] = useState()
 const [des,setDesc] = useState()
 const [hide,setHide] = useState(false)
 const store = useRef(null)
const [storeId,setStoreId] = useState()
 const [Id,setId] = useState()
 const recId = useRef(null)
 const [storeData,setStoreData] = useState()
 const[isVisible,setIsVisible] = useState(false)
const [storeName,setStoreName] = useState()
const [storeLoc,setStoreLoc] = useState()

 let a=0;
 
 const pickupReq =async() => {
const response = await axios.post(`https://www.queekdelever.com/queekApi/foodDellivery.php?custID=${Id}&storeId=${storeId}`)
window.open('https://www.google.com') 
}

 const loading =(e) => {
    setLoad(e)
   }

    const Store =(e) => {
setIsType(e.target.value)

    }
    const desc =(e) => {
       setDesc(e)
       setLoadHide(false)
 setHide(false)
 store.current.value =''
 }
         
    const back=(e) => props.back(e)


      
         const [loadHide,setLoadHide] = useState(false)
         const wait =(e) => {setLoadHide(e) }

const sendLink =() => {
if(!storeName)
return alert('Please select store name')

if(recId.current.value.length !== 10)
 return alert('Please enter correct mobile number')

 pickupReq()
}
const fetch =async() => {
  const response = await axios.get(`https://easy-ship.in/StoreLocation.php?empId=${props.empId}`)
  if(response.data)
  {
    setStoreData(response.data)
    console.log(response.data)
  }
}

useEffect(() => {

if(!storeData)
fetch()
})


  return (
<div className='h-100 position-relative text-select-none'>
<div className={`${!hide ? 'd-block' :'d-none'}`}>
<div className='tops d-flex justify-content-start ' >
            <span className='px-2'><BsChevronLeft size='22' color='black ' onClick={()=> {back('close')}} /></span>
            <span className=''>Food delivery</span>
        </div>


<div className='px-3 mt-5' onClick={()=> setIsVisible(true)}>
<div className='d-flex mt-2 align-items-center'>
<div className='pe-4 position-relative'><FaRegDotCircle size='23' color='#EB4335' />
<div className='tran'> < BsThreeDotsVertical size='30' color='gray' /></div></div>
<div className='pickupCard p-2 position-relative '>
<div className='floatLbl px-1 text-muted'>Store name</div>
{!storeName && !storeLoc && <div className='ps-2 pikName mt-1 text-muted py-2 fs-6'> Search store name...</div>}
{storeName && storeLoc && <div><div className='ps-2 pikName mt-1'>{storeName && storeName}</div>
<div className='pikAdd ps-2'>{storeLoc && storeLoc}</div></div>}
</div>
</div>
</div>

<div className='d-flex mt-4 align-items-center px-3 '>
<div className='pe-3'><MdOutlinePinDrop size='29' color='#008000' /></div>
<div className='pickupCard p-2 py-3 position-relative '>
<div className='floatLbl px-1  text-muted'>Customer Id </div>
{/* <div className='ps-2 pikName mt-1 text-dark'>Dinesh Sharma</div> */}
<div className='ps-2 d-flex '><div className='pe-2'>+91</div><div className='w-100'><input className='inputTxt text-dark' placeholder='Customer mobile number' onChange={(e) =>setId(e.target.value)} ref={recId} /></div></div>
{/* <div className='pikAdd ps-2'>Opp.Kutia sahib gurudwara,chandigarh road</div> */}
</div>
</div>

<div className='mt-4 d-flex justify-content-center w-100' onClick={() => {sendLink()}}><button className='btn btn-warning'>Send Pickup Link </button></div>
</div>


<div className={`${!hide ?'d-none':'d-block'}`}>
<div className='searchBar pe-2'>
    <div className='px-2 my-2' onClick={() => {setHide(false)}}><BsChevronLeft size='20'/> </div>
    <div className='w-100 ps-2'><input className='inputTxt' placeholder='Search store name....' onChange={Store} ref={store}  /></div>
    <div className={`${Load === 'done'? "d-block" : "d-none"}`}> <AiOutlineCloseCircle /></div>
                <div className={`spinner-border text-dark  spinner-border-sm   ${Load ==='loading' ? "d-block": "d-none"}  `} role="status"></div>
           
</div>

<div><hr className='divide1 m-0 p-0' /></div>
<div className='px-3 mt-2'>

</div>
</div>

{ loadHide && <Spinner />}


<Drawer isVisible={isVisible} >
<div className='d-flex justify-content-between px-3 mb-3'>
  <div>Select Store for pickup</div>
<div onClick={() => setIsVisible(false)}><button type="button" className="btn-close" aria-label="Close"></button></div>
</div>

{storeData && storeData.map((x) => <div key={a=a+1}  onClick ={() => {
  setStoreId(x.storeId)
  setStoreName(x.name)
  setStoreLoc(x.add)
  setIsVisible(false)
  }} >
  <div className='px-2  d-flex align-items-center user-select-none'>
<div className='roundCir p-2'><BsShop  size='20' color='black' /></div>
<div className='d-flex flex-column px-4'>
  <div className='title'>{x.name}</div>
  <div className='addDetail'>{x.add}</div>
</div>

</div>  
<div><hr className='divide1' /></div>
</div>
)}

</Drawer>

    </div>
  )
}

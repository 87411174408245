import React, { useEffect, useRef, useState } from 'react'
import { BsChevronLeft } from "react-icons/bs";
import './TrackEmp.css'
import { FaBoxOpen } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import { BsBoxSeam} from "react-icons/bs";
import { MdArrowForwardIos} from "react-icons/md";
import { BsCheck2All} from "react-icons/bs";
import { BsWhatsapp} from "react-icons/bs";
import { FaRegDotCircle } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";
import { Spinner } from '../../Components/Spinner';
import { BottomMsg } from '../../Components/BottomMsg';
import { MdOutlineArrowForwardIos} from "react-icons/md";



export const TrackEmp = (props) => {
  const [load,setLoad] = useState(false)
  const [hide,setHide] = useState(false)
  const [data,setData] = useState(null)
  const [awb,setAwb] = useState('')
  const [trackData,setTrackData] = useState('')
  const trc = useRef(null)
  const [err,setErr] = useState(false)
  const [hideCap,setHideCap] = useState(false)
  const receiver =useRef(null)
  const fwd = useRef(null)
  const sender = useRef(null)
  const [pending,setPending] = useState(false)
  const [caption,setCaption] = useState('')
  const [invalid,setInvalid] = useState(false)
  const [errCap,setErrCap] = useState('')
  const [track,setTrack] = useState('')
  const [ndr,setNdr] = useState('')
  let a=0;
  let b=0;
  const back =() => {
    props.back('close')
  }

  const Track =(e) => {
    setErr(false)
    setHide(false)
    setLoad(true)
    setInvalid(false)

    fetch(`https://queekdelever.com/queekApi/TrackInfo.php?awb=${e}`)
    .then(res => res.json())
    .then(data => {
      if(data.length > 0)
      {setTrackData(data)
      tarckDetail(e)}
      else
      {
        setInvalid(true)
        setHide(false)
        setLoad(false)}
        setErrCap('Sorry, Shipment is not found')
    })
  }

  const tarckDetail =(e) => {
    fetch(`https://www.queekdelever.com/queekApi/TrackingDetails.php?awb=${e}`)
    .then(res => res.json())
    .then(data => {
      setData(data)
      setPending(false)
      setHideCap(false)
      setHide(true)
      setLoad(false)

    })
  }

  const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    maximumSignificantDigits: 5 
  }).format(value);

  useEffect(() => {
    setLoad(true)
    // fetch(`https://queekdelever.com/queekApi/SingleTracking.php?empId=${props.empId}`)
    fetch(`https://queekdelever.com/queekApi/NDR.php?empId=${props.empId}`)
    .then(res => res.json())
    .then(data => {
      setNdr(data)
      setLoad(false)
    } )
  },[])


  const trcCopyFwd =() => {
    setHideCap(false)
    setTimeout(() =>{setHideCap(true)},200 )
    copy(fwd.current.innerText )
    // setHideCap(true)
 }

 const trcCopySender =() => {
  setHideCap(false)
  setTimeout(() =>{setHideCap(true)},200 )
  copy(sender.current.innerText )
  // setHideCap(true)
}

  const trcCopy =() => {
    setHideCap(false)
    setTimeout(() =>{setHideCap(true)},200 )
    copy(receiver.current.innerText )
    // setHideCap(true)
 }
  const copy = require('clipboard-copy')



  const whatsMsg = async() => {
    

     window.open(`whatsapp://send?text=https://queekdelever.com/tracking/${trackData && trackData[0].awb}`)
  }

  const fetchTrack =(type) => {
    setTrack('')
    setLoad(true)
    fetch(`https://queekdelever.com/queekApi/SingleTracking.php?empId=${props.empId}&type=${type}`)
    .then(res => res.json())
    .then(data => {
       setTrack(data)
       setLoad(false)
      })
  }
  const trackErr = () => {
    setErrCap('Enter Valid Tracking Id') 
    setInvalid(true)
    setTimeout(() => {setInvalid(false)},1000)
  }

  return (
    <>
    {/* track a courier  */}
    <div className={` vh-100 bgYel  ${hide && !load ? 'd-none' :'d-block'} user-select-none  ${pending ? 'd-none':'d-block'} `}>
    <div className='d-flex mx-auto justify-content-start align-items-center trcBnd mt-2'>
                    <div onClick={back}><BsChevronLeft size="18" /> </div>
                   <div className='ps-3 w-100'><input type='number' ref={trc} className='inputTxt ' placeholder='Search by Tracking Number' />
</div>
<div className='ps-2 pe-1' onClick={() =>  {trc.current.value.length !== 10 ? trackErr() : Track(trc.current.value)}}><BsSearch  /></div>
                     </div>
      <div className='position-relative '>
               
            

                <div className='TracCon'>
                  <div className='d-flex justify-content-center py-2'><FaBoxOpen size='80' /></div>
                  <div className='py-3 text-center'><h4>Track your parcel</h4></div>
                  <div className='text-center px-5'><p>Enter your Tracking number for search your parcel</p></div>
                  <div className='pb-3' >
                  
                      {/* {err && <div className='TrcEr ps-5 mt-1'>Enter 10 digit Tracking Number</div>} */}
                  </div>
                </div>
              
              {ndr && <div>
              <div className='lblCon mx-2 mt-5' 
               onClick={() =>{ 
                setPending(true)
                setCaption('Label Created Shipment')
                fetchTrack('0')
                }}  >
              <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex align-items-center'>
               <div className='lblRnd'><div>{ndr && ndr[0].lbl}</div></div>
               <div className='ps-3'>Label Created</div>
               </div>
               <div> <MdOutlineArrowForwardIos size='25' /></div>
              </div>
                </div>

                <div className='lblCon mx-2 mt-3' onClick={() =>{ 
                  setPending(true)
                  setCaption('In Transit Shipment')
                  fetchTrack('1')
                  }}  >
              <div className='d-flex justify-content-between align-items-center' >
              <div className='d-flex align-items-center'>
               <div className='lblRnd bg-primary text-white'><div>{ndr && ndr[0].trans}</div></div>
               <div className='ps-3'>In-Transit</div>
               </div>
               <div> <MdOutlineArrowForwardIos size='25' /></div>
              </div>
                </div>

                <div className='lblCon mx-2 mt-3' 
                 onClick={() =>{ 
                  setPending(true)
                  setCaption('Exception Shipment')
                  fetchTrack('2')
                  }}  >
              <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex align-items-center'>
               <div className='lblRnd bg-danger text-white'><div>{ndr && ndr[0].fail}</div></div>
               <div className='ps-3 '>Exception</div>
               </div>
               <div> <MdOutlineArrowForwardIos size='25' /></div>
              </div>
                </div>
                </div>}
    

               
                </div>
   {invalid && <div><BottomMsg name ={errCap} /></div>}

           { load &&     <Spinner />}
    </div>
    {/* *********************************************************************************** */}
    {/* Tracking status  */}
    <div className={`${hide ? 'd-block' :'d-none'} user-select-none`}>
    <div className={`d-flex justify-content-between parcelHeader align-items-center fixed-top  `}><div className="px-2 pe-4 back" onClick={() => {
      setHide(false)
      setHideCap(false)
      }}><BsChevronLeft size="18" /> #{trackData && trackData[0].awb} <span className='ps-2'></span> </div> <div className='pe-2' onClick={() => whatsMsg()}><BsWhatsapp size='20' /> </div></div>
      
        <div className='pt-3'  ref={sender} >
      <div className='card mx-2 mt-5'>
      <div className="card-header">
      Consignor
  </div>
   
      <div className='p-2'><div className='toName'>{trackData && trackData[0].fName}</div>
      <div className='invAds'>{trackData && trackData[0].fAdd}</div>
      <div className='invAds d-flex justify-content-between'><div>+91 {trackData && trackData[0].mob}</div> <div onClick={() => trcCopySender() }><MdContentCopy size='20' /></div> </div>
      </div>
      </div>
      </div>

      <div className='card m-2' ref={receiver}>
        
        
        <div className='card-header'>
        Consignee
        </div>
      
      <div   className='p-2'><div className='toName'>{trackData && trackData[0].tName}</div>
      <div className='twoLine delADS pe-2'>{trackData && trackData[0].tAdd1}</div>
      {/* <div className='invAds text-nowrap InvFAdd'>{trackData && trackData[0].tAdd2}</div> */}
      <div className='invCnt'>{trackData && trackData[0].tAdd2}</div>
      <div className='invAds d-flex justify-content-between'><div>+{trackData && trackData[0].cntCode} {trackData && trackData[0]?.receiver}</div> <div className='' onClick={() => trcCopy()}><MdContentCopy size='20' /></div> </div>
                    

      </div>
      </div>

      <div className='m-2 card'>
      <div className='card-header'>
      Shipment Details
      </div>
      <div className='p-2'>
        <div className='d-flex justify-content-between'>
          <div className='invAds'>Network: {trackData && trackData[0].mode}</div>
          <div className='fName'onClick={() => trcCopyFwd()} ref={fwd}> {trackData && trackData[0].fwdNo} </div>
        </div>

        <div className='d-flex justify-content-between'>
          <div className='invAds mt-1'>Content: {trackData && trackData[0].content}</div>
          <div>Dec.Value :<span className='ps-1'>{numberFormat(trackData && trackData[0].decValue)}</span> </div>
        </div>
        <div className='d-flex justify-content-between'>
          <div className='invAds ShipChg px-1 '>Shipping Charges:  {numberFormat(trackData && trackData[0].charges)}  </div>
          <div className='invAds'>Type : <span className='toName'>{trackData && trackData[0].type}</span></div>
        </div>

      </div>
      </div>

      <div className='text-center mt-3'>Tracking History</div>

      {/* ************** tracking history **************** */}
      <div className='d-flex flex-column justify-content-center w-100 mb-3' >
{data && data.map((x)=>
<div   key={b=b+1} className={`px-2  ${b >0 ? 'd-block' :'d-none'} `} >

<div className='d-flex align-items-center'>
    {/* ****************Date & time ************** */}
    <div className='d-flex flex-column text-end mt-3 mb-2 dateWidth'>
        <div className='dateD'>{x.date}</div>
        <div className='time d-flex justify-content-end'>{x.time}</div>
    </div>

    {/* *************************************************** */}
    
<div className='d-flex flex-column justify-content-center align-items-center position-relative  '>
<div className='px-3 zIndex'><FaRegDotCircle size='20' color={b===1 ? '#008000' :'gray'}  /></div>
<div className={`TranLine1 ${data && data.length-1 === b ?'d-none' :'d-block'} `}  ></div>

</div>

<div className='d-flex flex-column mt-2 w-75 '>
    <div className='fName twoLine '>{x.status}</div>
    <div className='rAdd'>{x.loc}</div>
</div>

</div>


</div>
)}</div>
      {/* ************************************************* */}
      {hideCap && <div><BottomMsg name ='Copied...' /></div>}

    { load && <Spinner/>}
    </div>
  {/* ********************************************************************* */}
{/* tracking details  */}
    <div className={`${pending ?'d-block':'d-none'}`} >
    <div className={`position-relative d-flex justify-content-between parcelHeader align-items-center fixed-top `}><div className="px-2 pe-4 back" onClick={() => {setPending(false)}}><BsChevronLeft size="18" />  <span className='ps-2'>{caption}</span> </div> <div className='pe-2' > </div></div>

{track && track.length ===0 &&    <div className='position-absolute start-0 top-50 w-100'><div className='text-center'>No shipment found</div></div>
}
    {track.length > 0 && <div className='mx-3  trackHis  bg-white '>
                {/* <div className=' ps-3 pt-3 '>Your Recent Parcel</div> */}
              
                <div className='mt-2 pb-3' >
    <div className='scrollable-div '>

               { track && track.map((x) =>  <div key={a=a+1} className='my-3' onClick={() => {
               setAwb(x.awb)
                      Track(x.awb)
                    
                    }}> 
                   {track && x.date && <div className='text-center check-add text-muted'>{x.date}</div>}
                  <div className='d-flex px-3 justify-content-between'>
                    <div className='d-flex'>
                    <div className='parSta d-flex justify-content-center align-item-center'> { x.status === 'Delivered' ? <span><BsCheck2All size='25' color='#008000' /></span> : <span><BsBoxSeam size='22' /></span>}</div>
                    <div className='d-flex flex-column px-3'>
                      <div className='trackID'>{x.awb}</div>
                      <div className='statusPAr'>{x.status}</div>
                    </div>
                    </div>
                    <div className='' >  <MdArrowForwardIos size='25' /></div>
                  </div>
                 
                 { a+1 !== x.sr && <div><hr className='divide my-2' /></div>}
                </div> )}
                </div>
                </div>
                </div>}
                { load && <Spinner/>}

</div>
    </>
  )
}
